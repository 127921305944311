<nav class="sb-topnav navbar navbar-dark header-bg navbar-expand-sm">
  <div class="col-4 col-sm-3 col-md-5 col-lg-4 col-xl-3">
    <img class="img-fluid header-logo" src="../../../assets/img/sa800-logo.ico" />
    <a class="navbar-brand text-white">
      SA800 Partnership
    </a>
    <button class="menu-toggle btn btn-link btn-sm order-1 order-lg-0" type="button" id="sidebarToggle" (click)="toggleSidebar()" style="font-size: 18px; margin-top: 0; margin-left: -28px; border-radius: 0px; display: inline-block; "></button>
  </div>
  <div class="col-md-4 col-lg-5 col-xl-4 col-6 text-center text-lg-right">
    <form [formGroup]="time" class="d-inline-block form-inline ml-auto" *ngIf="this.usersService.Current_taxyear > '0'">
      <div class="form-group row mb-0">
        <label class="col-md-5 col-5  yearlabel" style="font-size: 18px; color: white; white-space: pre;">Tax year</label>
        <div class="col-md-6 col-7">
          <select class="form-select w-auto" formControlName="Years" (change)="changeYears($event.target.value)">
            <option *ngFor="let taxyears of response1" value="{{taxyears.taxyear}}">{{taxyears.taxyear}}</option>
          </select>
        </div>
      </div>
    </form>
  </div>
  <div class="col-md-3 col-lg-3 col-xl-5 col-2 text-right">
    <!--<div class="d-flex">-->
    <div class="text-right HeaderName client-name d-inline-block"> {{TaxpayerName_obj }} </div>
    <ul class="navbar-nav d-inline-block">

      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" id="userDropdown" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-user fa-fw text-white"></i></a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
          <span class=" client-name-mobile fw-bold dropdown-item border-bottom"> {{TaxpayerName_obj}} </span>
          <a class="dropdown-item border-bottom" (click)="QuickStart()"><i><img src="../../../assets/sa100-icon/Quick-Start-icon.png" /></i> Quick Start</a>
          <a class="dropdown-item border-bottom" (click)="getModule()"><i><img src="../../../assets/sa100-icon/Status-icon.gif" /></i> Status</a>
          <a class="dropdown-item border-bottom" (click)="aboutPopup()"><i><img src="../../../assets/sa100-icon/License-d-icon.ICO" /></i> Licence Details</a>
          <a class="dropdown-item border-bottom" (click)="HelpFile()"><i><img src="../../../assets/sa100-icon/HorizontalToolbar_image028.jpg" /></i> Help Topics</a>
          <a class="dropdown-item" (click)="logout()"><i class="nav-link-icon"><img src="../../../assets/sa100-icon/Logout-icon.png" /></i> Logout</a>
        </div>
      </li>
    </ul>
    
    <!-- </div> -->
  </div>
</nav>


<div class="modal fade" id="sa800License" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg  modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="exampleModalLabel">  <span class="sa800-icon"><img src="assets\img\sa800-logo.ico" width="32px" /></span>  Andica SA800 Partnership</h6>
        <button type="button" class="btn-close p-0" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class=" col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-0 text-center left-img-responsive" style="background-color: #ec720e; margin: 0px 0 0 0px;">
            <img src="../assets/img/andica-sa800-license-image.png" alt="Andica Self Assessment Software" class="img-fluid" style="height: 100%;" />
          </div>
          <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12" style="background-color: #f0f0f0; margin: 0px 0 0 0px;">
            <div class="row">
              <h5 class="text-black pt-3">Andica SA800 Partnership</h5>
            </div>
            <div class="row">
              <div class="col-lg-12 pb-3">
                <span style="font-weight: 600;">This product is licenced to: </span><br />
                <span style="font-weight: 600;">Name: </span> <span>{{MasterLicenName}}</span><br />
                <!--<span style="font-weight: 600;">Name: </span> <span>MR.Sam</span><br />-->

                <span style="font-weight: 600;">Email: </span> <span>{{MasterUserName}}</span><br />
                <!--<span style="font-weight: 600;">Email: </span> <span>Sam.actsoft@acuitysoft.com</span><br />-->

                <hr />
                <span style="font-weight: 600;">Software is licenced for {{limitAdd_taxper}} Partnership record(s).</span><br />
                <span style="font-weight: 600;">Number of Users: </span> <span>Software is licenced for {{Licenceusers}} user record(s).</span><br />
                <span style="font-weight: 600;">Tax Return Year: </span> <span>{{this.usersService.Currenttax_shortyear}}</span><br />
                <!--<span style="font-weight: 600;">Tax Return Year: </span> <span>2020-21</span><br />-->

                <span style="font-weight: 600;">Purchased Date: </span> <span>{{MasterPurchasedDate | date:'dd/MM/yyyy'}}</span><br />
                <!--<span style="font-weight: 600;">Purchased Date: </span> <span>October15 2020</span><br />-->

                <span style="font-weight: 600;">Expiry Date: </span> <span>{{MasterExpiryDate | date:'dd/MM/yyyy'}}</span><br />

                <!--<span style="font-weight: 600;">Expiry Date: </span> <span>October14 2021</span><br />-->
                <!--<span style="font-weight: 600;">Number of Bandwidth: </span> <span>Software is licenced for 5 record(s).</span><br />-->
                <span style="font-weight: 600;">Partnership Status: </span> <span>{{TableRecordCnt}} out of {{limitAdd_taxper}} record(s) has been created.</span><br />
                <span style="font-weight: 600;">Users Status: </span> <span>{{Number_usercreated }} out of {{Licenceusers}} user(s) has been created.</span><br />
                <span style="font-weight: 600;">Submission Status: </span> <span> Live submission for {{Livesubmission}} out of {{limitAdd_taxper}} return(s) has been submitted successfully.</span><br />
                <!--<span style="font-weight: 600;">Submission Status: </span> <span> Live submission for 5 out of 5 return(s) has been submitted successfully.</span><br />-->

                <hr />
                <b class="color-red">Warning: </b> <span>This Computer program is protected by copyright law and international treaties.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <!--<button type="button" class="btn btn-success" *ngIf="ShowUpgrade" (click)="Upgrade('Upgrade')">Upgrade</button>-->
        <button type="button" class="btn btn-success" *ngIf="Showupgrade" (click)="Upgrade('Upgrade')">Upgrade</button>
        <button type="button" class="btn btn-primary" *ngIf="ShowRenew" (click)="Upgrade('Renew')">Renew</button>
        <button type="button" class="btn btn-danger" (click)="Aboutcancel()" data-toggle="collapse" data-target="#FormName" data-dismiss="modal" aria-label="Close">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade mt-5" id="Importdatamodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" data-keyboard="false" data-backdrop="static" aria-hidden="true">
  <div class="modal-dialog">
    <form id="popup1" name="popup1" [formGroup]="popup1" novalidate autocomplete="off">
      <div class="modal-content">
        <div class="modal-header">
          <a class="navbar-brand" style=" font-size:16px;"><img src="../../../assets/img/sa800-logo.ico" alt="logo" width="32px" class="me-2" />Andica SA800 Partnership Software</a>
          <button type="button" class="close p-0" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="border p-3">
            <div class="form-group row" id="selected_file">
              <label class=" col-lg-10 col-sm-12 col-xs-12">
                Do you want to enable supplementary form(s) as per previous year for selected Partnership(s)?
              </label>
              <div class="col-lg-2 col-sm-12 col-xs-12 mb-3">
                <label class="checkbox">
                  <input type="checkbox" (change)="Onlyforms($event)" formControlName="Onlyforms" />
                  <span class="default"></span>
                </label>
              </div>
              <label class="col-lg-12 col-sm-12 col-xs-12">
                (Supplementary forms selected in previous year will be enabled without data and values)
              </label>
            </div>
            <div class="form-group row" id="selected_file">
              <label class=" col-lg-10 col-sm-12 col-xs-12">
                Do you also want to import data and values for the selected form(s) as per previous year?
              </label>
              <div class="col-lg-2 col-sm-12 col-xs-12 mb-3">
                <label class="checkbox">
                  <input type="checkbox" formControlName="Alldata" />
                  <span class="default"></span>
                </label>
              </div>
              <label class="col-lg-10 col-sm-12 col-xs-12">
                (Most data and values from last year's returns will be imported. It is your responsibility to ensure accuracy of the returns)
              </label>
            </div>
            <div *ngIf="this.usersService.windowsimportbtn">
              <div class="form-group row">
                <label class=" col-lg-2 col-sm-12 col-xs-12">
                  <b>Select File:</b>
                </label>
                <div class="col-lg-10 col-sm-12 col-xs-12">
                  <!--<input class="form-control form-control-file" type="file" placeholder="">-->
                  <!--<div class="custom-file">
                    <input type="file" class="custom-file-input ">
                    <input id="file-input" #Image type="file" class="custom-file-input " (change)="handleFileInput($event.target.files)">
                    <label class="custom-file-label" for="customFile">Choose file...</label>
                  </div>-->
                  <input id="file-input inputFile" class="Attachment_inputFile " type="file" (change)="handleFileInput($event)" value="" formControlName="inputFile" size="10" accept=".xml" name="inputFile" />
                </div>

              </div>
              <!--<div class="form-group row">
                <label class=" col-lg-4 col-sm-12 col-xs-12">
                  <b>Simple Automation XML file:</b>
                </label>
                <div class="col-lg-8 col-sm-12 col-xs-12">
                  <input id="file-input inputFile" class="Attachment_inputFile " type="file" (change)="wizardhandleFileInput($event)" value="" formControlName="wizardinputFile" size="10" accept=".xml" name="inputFile" />
                </div>
              </div>-->
            </div>
          </div>
        </div>
        <div class="card-footer d-flex align-items-center justify-content-end">
          <!--<input id="file-input" #Image type="file" (change)="handleFileInput($event.target.files)" />-->
          <button class="btn btn-success btn-sm" type="button" (click)="Fileupload()">
            Upload
          </button>&nbsp;
          <!--<button class="btn btn-primary btn-sm mr-1" type="submit">Upload File</button>-->
          <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Cancel</button>
        </div>

      </div>
    </form>
  </div>
</div>


<div class="modal fade" id="supplyimport" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" data-keyboard="false" data-backdrop="static" aria-hidden="true">
  <div class="modal-dialog supplyform modal-lg" style="margin-top:80px">
    <form novalidate autocomplete="off">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" style=" font-size:16px;"><img src="../../../assets/img/sa800-logo.ico" alt="logo" width="32px" class="me-2" />Import supplementary forms data for {{TaxpayerName_obj}}</h5>
          <button type="button" class="close p-0" data-dismiss="modal" (click)="close()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <!--<h5 class="text-header p-2" style="color:#00928f;">
          Import supplementary forms data
        </h5>-->
        <div class="modal-body bg-light-green">
          <div class="col-12 mb-3 ">
            <p class="d-inline-block col-10" style="position: relative; top: -7px;">
              Do you want to enable supplementary forms as per previous year?

            </p>
            <label class="checkbox col-2 d-inline-block">
              <!--<input type="checkbox" (change)="importcheck($event)" [checked]="ischecked" />-->
              <input type="checkbox" (change)="checkboxforsuppforms($event)" [checked]="ischecked">
              <span class="default"></span>
            </label>
          </div>
          <div class="col-12 ">
            <p class="mt-2 col-10">
              Note : Following supplementary forms were used in the previous year.<br />
              Select forms and records to import from the list below.<br />
              Forms marked in blue already exists.<br />
            </p>
          </div>

          <div class="border pt-2 pb-3 px-4">
            <!--<div data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" style="border:none">-->
            <!--<span class="fa fa-plus iconbox"></span> Collapse--> <!--<span (click)="showArea()"><span class="iconbox"> <span [class]=" faIcon"></span></span> Collapse</span>-->
            <!--<span (click)="showArea()" class="iconbox"><i [ngClass]="faIcontoggle? 'fa-minus' : 'fa-plus'"></i><span class="ms-4"></span></span>
            </div>-->
            <ul class="" id="treecustom" style="margin-top:-27px">

              <li *ngFor="let supdata of usersService.supplyformdata;let i=index">
                <label [ngClass]="this.alreadyimportedforms[supdata.value] == true ? 'blue' : 'grey'" class="checkbox  d-inline-block">
                  <input #checkbox type="checkbox" (change)="importcheck($event,i)" [disabled]="!ischecked" /> <span class="default"></span><span class="text-display-custom"> {{supdata.name}}</span>
                </label>
              </li>
              <!--<li> <label class="checkbox  d-inline-block"> <input type="checkbox" /> <span class="default"></span>   <span class="mx-3"> Non Residence</span> </label> </li>-->
            </ul>
          </div>


          <div align="right" class="mt-3">
            <button class="btn-info" type="button" (click)="selectbutton()" [disabled]="!ischecked">Select/Deselect </button>
            <ul class="deselect" *ngIf="menudeselect">
              <li (click)="selectAll('select')">Select All</li>
              <li (click)="selectAll('deselect')">Deselect All</li>
              <li (click)="selectAll('inverse')">Inverse</li>
            </ul>

          </div>

        </div>
        <div class="card-footer d-flex align-items-center justify-content-end">



          <button class="btn btn-success btn-sm mx-2" type="button" (click)="Fileupload()" [disabled]="!ischecked"> Import</button>
          <button *ngIf="usersService.issupplyid" type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" (click)="skip()" [disabled]="ischecked">Skip</button>
          <button *ngIf="!usersService.issupplyid" type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" (click)="close()" [disabled]="ischecked">Cancel</button>
        </div>



      </div>
    </form>
  </div>
</div>


<div class="modal fade" id="successpopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" data-keyboard="false" data-backdrop="static" aria-hidden="true">
  <div class="modal-dialog " style="margin-top:80px">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" style=" font-size:16px;"><img src="../../../assets/img/sa800-logo.ico" alt="logo" width="32px" class="me-2" /> Andica SA800 Partnership</h5>
        <button type="button" class="close p-0" data-dismiss="modal" (click)="cancel()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-1">
            <div class="fa fa-question-circle fa-2x text-info" style="font-size: 60px "></div>
          </div>
          <div class="col-11">
            <ul style="list-style-type: none; font-size: 15px; font-weight: 600; line-height: 28px;">
              <li> Some of the selected form(s) already exists and importing will overwrite the existing data.</li>
              <li>  Do you want to import data for selected form(s) as per previous year?</li>
              <li> Click 'Yes' to import form(s) with data entered, or</li>
              <li> Click 'No' to import form(s) only, or</li>
              <li> Click 'Cancel' to go back to forms list screen.</li>
              <li>It is your responsibility to ensure accuracy of the returns.</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="modal-footer" align="center">
        <button type="button" value="b" class="btn btn-success mx-1" (click)="Upload('Alldata')">Yes</button>
        <button type="button" value="b" class="btn btn-info mx-1" (click)="Upload(null)">No</button>
        <button type="button" value="b" class="btn btn-danger mx-1" (click)="cancel()">Cancel</button>

      </div>
    </div>
  </div>
</div>
<!--<div class="modal fade" id="Importdatamodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" data-keyboard="false" data-backdrop="static" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <form id="popup1" name="popup1" [formGroup]="popup1" novalidate autocomplete="off">
      <div class="modal-content">-->
<!--<div class="modal-header">
  <h6 class="modal-title">Data Import</h6>
  <button type="button" class="close" data-dismiss="modal" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>-->
<!--<div class="modal-header data-import-header">
  <h6 class="modal-title" style="margin-top: 5px;">Data Import</h6>
  <button type="button" class="close" data-dismiss="modal" (click)="close()" aria-label="Close" style="line-height: 0;">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="border p-3">
    <div class="form-group row" id="selected_file">
      <label class=" col-lg-10 col-sm-12 col-xs-12">
        Do you want to enable supplementary form(s) as per previous year for selected Partnership(s)?
      </label>
      <div class="col-lg-2 col-sm-12 col-xs-12 mb-3">
        <label class="checkbox">
          <input type="checkbox" (change)="Onlyforms($event)" formControlName="Onlyforms" />
          <span class="default"></span>
        </label>
      </div>
      <label class="col-lg-12 col-sm-12 col-xs-12">
        (Supplementary forms selected in previous year will be enabled without data and values)
      </label>
    </div>
    <div class="form-group row" id="selected_file">
      <label class=" col-lg-10 col-sm-12 col-xs-12">
        Do you also want to import data and values for the selected form(s) as per previous year?
      </label>
      <div class="col-lg-2 col-sm-12 col-xs-12 mb-3">

        <label class="checkbox">
          <input type="checkbox" formControlName="Alldata" disabled />
          <span class="default"></span>
        </label>
      </div>
      <label class="col-lg-10 col-sm-12 col-xs-12">
        (Most data and values from last year's returns will be imported. It is your responsibility to ensure accuracy of the returns)
      </label>
    </div>
    <div class="form-group row">
      <label class=" col-lg-3 col-sm-12 col-xs-12">
        <b>Select File:</b>
      </label>
      <div class="col-lg-9 col-sm-12 col-xs-12">-->
<!--<input class="form-control form-control-file" type="file" placeholder="">-->
<!--<div class="custom-file">
  <input type="file" class="custom-file-input ">
  <input id="file-input" #Image type="file" class="custom-file-input " (change)="handleFileInput($event.target.files)">
  <label class="custom-file-label" for="customFile">Choose file...</label>
</div>-->
<!--<input id="file-input inputFile" class="Attachment_inputFile " type="file" (change)="handleFileInput($event)" value="" formControlName="inputFile" size="10" accept=".xml" name="inputFile" />
      </div>
    </div>
  </div>
</div>
<div class="card-footer d-flex align-items-center justify-content-end">-->
<!--<input id="file-input" #Image type="file" (change)="handleFileInput($event.target.files)" />-->
<!--<button class="btn btn-success btn-sm" type="button" (click)="Fileupload()">
  Upload
</button>&nbsp;-->
<!--<button class="btn btn-primary btn-sm mr-1" type="submit">Upload File</button>-->
<!--<button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" (click)="close()">Cancel</button>
        </div>

      </div>
    </form>
  </div>
</div>-->

<img src="../../../../assets/img/andica-loading2 (002).gif" *ngIf="toggleSpinner" class="toggle-spinner" />
