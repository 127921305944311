import { Component, OnInit } from '@angular/core';
import { UsersService } from '../modules/users/users.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-helpfile',
    templateUrl: './helpfile.component.html',
    styleUrls: ['./helpfile.component.css'],
    standalone: true,
    imports: [NgIf]
})

export class HelpfileComponent implements OnInit {
  previousPageUrl = document.referrer;
  host = "https://localhost:44350/"

  constructor(public userService: UsersService) {
  }

  confirm() {
   debugger

   /// alert(`Previously visited page URL: ${this.previousPageUrl}`);
  /*mainbox url check */
  var mainnavactive = (document.getElementById("mainnav") as HTMLInputElement)
   var submenushow = (document.getElementById("mainnavsubmenu") as HTMLInputElement)
  var contentnavactive = (document.getElementById("contentnav") as HTMLInputElement)

    var Andicainvestmentcontent = (document.getElementById("content") as HTMLInputElement)

   /*License url check*/
   var licencenavactive = (document.getElementById("licencenav") as HTMLInputElement)
   var licencebox = (document.getElementById("licence") as HTMLInputElement)


   /*Copyrigght url check*/
    var copyrightbox = (document.getElementById("copyright") as HTMLInputElement)
    var copynavactive = (document.getElementById("copyrightnav") as HTMLInputElement)


   //  /* introactive url check*/

   var intronavactive = (document.getElementById("intronav") as HTMLInputElement)
   var introsubmenushow = (document.getElementById("introsubmenu") as HTMLInputElement)
   var intronavactive = (document.getElementById("introactive") as HTMLInputElement)
   var introsubmenushow = (document.getElementById("Introduction1") as HTMLInputElement)
   var welcomeactive = (document.getElementById("welcomeactive") as HTMLInputElement)
   var welcomebox = (document.getElementById("welcome") as HTMLInputElement)
   var featuresactive = (document.getElementById("featureactive") as HTMLInputElement)
   var featuresbox = (document.getElementById("Features1") as HTMLInputElement)
   var conventionactive = (document.getElementById("conventionactive") as HTMLInputElement)
   var conventionbox = (document.getElementById("Quick") as HTMLInputElement)
   var quickrefactive = (document.getElementById("quickrefrenceactive") as HTMLInputElement)
   var quickrefbox = (document.getElementById("Reference1") as HTMLInputElement)
   var starthereactive = (document.getElementById("startactive") as HTMLInputElement)
   var startherebox = (document.getElementById("Start1") as HTMLInputElement)

 

   //  /*working tab start.....................................................................*/


   //var registeractive = (document.getElementById("workingnav") as HTMLInputElement)
   //var registerbox = (document.getElementById("workingsubmenu") as HTMLInputElement)
   var registeractive = (document.getElementById("workingactive") as HTMLInputElement)
   var registerbox = (document.getElementById("Working") as HTMLInputElement)
   var regactive = (document.getElementById("gatheractive") as HTMLInputElement)
   var regbox = (document.getElementById("Information1") as HTMLInputElement)
   var logactive = (document.getElementById("enteringactive") as HTMLInputElement)
   var logbox = (document.getElementById("Information2") as HTMLInputElement)
   var logactive = (document.getElementById("submittingactive") as HTMLInputElement)
   var logbox = (document.getElementById("Submitting") as HTMLInputElement)


   //  /* creating tab statrt....................................................*/
   var gatherinfoactive = (document.getElementById("creatingactive") as HTMLInputElement)
   var gatherinfobox = (document.getElementById("PartnershipRecords2") as HTMLInputElement)
   var enteractive = (document.getElementById("creatinguseractive") as HTMLInputElement)
   var enterbox = (document.getElementById("PartnershipRecords2") as HTMLInputElement)
   var submitactive = (document.getElementById("openingactive") as HTMLInputElement)
   var submitbox = (document.getElementById("Opening") as HTMLInputElement)



   //  /* getting tab start....................................................*/



   //var createactive = (document.getElementById("gettingmenu") as HTMLInputElement)
   //var creatbox = (document.getElementById("gettingsubmenu") as HTMLInputElement)
   var creatingactive = (document.getElementById("startedactive") as HTMLInputElement)
   var creatingbox = (document.getElementById("Started1") as HTMLInputElement)
   var createsoftactive = (document.getElementById("startingactive") as HTMLInputElement)
   var createsoftbox = (document.getElementById("Started1") as HTMLInputElement)
   var creattrustactive = (document.getElementById("loginactive") as HTMLInputElement)
   var creattrustbox = (document.getElementById("LogIn1") as HTMLInputElement)



   //  //Familiarise yourself with Andica SA800 Partnership Software start..............................................


   //var gettingmenuactive = (document.getElementById("familiariesmenu") as HTMLInputElement)
   //var gettingmenubox = (document.getElementById("familiariessubmenu") as HTMLInputElement)
   var gettingstartactive = (document.getElementById("familiariesactive") as HTMLInputElement)
   var gettingstartbox = (document.getElementById("PartnershipSoftware2") as HTMLInputElement)
   var gettingandicabox = (document.getElementById("MenuBar2") as HTMLInputElement)
   var gettingandicaactive = (document.getElementById("menuactive") as HTMLInputElement)
   var gettinglogbox = (document.getElementById("HorizontalToolbar1") as HTMLInputElement)
   var gettinglogactive = (document.getElementById("horizontalactive") as HTMLInputElement)
   var gettingyourselfbox = (document.getElementById("VerticalToolbars1") as HTMLInputElement)
   var gettingyourselfactive = (document.getElementById("verticalactive") as HTMLInputElement)
   var gettingmenubox = (document.getElementById("StatusBar1") as HTMLInputElement)
   var gettingmenuactive = (document.getElementById("statusactive") as HTMLInputElement)
   var gettinghorizontalbox = (document.getElementById("RecordsListView1") as HTMLInputElement)
   var gettinghorizontalactive = (document.getElementById("recordactive") as HTMLInputElement)
   var gettingverticalbox = (document.getElementById("UsingHelp1") as HTMLInputElement)
   var gettingverticalactive = (document.getElementById("helpactive") as HTMLInputElement)
   var gettingstatusbox = (document.getElementById("details1") as HTMLInputElement)
   var gettingstatusactive = (document.getElementById("taxactive") as HTMLInputElement)
   var gettingrecordbox = (document.getElementById("Attachments1") as HTMLInputElement)
   var gettingrecordactive = (document.getElementById("attachmanrtactive") as HTMLInputElement)


   //  /*Using Data Entry Forms start........................................................>..*/







   //var dataenteringmenuactive = (document.getElementById("datamenu") as HTMLInputElement)
   //var dataenteringmenubox = (document.getElementById("datasubmenu") as HTMLInputElement)



   var usingdataactive = (document.getElementById("usingactive") as HTMLInputElement)
   var usingdatabox = (document.getElementById("Forms1") as HTMLInputElement)
   var navigationbox = (document.getElementById("Forms2") as HTMLInputElement)
   var navigationactive = (document.getElementById("navigationactive") as HTMLInputElement)
   var enteringtrustbox = (document.getElementById("Forms3") as HTMLInputElement)
   var enteringtrustactive = (document.getElementById("enteringactive") as HTMLInputElement)
   var enteringHMRCbox = (document.getElementById("Forms4") as HTMLInputElement)
   var enteringHMRCactive = (document.getElementById("enteringhmrcactive") as HTMLInputElement)
   var enteringsupplementbox = (document.getElementById("Forms5") as HTMLInputElement)
   var enteringsupplementactive = (document.getElementById("supplementaryactive") as HTMLInputElement)
   var enteringHMRCbox = (document.getElementById("Forms6") as HTMLInputElement)
   var enteringHMRCactive = (document.getElementById("patnershipactive") as HTMLInputElement)

   //  //File By Internet start...................................................


   var filebox = (document.getElementById("File1") as HTMLInputElement)
   var fileactive = (document.getElementById("internetactive") as HTMLInputElement)
   var onlinefilebox = (document.getElementById("file1") as HTMLInputElement)
   var onlinefileactive = (document.getElementById("onlineactive") as HTMLInputElement)
   var registeronlinebox = (document.getElementById("file2") as HTMLInputElement)
   var registeronlineactive = (document.getElementById("registeractive") as HTMLInputElement)
   var usingfilebox = (document.getElementById("file3") as HTMLInputElement)
   var usingfileactive = (document.getElementById("usingactive") as HTMLInputElement)


   //  //CESA Characters start............................................


   var characterbox = (document.getElementById("Characters1") as HTMLInputElement)
   var characteractive = (document.getElementById("characteractive") as HTMLInputElement)
   var hmrcComputerbox = (document.getElementById("Characters1") as HTMLInputElement)
   var hmrcComputeractive = (document.getElementById("computeractive") as HTMLInputElement)

   //  /*printingreport start*/
   //var printingmenubox = (document.getElementById("printingsubmenu") as HTMLInputElement)
   //var printingmenuactive = (document.getElementById("printingmenu") as HTMLInputElement)

   var printingreportbox = (document.getElementById("reports1") as HTMLInputElement)
   var printingreportactive = (document.getElementById("reportactive") as HTMLInputElement)
   var printingtaxbox = (document.getElementById("reports1") as HTMLInputElement)
   var printingtaxactive = (document.getElementById("substituteactive") as HTMLInputElement)
   var printingdeclarationbox = (document.getElementById("reports2") as HTMLInputElement)
   var printingdeclarationactive = (document.getElementById("declarationactive") as HTMLInputElement)
   var printreportbox = (document.getElementById("reports3") as HTMLInputElement)
   var printreportactive = (document.getElementById("toolbaractive") as HTMLInputElement)
   var printingfbibox = (document.getElementById("reports4") as HTMLInputElement)
   var printingfbiactive = (document.getElementById("fbiactive") as HTMLInputElement)
   var clearsubmissionsbox = (document.getElementById("reports5") as HTMLInputElement)
   var clearsubmissionsactive = (document.getElementById("claeringactive") as HTMLInputElement)


   //  /*      Backup and Restore start......................*/

   //var backupmenubox = (document.getElementById("backupsubmenu") as HTMLInputElement)
   //var backupmenuactive = (document.getElementById("backupmenu") as HTMLInputElement)
   var backupbox = (document.getElementById("Backup1") as HTMLInputElement)
   var backupactive = (document.getElementById("restoreactive") as HTMLInputElement)
   var backupdatabox = (document.getElementById("Backup1") as HTMLInputElement)
   var backupdataactive = (document.getElementById("databackupactive") as HTMLInputElement)
   var restorebox = (document.getElementById("Backup2") as HTMLInputElement)
   var restoreactive = (document.getElementById("restoringactive") as HTMLInputElement)

   /*AndicaSA804*/
    var Andicainvestactive = (document.getElementById("SA800TP") as HTMLInputElement)
   var AndicainvestmentSAmain = (document.getElementById("SA800") as HTMLInputElement)
   var Andicainvestment801 = (document.getElementById("SA801") as HTMLInputElement)
   var Andicainvestment802 = (document.getElementById("SA802") as HTMLInputElement)
   var Andicainvestment803 = (document.getElementById("SA803") as HTMLInputElement)
   var Andicainvestment804 = (document.getElementById("SA804") as HTMLInputElement)
    var AndicainvestmentSA800ps = (document.getElementById("SA800PS") as HTMLInputElement)
    mainnavactive.classList.add("caret-down");
    submenushow.classList.add("active-tree");

    if (window.location.href.includes("help?Quickstart=")) {
      this.openNav();
      //this.tabactive1();

      Andicainvestmentcontent.classList.add("active");
      Andicainvestmentcontent.classList.add("show");
      Andicainvestmentcontent.classList.add("active")
      //contentnavactive.classList.remove("active");

    }

   else if(this.previousPageUrl.includes("page1")) {
     debugger
     this.openNav();
     this.tabactive1();

     licencebox.classList.add("active");
     licencebox.classList.add("show");
     licencenavactive.classList.add("active")
     contentnavactive.classList.remove("active");

   } else if (this.previousPageUrl.includes("page1")) {
     this.openNav();

     copyrightbox.classList.add("active");
     copyrightbox.classList.add("show");
     copynavactive.classList.add("active")

   }
   else if (this.previousPageUrl.includes("sa900main/hmrcdeta1ils")) {
     this.openNav();
     introsubmenushow.classList.add("active-tree");
     welcomebox.classList.add("active");
     welcomebox.classList.add("show");
     intronavactive.classList.add("caret-down");
     welcomeactive.classList.add("active")
   }
   else if (this.previousPageUrl.includes("sa900main/hmrcde111tails")) {
     this.openNav();
     introsubmenushow.classList.add("active-tree");
     intronavactive.classList.add("caret-down");
     featuresbox.classList.add("active");
     featuresbox.classList.add("show");
     featuresactive.classList.add("active")
   } else if (this.previousPageUrl.includes("sa900main/hmrcde11tai1ls")) {
     this.openNav();
     introsubmenushow.classList.add("active-tree");
     intronavactive.classList.add("caret-down");
     conventionbox.classList.add("active");
     conventionbox.classList.add("show");
     conventionactive.classList.add("active")
   }
   else if (this.previousPageUrl.includes("dashboard/quickstart")) {
     this.openNav();
     quickrefbox.classList.add("active");
     quickrefbox.classList.add("show");
     quickrefactive.classList.add("active")
   }
   else if (this.previousPageUrl.includes("sa900main/hmrcdeta1ils")) {
     this.openNav();
     startherebox.classList.add("active");
     startherebox.classList.add("show");
     starthereactive.classList.add("active")
   }
   else if (this.previousPageUrl.includes("sa900main/hmrcdeta1ils")) {
     this.openNav();
     this.tabactive2();
     registerbox.classList.add("active");
     registerbox.classList.add("show");
     registeractive.classList.add("active")
   }
   else if (this.previousPageUrl.includes("SA100/page06")) {
     this.openNav();
     regbox.classList.add("active");
     regbox.classList.add("show");
     regactive.classList.add("active")
   }
   else if (this.previousPageUrl.includes("SA100/page06")) {
     this.openNav();
     logbox.classList.add("active");
     logbox.classList.add("show");
     logactive.classList.add("active")
   }
   else if (this.previousPageUrl.includes("sa900main/hmrcdetails")) {
     this.openNav();
     this.tabactive3()
     gatherinfoactive.classList.add("active")
     gatherinfobox.classList.add("active");
     gatherinfobox.classList.add("show");
   }
   else if (this.previousPageUrl.includes("sa800main")) {
     this.openNav();
    
     AndicainvestmentSAmain.classList.add("active")
     AndicainvestmentSAmain.classList.add("active");
     AndicainvestmentSAmain.classList.add("show");
   }
   else if (this.previousPageUrl.includes("sa800tp")) {
     this.openNav();
     this.userService.taxyeras();
     Andicainvestactive.classList.add("active")
     Andicainvestactive.classList.add("active");
     Andicainvestactive.classList.add("show");
   }
   else if (this.previousPageUrl.includes("sa801")) {
     this.openNav();
     Andicainvestment801.classList.add("active")
     Andicainvestment801.classList.add("active");
     Andicainvestment801.classList.add("show");
   }
   else if (this.previousPageUrl.includes("sa802")) {
     this.openNav();
     Andicainvestment802.classList.add("active")
     Andicainvestment802.classList.add("active");
     Andicainvestment802.classList.add("show");
   }
   else if (this.previousPageUrl.includes("sa803")) {
     this.openNav();
     Andicainvestment803.classList.add("active")
     Andicainvestment803.classList.add("active");
     Andicainvestment803.classList.add("show");
   }

   else if (this.previousPageUrl.includes("sa804")) {
     this.openNav();
     Andicainvestment804.classList.add("active")
     Andicainvestment804.classList.add("active");
     Andicainvestment804.classList.add("show");
   }
   else if (this.previousPageUrl.includes("sa800ps")) {
     this.openNav();
     AndicainvestmentSA800ps.classList.add("active")
     AndicainvestmentSA800ps.classList.add("active");
     AndicainvestmentSA800ps.classList.add("show");
   }
   
  }

  search() {
    debugger
    var input = document.getElementById("Search") as HTMLInputElement;;
    var filter = input.value.toLowerCase();
    var nodes = document.getElementsByTagName("li") as HTMLCollectionOf<HTMLElement>;
    var i;
    for (i = 0; i < nodes.length; i++) {
      if (nodes[i].innerText.toLowerCase().includes(filter)) {
        nodes[i].style.display = "block";

      } else {
        nodes[i].style.display = "none";
      }
    }
  }

  tabactive1() {
    /*overview url check*/
    var overviewnavactive = (document.getElementById("contentnav") as HTMLInputElement)
    var contentbox = (document.getElementById("content") as HTMLInputElement)
    //  var overviewbox = (document.getElementById("content") as HTMLInputElement)
    contentbox.classList.remove("active");
    overviewnavactive.classList.remove("active")
  }

  tabactive2() {
    var Registernavactive = (document.getElementById("Registernav") as HTMLInputElement)
    var Registersubmenushow = (document.getElementById("Registersubmenu") as HTMLInputElement)
    Registersubmenushow.classList.add("active-tree");
    Registernavactive.classList.add("caret-down");

  }

  tabactive3() {
    var workingmenuactive = (document.getElementById("workingmenu") as HTMLInputElement)
    var workingsubmenushow = (document.getElementById("workingsubmenu") as HTMLInputElement)
    workingsubmenushow.classList.add("active-tree");
    workingmenuactive.classList.add("caret-down");

  }

  tabactive4() {
    var creatingmenuactive = (document.getElementById("creatingmenu") as HTMLInputElement)
    var creatingsubmenushow = (document.getElementById("creatingsubmenu") as HTMLInputElement)
    creatingsubmenushow.classList.add("active-tree");
    creatingmenuactive.classList.add("caret-down");

  }

  tabactive5() {
    var gettingmenuactive = (document.getElementById("gettingmenu") as HTMLInputElement)
    var gettingsubmenushow = (document.getElementById("gettingsubmenu") as HTMLInputElement)
    gettingsubmenushow.classList.add("active-tree");
    gettingmenuactive.classList.add("caret-down");

  }


  tabactive6() {
    var dataenteringmenuactive = (document.getElementById("dataenteringmenu") as HTMLInputElement)
    var dataenteringsubmenushow = (document.getElementById("dataenteringsubmenu") as HTMLInputElement)
    dataenteringsubmenushow.classList.add("active-tree");
    dataenteringmenuactive.classList.add("caret-down");

  }
  tabactive7() {
    var Calculatemenuactive = (document.getElementById("Calculatemenu") as HTMLInputElement)
    var Calculatesebmenushow = (document.getElementById("Calculatesebmenu") as HTMLInputElement)
    Calculatesebmenushow.classList.add("active-tree");
    Calculatemenuactive.classList.add("caret-down");
  }
  tabactive8() {
    var filemenuactive = (document.getElementById("filemenu") as HTMLInputElement)
    var filesubmenushow = (document.getElementById("filesubmenu") as HTMLInputElement)
    filesubmenushow.classList.add("active-tree");
    filemenuactive.classList.add("caret-down");
  }
  tabactive9() {
    var cesamenuactive = (document.getElementById("cesamenu") as HTMLInputElement)
    var cesasubmenushow = (document.getElementById("cesasubmenu") as HTMLInputElement)
    cesasubmenushow.classList.add("active-tree");
    cesamenuactive.classList.add("caret-down");
  }
  tabactive10() {
    var printingmenuactive = (document.getElementById("printingmenu") as HTMLInputElement)
    var printingsubmenushow = (document.getElementById("printingsubmenu") as HTMLInputElement)
    printingsubmenushow.classList.add("active-tree");
    printingmenuactive.classList.add("caret-down");

  }
  tabactive11() {
    var backupmenuactive = (document.getElementById("backupmenu") as HTMLInputElement)
    var backupsubmenushow = (document.getElementById("backupsubmenu") as HTMLInputElement)
    backupsubmenushow.classList.add("active-tree");
    backupmenuactive.classList.add("caret-down");

  }
  tabactive12() {
    var datamenuactive = (document.getElementById("datamenu") as HTMLInputElement)
    var datasubmenushow = (document.getElementById("datasubmenu") as HTMLInputElement)
    datasubmenushow.classList.add("active-tree");
    datamenuactive.classList.add("caret-down");

  }
  tabactive13() {
    var backupmenuactive = (document.getElementById("backupmenu") as HTMLInputElement)
    var backupsubmenushow = (document.getElementById("backupsubmenu") as HTMLInputElement)
    backupsubmenushow.classList.add("active-tree");
    backupmenuactive.classList.add("caret-down");

  }
  openNav() {
    this.userService.taxyeras();
    (document.getElementById("mySidenav") as HTMLInputElement).style.width = "350px";
    (document.getElementById("mySidenav") as HTMLInputElement).style.marginLeft = "50px";
    (document.getElementById("main") as HTMLInputElement).style.marginLeft = "338px";
    (document.getElementById("main") as HTMLInputElement).style.maxWidth = "calc(100% - 325px)";
    (document.getElementById("show-nav") as HTMLInputElement).style.display = "none";
    (document.getElementById("hide-nav") as HTMLInputElement).style.display = "inline-block";

  }

  closeNav() {
    (document.getElementById("mySidenav") as HTMLInputElement).style.width = "0px";
    (document.getElementById("mySidenav") as HTMLInputElement).style.marginLeft = "-50px";
    (document.getElementById("main") as HTMLInputElement).style.marginLeft = "0px";
    (document.getElementById("main") as HTMLInputElement).style.maxWidth = "100%";
    (document.getElementById("show-nav") as HTMLInputElement).style.display = "inline-block";
    (document.getElementById("hide-nav") as HTMLInputElement).style.display = "none";
  }

  ngOnInit() {
    debugger;
    this.openNav()
    this.confirm()
    var toggler = document.getElementsByClassName("caret");
    var i;
    for (i = 0; i < toggler.length; i++) {
      toggler[i].addEventListener("click", function () {
        this.parentElement.querySelector(".nested").classList.toggle("active-tree");
        this.classList.toggle("caret-down");
      });
    }
  }
}
