import { Component } from '@angular/core';
import { NullValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { authconfig, DiscoveryDocumentConfig } from './auth.config';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import * as CryptoJS from 'crypto-js';
import { DashboardServiceService } from '../app/modules/dashboard/dashboard-service.service';
import { AuthConfigService } from './Configuration/auth-config.service';
import { ConfigurationService } from './Configuration/configuration.service';
import { RouterOutlet } from '@angular/router';
import { NgIf, JsonPipe } from '@angular/common';



@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: true,
    imports: [NgIf, RouterOutlet, JsonPipe]
})
export class AppComponent {
  UserLoginHistoryID: any;
  UserLoginID: any;
  time: FormGroup;
  response: any;
  title = 'Partnership'; constructor(private oauthService: OAuthService, private Sa800Service: DashboardServiceService, private authService: AuthConfigService, private configService: ConfigurationService) {

    this.time = new FormGroup({
      AdminLogout_time: new FormControl(''),
      AdminLogin_time: new FormControl(''),
      UserLoginID: new FormControl(''),
      Flag: new FormControl(''),
      UserLoginHistoryID: new FormControl('')
    })

    this.configure();
    this.oauthService.setupAutomaticSilentRefresh();

    this.oauthService.tryLoginCodeFlow()


  }

  message: string;


  public getMessage() {

  }

  public login() {
    this.oauthService.initCodeFlow();
    this.oauthService.setupAutomaticSilentRefresh();
    console.log(this.oauthService.getIdentityClaims())
  }

  public logout() {
    this.UserLoginHistoryID = CryptoJS.AES.decrypt(localStorage.getItem("UserLoginHistoryID"), "SA800").toString(CryptoJS.enc.Utf8)
    this.UserLoginID = localStorage.getItem('UserLoginID');
    this.time.controls["UserLoginHistoryID"].setValue(this.UserLoginHistoryID);
    this.time.controls["UserLoginID"].setValue(this.UserLoginID);
    this.time.controls["Flag"].setValue("U");
    this.time.controls["AdminLogin_time"].setValue(false);
    this.time.controls["AdminLogout_time"].setValue(true);
    localStorage.clear();
    this.Sa800Service.insertTime(this.time.value).subscribe(data => {
      this.response = data;
      localStorage.setItem('MasterLogin_time', '');
      localStorage.clear();
      this.oauthService.logOut();
    });



   console.log(this.oauthService.getIdentityClaims())
  //  // this.oauthService.logOut();
  }

  public get claims() {
    let claims = this.oauthService.getIdentityClaims();
    return claims;

  }

  private configure() {
    this.oauthService.configure(this.authService.get());
    this.oauthService.tokenValidationHandler = new NullValidationHandler();
    this.oauthService.loadDiscoveryDocument(this.configService.get().discoveryDocumentConfigUrl);
  }

}
