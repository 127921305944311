// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
 
  //apiEndpointforchecksubcription: 'https://subscription-management-api-54ae.azurewebsites.net/',
  apiEndpointforchecksubcription: 'https://localhost:44366',

  apiEndpoint: 'https://localhost:44386',
  //apiEndpoint: 'http://localhost:57493',
  HeaderText: 'Tax year 6 April 2022 to 5 April 2023 (2022-23)',
  CurrTaxPeriod: "2021-22",  
  NextTaxPeriod: "2022-23",

  url: "https://acuitysoftorg.b2clogin.com/acuitysoftorg.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=b2c_1_sa800login",
 // url: 'http://localhost:4200',
 // redirectUri: 'http://localhost:4200',
  redirectUri: 'https://sa-partnership-software-54ae.azurewebsites.net/dashboard',
  responseType: 'code',
  issuer: 'https://acuitysoftorg.b2clogin.com/a34d5a9d-91e8-424f-8b20-c62efa203a0c/v2.0/',
  strictDiscoveryDocumentValidation: false,
  tokenEndpoint: 'https://acuitysoftorg.b2clogin.com/acuitysoftorg.onmicrosoft.com/oauth2/v2.0/token?p=b2c_1_sa800login',
  loginUrl: 'https://acuitysoftorg.b2clogin.com/acuitysoftorg.onmicrosoft.com/oauth2/v2.0/authorize?p=b2c_1_sa800login',
  clientId: '8410620a-e2f9-4b9f-b317-a01bb9686071',
  scope: 'openid offline_access profile  https://acuitysoftorg.onmicrosoft.com/sa800api/sa800access ',
  skipIssuerCheck: true,
  clearHashAfterLogin: true,
  oidc: true,
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
