import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HeaderComponent } from './layout/header/header.component';
import { CommonLayoutComponent } from './layout/common-layout/common-layout.component';
import { AuthGuard } from './auth-guard';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { HelpfileComponent } from './helpfile/helpfile.component';
//t
const routes: Routes = [

  { path: 'header', component: HeaderComponent },
  { path: 'help', component: HelpfileComponent },
  {
    path: "",                     //commented by kiran for local login page 
    //component:,
    redirectTo: 'dashboard',
    pathMatch: 'full',
    //canActivate: [AuthGuard]
  },
  //{
  //  path: "",
  //  redirectTo: '/users/login',           //commented by kiran for B2C login page 
  //  pathMatch: 'full',
  // // canActivate: [AuthGuard]
  //},
  {
    path: 'users',
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import('./modules/users/users.module').then(m => m.UsersModule)
      }
    ]
  },
  {
    path: "dashboard",
    component: CommonLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
      },

    ]
  },
  {
    path: "sa802",
    component: CommonLayoutComponent,
    children: [

      {
        path: '', loadChildren: () => import('./modules/sa802/sa802.module').then(m => m.Sa802Module),
        canActivate: [AuthGuard]
      },
    ], //canActivate: [AuthGuard]
  },
  {
    path: "sa800main",
    component: CommonLayoutComponent,
    children: [

      {
        path: '', loadChildren: () => import('./modules/sa800main/sa800main.module').then(m => m.Sa800mainModule),
        canActivate: [AuthGuard]
      },
    ], //canActivate: [AuthGuard]
  },
  {
    path: "sa800tp",
    component: CommonLayoutComponent,
    children: [
      {
        path: '', loadChildren: () => import('./modules/sa800tp/sa800tp.module').then(m => m.Sa800tpModule),
        canActivate: [AuthGuard]
      }
    ], //canActivate: [AuthGuard]
  },
  {
    path: "sa801",
    component: CommonLayoutComponent,
    children: [
      {
        path: '', loadChildren: () => import('./modules/sa801/sa801.module').then(m => m.Sa801Module),
        canActivate: [AuthGuard]
      }
    ], //canActivate: [AuthGuard]
  },
  {
    path: 'sa803',
    component: CommonLayoutComponent,
    children: [
      {
        path: '', loadChildren: () => import('./modules/sa803/sa803.module').then(m => m.Sa803Module),
        canActivate: [AuthGuard]
      }
    ], //canActivate: [AuthGuard]
  },
  {
    path: 'sa804',
    component: CommonLayoutComponent,
    children: [
      {
        path: '', loadChildren: () => import('./modules/sa804/sa804.module').then(m => m.Sa804Module),
        canActivate: [AuthGuard]
      }
    ], //canActivate: [AuthGuard]
  },
  {
    path: 'sa800ps',
    component: CommonLayoutComponent,
    children: [
      {
        path: '', loadChildren: () => import('./modules/sa800ps/sa800ps.module').then(m => m.Sa800psModule),
        canActivate: [AuthGuard]
      }
    ], //canActivate: [AuthGuard]
  },
  {
    path: 'FBI',
    component: CommonLayoutComponent,
    children: [

      {
        path: '', loadChildren: () => import('./modules/fbi/fbi.module').then(m => m.FbiModule),
        canActivate: [AuthGuard]
      }
    ], //canActivate: [AuthGuard]
  },
  {
    path: 'reports',
    component: CommonLayoutComponent,
    children: [
      {
        path: '', loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule),
        canActivate: [AuthGuard]
      }
    ], //canActivate: [AuthGuard]
  },
  {
    path: 'sa800main', loadChildren: () => import('./modules/sa800main/sa800main.module').then(m => m.Sa800mainModule),
    //canActivate: [AuthGuard]
  },
  {
    path: 'sa802', loadChildren: () => import('./modules/sa802/sa802.module').then(m => m.Sa802Module),
    //canActivate: [AuthGuard]
  },

  {
    path: 'sa800ps', loadChildren: () => import('./modules/sa800ps/sa800ps.module').then(m => m.Sa800psModule),
    //canActivate: [AuthGuard]
  },

  { path: 'users', loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule) },



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

