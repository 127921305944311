import { Component, OnInit } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { Sa800partnershipinfoService } from '../../modules/sa800main/sa800partnershipinfo.service';
import * as $ from 'jquery';
import { DashboardServiceService } from '../../modules/dashboard/dashboard-service.service';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { formatDate, NgIf, NgFor, NgStyle, SlicePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { UsersService } from '../../modules/users/users.service';
import { SideNavService } from './side-nav.service';
@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.css'],
    standalone: true,
    imports: [RouterLink, RouterLinkActive, NgIf, NgFor, NgStyle, SlicePipe]
})
export class SidenavComponent implements OnInit {

  LandProperty: Boolean;
  Foreign: Boolean;
  Trade: Boolean;
  ChargeableAssets: Boolean;
  MemberIsCompany: Boolean;
  MemberIsNonResident: Boolean;
  PartnerOfBusinessAbroad: Boolean;
  EuroEconomicInterestGrouping: Boolean;
  GoodsExported: Boolean;
  ServicesExported: Boolean;
  NeitherGoodsNorServicesExported: Boolean;

  today = new Date();
  IncomeNotIncludedElsewhere: Boolean;
  InterestEtcWithTaxTakenOff: Boolean;
  DetailsIncorrect: Boolean;
  MasterLogin_time: any;
  UserMaster_Loginname: any;
  response: any;
  UserLoginID: any;
  MasterUserID: any;
  MasterLicenName: any;
  UserType: any;
  MasterPurchasedDate: any;
  MasterExpiryDate: any;
  PartnershipStatement: Boolean
  time: FormGroup;
  clear_value: any;
  RecordID: any;
  UserID: any;
  Allmodule: any;
  order1: any;
  order2: any;
  FormStatus: boolean = true;
  Sa800: Boolean;
  User_Login_History_ID: any;
  getlicen: any;
  collapsePlusMinus: any;
  collapseValue: boolean;
  clearfieldbtn: boolean;
  taxadviserflag: boolean = false
  Attachment: Boolean;
  constructor(private route: Router, private Sa800Service: Sa800partnershipinfoService, public usersService: UsersService, private dashboard: DashboardServiceService, private router: Router, public sidNavService: SideNavService) {
    this.time = new FormGroup({
      Last_Logout_bl: new FormControl(''),
      Last_Login_bl: new FormControl(''),
      User_ID: new FormControl(''),
      Flag: new FormControl(''),
      User_Login_History_ID: new FormControl('')
    })
  }

  ngOnInit() {
    debugger;
    if (localStorage.getItem('TempStorage') == '1' && localStorage.getItem('NewEntry') == 'New') {
      this.RecordID = ''
    } else {
      if (localStorage.getItem("RecordID"))
        this.RecordID = CryptoJS.AES.decrypt(localStorage.getItem("RecordID"), "SA800").toString(CryptoJS.enc.Utf8)
      if (this.RecordID) {
        this.sidNavService._getShowBubble()
        this.dashboard.GetsubModuleStatusByUser(this.RecordID).subscribe((data) => {

          this.Allmodule = data;

          this.order1 = this.Allmodule.filter(x => x.ORDER_LEVEL == 1)
          this.order2 = this.Allmodule.filter(x => x.ORDER_LEVEL == 2)

          for (let k = 0; k <= this.order1.length - 1; k++) {
            this.order1[k].submodule = [];
            for (let m = 0; m <= this.order2.length - 1; m++) {
              if (this.order1[k].Ref_Number == this.order2[m].Ref_Number) {
                this.order1[k].submodule.push(this.order2[m])

              }
            }
            for (let i1 = 0; i1 <= this.order1[k].submodule.length - 1; i1++) {
              this.order1[k].FormStatus = null
              if (this.order1[k].submodule[i1].Status == false)
                this.order1[k].FormStatus = false
            }

          }

        })
      }


      ////1.getting master UID BY mail
      ////2. insert login time
      ////3.then getting licencense details.

      var Session_Object = JSON.parse(localStorage.getItem('id_token_claims_obj')); // for Azure
      this.UserMaster_Loginname = Session_Object.emails[0];  // for Azure
      localStorage.setItem('Email', this.UserMaster_Loginname)
      //this.UserMaster_Loginname = localStorage.getItem("LoginName"); //uncommented for local login
      if (!localStorage.getItem('MasterLogin_time')) {
        localStorage.setItem('MasterLogin_time', formatDate(this.today, 'HH:mm:ss on dd/MM/yyyy', 'en-US'));
        this.MasterLogin_time = localStorage.getItem('MasterLogin_time');
      } else {
        this.MasterLogin_time = localStorage.getItem('MasterLogin_time');
      }
      if (!localStorage.getItem('MasterUserID')) {
        this.Sa800Service.GetMasterUID(this.UserMaster_Loginname).subscribe(data => {
          this.response = data;
          if (this.response) {
            this.UserLoginID = this.response[0].UserLoginID;
            this.MasterUserID = this.response[0].MasterLoginUserID;
            this.MasterLicenName = this.response[0].UserFullName;
            this.UserType = this.response[0].UserType;
            this.MasterPurchasedDate = formatDate(this.response[0].TUCreatedDate, 'dd/MM/yyyy', 'en-US');
            this.MasterExpiryDate = formatDate(this.response[0].TUEndDate, 'dd/MM/yyyy', 'en-US');
            localStorage.setItem('UserLoginID', this.UserLoginID)
            localStorage.setItem('MasterUserID', this.MasterUserID)
            localStorage.setItem('MasterLicenName', this.MasterLicenName)
            localStorage.setItem('MasterPurchasedDate', this.MasterPurchasedDate);
            localStorage.setItem('MasterExpiryDate', this.MasterExpiryDate);
            localStorage.setItem('UserType', this.UserType);
          }
          var temp = (localStorage.getItem("UserLoginHistoryID") ? CryptoJS.AES.decrypt(localStorage.getItem("UserLoginHistoryID"), "SA800").toString(CryptoJS.enc.Utf8) : null)
          if (!temp && this.MasterUserID) {
            this.UserLoginID = !this.UserLoginID ? null : Number(this.UserLoginID)
            this.time.controls["User_ID"].setValue(this.UserLoginID);
            if (this.User_Login_History_ID == null) {
              this.time.controls["Flag"].setValue("I");
              this.time.controls["Last_Login_bl"].setValue(true);
              this.time.controls["Last_Logout_bl"].setValue(false);
              this.time.controls["User_Login_History_ID"].setValue(null);
            } else {
              this.time.controls["Flag"].setValue("U");
              this.time.controls["Last_Login_bl"].setValue(false);
              this.time.controls["Last_Logout_bl"].setValue(true);
              this.time.controls["User_Login_History_ID"].setValue(Number(this.User_Login_History_ID));
            }
            this.Sa800Service.insertTime(this.time.value).subscribe(data => {
              this.response = data;
              if (this.response)
                this.User_Login_History_ID = (this.response).toString()
              localStorage.setItem('UserLoginHistoryID', CryptoJS.AES.encrypt(this.User_Login_History_ID, "SA800").toString());

              if (localStorage.getItem('MasterUserID')) {
                this.Sa800Service.GetUser_Licence_Details(localStorage.getItem('MasterUserID'), this.usersService.Subscriptionyear).subscribe(data => {
                  this.getlicen = data;
                  localStorage.setItem('Number_Of_Users', this.getlicen.Number_Of_Users);  //Number_Of_TaxPayers to Number_Of_Users
                  localStorage.setItem('Number_Of_Records', this.getlicen.Number_Of_Records); // Number_Of_Users to Number_Of_Records
                  localStorage.setItem('Submissions', this.getlicen.Submissions);
                });
              }
            });
          }
        });
      }
    }





    var windowsize = $(window).width();

    $(window).resize(function () {
      windowsize = $(window).width();
      if (windowsize >= 801) {
        if ($("#layoutSidenav_nav").show() == true) {
          $("#layoutSidenav_content").addClass("layout-show-hide");
        }
        else {
          $("#layoutSidenav_content").removeClass("layout-show-hide");
        }
      }

      if (windowsize <= 800) {
        $("#layoutSidenav_nav").hide();
        $("#layoutSidenav_content").addClass("layout-show-hide");
      }

      if (windowsize >= 769 && windowsize <= 800) {
        if ($("#layoutSidenav_nav").show() == true) {
          $("#layoutSidenav_nav").hide();
          $("#layoutSidenav_content").addClass("layout-show-hide");
        }
        else if ($("#layoutSidenav_nav").hide() == true) {
          $("#layoutSidenav_content").addClass("layout-show-hide");
        }
      }
    });

    if ($(window).width() <= 801) {

      $('.sb-sidenav-menu-nested').on('click', '.child-menu', function () {
        $("#layoutSidenav_nav").hide();
      });
    }
    else {
      $("#layoutSidenav_nav").show();
    }
    //if ($(window).width() <= 801) {
    //  $('.menu-visible-sm').click(function () {
    //    $("#layoutSidenav_nav").show();
    //  });
    //}
    //else {
    //  $("#layoutSidenav_nav").show();
    //}

    //if ($(window).width() <= 801) {
    //  $('.menu-visible-home').click(function () {
    //    $("#layoutSidenav_nav").hide();
    //  });
    //}
    //else {
    //  $("#layoutSidenav_nav").show();
    //}


    if (localStorage.getItem("RecordID"))
      this.UserID = (localStorage.getItem("RecordID") ? CryptoJS.AES.decrypt(localStorage.getItem("RecordID"), "SA800").toString(CryptoJS.enc.Utf8) : "");

    var Clear_Form_Obj = localStorage.getItem("clearform")

    if (Clear_Form_Obj != 'true') {
      // 
      if (localStorage.getItem('NewEntry') == 'New') {
        //     

        this.Sa800Service.load_Supp_data();


        this.Sa800Service.Sa800.subscribe((value) => {
          // 
          if (localStorage.getItem('NewEntry') == 'New')
            this.Sa800 = false;
          else
            this.Sa800 = value;
        });

        this.Sa800Service.Attachment.subscribe((value) => {
          // 
          if (localStorage.getItem('NewEntry') == 'New')
            this.Attachment = false;
          else
            this.Attachment = value;
        });

        this.Sa800Service.LandProperty.subscribe((value) => {

          if (localStorage.getItem('NewEntry') == 'New')
            this.LandProperty = false;
          else
            this.LandProperty = value;
        });

        this.Sa800Service.Foreign.subscribe((value) => {

          if (localStorage.getItem('NewEntry') == 'New')
            this.Foreign = false;
          else
            this.Foreign = value;
        });

        this.Sa800Service.Trade.subscribe((value) => {
          // 
          if (localStorage.getItem('NewEntry') == 'New')
            this.Trade = false;
          else
            this.Trade = value;
        });

        this.Sa800Service.ChargeableAssets.subscribe((value) => {
          // 
          if (localStorage.getItem('NewEntry') == 'New')
            this.ChargeableAssets = false;
          else
            this.ChargeableAssets = value;
        });

        this.Sa800Service.MemberIsCompany.subscribe((value) => {
          // 
          if (localStorage.getItem('NewEntry') == 'New')
            this.MemberIsCompany = false;
          else
            this.MemberIsCompany = value;
        });

        this.Sa800Service.MemberIsNonResident.subscribe((value) => {
          // 
          if (localStorage.getItem('NewEntry') == 'New')
            this.MemberIsNonResident = false;
          else
            this.MemberIsNonResident = value;
        });

        this.Sa800Service.PartnerOfBusinessAbroad.subscribe((value) => {
          // 
          if (localStorage.getItem('NewEntry') == 'New')
            this.PartnerOfBusinessAbroad = false;
          else
            this.PartnerOfBusinessAbroad = value;
        });

        this.Sa800Service.EuroEconomicInterestGrouping.subscribe((value) => {
          // 
          if (localStorage.getItem('NewEntry') == 'New')
            this.EuroEconomicInterestGrouping = false;
          else
            this.EuroEconomicInterestGrouping = value;
        });

        this.Sa800Service.GoodsExported.subscribe((value) => {
          // 
          if (localStorage.getItem('NewEntry') == 'New')
            this.GoodsExported = false;
          else
            this.GoodsExported = value;
        });

        this.Sa800Service.ServicesExported.subscribe((value) => {
          // 
          if (localStorage.getItem('NewEntry') == 'New')
            this.ServicesExported = false;
          else
            this.ServicesExported = value;
        });

        this.Sa800Service.NeitherGoodsNorServicesExported.subscribe((value) => {
          // 
          if (localStorage.getItem('NewEntry') == 'New')
            this.NeitherGoodsNorServicesExported = false;
          else
            this.NeitherGoodsNorServicesExported = value;
        });

        this.Sa800Service.IncomeNotIncludedElsewhere.subscribe((value) => {
          // 
          if (localStorage.getItem('NewEntry') == 'New')
            this.IncomeNotIncludedElsewhere = false;
          else
            this.IncomeNotIncludedElsewhere = value;
        });

        this.Sa800Service.InterestEtcWithTaxTakenOff.subscribe((value) => {
          // 
          if (localStorage.getItem('NewEntry') == 'New')
            this.InterestEtcWithTaxTakenOff = false;
          else
            this.InterestEtcWithTaxTakenOff = value;
        });

        this.Sa800Service.DetailsIncorrect.subscribe((value) => {
          // 
          if (localStorage.getItem('NewEntry') == 'New')
            this.DetailsIncorrect = false;
          else
            this.DetailsIncorrect = value;
        });

        this.Sa800Service.PartnershipStatement.subscribe((value) => {
          // 
          if (localStorage.getItem('NewEntry') == 'New')
            this.PartnershipStatement = false;
          else
            this.PartnershipStatement = value;
        });


      }
      else {
        // 
        this.Sa800Service.load_Supp_data();

        this.Sa800Service.Sa800.subscribe((value) => {
          this.Sa800 = value;

        });

        this.Sa800Service.Attachment.subscribe((value) => {
          this.Attachment = value;

        });

        this.Sa800Service.LandProperty.subscribe((value) => {
          // 
          this.LandProperty = value;

        });

        this.Sa800Service.Foreign.subscribe((value) => {
          this.Foreign = value;

        });

        this.Sa800Service.Trade.subscribe((value) => {
          this.Trade = value;

        });

        this.Sa800Service.ChargeableAssets.subscribe((value) => {
          this.ChargeableAssets = value;

        });

        this.Sa800Service.MemberIsCompany.subscribe((value) => {
          ////// 
          this.MemberIsCompany = value;

        });

        this.Sa800Service.MemberIsNonResident.subscribe((value) => {
          this.MemberIsNonResident = value;

        });

        this.Sa800Service.PartnerOfBusinessAbroad.subscribe((value) => {
          this.PartnerOfBusinessAbroad = value;

        });

        this.Sa800Service.EuroEconomicInterestGrouping.subscribe((value) => {
          this.EuroEconomicInterestGrouping = value;

        });

        this.Sa800Service.Foreign.subscribe((value) => {
          this.Foreign = value;

        });

        this.Sa800Service.IncomeNotIncludedElsewhere.subscribe((value) => {
          this.IncomeNotIncludedElsewhere = value;

        });

        this.Sa800Service.InterestEtcWithTaxTakenOff.subscribe((value) => {
          this.InterestEtcWithTaxTakenOff = value;

        });

        this.Sa800Service.DetailsIncorrect.subscribe((value) => {
          this.DetailsIncorrect = value;

        });
        this.Sa800Service.PartnershipStatement.subscribe((value) => {
          this.PartnershipStatement = value;
        });
      }

    }

    else {

      this.Sa800 = true;
      this.Attachment = true;

      this.Sa800Service.LandProperty.subscribe((value) => {
        // 
        this.LandProperty = false;

      });

      this.Sa800Service.Foreign.subscribe((value) => {
        this.Foreign = false;

      });

      this.Sa800Service.Trade.subscribe((value) => {
        this.Trade = false;

      });

      this.Sa800Service.ChargeableAssets.subscribe((value) => {
        this.ChargeableAssets = false;

      });

      this.Sa800Service.MemberIsCompany.subscribe((value) => {
        ////// 
        this.MemberIsCompany = false;

      });

      this.Sa800Service.MemberIsNonResident.subscribe((value) => {
        this.MemberIsNonResident = false;

      });

      this.Sa800Service.PartnerOfBusinessAbroad.subscribe((value) => {
        this.PartnerOfBusinessAbroad = false;

      });

      this.Sa800Service.EuroEconomicInterestGrouping.subscribe((value) => {
        this.EuroEconomicInterestGrouping = false;

      });

      this.Sa800Service.Foreign.subscribe((value) => {
        this.Foreign = false;

      });

      this.Sa800Service.IncomeNotIncludedElsewhere.subscribe((value) => {
        this.IncomeNotIncludedElsewhere = false;

      });

      this.Sa800Service.InterestEtcWithTaxTakenOff.subscribe((value) => {
        this.InterestEtcWithTaxTakenOff = false;

      });

      this.Sa800Service.DetailsIncorrect.subscribe((value) => {
        this.DetailsIncorrect = false;

      });
      this.Sa800Service.PartnershipStatement.subscribe((value) => {
        this.PartnershipStatement = false;
      });

      localStorage.removeItem("clearform");
    }

    this.collapsePlusMinus = "+";
    this.collapseValue = false;
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  Taxadviser() {
    this.dashboard.TaxadviserFlag = true;
    this.router.navigate(['dashboard'])   //taxadviser
  }
  UserManagment() {
    this.router.navigate(['dashboard/usermanagement'])
  }
  FBI() {
    if (this.RecordID) {
      this.route.navigate(["/FBI"]);
    }
    else {
      Swal.fire({
        title: 'Andica SA800 Partnership',
        icon: 'info',
        text: 'There is no Partnership record opened. Open an existing Partnership record or create a new record',
        showCancelButton: false,
        confirmButtonText: 'Ok',
      }).then((result) => {
        if (result.value) {

        }
      })
    }
  }
  FBIReceipt() {
    this.route.navigate(["/FBI/Receipt"]);
  }

  //status open close
  openCloseStatus(i) {
    debugger;
    if ($("#FormName" + i).hasClass("show")) {
      $("#index" + i).text("+");
    }
    else if ($("#FormName" + i).not("show")) {
      $("#index" + i).text("-");
    }
  }

  Goto_Perticular_page(PageName) {
    var windowsize = $(window).width();
    if ((windowsize <= 801) && $("#layoutSidenav_nav").show()) {
      $("#layoutSidenav_nav").hide();
    }

    if (PageName == 'SA800')
      this.route.navigate(['/sa800main/partnershipinfo' + '']);
    else if (PageName == 'UK Property')
      this.route.navigate(['sa801']);
    else if (PageName == 'Foreign')
      this.route.navigate(['sa802']);
    else if (PageName == 'Partnership Statements')
      this.route.navigate(['sa800ps']);
    else if (PageName == 'Trading and Professional')
      this.route.navigate(['sa800tp']);

    else if (PageName == 'Savings and Investments')
      this.route.navigate(['sa804']);
    else if (PageName == 'Disposal and Chargeable Assets')
      this.route.navigate(['sa803']);

    else if (PageName == "Declaration")
      this.route.navigate(["/reports/taxreturns/", PageName]);
    else if (PageName == "Client")
      this.route.navigate(["/reports/taxreturns/", PageName]);
    else if (PageName == "Attachments")
      this.route.navigate(["sa800ps/attachments"]);
  }
  //Goto_Perticular_Form(PageName) {
  //   

  //  // SA800
  //  if (PageName == 'SA800')
  //    this.route.navigate(['/sa800main/partnershipinfo' + '']);
  //  else if (PageName == 'Partnership Information')
  //    this.route.navigate(['/sa800main/partnershipinfo']);
  //  else if (PageName == 'HM Revenue & Customs')
  //    this.route.navigate(['sa800main/hmrcdetails']);
  //  else if (PageName == 'Other Information')
  //    this.route.navigate(['/sa800main/otherinformation']);
  //  else if (PageName == 'Declaration')
  //    this.route.navigate(['/sa800main/declarationform']);
  //  else if (PageName == 'SA800_Attachment')
  //    this.route.navigate(['/sa800ps/attachments']);
  //  else if (PageName == 'Business and Investment')
  //    this.route.navigate(['/sa800main/formselection']);

  //  //SA801

  //  else if (PageName == 'Partnership Details.')
  //    this.route.navigate(['/sa801']);
  //  else if (PageName == 'Other Property Income')
  //    this.route.navigate(['/sa801/Page02']);

  //  //SA802

  //  else if (PageName == 'Interest and Other Savings Income')
  //    this.route.navigate(['/sa802']);
  //  else if (PageName == 'Dividends.')
  //    this.route.navigate(['/sa802/Page2']);
  //  else if (PageName == 'Income from Land and property')
  //    this.route.navigate(['/sa802/Page3']);
  //  else if (PageName == 'Land and Property Abroad')
  //    this.route.navigate(['/sa802/Page4']);
  //  else if (PageName == "Summary")
  //    this.route.navigate(['/sa802/Page5']);

  //  // SA803 TP

  //  else if (PageName == "Partnership Details")
  //    this.route.navigate(['/sa800tp/Page1/1']);

  //  else if (PageName == 'Income and Professional Income')
  //    this.route.navigate(['/sa800tp/Page2/2']);
  //  else if (PageName == "Taxable Profit or Loss")
  //    this.route.navigate(['/sa800tp/Page3/3']);
  //  else if (PageName == "Summary of Balance Sheet")
  //    this.route.navigate(['/sa800tp/Page4/4'])




  //  else if (PageName == "Disposals of Chargeable Assets")
  //    this.route.navigate(['/sa803'])
  //  else if (PageName == "Additional Information")
  //    this.route.navigate(['/sa803/page2'])



  //  else if (PageName == "Interest and Alternative Finance Receipts")
  //    this.route.navigate(['/sa804'])



  //  else if (PageName == "Dividends")
  //    this.route.navigate(['/sa804/page02']);




  //  else if (PageName == "SA800_Partnership_Statement")
  //    this.route.navigate(['/sa800ps']);





  //}

  Goto_Perticular_Form(PageName) {


    // SA800
    if (PageName == 'SA800')
      this.route.navigate(['/sa800main/partnershipinfo' + '']);
    else if (PageName == 'Partnership information')
      this.route.navigate(['/sa800main/partnershipinfo']);
    else if (PageName == 'HM Revenue & Customs')
      this.route.navigate(['sa800main/hmrcdetails']);
    else if (PageName == 'Other information')
      this.route.navigate(['/sa800main/otherinformation']);
    else if (PageName == 'Declaration')
      this.route.navigate(['/sa800main/declarationform']);
    else if (PageName == 'SA800_Attachment')
      this.route.navigate(['/sa800ps/attachments']);
    else if (PageName == 'Business and investment')
      this.route.navigate(['/sa800main/formselection']);

    //SA801

    else if (PageName == 'Partnership details.')
      this.route.navigate(['/sa801']);
    else if (PageName == 'Other property income')
      this.route.navigate(['/sa801/Page02']);

    //SA802

    else if (PageName == 'Interest and other savings income')
      this.route.navigate(['/sa802']);
    else if (PageName == 'Dividends.')
      this.route.navigate(['/sa802/Page2']);
    else if (PageName == 'Income from land and property')
      this.route.navigate(['/sa802/Page3']);
    else if (PageName == 'Land and property abroad')
      this.route.navigate(['/sa802/Page4']);
    else if (PageName == "Summary")
      this.route.navigate(['/sa802/Page5']);

    // SA803 TP

    else if (PageName == "Partnership details")
      this.route.navigate(['/sa800tp/Page1/1']);

    else if (PageName == 'Income and professional income')
      this.route.navigate(['/sa800tp/Page2/2']);
    else if (PageName == "Taxable profit or loss")
      this.route.navigate(['/sa800tp/Page3/3']);
    else if (PageName == "Summary of balance sheet")
      this.route.navigate(['/sa800tp/Page4/4'])




    else if (PageName == "Disposals of chargeable assets")
      this.route.navigate(['/sa803'])
    else if (PageName == "Additional information")
      this.route.navigate(['/sa803/page2'])



    else if (PageName == "Interest and alternative finance receipts")
      this.route.navigate(['/sa804'])



    else if (PageName == "Dividends")
      this.route.navigate(['/sa804/page02']);




    else if (PageName == "Individual partner details")
      this.route.navigate(['/sa800ps']);





  }

  GotoHome() {


    //this.Sa100Service.setTaxpayerName("");
    this.Sa800Service.SetSelectedFormsValue(false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false);
    localStorage.removeItem('SubmissionStatus');
    //localStorage.removeItem("LoginName")
    localStorage.removeItem("PartnershipRefNo")
    localStorage.removeItem('Login_Credentials')
    localStorage.removeItem('SubmissionType')
    localStorage.removeItem('PartnershipName')
    localStorage.removeItem('RecordID')
    localStorage.removeItem('Amended_Return')
    localStorage.removeItem("SelectedUser");
    localStorage.removeItem("UserSelected");
    localStorage.removeItem("GatewayPassword");
    localStorage.removeItem("GatewayId");
    localStorage.removeItem("Sender");
    localStorage.removeItem('Amended_Return')
    localStorage.setItem('TempStorage', '1')

    this.route.navigate(["/dashboard"]);
    if (this.router.url == "/dashboard") {
      window.location.reload();
    }
  }
  checkforms_FormStatus() {
    debugger;
    for (let i = 0; i < this.order1.length; i++) {

      if (this.order1[i].FormStatus == false) {
        //Swal.fire('', this.order1[i].PageName + 'form(s) are incomplete, remove the form if not required.')
        this.FormStatus = false;
        return false;
      }
    }

  }
  NavigatePlainReport(value) {
    debugger;
    //this.route.navigate(["/reports/taxreturns/", value]);
    //this.Sa800Service.setFormStatus(this.FormStatus)

    if (value == 'ListofPartnerships') {
      this.route.navigate(["/reports/taxreturns/", value]);
    }
    else {
      if (this.RecordID) {

        this.checkforms_FormStatus()
        this.Sa800Service.setFormStatus(this.FormStatus)

        if (this.FormStatus)

          this.route.navigate(["/reports/taxreturns/", value]);
        else {
          Swal.fire({
            title: 'Andica SA800 Partnership',
            icon: 'info',
            html: "IRMark has not been generated as some of the forms are incomplete. <br>Hence reports can be viewed but cannot be printed.<br> Ensure all tax return forms are complete before printing reports.<br>Refer to 'Printing Reports' section of user guide for details.",
            showCancelButton: false,
            confirmButtonText: 'Ok',
          }).then((result) => {
            if (result.value) {
              this.route.navigate(["/reports/taxreturns/", value]);
            }
          })

        }
      }
      else if (value == 'ListofPartnerships') {
        this.route.navigate(["/reports/taxreturns/", value]);
      }
      else {
        Swal.fire({
          title: 'Andica SA800 Partnership',
          icon: 'info',
          text: 'There is no partnership record opened. Open an existing partnership record or create a new record.',
          showCancelButton: false,
          confirmButtonText: 'Ok',
        }).then((result) => {
          if (result.value) {

          }
        })
      }
    }
  }
  Clear_Forms() {
    //this.Sa100 = true;
    //this.Attachment = true;
    //this.TaxCalculation = true;
    this.clear_value = 1
    if (this.RecordID) {
      Swal.fire({
        // title: 'Do you want to continue?',
        title: 'Andica SA800 Partnership',
        icon: 'question',
        text: 'Reset all forms, clears all the data and is irreversible, Do you want to continue?',
        showCancelButton: true,
        confirmButtonText: 'Ok',
      }).then((result) => {
        if (result.value) {
          //window.location.href = "/dashboard";
          this.Sa800Service.Clear_Forms(this.RecordID).subscribe(data => {
            localStorage.setItem("clearform", "true")
            this.route.navigate(['/dashboard']);
          })
        }
      })
    }
    else {
      Swal.fire({
        title: 'Andica SA800 Partnership',
        icon: 'info',
        // title: 'Do you want to continue?',
        text: 'There is no partnership record opened. Open an existing partnership record or create a new record.',
        showCancelButton: false,
        confirmButtonText: 'Ok',
      }).then((result) => {
        if (result.value) {
          //window.location.href = "/dashboard";
        }
      })
    }
  }

  Dataimport() {
    debugger;
    if (this.usersService.issupplyid == false) {
      var TaxPayerId = localStorage.getItem("RecordID");
    }
    else {
       localStorage.setItem("RecordID","");
    }
    this.usersService.iscancel = false;
    //var TaxPayerId = localStorage.getItem("RecordID");
    //if (TaxPayerId) {
    //  if (this.usersService.windowsimportbtn && this.usersService.impstatus == 3)
    //    this.usersService.Dataimport_Method(true)
    //  else if (this.usersService.webimportbtn && this.usersService.impstatus == 1)
    //    this.usersService.Dataimport_Method(true)
    //  else
    //    Swal.fire("", "Previous year(s) data is not available for this Partnership record.")
    //  //$("#Importdatamodal").modal('show');
    //}

    if (TaxPayerId) {
      TaxPayerId = CryptoJS.AES.decrypt(localStorage.getItem("RecordID"), "SA800").toString(CryptoJS.enc.Utf8)
      //this.localService.Dataimport_Method(true)
      if (this.usersService.windowsimportbtn && this.usersService.impstatus == 3) {
        if (this.route.url == "/dashboard") {
          this.sidNavService.supplyFormsanddata(TaxPayerId);
          this.usersService.issupplyid = false;
        }
        else {
          this.validationmessage(TaxPayerId);
        }
      }
      else if (this.usersService.webimportbtn && this.usersService.impstatus == 1) {
        if (this.route.url == "/dashboard") {
          this.sidNavService.supplyFormsanddata(TaxPayerId);
        }
        else {
          this.validationmessage(TaxPayerId)
        }
      }

      else
        Swal.fire("Andica SA800 Partnership", "Previous year’s data is not available for this Partnership record.", "info")
    }



    else if (this.usersService.webimportbtn) {
      this.dashboard.getAllTaxpayer(localStorage.getItem('MasterUserID'), this.usersService.Current_taxyear).subscribe(resp => {
        this.usersService.allrecords = resp;
        this.usersService.webformsanddata_Method(true);
      })
    }
    else if (this.usersService.windowsimportbtn) {
      this.dashboard.getAllTaxpayer(localStorage.getItem('MasterUserID'), this.usersService.Current_taxyear).subscribe(resp => {
        this.usersService.allrecords = resp;
        this.usersService.webformsanddata_Method(true);
      })
    }
  }
  //var TaxPayerId = localStorage.getItem("RecordID");
  //if (TaxPayerId) {
  //  //this.usersService.Dataimport_Method(true)
  //  $("#Importdatamodal").modal('show');
  //}
  //else if (this.usersService.webimportbtn) {
  //  this.usersService.webformsanddata_Method(true);
  //}
  //else if (this.usersService.windowsimportbtn) {
  //  Swal.fire("", "No taxpayer is open to import form,data and values from last year.")
  //}


  validationmessage(TaxPayerId) {
    debugger;
    Swal.fire({
      title: 'Andica SA800 Partnership',
      icon: 'question',
      html: "Data entry form is open. <br/> Do you want to save and close the form and continue with Importing ?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      allowOutsideClick: false
    }).then(result => {
      if (result.value) {
        this.route.navigate(['/dashboard']);
        this.usersService.iscancel = false;
        this.sidNavService.supplyFormsanddata(TaxPayerId);
      }
    })
  }



  Winowsimport() {
    debugger;
    if (this.route.url == "/dashboard") {
      this.usersService.windowsimport_Method(true)
    }
    else if (this.route.url == "/dashboard/quickstart") {
      this.router.navigate(['dashboard'])
      this.usersService.windowsimport_Method(true)
    }
    else {
      Swal.fire("Andica SA800 Partnership", "Close Andica SA800 Partnership forms before Importing a file.", "info");
    }
  }

  Webimport() {
    debugger;
    if (this.route.url == "/dashboard") {
      this.usersService.clicked = true;
      this.dashboard.getAllTaxpayer(localStorage.getItem('MasterUserID'), this.usersService.PreviousFulltaxyear).subscribe((resp) => {
        this.usersService.webimportdata = resp;
        this.usersService.webimport_Method(true);
      })
    }
    else if (this.route.url == "/dashboard/quickstart") {
      this.router.navigate(['dashboard'])
      this.usersService.webimport_Method(true)
    }
    else {
      Swal.fire("Andica SA800 Partnership", "Close Andica SA800 Partnership forms before Importing a file.", "info");
    }
  }
}
