import { Injectable } from '@angular/core';
import { hmrcdetails } from './hmrcdetails/hmrcdetails';
import { partnershipinfo } from './partnershipinfo/partnershipinfo';

/*import { Injectable } from '@angular/core';*/
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable, from, Subject, BehaviorSubject, observable } from 'rxjs';
//import { environment } from '../../../../environments/environment';
import { Getupdate } from './partnershipinfo/partnershipinfo';

import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { suppform } from './formselection/suppform';
import * as CryptoJS from 'crypto-js';
import { otherinformation } from './otherinformation/otherinformation';
import { Selectedforms } from './formselection/Selectedforms';
import { declarationform } from './declarationform/declarationform';
import { GetUser_Licence_Details } from './declarationform/declarationform';
import { ConfigurationService } from '../../Configuration/configuration.service';

//import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})

export class Sa800partnershipinfoService {

  Sa800Subject = new BehaviorSubject<boolean>(false);
  Sa800: Observable<boolean>;

  AttachmentSubject = new BehaviorSubject<boolean>(false);
  Attachment: Observable<boolean>;


  PartnershipStatementSubject = new BehaviorSubject<boolean>(false);
  PartnershipStatement: Observable<boolean>;


  LandPropertySubject = new BehaviorSubject<boolean>(false);
  LandProperty: Observable<boolean>;


  ForeignSubject = new BehaviorSubject<boolean>(false);
  Foreign: Observable<boolean>;

  TradeSubject = new BehaviorSubject<boolean>(false);
  Trade: Observable<boolean>;

  ChargeableAssetsSubject = new BehaviorSubject<boolean>(false);
  ChargeableAssets: Observable<boolean>;

  MemberIsCompanySubject = new BehaviorSubject<boolean>(false);
  MemberIsCompany: Observable<boolean>;

  MemberIsNonResidentSubject = new BehaviorSubject<boolean>(false);
  MemberIsNonResident: Observable<boolean>;

  PartnerOfBusinessAbroadSubject = new BehaviorSubject<boolean>(false);
  PartnerOfBusinessAbroad: Observable<boolean>;

  EuroEconomicInterestGroupingSubject = new BehaviorSubject<boolean>(false);
  EuroEconomicInterestGrouping: Observable<boolean>;

  GoodsExportedSubject = new BehaviorSubject<boolean>(false);
  GoodsExported: Observable<boolean>;

  ServicesExportedSubject = new BehaviorSubject<boolean>(false);
  ServicesExported: Observable<boolean>;

  NeitherGoodsNorServicesExportedSubject = new BehaviorSubject<boolean>(false);
  NeitherGoodsNorServicesExported: Observable<boolean>;



  IncomeNotIncludedElsewhereSubject = new BehaviorSubject<boolean>(false);
  IncomeNotIncludedElsewhere: Observable<boolean>;


  InterestEtcWithTaxTakenOffSubject = new BehaviorSubject<boolean>(false);
  InterestEtcWithTaxTakenOff: Observable<boolean>;

  DetailsIncorrectSubject = new BehaviorSubject<boolean>(false);
  DetailsIncorrect: Observable<boolean>;


  nextPageSubject = new BehaviorSubject<string>(null);
  nextPage = this.nextPageSubject.asObservable();

  IsPartnershipShortSubject = new BehaviorSubject<boolean>(null);
  IsPartnershipShort = this.IsPartnershipShortSubject.asObservable();


  TaxPayerNameforDashbordSubject = new BehaviorSubject<string>(null);
  _TaxPayerNameforDashbord: Observable<string>;

  formStatusSubject = new BehaviorSubject<boolean>(false);
  _formStatus: Observable<boolean>;

  UserID: string;
  url = this.configurationService.get().apiEndpoint + '/api/PartnerShipInfo';
  url1Dashboard = this.configurationService.get().apiEndpoint + '/api/Dashboard';
  urlsubcription = this.configurationService.get().subscriptionURL + '/api/Values/GetPlansInformation?CentralUserID='; //Comment by kiran need to uncomment when subscription
  formname = [];
  modulename: string;

  constructor(private http: HttpClient, private router: Router, private configurationService: ConfigurationService) {

    this._formStatus = this.formStatusSubject.asObservable();

    this.LandProperty = this.LandPropertySubject.asObservable();

    this.Foreign = this.ForeignSubject.asObservable();

    this.Trade = this.TradeSubject.asObservable();

    this.ChargeableAssets = this.ChargeableAssetsSubject.asObservable();

    this.MemberIsCompany = this.MemberIsCompanySubject.asObservable();

    this.MemberIsNonResident = this.MemberIsNonResidentSubject.asObservable();

    this.PartnerOfBusinessAbroad = this.PartnerOfBusinessAbroadSubject.asObservable();

    this.EuroEconomicInterestGrouping = this.EuroEconomicInterestGroupingSubject.asObservable();

    this.GoodsExported = this.GoodsExportedSubject.asObservable();

    this.ServicesExported = this.ServicesExportedSubject.asObservable();

    this.NeitherGoodsNorServicesExported = this.NeitherGoodsNorServicesExportedSubject.asObservable();

    this.IncomeNotIncludedElsewhere = this.IncomeNotIncludedElsewhereSubject.asObservable();

    this.InterestEtcWithTaxTakenOff = this.InterestEtcWithTaxTakenOffSubject.asObservable();

    this.DetailsIncorrect = this.DetailsIncorrectSubject.asObservable();

    this.PartnershipStatement = this.PartnershipStatementSubject.asObservable();

    this.Sa800 = this.Sa800Subject.asObservable();

    this.Attachment = this.AttachmentSubject.asObservable();

  }

  dataimportwithxml(data) {
    return this.http.post(this.url + '/Importdatawithxml', data);
  }

  get Sa800Value(): boolean {
    return this.Sa800Subject.value;
  }

  get_userdata(dataId: any, TaxYear): Observable<partnershipinfo> {
     
    return this.http.get<partnershipinfo>(this.url + '/UserDetails/' + dataId + '/' + TaxYear);
  }

  get_userdataLY(dataId: any,TaxYear): Observable<partnershipinfo> {

    return this.http.get<partnershipinfo>(this.url + '/UserDetailsLY/' + dataId + '/' + TaxYear);
  }


  Validated(dataId, name, recordid,taxyear): Observable<Getupdate> {

    return this.http.get<Getupdate>(this.url + '/ValidateUTR/' + dataId + '/' + name + '/' + recordid + '/' + taxyear);
  }

  ValidateOnlyUTR(UTR): Observable<Getupdate> {
     
    return this.http.get<Getupdate>(this.url + '/ValidateUTR_Algoritham/' + UTR);
  }

  Importget_userdata(Name: any, TaxYear) {
    return this.http.get(this.url + '/UserDetailsimport/' + Name + '/' + TaxYear);
  }

  GetBase32Format(base64format): Observable<string> {
    return this.http.get<string>(this.url + '/GetBase32format/' + base64format);
  }

  Getsubscriptioncheck(CentralUserID) {
    return this.http.get(this.urlsubcription + CentralUserID)
  }
  GetUser_Licence_Details(id1, TaxYear): Observable<GetUser_Licence_Details> {
    return this.http.get<GetUser_Licence_Details>(this.url1Dashboard + '/Get_Master_Licence_Details/' + id1 + '/' + TaxYear);
  }
  insert(data: partnershipinfo): Observable<partnershipinfo> {
     
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<partnershipinfo>(this.url + '/Create', data, httpOptions);
    //  return this.http.post<HMRCDATA>(this.url, data, httpOptions);
  }

  get_hmrcId(dataId: any): Observable<hmrcdetails> {
     
    return this.http.get<hmrcdetails>(this.url + '/hmrcDetails/' + dataId);
  }

  get_hmrcIdLY(dataId: any): Observable<hmrcdetails> {
     
    return this.http.get<hmrcdetails>(this.url + '/hmrcDetailsLY/' + dataId);
  }

  get_otherinformationId(dataId: any): Observable<otherinformation> {
     
    return this.http.get<otherinformation>(this.url + '/GetOtherInformation/' + dataId);
  }

  get_otherinformationIdLY(dataId: any): Observable<otherinformation> {

    return this.http.get<otherinformation>(this.url + '/GetOtherInformationLY/' + dataId);
  }

  insertotherinformation(data: otherinformation): Observable<otherinformation> {
     
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<otherinformation>(this.url + '/InsertOtherinformation', data, httpOptions);
    //  return this.http.post<HMRCDATA>(this.url, data, httpOptions);
  }

  GetMasterUID(UserMaster_Loginname) {
    return this.http.get(this.url1Dashboard + '/GET_User_Login_P/' + btoa(UserMaster_Loginname))
  }

  insertTime(data) {
     
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.url1Dashboard + '/Loginout', data, httpOptions);
  }

  //GetUser_Licence_Details(id1): Observable<GetUser_Licence_Details> {
  //  return this.http.get<GetUser_Licence_Details>(this.url + '/GET_User_Licence_Details_P/' + id1);
  //}

  insertdeclarationform(data: declarationform): Observable<declarationform> {
     
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<declarationform>(this.url + '/InsertDeclarationForm', data, httpOptions);
    //  return this.http.post<HMRCDATA>(this.url, data, httpOptions);
  }

  getsup_reportsdata(dataId: any) {
     
    return this.http.get(this.url + '/SuppDetailsReports/' + dataId);
  }

  insert1(data: any) {
     
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<partnershipinfo>(this.url + '/Create', + data, httpOptions);
    /*return this.http.post<partnershipinfo>(this.url, data, httpOptions);*/
  }
  inserthmrc(data: hmrcdetails): Observable<hmrcdetails> {
     
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<hmrcdetails>(this.url + '/Create2', data, httpOptions);
    //  return this.http.post<HMRCDATA>(this.url, data, httpOptions);
  }

  setTaxpayerName(taxpayerName) {
     
    if (taxpayerName != "")
      this.TaxPayerNameforDashbordSubject.next(taxpayerName.valueOf());
    else
      this.TaxPayerNameforDashbordSubject.next(taxpayerName.valueOf());
  }

  SetSelectedFormsValue(ukproperty, foreign, trade, assets, company, notresident, abroad, eeig, goodexported, serviceexported, nithergoodserviceexported, otherincome, bankandsocities, taxreturnwrong, partnershipstatement, home) {
     

    if (home == true) {
      this.Sa800Subject.next(true);
      this.AttachmentSubject.next(true);

    }
    else {

      this.PartnershipStatementSubject.next(false)
      this.Sa800Subject.next(false);
      this.AttachmentSubject.next(false);

    }

    if (ukproperty != null || ukproperty != undefined)
      this.LandPropertySubject.next(ukproperty.valueOf());
    else
      this.LandPropertySubject.next(false);




    if (foreign != null || foreign != undefined)
      this.ForeignSubject.next(foreign.valueOf());
    else
      this.ForeignSubject.next(false);


    if (trade != null || trade != undefined)
      this.TradeSubject.next(trade.valueOf());
    else
      this.TradeSubject.next(false);



    if (assets != null || assets != undefined)
      this.ChargeableAssetsSubject.next(assets.valueOf());
    else
      this.ChargeableAssetsSubject.next(false);


    if (company != null || company != undefined)
      this.MemberIsCompanySubject.next(company.valueOf());
    else
      this.MemberIsCompanySubject.next(false);


    if (notresident != null || notresident != undefined)
      this.MemberIsNonResidentSubject.next(notresident.valueOf());
    else
      this.MemberIsNonResidentSubject.next(false);




    if (abroad != null || abroad != undefined)
      this.PartnerOfBusinessAbroadSubject.next(abroad.valueOf());
    else
      this.PartnerOfBusinessAbroadSubject.next(false);





    if (eeig != null || eeig != undefined)
      this.EuroEconomicInterestGroupingSubject.next(eeig.valueOf());
    else
      this.EuroEconomicInterestGroupingSubject.next(false);


    if (goodexported != null || goodexported != undefined)
      this.GoodsExportedSubject.next(eeig.valueOf());
    else
      this.GoodsExportedSubject.next(false);

    if (serviceexported != null || serviceexported != undefined)
      this.ServicesExportedSubject.next(eeig.valueOf());
    else
      this.ServicesExportedSubject.next(false);

    if (nithergoodserviceexported != null || nithergoodserviceexported != undefined)
      this.NeitherGoodsNorServicesExportedSubject.next(eeig.valueOf());
    else
      this.NeitherGoodsNorServicesExportedSubject.next(false);

    if (otherincome != null || otherincome != undefined)
      this.IncomeNotIncludedElsewhereSubject.next(otherincome.valueOf());
    else
      this.IncomeNotIncludedElsewhereSubject.next(false);


    if (bankandsocities != null || bankandsocities != undefined)
      this.InterestEtcWithTaxTakenOffSubject.next(bankandsocities.valueOf());
    else
      this.InterestEtcWithTaxTakenOffSubject.next(false);



    if (taxreturnwrong != null || taxreturnwrong != undefined)
      this.DetailsIncorrectSubject.next(taxreturnwrong.valueOf());
    else
      this.DetailsIncorrectSubject.next(false);
     
    if (partnershipstatement != null || partnershipstatement != undefined)
      this.PartnershipStatementSubject.next(partnershipstatement.valueOf());
    else
      this.PartnershipStatementSubject.next(false);





  }

  load_Supp_data() {
     
    this.UserID = (localStorage.getItem("RecordID") ? CryptoJS.AES.decrypt(localStorage.getItem("RecordID"), "SA800").toString(CryptoJS.enc.Utf8) : "");



    if (this.UserID != "" && this.UserID != null) {

      this.Sa800Subject.next(true);
      this.AttachmentSubject.next(true);

      this.get_Supp_data_Id(this.UserID).subscribe(data => {

        if (data.LandProperty != null)
          this.LandPropertySubject.next(data.LandProperty.valueOf())
        else
          this.LandPropertySubject.next(false)
        if (data.Foreign != null)
          this.ForeignSubject.next(data.Foreign.valueOf())
        else
          this.ForeignSubject.next(false)
        if (data.Trade != null)
          this.TradeSubject.next(data.Trade.valueOf());
        else
          this.TradeSubject.next(false);
        if (data.ChargeableAssets != null)
          this.ChargeableAssetsSubject.next(data.ChargeableAssets.valueOf());
        else
          this.ChargeableAssetsSubject.next(false);

        if (data.MemberIsCompany != null)
          this.MemberIsCompanySubject.next(data.MemberIsCompany.valueOf());
        else
          this.MemberIsCompanySubject.next(false);
        if (data.MemberIsNonResident != null)
          this.MemberIsNonResidentSubject.next(data.MemberIsNonResident.valueOf());
        else
          this.MemberIsNonResidentSubject.next(false);

        if (data.PartnerOfBusinessAbroad != null)
          this.PartnerOfBusinessAbroadSubject.next(data.PartnerOfBusinessAbroad.valueOf());
        else
          this.PartnerOfBusinessAbroadSubject.next(false);

        if (data.EuroEconomicInterestGrouping != null)
          this.EuroEconomicInterestGroupingSubject.next(data.EuroEconomicInterestGrouping.valueOf());
        else
          this.EuroEconomicInterestGroupingSubject.next(false);


        if (data.GoodsExported != null)
          this.GoodsExportedSubject.next(data.GoodsExported.valueOf());
        else
          this.GoodsExportedSubject.next(false);


        if (data.ServicesExported != null)
          this.ServicesExportedSubject.next(data.ServicesExported.valueOf());
        else
          this.ServicesExportedSubject.next(false);;

        if (data.NeitherGoodsNorServicesExported != null)
          this.NeitherGoodsNorServicesExportedSubject.next(data.NeitherGoodsNorServicesExported.valueOf());
        else
          this.NeitherGoodsNorServicesExportedSubject.next(false);


        if (data.IncomeNotIncludedElsewhere != null)
          this.IncomeNotIncludedElsewhereSubject.next(data.IncomeNotIncludedElsewhere.valueOf());
        else
          this.IncomeNotIncludedElsewhereSubject.next(false);
        if (data.InterestEtcWithTaxTakenOff != null)
          this.InterestEtcWithTaxTakenOffSubject.next(data.InterestEtcWithTaxTakenOff.valueOf());
        else
          this.InterestEtcWithTaxTakenOffSubject.next(false);

        if (data.DetailsIncorrect != null)

          this.DetailsIncorrectSubject.next(data.DetailsIncorrect.valueOf());
        else
          this.DetailsIncorrectSubject.next(false);
         

        if (data.PartnershipStatement != null)
          this.PartnershipStatementSubject.next(data.PartnershipStatement.valueOf());
        else
          this.PartnershipStatementSubject.next(false);

        if (data.Trade == true && data.LandProperty == true) {
          //returnValue = true
          this.IsPartnershipShortSubject.next(true)
          //Condition 
        }
        else {
          this.IsPartnershipShortSubject.next(false)
          //Condition 

        }
      });
    }


  }

  get_Supp_data_Id(dataId: any): Observable<suppform> {
     
    return this.http.get<suppform>(this.url + '/SuppDetails/' + dataId);
  }

  get_Supp_data_IdLY(dataId: any): Observable<suppform> {
     
    return this.http.get<suppform>(this.url + '/SuppDetailsLY/' + dataId);
  }

  get_Declaratin_data_Id(dataId: any): Observable<declarationform> {
     
    return this.http.get<declarationform>(this.url + '/DeclarationDetails/' + dataId);
  }

  get_Declaratin_data_IdLY(dataId: any): Observable<declarationform> {
     
    return this.http.get<declarationform>(this.url + '/DeclarationDetailsLY/' + dataId);
  }

  insertsuppform(data: suppform): Observable<suppform> {
     
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<suppform>(this.url + '/suppform1', data, httpOptions);

  }

  CheckPartnerShipFullAndShort() {
     
    var returnValue = false;
    this.UserID = CryptoJS.AES.decrypt(localStorage.getItem("RecordID"), "SA800").toString(CryptoJS.enc.Utf8);
    if (this.UserID != '') {
      this.get_Supp_data_Id(this.UserID).subscribe(data => {
        if ((data.LandProperty && data.Trade) && (!data.Foreign && !data.ChargeableAssets
          && !data.IncomeNotIncludedElsewhere && !data.InterestEtcWithTaxTakenOff && !data.DetailsIncorrect
          && data.MemberIsCompany && data.MemberIsNonResident && data.PartnerOfBusinessAbroad && data.EuroEconomicInterestGrouping)) {

          //returnValue = true
          this.IsPartnershipShortSubject.next(true)
          //Condition 
        }
        else {
          this.IsPartnershipShortSubject.next(false)
          //Condition 

        }
      }
      )
    }
    return returnValue;
  }


  Clear_Forms(id) {
     
    return this.http.get(this.url + '/Clear_Forms/' + id)
  }

  GetNextSelectedForm(formName1: string) {
     
    if (CryptoJS.AES.decrypt(localStorage.getItem("RecordID"), "SA800").toString(CryptoJS.enc.Utf8)) {
      this.get_ActiveForms_data_Id(CryptoJS.AES.decrypt(localStorage.getItem("RecordID"), "SA800").toString(CryptoJS.enc.Utf8)).subscribe(data => {

        for (let i = 0; i < data.length; i++) {
          if (formName1.toString() == data[i].formname) {
            if (i + 1 > data.length) {
              this.modulename = "SA800ps";
              this.nextPageSubject.next(this.modulename);
            }
            else {
              this.modulename = data[i + 1].modulename;
              this.nextPageSubject.next(this.modulename);
            }
          }
        }

      });
    }

  }

  get_ActiveForms_data_Id(dataId: any): Observable<Selectedforms[]> {
    return this.http.get<Selectedforms[]>(this.url + '/GetUserActiveForm/' + dataId);
  }

  setFormStatus(status) {
     

    this.formStatusSubject.next(status.valueOf());

  }
  //Getsubscriptioncheck(CentralUserID) {
  //  return this.http.get(this.urlsubcription + CentralUserID)          //Comment by kiran need to uncomment when subscription
  //}
}

  //Clear_Forms(id) {
  //   
  //  return this.http.get(this.url + '/Clear_Forms/' + id)
  //}

