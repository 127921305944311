import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomError implements ErrorHandler {

  constructor() { }

  handleError(error:any):void {
    if (!environment.production) {
      console.error(error);
    }
  }
}
