import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule, NgControl } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonLayoutComponent } from './layout/common-layout/common-layout.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { SidenavComponent } from './layout/sidenav/sidenav.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
//import { HttpClientModule } from '@angular/common/http';

import { HttpClientModule } from '@angular/common/http';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { ConfigurationService } from './Configuration/configuration.service';
import { AuthConfigService } from './Configuration/auth-config.service';
import { ToastrModule } from 'ngx-toastr';
import { CustomError } from './shared/custom-error.service';
import { HelpfileComponent } from './helpfile/helpfile.component';
//import { ToastrModule } from 'ngx-toastr';
//import { AllowedRegExpDirective } from './allowed-reg-exp.directive';
//import { TextAlignmentDirective } from './text-alignment.directive';

const configurationFactory = (configurationService: ConfigurationService) => {
  return () => configurationService.loadConfiguration();
};
export function storageFactory(): OAuthStorage {
  return localStorage
}
//import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
//import { MatDatepickerModule } from '@angular/material/datepicker'
//import { MatNativeDateModule } from '@angular/material/core';


