import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Sa800partnershipinfoService } from '../app/modules/sa800main/sa800partnershipinfo.service';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  currentpath: any;
  browserName: any;
  constructor(private oauthService: OAuthService, private router: Router, private sa800Service: Sa800partnershipinfoService) { this.browserName = this.getBrowserName(); }

  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      //case agent.indexOf('firefox') > -1:
      //  return 'firefox';
      //case agent.indexOf('safari') > -1:
      //  return 'safari';
      default:
        return 'other';
    }
  }


  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    this.currentpath = state.url                                   //'users/login';      //state.url commented for local login page

    return new Promise((resolve) => {
      setTimeout(() => {

    if (this.oauthService.hasValidAccessToken()) {

      if (state.url.includes('dashboard')) {               //users/login   //using *state.url.includes('users/login')* insted of *state.url.includes('dashboard')* for local login page
        resolve(true);
      }
      //else if (state.url =='/users/login') {
      //  return true;
      //}
      else if (state.url == '/FBI/Receipt') {
        resolve(true);
      } else if (state.url == '/sa800main/partnershipinfo') {
        resolve(true);
      } else if (state.url == '/sa800main/otherinformation') {
        resolve(true);
      }
      else if (state.url == '/sa800main/declarationform') {
        resolve(true);
      }
      else if (state.url == '/reports/taxreturns/ListofPartnerships') {
        resolve(true);
      } else if (this.userActivatedRoutes()) {
        //console.log(this.userActivatedRoutes())
        // this.oauthService.initCodeFlow(); 
        resolve(true);
      }
      else {
        this.router.navigate(['dashboard']) //commented by kiran for local login page use
        // this.router.navigate(['users/login'])   //commented by kiran for B2C login page use
      }
      //return true;
    }

    else {
      this.oauthService.initCodeFlow();        //commented for local login page
      resolve(false);
      //this.router.navigate(['dashboard'])          //commented for B2C login page
        }
      }, this.browserName == 'other' ? 1000 : 1)
    })

  }

  //async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise <boolean> {
  //  this.currentpath = state.url;
  //  if (!this.authService.isAdminUser()) {
  //    return false;
  //  }
  //  return true;
  //}

  private tokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }
  userActivatedRoutes(): any {
    //var currentpath:any = state.url;
    console.log(this.currentpath)

    this.currentpath = this.currentpath.split("/");
    var path = this.currentpath[1].trim();
    console.log(path)

    //this.router.navigate(['/dashboard']);
    let UserID = CryptoJS.AES.decrypt(localStorage.getItem("RecordID"), "SA800").toString(CryptoJS.enc.Utf8);
    //}
    if (UserID != "" && UserID != null) {

      //this.sa100Service.get_Supp_data_Id(UserID).subscribe(resp=>{

      if (path == "SA800") {
        return true;
      }
      else if (path == "sa800tp") {
        return this.sa800Service.TradeSubject;
      }

      else if (path == "sa801") {
        return this.sa800Service.LandPropertySubject;
      }
      else if (path == "sa802") {
        return this.sa800Service.ForeignSubject;

      }
      else if (path == "sa803") {
        return this.sa800Service.ChargeableAssetsSubject;
      }
      else if (path == "sa804") {
        return this.sa800Service.IncomeNotIncludedElsewhereSubject;
      }
      else if (path == "sa800ps") {
        return this.sa800Service.PartnershipStatementSubject;
      }
      else if (path == "sa800ps/attachments") {
        return true;
      }
      else if (path == "FBI") {
        return true;
      } else if (path == "reports") {
        return true;
      }
      else if (path == "WizardReports") {
        return true;
      }
      else {
        return false;
      }

      // console.log(resp)
      //})
      //console.log(a)
    }
    else {
      return false;
    }
  }
}
