import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ConfigurationService } from '../../Configuration/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  Current_taxyear;
  Current_taxyear_help;
  Previous_taxyear;
  Previous_year_help;
  Next_taxyear;
  Present_year;
  Present_year_help;
  Next_year;
  Next_Next_year;
  Previous_year;
  Currenttax_shortyear;
  Currenttax_shortyear_help;
  Previous_Previousyear;
  Importrecid: any;
  dataimportpathName: any;
  dataimportName: any;
  box36label: any;
  TaxYears: any = [];
  supplyformdata: any = [];
  presentyearsupplyformdata: any = [];
  expired: boolean;
  dataimport: boolean;
  windowsimportbtn: boolean;
  webimportbtn: boolean;
  Subscriptionyear: any;
  issupplyid: boolean;
  iscancel: boolean;
  formdataid: any; impstatus: any
  partnershipname: any;
  allrecords: any;
  clicked: any;
  webimportdata: any;
  editflag: boolean;
  reportflag: any;
  public dropdown = new BehaviorSubject<any>(null);
  dropdown_status = this.dropdown.asObservable();
  public Dataimport = new BehaviorSubject<boolean>(false)
  data_import = this.Dataimport.asObservable()
  public year_dropdown = new BehaviorSubject<any>(null)
  yearchange = this.year_dropdown.asObservable();

  public windowsimport = new BehaviorSubject<boolean>(false)
  windows_import = this.windowsimport.asObservable();

  public webimport = new BehaviorSubject<boolean>(false)
  web_import = this.webimport.asObservable();

  public webformsanddata = new BehaviorSubject<boolean>(false)
  webforms_data = this.webformsanddata.asObservable();

  PreviousFulltaxyear: any;
  public formdatavalidation = new BehaviorSubject<boolean>(false)
  formsdata_validation = this.formdatavalidation.asObservable();
  constructor(private http: HttpClient, private configurationService: ConfigurationService) { }

  dropdown_method(value) {
    this.dropdown.next(value);
  }

  Year_method(value) {
    this.year_dropdown.next(value)
  }
  Dataimport_Method(value) {
    this.Dataimport.next(value);
  }

  validationmessage(value) {
    this.formdatavalidation.next(value);
  }
  windowsimport_Method(value) {
    this.windowsimport.next(value)
  }
  webimport_Method(value) {
    this.webimport.next(value)
  }
  webformsanddata_Method(value) {
    this.webformsanddata.next(value)
  }
  userinfo(loginform) {

    return this.http.post(this.configurationService.get().apiEndpoint + '/api/Login/GetLogin', loginform, { responseType: 'text' as 'json' })
  }
  help: boolean
  taxyeras() {
    
    this.Present_year = localStorage.getItem("Presentyear")
    this.Present_year_help = this.Present_year;
    this.Previous_taxyear = localStorage.getItem("previoustaxyear");
    this.Next_taxyear = localStorage.getItem("nexttaxyear");
    this.Current_taxyear = localStorage.getItem("currenttaxyear");
    this.Previous_year = localStorage.getItem("previousyear");
    this.Previous_year_help = this.Previous_year;

    this.Next_year = localStorage.getItem("nextyear");
    this.Next_Next_year = localStorage.getItem("next_nextyear");
    this.Currenttax_shortyear = localStorage.getItem("currenttaxshortyear");
    this.Currenttax_shortyear_help = this.Currenttax_shortyear;
    this.Previous_Previousyear = localStorage.getItem("previous_Previousyear");
    this.PreviousFulltaxyear = localStorage.getItem("PreviousFulltaxyear");
    if (this.Currenttax_shortyear == "2022-23")
      this.box36label = '6,725'
    if (this.Currenttax_shortyear == "2021-22")
      this.box36label = '6,515'
    if (this.Currenttax_shortyear == "2020-21")
      this.box36label = '6,475'

    this.help = true
  }
  Taxyear(year) {
    //current returnyear
    var taxreturnyear = year.split('-');
    taxreturnyear = taxreturnyear[1];
    //Previousyear
    var Previousyear = year.split('-');
    Previousyear = Previousyear[0];

    //Previous_Previousyear
    var Previous_Previousyear = year.split('-');
    Previous_Previousyear = Previous_Previousyear[0] - 1;
    //Nextyear
    var Nextyear = year.split('-');
    Nextyear = +Nextyear[1] + 1
    //Previous taxyear
    var Previoustaxyear = year.split('-');
    var py1 = Previoustaxyear[0] - 1; var py2 = Previoustaxyear[1] - 1;
    var PreviousFulltaxyear = py1 + '-' + py2.toString()
    Previoustaxyear = py1 + '-' + py2.toString().slice(2);
    //Next taxyear
    var Nexttaxyear = year.split('-');
    var ny1 = +Nexttaxyear[0] + 1; var ny2 = +Nexttaxyear[1] + 1;
    Nexttaxyear = ny1 + '-' + ny2.toString().slice(2);

    var nny1 = ny1 + 1; var nny2 = ny2 + 1;
    var Next_Nexttaxyear = nny1 + '-' + (nny2).toString().slice(2);
    //current taxyear
    var Currenttaxyear = year;
    //current taxshortyear
    var Currenttaxshortyear = year.split('-');
    var csy1 = Currenttaxshortyear[0]; var csy2 = Currenttaxshortyear[1];
    Currenttaxshortyear = csy1 + '-' + csy2.slice(2);

    localStorage.setItem("Presentyear", taxreturnyear);
    localStorage.setItem("previoustaxyear", Previoustaxyear);
    localStorage.setItem("nexttaxyear", Nexttaxyear);
    localStorage.setItem("currenttaxyear", Currenttaxyear);
    localStorage.setItem("previousyear", Previousyear);
    localStorage.setItem("currenttaxshortyear", Currenttaxshortyear);
    localStorage.setItem("nextyear", Nextyear);
    localStorage.setItem("next_nextyear", Next_Nexttaxyear);
    localStorage.setItem("previous_Previousyear", Previous_Previousyear);
    localStorage.setItem("PreviousFulltaxyear", PreviousFulltaxyear);

    //Values
    //if (taxreturnyear == '2023')
    //  this.CAL12 = '2600'
    //if (taxreturnyear == '2022')
    //  this.CAL12 = '2520'
    //if (taxreturnyear == '2021')
    //  this.CAL12 = '2500'

    //if (taxreturnyear == '2023')
    //  this.CAL13 = '9415'
    //if (taxreturnyear == '2022')
    //  this.CAL13 = '9125'
    //if (taxreturnyear == '2021')
    //  this.CAL13 = '9075'
  }

}
