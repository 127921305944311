<nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
  <div class="sb-sidenav-menu">
    <div class="nav">
      <div class="sb-sidenav-menu-heading text-capitalize"> Andica SA800 Partnership</div>
      <a class="nav-link cursor-pointer menu-visible-home" (click)="GotoHome()" routerLink="/dashboard" routerLinkActive="active">
        <div class="sb-nav-link-icon"><i class="fas fa-tachometer-alt"></i></div>
        Home
      </a>
      <a class="nav-link collapsed " href="#" data-toggle="collapse" data-target="#collapseTask" aria-expanded="false" aria-controls="collapseLayouts" >
        <div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
        Forms
        <div class="sb-sidenav-collapse-arrow "><i class="fas fa-caret-down"></i></div>
      </a>
      <div class="collapse show" id="collapseTask" aria-labelledby="headingOne" data-parent="#sidenavAccordion" *ngIf="Sa800">
        <nav class="sb-sidenav-menu-nested nav">
          <a class="nav-link child-menu" *ngIf="Sa800" routerLink="/sa800main/partnershipinfo" routerLinkActive="active">
            <i class="nav-link-icon"><img src="../../../assets/sa100-icon/0.ico" /></i> SA800
          </a>
          <a class="nav-link child-menu" id="m1" *ngIf="Trade" routerLink="/sa800tp" routerLinkActive="active">
            <i class="nav-link-icon"><img src="../../../assets/sa100-icon/5.ico" /></i> Trading and Professional
          </a>
          <a class="nav-link child-menu" *ngIf="LandProperty" routerLink="/sa801" routerLinkActive="active">
            <i class="nav-link-icon"><img src="../../../assets/sa100-icon/10.ico" /></i> UK Property
          </a>
          <a class="nav-link child-menu" *ngIf="Foreign" routerLink="/sa802" routerLinkActive="active">
            <i class="nav-link-icon"><img src="../../../assets/sa100-icon/11.ico" /></i> Foreign
          </a>
          <a class="nav-link child-menu" *ngIf="ChargeableAssets" routerLink="/sa803" routerLinkActive="active">
            <i class="nav-link-icon"><img src="../../../assets/sa100-icon/13.ico" /></i> Disposals and Chargeable Assets
          </a>
          <a class="nav-link child-menu" *ngIf="IncomeNotIncludedElsewhere" routerLink="/sa804" routerLinkActive="active">
            <i class="nav-link-icon"><img src="../../../assets/sa100-icon/hm revenue customs.gif" /></i> Savings and Investments
          </a>
          <a class="nav-link child-menu" *ngIf="PartnershipStatement" routerLink="/sa800ps" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <i class="nav-link-icon"><img src="../../../assets/sa100-icon/ps_24.gif" /></i> Partnership Statement
          </a>
          <a class="nav-link child-menu" *ngIf="Attachment" routerLink="/sa800ps/attachments" routerLinkActive="active">
            <i class="nav-link-icon"><img src="../../../assets/sa100-icon/17.ico" /></i> Attached Files
          </a>
        </nav>
      </div>


      <a class="nav-link collapsed menu-visible-sm" routerLinkActive="active" href="#" data-toggle="collapse" data-target="#collapseStatus" aria-expanded="false" aria-controls="collapseLayouts" >
        <div class="sb-nav-link-icon"><i class="fa fa-tasks" aria-hidden="true"></i></div>
        Status
        <div class="sb-sidenav-collapse-arrow" ><i class="fas fa-caret-down"></i></div>
      </a>
      <!--<div class="collapse treeview-animated" id="collapseStatus" aria-labelledby="headingOne" data-parent="#sidenavAccordion" >

      <ul *ngFor="let item of order1;let i=index">

        <li id="FormName">
          <div class="box">
            <div class="badge badge-pill badge-primary sidenav-custom-badge" data-toggle="collapse" [attr.data-target]="'#FormName' + i" aria-expanded="false" aria-controls="collapseExample"><span style="color:black">+</span></div>
            <span (click)="Goto_Perticular_page(item.PAGENAME)">  {{item.PAGENAME}}  </span>
            <div class="collapse" id="FormName{{i}}">
              <ul *ngFor="let sub of item.submodule">
                <li id="FormName" (click)="Goto_Perticular_Form(sub.PAGENAME)">-->
      <!--<input type="checkbox" id="c1" name="cb" checked="checked">-->
      <!--<input type="checkbox"  checked="checked" />
                      <span></span>
                    <span for="c1" class="Forml" title="{{sub.PAGENAME}}" [ngStyle]="sub.Status == false? {'color': 'red','font-weight':'bold'}:{'color':'green'}"> {{sub.PAGENAME|slice:0:20}}{{sub.PAGENAME.length>20?'...':''}}</span>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>-->
      <div class="collapse treeview-animated" id="collapseStatus" aria-labelledby="headingOne" data-parent="#sidenavAccordion" *ngIf="Sa800">
        <ul *ngFor="let item of order1;let i=index" class="parentUl">
          <li id="FormName" class="p-tree">
            <div class="box">
              <div id="index{{i}}" class="badge badge-pill badge-primary sidenav-custom-badge" (click)="openCloseStatus(i)" data-toggle="collapse" [attr.data-target]="'#FormName' + i" aria-expanded="false" aria-controls="collapseExample" style="color:#000 !important; width: 16px; height: 20px; font-size: 16px;">
                {{collapsePlusMinus}}
              </div>
              <span (click)="Goto_Perticular_page(item.PAGENAME)" style="font-size: 15px; padding-left: 4px;">{{item.PAGENAME}}</span>
              <div class="collapse" id="FormName{{i}}">
                <ul *ngFor="let sub of item.submodule">
                  <li (click)="Goto_Perticular_Form(sub.PAGENAME)">
                    <input type="checkbox" id="c1" name="cb" checked="checked">
                    <input type="checkbox" checked="checked" />
                    <span></span>
                    <span for="c1" class="Forml" title="{{sub.PAGENAME}}" [ngStyle]="sub.Status == false? {'color': 'red','font-weight':'bold'}:{'color':'green'}" style="font-size: 13px;"> {{sub.PAGENAME|slice:0:20}}{{sub.PAGENAME.length>25?'...':''}}</span>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <a class="nav-link collapsed menu-visible-sm" href="#" data-toggle="collapse" data-target="#collapseTools" aria-expanded="false" aria-controls="collapseLayouts" routerLinkActive="active">
        <div class="sb-nav-link-icon"><i class="fa fa-cog"></i> </div>
        Tools
        <div class="sb-sidenav-collapse-arrow"><i class="fas fa-caret-down"></i></div>
      </a>
      <div class="collapse" id="collapseTools" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
        <nav class="sb-sidenav-menu-nested nav" *ngIf="!this.usersService.expired">
          <a class="nav-link cursor-pointer text-truncate" href="#submenu1" data-toggle="collapse" data-target="#submenu1" aria-expanded="false" aria-controls="collapseLayouts">
            <div class="sb-nav-link-icon"><img src="../../../assets/sa100-icon/30.ico" /> </div> Reports
            <div class="sb-sidenav-collapse-arrow "><i class="fas fa-caret-down"></i></div>
          </a>
          <!--<div class="collapse show" id="collapseReports" aria-labelledby="headingOne">
    <nav class="sb-sidenav-menu-nested nav">
      <a class="nav-link p-1" (click)="NavigatePlainReport('Plain')">
        <i class="nav-link-icon"><img src="../../../assets/sa100-icon/30.ico" /></i> Plain paper
      </a>
    </nav>
  </div>-->
          <!--<a class="nav-link cursor-pointer text-truncate child-menu">
    <div class="sb-nav-link-icon"><img src="../../../assets/sa100-icon/32.ico" /> </div>Partnership Information
  </a>
  <a class="nav-link cursor-pointer text-truncatechild-menu">
    <div class="sb-nav-link-icon"><img src="../../../assets/sa100-icon\21.ico" /> </div>Change Password
  </a>-->
          <div class="collapse" id="submenu1" aria-expanded="false">
            <ul class="flex-column pl-2 nav">
              <li class="nav-item">
                <a class="nav-link cursor-pointer collapsed py-1" href="#submenu1sub1" data-toggle="collapse" data-target="#submenu1sub1">
                  <span><i class="nav-link-icon"><img src="../../../assets/sa100-icon/2.ico" /></i> Tax Returns</span>   <div class="sb-sidenav-collapse-arrow"><i class="fas fa-caret-down"></i></div>
                </a>
                <div class="collapse" id="submenu1sub1" aria-expanded="false">
                  <ul class="flex-column nav pl-4 cursor-pointer">
                    <li class="nav-item">
                      <a class="nav-link p-1 child-menu" (click)="NavigatePlainReport('Declaration')">
                        <i class="nav-link-icon"><img src="../../../assets/sa100-icon/Declaration.ico" /></i> Declaration & Client copy
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link p-1 child-menu" (click)="NavigatePlainReport('Client')">
                        <i class="nav-link-icon"><img src="../../../assets/sa100-icon/client.ico" /></i> Client copy
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link p-1 child-menu" (click)="NavigatePlainReport('Substitute')">
                        <i class="nav-link-icon"><img src="../../../assets/sa100-icon/subs.ico" /></i> Substitute forms
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link p-1 child-menu" (click)="NavigatePlainReport('Plain')">
                        <i class="nav-link-icon"><img src="../../../assets/sa100-icon/30.ico" /></i> Plain paper
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link p-1 child-menu" (click)="NavigatePlainReport('ListofPartnerships')">
                        <i class="nav-link-icon"><img src="../../../assets/sa100-icon/Declaration.ico" /></i> List of Partnerships
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <!--<li class="nav-item">
        <a class="nav-link"><i class="nav-link-icon"><img src="../../../assets/sa100-icon/23.ico" /></i> File by internet</a>
      </li>-->
              <!--<li class="nav-item">
        <a class="nav-link"><i class="nav-link-icon"><img src="../../../assets/sa100-icon/23.ico" /></i> Tax Calculation Details</a>
      </li>-->
            </ul>
          </div>
          <!--<a class="nav-link" (click)="NavigateReport()"><i class="nav-link-icon"><img src="../../../assets/sa100-icon/30.ico" /></i> Reports</a>-->
          <!--<a class="nav-link cursor-pointer" (click)="TaxpayerInfo()"><i class="nav-link-icon"><img src="../../../assets/sa100-icon/32.ico" /></i> Taxpayer Information</a>-->
          <!--<a class="nav-link"><i class="nav-link-icon"><img src="../../../assets/sa100-icon/21.ico" /></i> Change Password</a>-->
          <a class="nav-link cursor-pointer child-menu" (click)="FBI()"><i class="nav-link-icon"><img src="../../../assets/sa100-icon/23.ico" /></i> File by Internet</a>
          <a class="nav-link cursor-pointer child-menu" (click)="FBIReceipt()"><i class="nav-link-icon"><img src="../../../assets/sa100-icon/FBI Receipt.png" style="width:16px" /></i> FBI receipt</a>
          <!--<a class="nav-link cursor-pointer" (click)="Clear_Forms()"><i class="nav-link-icon"><img src="../../../assets/sa100-icon/18.ico" /></i> Clear forms</a>-->
          <!--<a class="nav-link" href="/Taxcal"><i class="nav-link-icon"><img src="../../../assets/sa100-icon/18.ico" /></i> SA302 REPORT</a>-->
          <a class="nav-link cursor-pointer child-menu" (click)="Taxadviser()"><i class="nav-link-icon"><img src="../../../assets/sa100-icon/tax advisor details.gif" /></i> Tax adviser details</a>
          <a class="nav-link cursor-pointer child-menu" (click)="UserManagment()"><i class="nav-link-icon"><img src="../../../assets/sa100-icon/user account.gif" /></i> User Management</a>
          <a class="nav-link cursor-pointer child-menu" (click)="Clear_Forms()"><i class="nav-link-icon"><img src="../../../assets/sa100-icon/Clearforms.png" style="width:16px" /></i> Reset all forms</a>
          <a class="nav-link cursor-pointer child-menu" (click)="sidNavService.setClearAllFields(true)"><i class="nav-link-icon"><img src="../../../assets/sa100-icon/Forms.png" style="width:16px" /></i> Clear Fields</a>
          <a class="nav-link"  style="cursor:pointer;" (click)="sidNavService._setShowBubble()" *ngIf="!sidNavService.showHideShowBubble">

            <div class="sb-nav-link-icon">
              <span *ngIf="sidNavService.getShowBubble">
                <i class="fa fa-check-circle"  ngbTooltip="Show Bubble"  aria-hidden="true"  style="color:green;"></i>
              </span>
              <span *ngIf="!sidNavService.getShowBubble">
                <i class="fa fa-check-circle"  ngbTooltip="Show Bubble"  aria-hidden="true"  style="color:gray;"></i>
              </span>

            </div> Show Bubble<!--<span *ngIf="sidNavService.getShowBubble"  style="position: absolute; top: 4px; right: 15px;"><i class="fa fa-check-circle"  ngbTooltip="Show Bubble"  aria-hidden="true"  style="color:green;"></i></span>-->
          </a>
          <ul>
            <li><a class="nav-link cursor-pointer px-0" href="https://www.andica.com/andica-cloud-software.html" target="_blank"><i class="nav-link-icon"><img src="../../../assets/sa100-icon/iconSA.png" style="width:20px" /></i>Andica Limited</a></li>
            <li><a class="nav-link cursor-pointer px-0" href="http://www.hmrc.gov.uk/"  target="_blank"><i class="nav-link-icon"><img src="../../../assets/sa100-icon/iconSA2.png" style="width:20px" /></i>HM Revenue & Customs</a></li>
          </ul>
          <a class="nav-link collapsed text-truncate" href="#submenu2" data-toggle="collapse" data-target="#submenu2">
            <div class="nav-link-icon"><img src="../../../assets/sa100-icon/Imports.png" style="width:20px" /> </div>Data Imports
            <div class="sb-sidenav-collapse-arrow"><i class="fas fa-caret-down"></i></div>
          </a>

          <div class="collapse" id="submenu2" aria-expanded="false">
            <ul class="flex-column pl-2 nav ">
              <li class="nav-item">
                <a class="nav-link collapsed py-1" href="#submenu2sub2" data-toggle="collapse" data-target="#submenu2sub2" style="padding-left:0px !important">
                  <span><i class="nav-link-icon"><img src="../../../assets/sa100-icon/import_256.ico" style="width:18px" /></i> Andica Cloud Application</span>   <div class="sb-sidenav-collapse-arrow"><i class="fas fa-caret-down"></i></div>
                </a>
                <div class="collapse" id="submenu2sub2" aria-expanded="false">
                  <ul class="flex-column nav pl-4 cursor-pointer">
                    <li class="nav-item">
                      <a class="nav-link cursor:pointer" (click)="Webimport();usersService.webimportbtn=true;usersService.windowsimportbtn=false;">
                        <i class="nav-link-icon"><img src="../../../assets/sa100-icon/user_info.png" style="width:18px" /> </i>Tax Year {{this.usersService.Previous_taxyear}}
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link cursor:pointer" (click)="usersService.webimportbtn=true;usersService.windowsimportbtn=false;Dataimport();!this.usersService.dataimport">
                        <i class="nav-link-icon"><img src="../../../assets/sa100-icon/list_256.png" style="width:25px" /> </i>Forms, data and values from last year
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <ul class="flex-column pl-2 nav ">
              <li class="nav-item">
                <a class="nav-link collapsed py-1" href="#submenu1sub1" data-toggle="collapse" data-target="#submenu1sub1" style="padding-left:0px !important">
                  <span><i class="nav-link-icon"><img src="../../../assets/sa100-icon/import_256.ico" style="width:18px" /></i></span>  <span style="white-space:pre">Andica Windows Application</span>   <div class="sb-sidenav-collapse-arrow">
                    <i class="fas fa-caret-down"></i>
                  </div>
                </a>
                <div class="collapse" id="submenu1sub1" aria-expanded="false">
                  <ul class="flex-column nav pl-4 cursor-pointer">
                    <li class="nav-item">
                      <a class="nav-link cursor:pointer" (click)="Winowsimport();usersService.windowsimportbtn=true;usersService.webimportbtn=false">
                        <i class="nav-link-icon"><img src="../../../assets/sa100-icon/user_info.png" style="width:18px" /> </i>Tax Year {{this.usersService.Previous_taxyear}}
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link cursor:pointer" (click)="usersService.windowsimportbtn=true;usersService.webimportbtn=false;Dataimport();!this.usersService.dataimport;">
                        <i class="nav-link-icon"><img src="../../../assets/sa100-icon/list_256.png" style="width:25px" /> </i>Forms, data and values from last year
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <!--<a class="nav-link cursor-pointer"  [class.disabled]="!this.usersService.dataimport" (click)="Dataimport()"><i class="nav-link-icon"><img src="../../../assets/sa100-icon/Imports.png" style="width:16px" /> </i> Data Import </a>-->
          <!--<a class="nav-link cursor:pointer" (click)="Winowsimport();usersService.windowsimportbtn=true;usersService.webimportbtn=false">
    <i class="nav-link-icon"><img src="../../../assets/sa100-icon/37.png" style="width:16px" /> </i> Windows import
  </a>
  <a class="nav-link cursor:pointer" (click)="Webimport();usersService.windowsimportbtn=false;usersService.webimportbtn=true">
    <i class="nav-link-icon"><img src="../../../assets/sa100-icon/37.png" style="width:16px" /> </i> Web import
  </a>-->

        </nav>
      </div>
    </div>
  </div>
  <span style="font-size: small; padding: 15px; background: #e7e7e7; "> {{this.UserMaster_Loginname}} logged in at {{this.MasterLogin_time}}</span>
</nav>

<script type="text/javascript">
  $(document).ready(function () {
    //Prevent closing from click inside dropdown
    $(document).on('click', '.dropdown-menu', function (e) {
      e.stopPropagation();
    });
    // make it as accordion for smaller screens
    if ($(window).width() < 992) {
      $('.dropdown-menu a').click(function (e) {
        e.preventDefault();
        if ($(this).next('.submenu').length) {
          $(this).next('.submenu').toggle();
        }
        $('.dropdown').on('hide.bs.dropdown', function () {
          $(this).find('.submenu').hide();
        })
      });
    }
  });
</script>
