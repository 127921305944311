import { Component, HostListener, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { FooterComponent } from '../footer/footer.component';
import { RouterOutlet } from '@angular/router';
import { SidenavComponent } from '../sidenav/sidenav.component';
import { HeaderComponent } from '../header/header.component';

@Component({
    selector: 'app-common-layout',
    templateUrl: './common-layout.component.html',
    styleUrls: ['./common-layout.component.css'],
    standalone: true,
    imports: [HeaderComponent, SidenavComponent, RouterOutlet, FooterComponent]
})
export class CommonLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    //if ($("#layoutSidenav_nav").hasClass("sidenav-show-hide")) {
    //  $("#sidebarToggle").removeClass("is-active");
    //}
    //else {
    //  $("#sidebarToggle").addClass("is-active");
    //}
    var windowsize = $(window).width();
    if ((windowsize <= 801) && $("#layoutSidenav_nav").show()) {
      $("#layoutSidenav_nav").hide();
      // alert(0);
    }
  }

  closeOpensidenNav() {
    var windowsize = $(window).width();
    if ((windowsize <= 801) && $("#layoutSidenav_nav").show()) {
      $("#layoutSidenav_nav").hide();
    }
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    let value = event.which;
    if (value == 112) {
      window.open("help", "", "width=800,height=1000");
    }
  }

}
