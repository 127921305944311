<div class="container-fluid mt-3 mb-3">
  <div class="d-flex align-items-center justify-content-between small row">

    <div class="text-muted col-md-6"><strong>Copyright &copy; <a href="http://www.andica.com/" target="_blank">2004-{{userService.Present_year}} Andica Limited</a>.</strong> All rights reserved.</div>
    <div class="col-md-6 justify-content-between justify-content-end text-lg-right">
      <a href="https://www.andica.com/legal-software-licence-agreement.html" target="_blank">Licence Agreement</a>
      &middot;
      <!--<a href="https://www.andica.com/legal.html">Terms &amp; Conditions</a>-->
      <a href="https://www.andica.com/legal.html" target="_blank">Legal Terms</a>
    </div>
  </div>
</div>
