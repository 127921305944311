import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DashboardServiceService } from '../../modules/dashboard/dashboard-service.service';
import * as CryptoJS from 'crypto-js';
import { UsersService } from '../../modules/users/users.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class SideNavService {
  RecordID: any;
  showBubbleSpinner: boolean;
  private _apShowBubble: string = null;
  private readonly apShowBubbleKey: string = 'ShowBubble'

  constructor(private dashboardService: DashboardServiceService, public usersService: UsersService, private route: Router) { }
  private clearAllFields$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  clearAllFields = this.clearAllFields$.asObservable();
  //Tool tip functionality
  private showBubble$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showBubble = this.showBubble$.asObservable();
  showHideShowBubble: boolean;
  setClearAllFields(computations: boolean) {
    debugger
    this.RecordID = CryptoJS.AES.decrypt(localStorage.getItem("RecordID"), "SA800").toString(CryptoJS.enc.Utf8);
    var navi = this.route.url
    if (computations == true && this.RecordID && (this.usersService.reportflag == undefined || !this.route.url.includes('/reports/taxreturns/'))) {
      Swal.fire({
        title: 'Andica SA800 Partnership',
        icon: 'question',
        text: 'You are about to clear all the visible fields, do you want to continue?',
        showCancelButton: true,
        confirmButtonText: 'yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.value) {
          this.clearAllFields$.next(computations);
        }
        else {
          this.clearAllFields$.next(false);
        }
      })
    }
  }
  setShowBubble(ShowBubble: boolean) {
    this.showBubble$.next(ShowBubble);
  } get getShowBubble(): boolean {
    return this.showBubble$.value;
  }
  _setShowBubble() {

    debugger
    //this.UserID = CryptoJS.AES.decrypt(localStorage.getItem("TaxPayerId"), "SA100").toString(CryptoJS.enc.Utf8);//localStorage.getItem("TaxPayerId"); #Decripting taxpayer id
    //this.UserID = +this.UserID;
    //this.SaveShowBubble(this.UserID).subscribe((data: any) => {
    //  debugger
    //  if (data != null) {
    //    if (this.getShowBubble) {
    //      this.showBubbleSpinner = true;
    //      setTimeout(() => {
    //        this.showBubbleSpinner = false;
    //      }, 1000)
    //    }
    //    this.localService.setShowBubble(data == 0 ? false : true)
    //    this.setShowBubble(data == 0 ? false : true)
    //  } else {
    //    this.localService.setShowBubble(false)
    //  }
    //})
    this.RecordID = CryptoJS.AES.decrypt(localStorage.getItem("RecordID"), "SA800").toString(CryptoJS.enc.Utf8);
    this.dashboardService.SaveShowbubble(this.RecordID).subscribe((data: any) => {
      debugger
      if (data != null) {
        if (this.getShowBubble) {
          this.showBubbleSpinner = true;
          setTimeout(() => {
            this.showBubbleSpinner = false;
          }, 1000)
        }
        //if (data == 0) {
        //  data = false
        //  localStorage.setItem(this.apShowBubbleKey, data);
        //} else {
        //  data = true
        //  localStorage.setItem(this.apShowBubbleKey, data);
        this.setShowBubbleL(data == 0 ? false : true)
        this.setShowBubble(data == 0 ? false : true)

      } else {
        //data = false;
        //localStorage.setItem(this.apShowBubbleKey, data);
        this.setShowBubbleL(false)
      }
    })
  }
  public setShowBubbleL(showBubble: any) {
    localStorage.setItem(this.apShowBubbleKey, showBubble);
    this._apShowBubble = showBubble;
  }


  public get getShowBubbleL(): any {
    return this._apShowBubble;
  }

  _getShowBubble() {

    //this.UserID = CryptoJS.AES.decrypt(localStorage.getItem("TaxPayerId"), "SA100").toString(CryptoJS.enc.Utf8);//localStorage.getItem("TaxPayerId"); #Decripting taxpayer id
    //this.GetShowBubble(this.UserID).subscribe((data: any) => {
    //  debugger
    //  if (data != null) {
    //    this.localService.setShowBubble(data)
    //    this.setShowBubble(data)
    //  } else {
    //    this.setShowBubble(false)
    //  }
    //})
    this.RecordID = CryptoJS.AES.decrypt(localStorage.getItem("RecordID"), "SA800").toString(CryptoJS.enc.Utf8);
    this.dashboardService.GetShowBubble(this.RecordID).subscribe((data: any) => {
      if (data != null) {
        localStorage.setItem(this.apShowBubbleKey, data);
        this.setShowBubble(data)
      } else {
        this.setShowBubble(false)
      }
    })
  }
  Dataimport() {
    debugger;
    var TaxPayerId = localStorage.getItem("TaxPayerId");
    if (TaxPayerId) {
      this.usersService.Dataimport_Method(true)
      //$("#DetailsPopup1").modal('show');
    }
    else {
      Swal.fire("Andica SA800 Partnership", "Please create taxpayer.", 'info')
    }
  }

  supplyFormsanddata(TaxPayerId) {
    debugger;
    var suppforms = ['SA800', 'LandProperty', 'Foreign', 'Trade', 'ChargeableAssets', 'IncomeNotIncludedElsewhere'];
    this.usersService.supplyformdata = [];
    this.dashboardService.getwebtotwebsupplyforms(TaxPayerId).subscribe((result: any) => {
      this.usersService.presentyearsupplyformdata = result[1]
      if (result.length > 0) {
        for (let i = 0; i < suppforms.length; i++) {
          if (result[0][suppforms[i]] == true)
            this.usersService.supplyformdata.push({ name: suppforms[i], value: suppforms[i] })
          for (let i = 0; i < this.usersService.supplyformdata.length; i++) {
              if (this.usersService.supplyformdata[i].name == "SA800") {
                suppforms[i] = "SA800";
                this.usersService.supplyformdata[i].name = suppforms[i]
              }
              if (this.usersService.supplyformdata[i].name == "LandProperty") {
                suppforms[i] = "UK Property";
                this.usersService.supplyformdata[i].name = suppforms[i]
              }
              if (this.usersService.supplyformdata[i].name == "Foreign") {
                suppforms[i] = "Foreign";
                this.usersService.supplyformdata[i].name = suppforms[i]
              }
              if (this.usersService.supplyformdata[i].name == "Trade") {
                suppforms[i] = "Trading and Professional"
                this.usersService.supplyformdata[i].name = suppforms[i]
              }
              if (this.usersService.supplyformdata[i].name == "ChargeableAssets") {
                suppforms[i] = "Disposal and Chargeable Assests"
                this.usersService.supplyformdata[i].name = suppforms[i]
              }
              if (this.usersService.supplyformdata[i].name == "IncomeNotIncludedElsewhere") {
                suppforms[i] = "Savings and Investments"
                this.usersService.supplyformdata[i].name = suppforms[i]
              }
          }

        }
        this.usersService.Dataimport_Method(true)
      }
      else {
        this.usersService.editflag = true;
      }
    })
  }

}
