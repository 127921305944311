/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
import 'hammerjs';
import { enableProdMode, ErrorHandler, APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { storageFactory } from './app/app.module';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app/app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, provideProtractorTestingSupport, bootstrapApplication } from '@angular/platform-browser';
import { AuthConfigService } from './app/Configuration/auth-config.service';
import { OAuthStorage, OAuthModule } from 'angular-oauth2-oidc';
import { ConfigurationService } from './app/Configuration/configuration.service';
import { CustomError } from './app/shared/custom-error.service';

const configurationFactory = (configurationService: ConfigurationService) => {
  return () => configurationService.loadConfiguration();
};



export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
];

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, FormsModule, ReactiveFormsModule, ToastrModule.forRoot(), AppRoutingModule, OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: ['http://localhost:8092/api'],
                sendAccessToken: true
            }
        })),
        { provide: ErrorHandler, useClass: CustomError },
        {
            provide: APP_INITIALIZER,
            useFactory: configurationFactory,
            deps: [ConfigurationService],
            multi: true
        },
        { provide: OAuthStorage, useFactory: storageFactory },
        ConfigurationService, AuthConfigService,
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        provideProtractorTestingSupport(),
    ]
})
  .catch(err => console.log(err));
