import { Injectable } from "@angular/core";
import { Configuration } from "./configuration";


@Injectable({
  providedIn: "root",
})
export class ConfigurationService {
  private configuration: Configuration;

  get(): Configuration {
    return this.configuration;
  }

  async loadConfiguration(): Promise<void> {
     
    const response = await fetch("/api/configuration");
    const configuration = await response.json();
    this.configuration = configuration;
    // console.log(this.configuration.test)
  }
}
