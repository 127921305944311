import { Injectable } from "@angular/core";
import { AuthConfig } from "angular-oauth2-oidc";
import { ConfigurationService } from "./configuration.service";

@Injectable({
  providedIn: "root",
})
export class AuthConfigService {
  constructor(private configurationService: ConfigurationService) {}

  get(): AuthConfig {
    return {
      redirectUri: this.configurationService.get().redirectUri,
      responseType: this.configurationService.get().responseType,
      issuer: this.configurationService.get().issuer,
      strictDiscoveryDocumentValidation: this.configurationService.get().strictDiscoveryDocumentValidation,
      tokenEndpoint: this.configurationService.get().tokenEndpoint,
      loginUrl: this.configurationService.get().loginUrl,
      clientId: this.configurationService.get().clientId,
      scope: this.configurationService.get().scope,
      skipIssuerCheck: this.configurationService.get().skipIssuerCheck,
      clearHashAfterLogin: this.configurationService.get().clearHashAfterLogin,
      oidc: this.configurationService.get().oidc,
    };
  }
  getAPIBaseUrl(): string {
    return this.configurationService.get().apiEndpoint;
  }
  getSubscriptionAPIUrl(): string {
    return this.configurationService.get().subscriptionURL;
  }
}
