export class suppform {
  Flag: string;
  RecordID: string;
  SupplementaryFormID: string;
  AgentName: string;
  LandProperty: Boolean;
  Foreign: Boolean;
  Trade: Boolean;
  ChargeableAssets: Boolean;
  MemberIsCompany: Boolean;
  MemberIsNonResident: Boolean;
  PartnerOfBusinessAbroad: Boolean;
  GoodsExported: Boolean;
  ServicesExported: Boolean;
  NeitherGoodsNorServicesExported: Boolean;
  EuroEconomicInterestGrouping: Boolean;
  IncomeNotIncludedElsewhere: Boolean;
  InterestEtcWithTaxTakenOff: Boolean;
  DetailsIncorrect: Boolean;
  PartnershipStatement: boolean;
  SA800_11p2_CountValue: string;
  SA800PS_IsFullStatement: boolean;
  SA800_CountAttachmentValue: string;
  CountAttachment: string;
  Count: string;
  Line1: string;
  Line2: string;
  Line3: string;
  Line4: string;
  PostCode: string;
  PartnerTelephone: string;
  AgentTelephone: string;
  OneTo5Data: string;
  SubFlag: string;
  FormsFlag: string;
}
