<app-header></app-header>

<div id="layoutSidenav">
  <div id="layoutSidenav_nav">
    <app-sidenav></app-sidenav>
  </div>
  <div id="layoutSidenav_content" (click)="closeOpensidenNav()">
    <main class="main-top">
      <router-outlet></router-outlet>
    </main>
    <app-footer></app-footer>
  </div>
</div>
