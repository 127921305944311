<div class="overflow-hidden">
  <div class=" container-fluid p-0  topbar">
    <div class="bg-light row px-2" style="box-shadow: 0 2px 1px 0 rgb(0 0 0 / 5%), 0 0px 18px 0 rgb(0 0 0 / 10%);">
      <div class="col-sm-4 col-md-2 col-3 text-center">
        <div class="d-flex">
          <div class="logo-div">
            <a class="navbar-brand" style="line-height: 50px;">
              <span class="logo-dark">
                <img src="../../assets/images/Andica-Logo-2-email.jpg" alt="Andica" class=" " />
              </span>
              <span class="logo-sm">
                <img src="../../assets/images/logo-sm.png" alt="Andica" height="36">
              </span>
            </a>
          </div>
          <div class="icon-box pt-4" (click)="openNav()" id="show-nav">
            <span class="fa fa-times"></span>
          </div>
          <div class="icon-box pt-4" (click)="closeNav()" id="hide-nav">
            <span class="fa fa-bars"></span>
          </div>
        </div>

      </div>

      <div class="col-sm-8 col-md-10 col-9 text-sm-right text-left ">
        <div class=" mx-2">
          <input class="form-control mr-sm-2 d-inline-block mt-2 w-md-50 w-25" type="search" placeholder="Search" aria-label="Search">
          <button type="button" class="btn btn-sm p-1 pl-2 pr-2" style="background: #009ddc; color: #ffffff !important; ">
            Access Andica <span class="mx-1 fa fa-question-circle-o text-white"></span><b>SA800</b>
          </button>
        </div>
      </div>
    </div>
      </div>
  <div class="container-fluid">
    <div class="row">
      <div class="">
        <div class="sidenav" id="mySidenav">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a class="nav-link active" data-bs-toggle="tab" href="#contents">Contents</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-bs-toggle="tab" href="#index">Index</a>
            </li>
            <!--<li>
              <a class="" data-bs-toggle="tab" href="#licence" id="licencenav">Licence</a>
            </li>-->
          </ul>
           <!--Tab panes--> 
          <div class="tab-content">
            <div class="tab-pane container active" id="contents">
            
              <ul id="myUL" class="nav nav-tabs" role="tablist" id="tree">
                <li>
                  <span class="caret" id="mainnav">
                    <span class="book"></span>
                  </span>
                  <b>
                    <a class="active" data-bs-toggle="tab" href="#content" style="font-size: small;">ANDICA® SA800 PARTNERSHIP SOFTWARE</a>
                  </b>
                  <ul class="nested" id="mainnavsubmenu">
                    <li class="sub-parent">
                      <a class="" data-bs-toggle="tab" href="#copyright" id="copyrightnav">Copyright</a>
                    </li>
                    <li class="sub-parent">
                      <a class="" data-bs-toggle="tab" href="#licence" id="licencenav">Licence</a>
                    </li>
                    <li class="sub-parent" id="submenu">
                      <span class="caret" id="intronav">
                        <span class="book"></span>
                      </span>
                      <b>
                        <a class="" data-bs-toggle="tab" href="#Introduction1" id="introactive">Introduction</a>
                      </b>
                      <ul class="nested" id="introsubmenu">
                        <li>
                          <a class="" data-bs-toggle="tab" href="#welcome" id="welcomeactive">Welcome to Andica SA 800 Partnership Software</a>
                        </li>
                        <li>
                          <a class="" data-bs-toggle="tab" href="#Features1" id="featureactive">Features</a>
                        </li>
                        <li >
                          <span class="book"></span>
                          <a class="" data-bs-toggle="tab" href="#Quick" id="conventionactive">Convention</a>
                        </li>
                      </ul>
                    </li>
                    <li class="sub-parent">
                      <span class="book"></span>
                      <a class="" data-bs-toggle="tab" href="#Reference1" id="quickrefrenceactive"><b> Quick Reference</b></a>
                    </li>
                    <li class="sub-parent">
                      <span class="book"></span>
                      <a class="" data-bs-toggle="tab" href="#Start1" id="startactive"><b>Start Here</b></a>
                    </li>
                    <li class="sub-parent">
                      <span class="caret" id="workingmenu">
                        <span class="book"></span>
                      </span>
                      <b>
                        <a class="" data-bs-toggle="tab" href="#Working" id="workingactive"><b>Working with Andica SA800 Software</b></a>
                      </b>
                      <ul class="nested" id="workingsubmenu">
                        <li>
                          <a class="" data-bs-toggle="tab" href="#Information1" id="gatheractive">Gather Information</a>
                        </li>
                        <li>
                          <a class="" data-bs-toggle="tab" href="#Information2" id="enteringactive">Entering Information</a>
                        </li>
                        <li>
                          <a class="" data-bs-toggle="tab" href="#Submitting" id="submittingactive">Submitting returns to HMRC</a>
                        </li>
                      </ul>
                    </li>
                    <li class="sub-parent">
                      <span class="caret" id="creatingmenu">
                        <span class="book"></span>
                      </span>
                      <b>
                        <a class="" data-bs-toggle="tab" href="#PartnershipRecords2" id="creatingactive"><b>Creating Users and Partnership Records</b></a>
                      </b>
                      <ul class="nested" id="creatingsubmenu">
                        <li>
                          <a class="" data-bs-toggle="tab" href="#PartnershipRecords2" id="creatinguseractive">Creating software User accounts  and Partnership Records </a>
                        </li>
                        <li>
                          <a class="" data-bs-toggle="tab" href="#Opening" id="openingactive">Opening Partnership Records</a>
                        </li>
                      </ul>
                    </li>
                    <li class="sub-parent">
                      <span class="caret" id="gettingmenu">
                        <span class="book"></span>
                      </span>
                      <b>
                        <a class="" data-bs-toggle="tab" href="#Started1" id="startedactive"><b>Getting Started</b></a>
                      </b>
                      <ul class="nested" id="gettingsubmenu">
                        <li>
                          <a class="" data-bs-toggle="tab" href="#Started1" id="startingactive">Starting Andica Self Assessment Partnership</a>
                        </li>
                        <li>
                          <a class="" data-bs-toggle="tab" href="#LogIn1" id="loginactive">Log In</a>
                        </li>
                      </ul>
                    </li>
                    <li class="sub-parent">
                      <span class="caret" id="familiariesmenu">
                        <span class="book"></span>
                      </span>
                      <b>
                        <a class="" data-bs-toggle="tab" href="#PartnershipSoftware2" id="familiariesactive"><b>Familiarise yourself with Andica SA800 Partnership Software</b></a>
                      </b>
                      <ul class="nested" id="familiariessubmenu">
                        <li>
                          <a class="" data-bs-toggle="tab" href="#MenuBar2" id="menuactive">Menu Bar</a>
                        </li>
                        <li>
                          <a class="" data-bs-toggle="tab" href="#HorizontalToolbar1" id="horizontalactive">Horizontal Toolbar</a>
                        </li>
                        <li>
                          <a class="" data-bs-toggle="tab" href="#VerticalToolbars1" id="verticalactive">Vertical Toolbars</a>
                        </li>
                        <li>
                          <a class="" data-bs-toggle="tab" href="#StatusBar1" id="statusactive">Status Bar</a>
                        </li>
                        <li>
                          <a class="" data-bs-toggle="tab" href="#RecordsListView1" id="recordactive">Records List View</a>
                        </li>
                        <li>
                          <a class="" data-bs-toggle="tab" href="#UsingHelp1" id="helpactive"> Using Help</a>
                        </li>
                        <li>
                          <a class="" data-bs-toggle="tab" href="#details1" id="taxactive">Tax adviser details (Agent)</a>
                        </li>
                        <li>
                          <a class="" data-bs-toggle="tab" href="#Attachments1" id="attachmanrtactive">Attachments</a>
                        </li>
                      </ul>
                    </li>
                    <li class="sub-parent">
                      <span class="caret" id="datamenu">
                        <span class="book"></span>
                      </span>
                      <b>
                        <a class="" data-bs-toggle="tab" href="#Forms1" id="usingactive"><b>Using Data Entry Forms</b></a>
                      </b>
                      <ul class="nested" id="datasubmenu">
                        <li>
                          <a class="" data-bs-toggle="tab" href="#Forms2" id="navigationactive">Navigating</a>
                        </li>
                        <li>
                          <a class="" data-bs-toggle="tab" href="#Forms3" id="enteringactive">Entering Partnership Information</a>
                        </li>
                        <li>
                          <a class="" data-bs-toggle="tab" href="#Forms4" id="enteringhmrcactive"> Entering HMRC references</a>
                        </li>
                        <li>
                          <a class="" data-bs-toggle="tab" href="#Forms5" id="supplementaryactive">Supplementary pages</a>
                        </li>
                        <li>
                          <a class="" data-bs-toggle="tab" href="#Forms6" id="patnershipactive"> Partnership Statement SA800(PS)</a>
                        </li>
                      </ul>
                    </li>
                    <li class="sub-parent">
                      <span class="caret" id="filemenu">
                        <span class="book"></span>
                      </span>
                      <b>
                        <a class="" data-bs-toggle="tab" href="#File1" id="internetactive"><b>File By Internet</b></a>
                      </b>
                      <ul class="nested" id="filesubmenu">
                        <li>
                          <a class="" data-bs-toggle="tab" href="#File1" id="onlineactive">Online filling of Tax Return</a>
                        </li>
                        <li>
                          <a class="" data-bs-toggle="tab" href="#File2" id="registeractive">Register for online filing</a>
                        </li>
                        <li>
                          <a class="" data-bs-toggle="tab" href="#File3" id="usingactive">Using File By Internet</a>
                        </li>
                      </ul>
                    </li>
                    <li class="sub-parent">
                      <span class="caret" id="cesamenu">
                        <span class="book"></span>
                      </span>
                      <b>
                        <a class="" data-bs-toggle="tab" href="#Characters1" id="characteractive"><b>CESA Characters</b></a>
                      </b>
                      <ul class="nested" id="cesasubmenu">
                        <li>
                          <a class="" data-bs-toggle="tab" href="#Characters1" id="computeractive">HMRC Computer Environment for Self Assessment (CESA) service</a>
                        </li>
                      </ul>
                    </li>
                    <li class="sub-parent">
                      <span class="caret" id="Printingmenu">
                        <span class="book"></span>
                      </span>
                      <b>
                        <a class="" data-bs-toggle="tab" href="#reports1" id="reportactive"><b>Printing reports</b></a>
                      </b>
                      <ul class="nested" id="Printingsubmenu">
                        <li>
                          <a class="" data-bs-toggle="tab" href="#reports1" id="substituteactive">Printing Substitute Tax Returns</a>
                        </li>
                        <li>
                          <a class="" data-bs-toggle="tab" href="#reports2" id="declarationactive">Printing Declaration and Client copy</a>
                        </li>
                        <li>
                          <a class="" data-bs-toggle="tab" href="#reports3" id="toolbaractive">Report toolbar </a>
                        </li>
                        <li>
                          <a class="" data-bs-toggle="tab" href="#reports4" id="fbiactive">Printing FBI receipt</a>
                        </li>
                        <li>
                          <a class="" data-bs-toggle="tab" href="#reports5" id="claeringactive">Clearing submission history</a>
                        </li>
                      </ul>
                    </li>
                    <li class="sub-parent">
                      <span class="caret" id="backupmenu">
                        <span class="book"></span>
                      </span>
                      <b>
                        <a class="" data-bs-toggle="tab" href="#Backup1" id="restoreactive"><b>Backup and Restore</b></a>
                      </b>
                      <ul class="nested" id="backsubmenu">
                        <li>
                          <a class="" data-bs-toggle="tab" href="#Backup1" id="databackupactive">Backup data files</a>
                        </li>
                        <li>
                          <a class="" data-bs-toggle="tab" href="#Backup2" id="restoringactive">Restoring data files</a>
                        </li>
                      </ul>
                    </li>
                    <li class="sub-parent">
                      <span class="caret" id="datamenu">
                        <span class="book"></span>
                      </span>
                      <b>
                        <a class="" data-bs-toggle="tab" href="#DataImport1" id="importactive"><b>Data Import</b></a>
                      </b>
                      <ul class="nested" id="datasubmenu">
                        <li>
                          <a class="" data-bs-toggle="tab" href="#DataImport1" id="previousactive">Importing Previous Year's Data Files</a>
                        </li>
                        <li>
                          <a class="" data-bs-toggle="tab" href="#DataImport2" id="valueactive">Importing Forms, Data and values from last year</a>
                        </li>
                        <li>
                          <a class="" data-bs-toggle="tab" href="#DataImport3" id="comparisionactive">Data comparison with last year</a>
                        </li>
                      </ul>
                    </li>
                    <li class="sub-parent">
                      <span class="book"></span>
                      <a class="" data-bs-toggle="tab" href="#SA800" id="raturnactive"><b>SA800-Main Return</b></a>
                    </li>
                    <li class="sub-parent">
                      <span class="book"></span>
                      <a class="" data-bs-toggle="tab" href="#SA800TP" id="tradingactive"><b>SA800TP-Trading and Professional</b></a>
                    </li>
                    <li class="sub-parent">
                      <span class="book"></span>
                      <a class="" data-bs-toggle="tab" href="#SA801" id="propertyactive"><b>SA801-UK property</b></a>
                    </li>
                    <li class="sub-parent">
                      <span class="book"></span>
                      <a class="" data-bs-toggle="tab" href="#SA802" id="foreignactive"><b>SA802- Foreign</b></a>
                    </li>
                    <li class="sub-parent">
                      <span class="book"></span>
                      <a class="" data-bs-toggle="tab" href="#SA803" id="disposableactive"><b>SA803- Disposable and Chargeable Assets</b></a>
                    </li>
                    <li class="sub-parent">
                      <span class="book"></span>
                      <a class="" data-bs-toggle="tab" href="#SA804" id="investmentactive"><b>SA804- Savings and Investment</b></a>
                    </li>
                    <li class="sub-parent">
                      <span class="book"></span>
                      <a class="" data-bs-toggle="tab" href="#SA800PS" id="statementactive"><b>SA800PS- Partnership Statement</b></a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div class="tab-pane container fade" id="index">
               <h6>Type your Search</h6>
               <input type="text" placeholder="enter your search" class="form-control" />
            </div>
          </div>
        </div>
      </div>
      <div class=" p-0" id="main">
         <!--Tab panes--> 
        <div class="tab-content">
          <div id="content" class=" tab-pane ">
            <br>
            <div id="mainSection">
              <div id="mainBody">
                <p align="center">
                  <b>ANDICA <sup>®</sup> SA800 PARTNERSHIP SOFTWARE </b>
                  <sup>™</sup>
                </p>
                <p align="center">
                  <b>USER GUIDE</b>
                </p>
                <p align="center">
                  <img src="../../assets/screens/Startpage01.jpg" height="282" width="468" border="0" style="margin:0px;" alt="">
                </p>
              </div>
            </div>
          </div>
          <!--Copyy Right Section Starts Here-->
          <div id="copyright" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Copyright </b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader">&nbsp;<b>Copyright</b> </div>
                  <br />
                  <p>The software, documentations, images, icons, text or any other information contained in the software, user's guide, brochures and other documentations are protected under the copyright laws and may not be copied, photocopied, reproduced, translated or converted in whole or in part to any electronic medium, machine-readable form or any other format without the prior written consent of Andica Limited. Such written consent must be specific and not implied.</p>
                  <p>Note: This guide covers Andica® SA800 Partnership Professional <sup>TM</sup> and Andica® Self Assessment Personal <sup>TM</sup> software version {{this.userService.Present_year}} developed by Andica Limited. Taxation and other rates and any calculations displayed in the user guide are for display purposes only. Certain software features may differ between editions. </p>
                  <p>This guide is by no means a substitute to training and knowledge of the tax return functions and it does not cover all the features of Andica Software. Tax return routines may vary from one return to another. It is strongly recommended that you obtain training on use of the software to suit your requirements. Support is not included in the price of the software and the company reserves the right to provide (and/or refuse) support for the product. Customers can separately purchase technical support.</p>
                  <p>All Andica SA800 Partnership software screen shots are taken from Version {{this.userService.Present_year}} and earlier versions and are for illustration only and may be from Personal or Professional editions, the software may differ from these images displayed.</p>
                  <p>Andica Limited © 2003-{{this.userService.Present_year}}. All rights reserved. Andica, Andica Software, Andica logo with or without the words Andica Limited, Andica Self Assessment, Andica with a product descriptive name, are trademarks or registered trademarks of Andica Limited in the European Community and and/or other countries. Microsoft and Windows are registered trademarks and/or trademarks of Microsoft Corporation. InstallShield is a registered trademark of InstallShield Software Corporation. All other company names or product names may be trademarks of their respective owners. All parties’ registered trademarks or service marks are the property of their respective owners and should be treated as such.</p>
                  <p>This program is protected by International copyright laws.</p>
                  <p>&nbsp;</p>
                  <p>
                    <b>Developers:</b>
                  </p>
                  <p>
                    <b>Andica Limited </b>
                    <br>The Pines <br>Daws Hill Lane <br>High Wycombe <br> Buckinghamshire <br>HP11 1PW
                  </p>
                  <p>
                    Web: <a href="http://www.andica.com" target="_blank">www.andica.com</a>
                    <br>Email: <a href="mailto:info@andica.com">info&#64;andica.com</a>
                  </p>
                  <p>Tel: 01494 360 960</p>
                </div>
              </div>
            </div>
          </div>

          <!--Licence Section Starts Here-->
          <div id="licence" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>License</b> </div>
                  <br />
                  <p>ANDICA LIMITED</p>
                  <p>SOFTWARE LICENSE AGREEMENT AND WARRANTY</p>
                  <p>IMPORTANT - READ CAREFULLY</p>
                  <p>ANDICA LIMITED (&quot;ANDICA&quot;) ON ITS OWN BEHALF AND ON BEHALF OF ITS SUBSIDIARIES, DIVISIONS AND AFFILIATES (COLLECTIVELY REFERRED TO AS &quot;ANDICA&quot;) LICENSES THIS SOFTWARE WHICH INCLUDES COMPUTER SOFTWARE AND ASSOCIATED MEDIA AND DOCUMENTATION (COLLECTIVELY REFERRED TO AS “SOFTWARE”) TO YOU (&quot;YOU&quot;, “END USER” OR &quot;LICENSEE&quot;) ONLY UPON THE CONDITION THAT YOU ACCEPT ALL OF THE TERMS CONTAINED IN THIS LICENSE AGREEMENT. PLEASE READ THE TERMS CAREFULLY BEFORE CONTINUING THE INSTALLATION PROCESS, AS SELECTING THE “I ACCEPT THE TERMS IN THE LICENSE AGREEMENT” AND PRESSING THE &quot;NEXT&quot; BUTTON, YOU AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT. IF YOU DO NOT AGREE TO THESE TERMS, SELECT “I DO NOT ACCEPT THE TERMS IN THE LICENSE AGREEMENT” AND PRESS THE &quot;CANCEL&quot; BUTTON TO TERMINATE THE INSTALLATION PROCESS AND PROMPTLY RETURN THE UNUSED SOFTWARE TO THE PLACE FROM WHERE YOU OBTAINED IT.</p>
                  <p>IF YOU ARE UNWILLING TO ACCEPT THE TERMS OF THE LICENSE AGREEMENT AND YOU PURCHASED THE SOFTWARE FROM A RESELLER, YOU SHOULD RETURN THE FULL SOFTWARE WITH YOUR PROOF OF PURCHASE TO THE RESELLER FROM WHOM IT WAS PURCHASED. THE RESELLER MAY HAVE THEIR OWN TERMS AND CONDITIONS FOR RETURNS AND REFUNDS. ANDICA DO NOT ACCEPT ANY OBLIGATIONS AND LIABILITIES TO ANY CLAIMS YOU MAY HAVE WITH THE RESELLER. BREAKING THE SEAL ON THE SOFTWARE PACKAGING, WHERE A SEAL IS AFFIXED, OR INSTALLATION OF THE SOFTWARE, CONSTITUTES ACCEPTANCE OF THE AGREEMENT. </p>
                  <p>The software that accompanies this license (the &quot;Software&quot;) is the property of Andica and is protected by copyright law and international treaty. While Andica continues to own the software, you will have certain rights to use the software after your acceptance of this license.</p>
                  <p>The software may include software owned by third parties (Referred here as &quot;Third-Party Owners&quot; or “Third Party Software”). The Third-Party Owners are third-party beneficiaries of this license agreement and you agree to be bound to them under all the terms and conditions of this agreement, unless and to the extent that a separate license agreement for such third party software governs. The software is protected by copyright and other intellectual property laws. Andica or the Third Party Owners retain ownership of their respective software and the copies of the software provided herewith. The software is licensed to Licensee for use subject to the terms set forth in this agreement. Purchasing the software, paying for it and using the software, does not imply passing of ownership titles of the software to the user.</p>
                  <p>Terms and conditions of this license agreement may be modified by an addendum to this or future license agreements. </p>
                  <p>Andica hereby grants to Licensee and Licensee fully accepts, upon delivery, a nonexclusive, non-transferable, perpetual or time limited (unless terminated as provided below) rights to use only the software executables for its own internal business purposes in case of the business software or for personal use in case of the personal software. Installation of the Licensed Software entails completion of registration process with Andica and activation procedure. Activation may be required for each computer and/or hard disk drive where the software is installed subject to the number of software licenses acquired.</p>
                  <p>For the purpose of removing any doubt, licence is granted for use of a specific version of the software for the tax year supported by that version.</p>
                  <p>You can use only one copy of the Software on a single licensed computer and/or hard disk drive, a license must be purchased for each computer and/or hard disk drive on which software is installed. For a network version of the software, use the software on a network provided that you have a licensed copy of the software for each computer that can access the software over that network. Software licenses are also restricted by the number of records that can be created and processed. In addition, usage is only permitted for submission of number of records and/or returns purchased under the licence. Software licence will count the number of submissions made.</p>
                  <p>You may not copy any documentation that accompanies the software or make any copies of all or part of the software other than as expressly permitted in this agreement. You may not resell, sublicense, rent or lease any portion of the software or host the software on your computer for others to use, transfer the software to another person or entity, without a specific agreement in writing by Andica, reverse engineer, decompile, disassemble, modify, translate and make any attempt to discover the source code of the software or create derivative works from the software. You may not use a previous version or copy of the software after you have received a replacement set or an upgraded version as a replacement of the prior version. </p>
                  <p>You may not use the Andica and/or Andica Limited trademarks as part of a product name, trademark or business name without prior written approval from Andica. You may not develop, market or distribute add-ons or enhancements to the software without the prior written consent of Andica, separate license fees and royalties may be payable to Andica. You may not copy any portion of the Andica products graphical user interface for incorporation into or use for any software or other products without the prior written consent of Andica.</p>
                  <p>You acknowledge and agree that Andica or its licensor are the exclusive owners of all rights in the Software, including all intellectual property rights now in existence or which come into existence and you will comply with all of the terms and conditions of this Agreement. Any violation by you of this Agreement that will cause Andica or its Licensor irreparable harm will entitle Andica to immediate and permanent injunctive relief in addition to all other available remedies. Any claim or dispute relating to this Agreement must be brought in Courts within the courts located in Middlesex and London, United Kingdom. You have had a complete opportunity to review and understand this Agreement before using the Software.</p>
                  <p>Links to third party web sites or documents within the software or any options that allow links to third party sites or documents is provided to you for convenience only. Andica does not generate or control the content of the third party sites and is not responsible for and does not endorse the policies, practices or contents of any third party sites and cannot be held responsible for the content, unavailability, services and neither can we provide any advice on the third party sites or documents.</p>
                  <p>
                    <b>LIMITED WARRANTY:</b>
                    <br>
                    <br>To the maximum extent permitted by the applicable law, the warranty and remedy set forth below are exclusive and in lieu of all other warranties and remedies, oral or written, expressed or implied. Except as provided for in this agreement, no Andica reseller, dealer, agent or employee is authorized to modify or add to the following warranties and remedies. <br>
                    <br>Andica warrants that the media on which the Software is distributed will be free from defects in material and workmanship under normal use for the period of 14 days from the date of delivery of the Software to you. Your sole remedy in the event of a breach of this warranty will be that Andica will, at its option, replace any defective media returned to Andica within the warranty period. Andica does not warrant that the software will meet your requirements or that operation of the Software will be uninterrupted or that the Software will be error-free or that all defects will be corrected. Warranty is void if failure of the Licensed Software has resulted from accident, abuse, modification or misapplication of the Licensed Software and/or use of the Licensed Software on a defective computer system and/or incompatible, defective, out-of-date, unsupported Operating Systems. The aforementioned warranty specifically excludes Third-Party Owners&#39; proprietary preloaded content. <br>
                    <br>If you believe there is a defect in the software such that it does not meet the limited warranty provided above, you must notify Andica in writing within the 14-day warranty period. Andica's entire liability and your exclusive remedy with regard to the Limited Warranty, shall be, at Andica's sole discretion, to either repair or replacement of the software or a refund of the amount paid by you for the software (provided in that case that you also return the software). Any repaired or replacement software shall be warranted for the remainder of the original warranty period. <br>
                    <br>IN NO EVENT WILL ANDICA BE LIABLE FOR INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL DAMAGES OR SIMILAR DAMAGES ARISING OUT OF THE USE OR INABILITY TO USE THE LICENSED SOFTWARE INCLUDING ANY LOST PROFITS OR LOST DATA ARISING OUT OF THE USE OR INABILITY TO USE THE SOFTWARE EVEN IF ANDICA OR ITS LICENSOR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. In particular, and without limitation, Andica shall have no liability for any data stored or processed with the Licensed Software, including the costs of recovering such data. IN NO CASE SHALL ANDICA&#39;S LIABILITY EXCEED THE PURCHASE PRICE FOR THE SOFTWARE. The disclaimers and limitations set forth above will apply regardless of whether you accept the Software. <br>
                    <br>Andica provides maintenance and/or technical support (including upgrades and enhancements) for the Software only through separate support Agreements. Support is not included in the price of the software and the company reserves the right to provide and/or refuse support for the product. Please contact Andica or the place from which you obtained the Software if you wish to obtain maintenance and/or technical support through the execution of such an agreement. <br>
                    <br>Your right to use the software continues until this agreement is terminated; or, in the event of the limited period license, the expiry of such license period. You may terminate this agreement at any time by completely removing, uninstalling and deleting all of your copies of the software and returning the software to Andica at your own costs. This agreement will automatically terminate if you fail to comply with the material terms of this agreement. Upon any termination, you agree to remove all software from your computers, destroy all copies of the Licensed Software, and, upon request from Andica, certify in writing your compliance herewith. <br>
                    <br>If any of the terms, or portions thereof, of this agreement are invalid or unenforceable under any applicable statute or rule of law, the court shall reform the contract to include an enforceable term as close to the intent of the original term as possible; all other terms shall remain unchanged. <br>
                    <br>This agreement shall inure to the benefit of, and is freely assignable to, Andica&#39;s successors and assignees of rights in the Licensed Software. <br>
                    <br>If the Licensee acquired the software through a Reseller, the Licensee acknowledges that:
                  </p>
                  <ol style="padding: 0px 0px 0px 25px; list-style: lower-roman;">
                    <li>Payment and delivery terms for the Licensed Software must be established separately and independently between the Licensee and the Reseller.</li>
                    <li>This software license agreement constitutes the entire agreement between the Licensee and Andica regarding the license rights for the software as described above and is controlling.</li>
                    <li>The terms and conditions of any purchase order or any other agreement between the Licensee and the Reseller are not binding on Andica.</li>
                    <li>The Reseller is not Andica&#39;s agent and is not authorised to alter, amend or modify the terms of this agreement or to otherwise grant any license or other rights relating in any way to the Licensed Software.</li>
                    <li>Licensee&#39;s non-payment of any amount due to a Reseller or any other relevant third party relating to its licensed rights under this agreement shall constitute a basis for Andica&#39;s termination of this agreement. Licensee further acknowledges that Andica makes no representation or warranty with regard to any services provided by any Reseller, or any actions or failures to act by any Reseller.</li>
                  </ol>
                  <p>This Agreement will be governed by the United Kingdom laws relating to contracts made here. The U.N. Convention on the International Sale of Goods is expressly excluded. This Agreement may only be modified by a license addendum which accompanies this license or by a written document which has been signed by both you and Andica. Should you have any questions concerning this Agreement, or if you desire to contact Andica for any reason, please write to:</p>
                  <p>&nbsp;</p>
                  <p>
                    <b>ANDICA LIMITED</b>
                    <br>THE PINES <br>DAWS HILL LANE <br>HIGH WYCOMBE <br> BUCKINGHAMSHIRE <br>HP11 1PW
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!--introduction section start/..........................................................-->
          <div id="Introduction1" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Introduction</b></div>
                  <br />
                  <h3>Welcome to Andica SA800 Partnership Software</h3>
                  <br />
                  <p>
                    Introducing Partnership Software from Andica Limited,
                    the easiest way to complete SA800 Partnership tax return forms and submit
                    the returns online.
                  </p>
                  <p><b>Features</b></p>
                  <p>
                    Key features of Andica SA800 Partnership software
                    include:
                  </p>
                  <p><u>Tax Return Forms</u></p>
                  <p>
                    The software supports HM Revenue &amp; Customs (formerly Inland Revenue)
                    Partnership tax return form SA800 with the most common supplementary
                    forms you might need, dependent on your circumstances. In all, Andica
                    SA800 Partnership software provides the following forms:
                  </p>
                  <ul>
                    <li><a href="SA800.htm">SA800</a> – Partnership Tax Return pages</li>
                    <li><a href="SA800PS.htm">SA800(PS)</a> – Partnership Statement Full and Partnership Statement Short</li>
                    <li><a href="SA800TP.htm">SA800(TP)</a> – Partnership Trading and Professional Income</li>
                    <li><a href="SA801.htm">SA801</a> – Partnership UK Property</li>
                    <li><a href="SA802.htm">SA802</a> – Partnership Foreign </li>
                    <li><a href="SA803.htm">SA803</a> – Partnership Disposal of Chargeable Assets</li>
                    <li><a href="SA804.htm">SA804</a> – Partnership Savings, Investments and Other Income</li>
                  </ul>
                  <p><u>Online submission</u></p>
                  <p>
                    You can complete and submit your tax return electronically to the
                    HMRC via the Government Gateway using a File By Internet feature. You
                    will need a prior registration with the HMRC who will provide you with
                    a Gateway User ID and Password. For details refer to the 'File By Internet'
                    chapter of this user guide.
                  </p>
                  <p><u>Reports</u></p>
                  <p>
                    The software provides reports for self assessment Partnership return
                    that can be printed for your records. If you are a tax adviser, you
                    can use the reports to obtain your clients signatures. Reports cannot
                    be submitted to the HMRC to file the tax returns.
                  </p>
                  <p><b>Convention</b></p>
                  <p>In this guide the following conventions will be used:</p>
                  <p>
                    Using Keyboard:<br>When referring to the keyboard commands, we have
                    shown this in square brackets [Enter], [Alt+U].
                  </p>
                  <p>
                    To open a menu using the keyboard, press [Alt] key and, at the same
                    time, press the underlined character from the menu. Once the menu is
                    displayed, select the option you require using the arrow keys or by
                    pressing the underlined character on your keyboard. For example, to Open a Partnership record using the keyboard, you can press [Alt+F] and once the file menu is displayed, press the 'O' key on your keyboard.
                  </p>
                  <ul>
                    <li>
                      Press [Alt+V] and once the file menu is displayed, press the
                      'E' key on your keyboard.
                    </li>
                    <li>Press [Alt+V] and press [Enter] key.</li>
                  </ul>
                  <p>
                    Buttons/tabs/menu options in the program will be shown in single
                    quotes: &#39;OK&#39;, 'Edit', &#39;Cancel&#39;
                  </p>
                  <p>
                    Successive directions will be separated by an arrow
                    <span class="arrow">à</span> : For instance, clicking on the &#39;View&#39;
                    menu, then selecting &#39;Tax Forms' and then selecting the &#39;SA800&#39; is shown
                    as: 'View' <span class="arrow">à</span> 'Tax Forms'
                    <span class="arrow">à</span> 'SA800'.
                  </p>
                  <p>In the Andica SA800 Partnership software user guide and help files (but not in the HMRC Guides and Notes), the words 'You', 'Your', etc are used to refer to the Partnership and/or Partners, however if you are an agent of the Partnership and/or Partners, system administrator, installer or installing the software on behalf of the Partnership and/or Partners, please read them in the correct context.</p>
                </div>
              </div>
            </div>
          </div>

          <!--Welcome to Andica Self Assessment SA 800 Partnership Software started/...............................................................................................-->
          <div id="welcome" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Introduction</b> </div>
                  <br />
                  <p>
                    <b>Welcome to Andica Self Assessment SA 800 Partnership Software</b>
                  </p>
                  <p>
                    Introducing Self Assessment Partnership Software from Andica Limited, the easiest way to complete SA800 Partnership tax return forms and submit the returns online.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!--Features start ..................................................................................................................................-->
          <div id="Features1" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"><b> &nbsp;Features</b> </div>
                  <br />
                  <p>Key features of Andica Self Assessment SA800 Partnership software include:</p>
                  <p><u>Tax Return Forms</u></p>
                  <p>The software supports HM Revenue &amp; Customs (formerly Inland Revenue) Partnership tax return form SA800 with the most common supplementary forms you might need, dependent on your circumstances. In all, Andica Self Assessment software provides the following forms:</p>
                  <ul>
                    <li><a href="SA800.htm">SA800</a> – Partnership Tax Return pages</li>
                    <li><a href="SA800PS.htm">SA800(PS)</a> – Partnership Statement Full and Partnership Statement Short</li>
                    <li><a href="SA800TP.htm">SA800(TP)</a> – Partnership Trading and Professional Income</li>
                    <li><a href="SA801.htm">SA801</a> – Partnership UK Property</li>
                    <li><a href="SA802.htm">SA802</a> – Partnership Foreign </li>
                    <li><a href="SA803.htm">SA803</a> – Partnership Disposal of Chargeable Assets</li>
                    <li><a href="SA804.htm">SA804</a> – Partnership Savings, Investments and Other Income</li>
                  </ul>
                  <p><u>Online submission</u></p>
                  <p>
                    You can complete and submit your tax return electronically to
                    the HMRC via the Government Gateway using a File By Internet feature. You will
                    need a prior registration with the HMRC who will provide you with a Gateway User
                    ID and Password. For details refer to the 'File By Internet' chapter of this
                    user guide.
                  </p>
                  <p><u>Reports</u></p>
                  <p>
                    The software provides reports for self assessment Partnership
                    return that can be printed for your records. If you are a tax adviser, you can
                    use the reports to obtain your clients signatures. Reports cannot be submitted
                    to the HMRC to file the tax returns.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--Convention start............-->
          <div id="Quick" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Convention</b> </div>
                  <br />
                  <p>
                    In this guide the following conventions will
                    be used:
                  </p>
                  <p>
                    <b>Using Keyboard:</b><br>
                    When referring to the keyboard commands, we
                    have shown this in square brackets [Enter], [Alt+U].
                  </p>
                  <p>
                    To open a menu using the keyboard, press
                    [Alt] key and, at the same time, press the underlined character from the
                    menu. Once the menu is displayed, select the option you require using the
                    arrow keys or by pressing the underlined character on your keyboard. For
                    example, to Open a Partnership record using the keyboard, you can press
                    [Alt+F] and once the file menu is displayed, press the 'O' key on your keyboard.
                  </p>
                  <p>
                    Buttons/tabs/menu options in the program
                    will be shown in single quotes: &#39;OK&#39;, 'Edit', &#39;Cancel&#39;
                  </p>
                  <p>
                    Successive directions will be separated by
                    an arrow <span class="arrow">
                      à
                    </span> : For instance, clicking on the &#39;View&#39;
                    menu, then selecting &#39;Tax Forms' and then selecting the &#39;SA800&#39; is shown
                    as: 'View' 		<span class="arrow">à</span>
                    'Tax Forms' 		<span class="arrow">à</span>
                    'SA800'.
                  </p>
                  <p>
                    In the Andica Self Assessment software user
                    guide and help files (but not in the HMRC Guides and Notes), the words 'You',
                    'Your', etc are used to refer to the Tax Payer, however if you are an agent
                    of the tax payer, system administrator, installer or installing the software
                    on behalf of the tax payer, please read them in the correct context.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!--Quick reference start ....................................................................-->
          <div id="Reference1" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Quick Reference</b> </div>
                  <br />
                  <p>This information is provided to describe the routines you need to follow to complete your returns and submit them online to HMRC. It must be read in conjunction with the detailed information provided in the user guide.</p>
                  <p>Install <span class="arrow">à</span> Complete returns <span class="arrow">à</span> Submit online</p>
                  <ol>
                    <li>Installation and Activation</li>
                    <li>Creating Partnership record</li>
                    <li>Complete SA800 and Supplementary forms (SA801 to SA804 as necessary)</li>
                    <li>Complete Partnership Statement</li>
                    <li>Print reports</li>
                    <li>Submitting returns using File by Internet </li>
                  </ol>
                  <p>
                    This guide is by no means a substitute to your knowledge of completion of tax returns forms and is only aimed at providing a quick reference. We strongly recommend that you read the full user guide and the FAQ section of our website for further details.
                  </p>
                  <p><b>Installation and Activation</b></p>
                  <blockquote>
                    <ol type="a">
                      <li>Install the software using a downloaded installation file or a CD</li>
                      <li>Activate software – refer to 'Registering and Activating' section of the guide.</li>
                    </ol>
                  </blockquote>
                  <p><b>Creating Partnership record</b></p>
                  <p>Create a record for the partnership business as described in 'Creating Users and Partnership Record' <span class="arrow">à</span> 'Creating Partnership Record' section of the guide.</p>
                  <p><b>Complete SA800 and Supplementary forms (SA801 to SA804)</b></p>
                  <blockquote>
                    <ol type="a">
                      <li>Log into the software and open the Partnership record</li>
                      <li>Access and complete SA800 (At least one of Q1 to Q7 will need to be selected)</li>
                      <li>Supplementary forms you require can be selected by ticking Q1 to Q7 in SA800.</li>
                      <li>Complete entries in all the supplementary forms you have selected</li>
                    </ol>
                  </blockquote>
                  <p><b>Complete Partnership Statement</b></p>
                  <p>Once you have completed entering data in SA800 and the supplementary forms, you can create Individual Partner's details and complete Partnership Statement. Refer to 'Partnership Statement SA800(PS)' section of the guide.</p>
                  <p><b>Print reports</b></p>
                  <p>Print a copy of your returns. Refer to 'Printing reports' section of the guide.</p>
                  <p><b>Submitting returns – File by Internet</b></p>
                  <p>Submit your returns online using File by Internet. Refer to 'File By Internet' section of the guide.</p>
                  <p><u>'Start Here' feature of the software is designed to take you through important functions of the software to complete your returns.</u></p>
                </div>
              </div>
            </div>
          </div>
          <!--start here..............................................-->
          <div id="Start1" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Start Here</b> </div>
                  <br />
                  <p>Start Here screen is displayed when you log into the software. This 'flow-chart' style feature is designed to take you step-by-step through important functions of the software to complete a tax return.</p>
                  <p>This feature only provides a few key steps and it must be used in conjunction with other functions of the software as detailed in the user guide.</p>
                  <br>
                  <img border="0" src="../../../../assets/screens/StartHereImage003.jpg">
                  <p><b><u>Steps:</u></b></p>
                  <p>Clicking on the Step numbers will display the Help notes relating to that function. Follow the steps in the order displayed on screen.</p>
                  <p><b><u>Buttons:</u></b></p>
                  <p>Click on the buttons to navigate to their relevant functions within the software.</p>
                  <p>
                    Buttons may be disabled when another function that precedes it has not been completed. For example:
                    'Open Partnership record' button will be disabled if there are no Partnership records created.
                  </p>
                  <p>Function of a button will change if you have already completed a function and there is another associated function for that feature. For example: If you have already created a Partnership record and have opened that Partnership record, the 'Create Partnership record' button changes to 'Edit Partnership information'.</p>
                  <a name="DontShow"></a>
                  <p><b><u>'Don't show this page again':</u></b></p>
                  <p>
                    The 'Start Here' screen will be displayed every time you log into the software.
                    Tick this box if you do not want see this screen again. You can display the screen again by selecting either:
                  </p>
                  <p>a) 'Start Here' <span class="arrow">à</span> 'Quick Start' option from drop down menu on the vertical toolbar</p>
                  <img border="0" src="../../../../assets/screens/StartHereImage005.jpg" width="613" height="91">
                  <p>b) 'Help' <span class="arrow">à</span> 'Show Start Here' option from the Menu bar</p>
                  <img border="0" src="../../../../assets/screens/StartHereImage007.jpg" width="572" height="98">
                  <p><u>'Start Here' dropdown (additional options):</u></p>
                  <img border="0" src="../../../../assets/screens/StartHereImage009.jpg" width="173" height="99">
                  <p>'Start Here' option on the vertical toolbar provides additional features that are activated once you start processing your returns. Selecting the option will take you to that screen within the software.</p>
                  <p>These options are only provided on the dropdown as a means to quickly access those screens in the software and should not be used to 'jump' to the process eliminating other vital steps you should go through to complete the tax returns.</p>
                  <p>'Status' and 'Online submission' screens will displayed towards the end when processing the returns step-by-step through all the SA800 and supplementary forms.</p>
                  <p><b><u>Status</u></b></p>
                  <p>Status screen shows an overview of tax return progress.</p>
                  <p>Upon creating a Partnership record, the 'Status' screen lists initial forms and features available to start processing the tax return.</p>
                  <p>Although some of the items will show a tick, you will need to access each section and complete them. Further details are provided in the relevant sections of the Guide and Help file. You can access the sections by clicking on the green text (hyperlinks).</p>
                  <p>It is important that you complete the SA800 and select any supplementary forms you may need by selecting Q1 to Q7 as required.</p>
                  <p>This feature is different to the 'Status' strip option within the horizontal tool bar.</p>
                  <img border="0" src="../../../../assets/screens/StartHereImage011.jpg" width="588" height="422">
                  <p><b><u>Online submission</u></b></p>
                  <p>
                    Selecting this option opens a screen through which you can start the 'File by Internet' function.
                    Details are provided on screen and in the 'File by Internet' section.
                  </p>
                  <img border="0" src="../../../../assets/screens/StartHereImage013.png" width="564" height="386">
                </div>
              </div>
            </div>
          </div>
          <!--Working with Andica SA800 Software start here..............................................-->
          <div id="Working" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Working with Andica SA800 Software</b> </div>
                  <br />
                  <p>Completion and submission of Partnership tax return can be accomplished in four simple routines within Andica SA800 Partnership Software.</p>
                  <ol>
                    <li>Gather information about the business activities of the Partnership</li>
                    <li>Enter information gathered in the forms</li>
                    <li>
                      View and print reports for your records
                      or for your clients
                    </li>
                    <li>
                      Submit Partnership tax return online
                      to HMRC and print a submission receipt for your records.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div id="Information1" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Gather Information</b> </div>
                  <br />
                  <p>
                    Gather all the relevant information and paperwork
                    you need to complete Partnership tax returns. You will need information
                    about the partnership&#39;s business, including any investments. Useful paperwork
                    includes:
                  </p>
                  <ul>
                    <li>Accounts for the Partnership business</li>
                    <li>
                      Details of Partners – Names, addresses,
                      personal UTR and NI Numbers
                    </li>
                    <li>
                      Details of any Savings, investments
                      and other income
                    </li>
                    <li>Details of any Foreign income</li>
                    <li>Details of any UK property income</li>
                    <li>
                      Details of any Disposal proceeds on
                      chargeable assets
                    </li>
                  </ul>
                  <p>
                    This is just a brief list of information
                    that may be required. Where necessary you may be asked to submit additional
                    details to HMRC, this information can be provided as a PDF attachment or
                    where appropriate as text in the 'Additional information' boxes within the
                    relevant supplementary form.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="Information2" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Entering Information</b> </div>
                  <br />
                  <p>
                    You can start entering tax return details
                    based on the information you have gathered.
                  </p>
                  <p>
                    Andica SA800 Partnership Software and data
                    files are stored on your computer. As information is entered, it is saved
                    on your computer. You can close the software at any point and continue data
                    entry later. Data can be amended any time prior to submission of returns.
                    After amending data or opening the data entry form, click 'Next' to the
                    end of the software and click 'Finish' to close the data entry screen. Data
                    should not be amended after the submission.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="Submitting" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Submitting returns to HMRC</b> </div>
                  <br />
                  <p>Once you have completed entering Partnership tax return data and have reviewed reports and are satisfied that the information entered is accurate, you can submit your returns to the HMRC in one of two ways:</p>
                  <ol>
                    <li>
                      Online filing using 'File By Internet'
                      feature
                    </li>
                    <li>
                      Manually complete the paper forms you have received from HMRC and post
                      them so as to get to the HMRC before the deadline for paper returns.
                      PDF forms can also be downloaded from HMRC website. Reports generated by
                      the
                      software are for your records only and should not be submitted
                      to the HMRC as they are not accepted as paper returns..
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <!--Creating Users and Partnership Records starts here...............................................-->
          <div id="PartnershipRecords2" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Creating Users and Partnership Records</b> </div>
                  <br />
                  <p><b>Creating and Opening software User Information</b></p>
                  <p><b>Creating software User accounts</b></p>
                  <p>
                    Before you start using the software, it is
                    recommended that you create at least one user account. This is the user
                    name and password that can be used to access the software for preparation
                    of tax returns. If you are the only person responsible for operating the
                    software, you can use the default user name of 'Admin' as described in the
                    'Getting Started'  section.
                  </p>
                  <p>To create User accounts, either:</p>
                  <ol>
                    <li>
                      Select 'File' 			<span class="arrow">à</span>
                      'New' on the menu, or,<br>
                      <img border="0" height="244" src="../../../../assets/screens/CreatingUsersAndPartnershipRecords01.jpg" width="211">
                    </li>
                    <li>
                      Press [Ctrl + N] keys on the keyboard,
                      or,
                    </li>
                    <li>
                      Click the 'New' icon on horizontal toolbar
                      <img border="0" height="24" src="../../../../assets/screens/CreatingUsersAndPartnershipRecords02.jpg" width="26" style="margin: 0px 0px 0px 5px;">
                    </li>
                    <p>
                      In the 'Create Record' screen, select
                      'Record type' of '<strong>Accountant</strong>' or '<strong>Administrator</strong>'
                    </p>
                  </ol>
                  <p><img border="0" height="359" src="../../../../assets/screens/CreatingUsersAndPartnershipRecords02.jpg"></p>
                  <p>
                    In the 'Create Record' screen, select and
                    enter details as appropriate.
                  </p>
                  <table border="1" cellpadding="5" cellspacing="0" width="90%">
                    <tr>
                      <td align="left" valign="top" width="20%">Record type</td>
                      <td>
                        Select from dropdown list one of the option:
                        <p><u><b>Administrator</b></u> – Select this option if you are creating a record of a software user with software administration functions. This user group has all access rights to the system maintenance and creation of all 'Record type'.</p>
                        <p><u><b>Accountant</b></u> – Select this option if you are creating a record of a software user that will be processing tax returns. This user group has limited access rights to the system and can create 'Record type' of 'Partnership'. This is useful if you have purchased multi-user software and want to allow staff in the office access to the software functions.</p>
                        <p>
                          <u><b>Partnership</b></u> – Select this option if you are
                          creating record of a Partnership (Client) whose tax return
                          details will be processed. Further information for this is
                          provided in '<a href="#CreatingPartnershipRecords"><u><font color="#0000FF">Creating Partnership records</font></u></a>' section.
                        </p>
                      </td>
                    </tr>
                  </table>
                  <p>
                    To create software user records select 'Record
                    type' of 'Administrator' or 'Accountant' and enter the required information:
                  </p>
                  <table border="1" cellpadding="5" cellspacing="0" width="90%">
                    <tr>
                      <td align="left" valign="top" width="20%">Name</td>
                      <td>Enter the full name of the software user. Field cannot be left blank.</td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="20%">User name</td>
                      <td>Enter a name you intend to use when accessing the software. Field cannot be left blank.</td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="20%">Password</td>
                      <td>
                        Enter a password you intend to use when accessing the software. Password is case sensitive and can be left blank.
                        <p><b>IMPORTANT</b>: Please ensure you have used a memorable password.</p>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="20%">Re-enter Password</td>
                      <td>
                        Confirm the password entered in 'Password' field by re-entering it here. Passwords in both fields should match.<br>
                        <span style="font-size:10.0pt;font-family:Arial;
                           mso-fareast-font-family:&quot;Times New Roman&quot;;mso-ansi-language:EN-GB;mso-fareast-language:
                           EN-GB;mso-bidi-language:AR-SA">
                          Click 'Save' once you have entered all the
                          details on this screen.
                        </span>
                      </td>
                    </tr>
                  </table>
                  <a name="CreatingPartnershipRecords"></a>
                  <p><b>Creating Partnership Records</b></p>
                  <p>Before you start using the software, you will need to create a record for the Partnership for which you are preparing the tax returns.</p>
                  <p>To create a Partnership record, either:</p>
                  <ol>
                    <li>
                      Select 'File' <span class="arrow">à</span>
                      'New' on the menu, or,<br>
                      <img border="0" height="244" src="../../../../assets/screens/CreatingUsersAndPartnershipRecords01.jpg" width="211">
                    </li>
                    <li>
                      Press [Ctrl + N] keys on the keyboard,
                      or,
                    </li>
                    <li>
                      Click the 'New' icon on horizontal toolbar
                      <img border="0" height="24" src="../../../../assets/screens/CreatingUsersAndPartnershipRecords02.jpg" width="26" style="margin: 0px 0px 0px 5px;">
                    </li>
                    <li>In the 'Create Record' screen, select 'Record type' of '<strong>Partnership</strong>'</li>
                  </ol>
                  <p>
                    <img border="0" src="../../../../assets/screens/CreatingUsersAndPartnershipRecords03.jpg">
                  </p>
                  <p>
                    <strong>IMPORTANT:</strong>
                    Please ensure all details entered here are accurate as you will not be able to change some of this information once you have saved it. Some of the critical information required here is usually shown on the SA800 form, a Notice to complete a Partnership Tax Return or on some other documents issued by the HMRC. You will need to contact the HMRC office if you have not got critical details normally printed on the Partnership Tax Return form.
                  </p>
                  <p>In the 'Create Record' screen, select and enter details as appropriate.</p>
                  <table border="1" cellpadding="5" cellspacing="0" width="90%">
                    <tr>
                      <td align="left" valign="top" width="20%">Record type</td>
                      <td>
                        <p>Select from dropdown list the option:</p>
                        <p>
                          <strong>Partnership </strong>– Select this option if you are creating record of a Partnership business (Client) whose tax return details will be processed. This group (record type) does not have access rights to log into and open the software.
                        </p>
                        <p>(Details of Record type 'Administrator' and 'Accountant' is explained in <a href="#Creating software User accounts"><u><font color="#0000FF">Creating software User accounts</font></u></a>' section of the guide and Help files.</p>
                      </td>
                    </tr>
                  </table>
                  <p>To create Partnership business records select 'Record type' of 'Partnership' and enter the required information: </p>
                  <p><u><b>Business</b></u>:</p>
                  <table border="1" cellpadding="5" cellspacing="0" width="90%">
                    <tr>
                      <td align="left" valign="top" width="20%">Partnership name</td>
                      <td>Name of the Partnership business as you wish to display on the SA800 reports and submissions. Partnership name cannot be left blank.</td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="20%">Partnership tax ref.</td>
                      <td>
                        <p>This is the Unique Tax Reference (UTR) issued to you by HM Revenue & Customs for the Partnership business and not a partners personal UTR.</p>
                        <p>Partnership tax ref. is made up of ten numbers e.g. 9876556789 and you will find it on the Partnership Tax Return or a Notice to complete a Partnership Tax Return. It may also be on other documents.</p>
                        <p>Tax reference cannot be left blank. Software will also check if the Tax reference already exists and stop creation of duplicate records with the same reference.</p>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="20%">Address and Postcode</td>
                      <td>
                        Partnership business address and postcode.
                        Postcode should be entered in UK postcode format. Where the Partnership has a non-UK address, leave the postcode field blank and provide the foreign postcode / Zip code as part of the address field.
                      </td>
                    </tr>
                  </table>
                  <p><u><b>Principal contact</b></u>:</p>
                  <table border="1" cellpadding="5" cellspacing="0" width="90%">
                    <tr>
                      <td align="left" valign="top" width="20%">Title</td>
                      <td>Select from dropdown list or enter a title. Enter maximum 4 characters.</td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="20%">Full name</td>
                      <td>Enter full name of the nominated partner who is responsible for signing or authorising submission of the SA800 returns for this Partnership business.</td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="20%">Telephone number</td>
                      <td>Enter nominated partners telephone number.</td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="20%">Fax number</td>
                      <td>Enter nominated partners fax number.</td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="20%">Email address</td>
                      <td>Enter nominated partners email address.</td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="20%">Data file path</td>
                      <td>
                        Data file path will automatically be completed with the
                        drive letter, folder name and file name.
                        <p>
                          For single user software, data file path must be in Windows Public Documents folder of your computer: For example: C:\Users\Public\Documents\Andica\Andica SA800 Partnership 201x
                        </p>
                        <p>From version 2014 onwards this cannot be modified and is for display purpose only.</p>
                        <p>For Multi Users (Network) software, either accept the default path displayed or change to the shared network folder path if necessary.</p>
                        <p>Data files can be created in a different location if required.</p>
                        <p>Once data files have been created, it should not be moved using Windows Explorer.</p>
                        <p><b>IMPORTANT:</b> Some information cannot be changed once saved, please ensure you have entered correct details.</p>
                        <p>Click 'Save' once you have entered all the details on this screen.</p>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div id="Opening" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Opening Partnership Records</b> </div>
                  <br />
                  <p>
                    Once created, Partnership records will be
                    listed in the List View grid (workspace) and can be opened either by:
                  </p>
                  <ol>
                    <li>
                      Selecting 'File' 			<span class="arrow">à</span>
                      'Open' on the menu, or,
                    </li>
                    <li>
                      Double clicking on the Partnership record
                      on the List View, or
                    </li>
                    <li>
                      Press [Ctrl + O] keys on the keyboard,
                      or,
                    </li>
                    <li>
                      Click the 'Open' icon on horizontal
                      toolbar
                      <img border="0" height="23" src="../../../../assets/screens/CreatingUsersAndPartnershipRecords04.jpg" width="24" style="margin: 0px 0px 0px 5px;">
                    </li>
                  </ol>
                  <a name="AmendingPartnershipInformation"></a>
                  <p><b>Amending Partnership Information</b></p>
                  <p>
                    A software user with Administrator or Accountants
                    access rights can access and amend Partnership information using the following
                    options:
                  </p>
                  <ol>
                    <li>
                      Open a Partnership record using any of the methods described in
                      <a href="Opening.htm"><a href="#Opening"><u><font color="#0000FF">Opening Partnership Records</font></u></a>' section. If a record is not open, the ‘Partnership information’ screen is not accessible.</a>
                    </li>
                    <li>Close any data entry forms or screens.</li>
                    <li>
                      Access 'Partnership information' through
                      either one of the following options:
                      <ol type="a">
                        <li>
                          From the menu, select 'Tools'
                          <span class="arrow">à</span>
                          'Security' 				<span class="arrow">à</span>
                          'Partnership information'<br>
                          <img border="0" height="187" src="../../../../assets/screens/CreatingUsersAndPartnershipRecords05.jpg" width="365">
                        </li>
                        <li>
                          From the vertical toolbar, select
                          'Tools' strip 				<span class="arrow">à</span>
                          'Partnership information'<br>
                          <img border="0" height="20" src="../../../../assets/screens/CreatingUsersAndPartnershipRecords08.jpg" width="160">&nbsp;<span class="arrow">à</span>&nbsp;<img border="0" height="22" src="../../../../assets/screens/CreatingUsersAndPartnershipRecords07.jpg" width="150" style="margin:0; padding:0">
                        </li>
                      </ol>
                    </li>
                  </ol>
                  <p><b>Amending 'User Accounts'</b></p>
                  <p>
                    A software user with Administrator or Accountants
                    privileges can access and amend certain 'User accounts' information by selecting
                    'Tools' <span class="arrow">à</span>
                    'Security' 		<span class="arrow">à</span>
                    'User accounts'
                  </p>
                  <p><img border="0" height="194" src="../../../../assets/screens/CreatingUsersAndPartnershipRecords06.jpg" width="372"></p>
                  <p><b>Change Password</b></p>
                  <p>While you are logged into the software using a software User name, you can change the password for that user by selecting 'Tools' <span class="arrow">à</span> 'Security' <span class="arrow">à</span> 'Change password'.</p>
                  <p><img border="0" height="194" src="../../../../assets/screens/ChangePasswordmenu.jpg" width="372"></p>
                  <p>The user name you have logged in with will be displayed at the bottom of the software. </p>
                  <p><b>Important:</b> We <b>do not</b> recommend changing the password for 'Admin' user. If this password is changed and you forget the new password you have specified, it will not be possible for you to access the software using this 'Admin' login.</p>
                  <p><img border="0" height="194" src="../../../../assets/screens/ChangePassword.jpg" width="372"></p>
                  <p><b>User Accounts access privileges 		</b></p>
                  <p>
                    If you are the only person responsible for
                    operating the software, you can use the default user name of 'Admin' which
                    has all the 'Administrators' privileges assigned.
                  </p>
                  <table id="AutoNumber1" border="0" cellpadding="6" cellspacing="0" width="80%">
                    <tr>
                      <td width="40%"><u>Functions accessible by 'Record Type'</u></td>
                      <td align="center" width="20%"><u>Administrators</u></td>
                      <td align="center" width="20%"><u>Accountant</u></td>
                      <td align="center" width="20%"><u>Partnership</u></td>
                    </tr>
                    <tr>
                      <td width="40%">Log into the software</td>
                      <td align="center" width="20%">Yes</td>
                      <td align="center" width="20%">Yes</td>
                      <td align="center" width="20%">No</td>
                    </tr>
                    <tr>
                      <td width="40%">
                        Create and amend software
                        user accounts
                      </td>
                      <td align="center" width="20%">Yes</td>
                      <td align="center" width="20%">
                        Amend
                        own
                      </td>
                      <td align="center" width="20%">No</td>
                    </tr>
                    <tr>
                      <td width="40%">Create tax payer's records</td>
                      <td align="center" width="20%">Yes</td>
                      <td align="center" width="20%">Yes</td>
                      <td align="center" width="20%">No</td>
                    </tr>
                    <tr>
                      <td width="40%">
                        Process tax payer's
                        data
                      </td>
                      <td align="center" width="20%">Yes</td>
                      <td align="center" width="20%">Yes</td>
                      <td align="center" width="20%">No</td>
                    </tr>
                    <tr>
                      <td width="40%">Print reports</td>
                      <td align="center" width="20%">Yes</td>
                      <td align="center" width="20%">Yes</td>
                      <td align="center" width="20%">No</td>
                    </tr>
                    <tr>
                      <td width="40%">Calculate tax liabilities</td>
                      <td align="center" width="20%">Yes</td>
                      <td align="center" width="20%">Yes</td>
                      <td align="center" width="20%">No</td>
                    </tr>
                    <tr>
                      <td width="40%">Submit returns online</td>
                      <td align="center" width="20%">Yes</td>
                      <td align="center" width="20%">Yes</td>
                      <td align="center" width="20%">No</td>
                    </tr>
                    <tr>
                      <td width="40%">Backup data</td>
                      <td align="center" width="20%">Yes</td>
                      <td align="center" width="20%">Yes</td>
                      <td align="center" width="20%">No</td>
                    </tr>
                    <tr>
                      <td width="40%">Restore data</td>
                      <td align="center" width="20%">Yes</td>
                      <td align="center" width="20%">Yes</td>
                      <td align="center" width="20%">No</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!--Getting Started starts..........................................................-->
          <div id="Started1" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Getting Started</b> </div>
                  <br />
                  <p><b>Starting Andica SA800 Partnership Software</b></p>
                  <p>
                    Upon successful installation of the software or if you had closed
                    the software at any time, you can access the software as described here.
                  </p>
                  <table border="1" cellpadding="5" cellspacing="0" width="80%">
                    <tr>
                      <td align="center">
                        <p><img border="1" height="63" src="../../../../assets/screens/gettingstarted1.jpg" width="70"></p>
                      </td>
                      <td>
                        <p>Start Andica SA800 Partnership Software by double clicking on the Andica SA800 Partnership icon on the desktop. The Andica SA800 Partnership icon is automatically created on the desktop on installation of the software.</p>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <p><img height="36" src="../../../../assets/screens/gettingstarted2.jpg" width="36"></p>
                      </td>
                      <td>
                        <p>You can also access the software from the Windows 'Start' Menu. The 'Start' Menu may look and be arranged differently depending on your version of the Microsoft Windows operating system.</p>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div id="LogIn1" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Log In</b> </div>
                  <br />
                  <p align="justify"><u>Please note:</u></p>
                  <p align="justify">User name is not case sensitive.</p>
                  <p align="justify">
                    Password is case sensitive.<u>
                      <span style="text-decoration: none">
                      </span>
                    </u>
                  </p>
                  <p align="justify">Log on to Andica SA800 Partnership software for the first time using the User Name <b>admin</b> and Password <b>admin </b>and click 'OK'. Once you have setup your own software users details, you can log on to the software using the User Name and Password assigned to you and click 'OK'.</p>
                  <p>
                    <img src="../../../../assets/screens/Logininstall2023.png">
                  </p>
                  <p align="justify"><span style="font-size: 10.0pt; font-family: Arial">The software will load and is ready for you to process Partnership Tax Return information</span></p>
                </div>
              </div>
            </div>
          </div>
          <div id="PartnershipSoftware2" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Familiarise yourself with Andica SA800 Partnership Software</b> </div>
                  <br />
                  <p><img height="592" src="../../../../assets/screens/Login2.jpg" width="627" alt=""> </p>
                  <p>(Progress column not available from version 2011)</p>
                </div>
              </div>
            </div>
          </div>
          <div id="MenuBar2" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Menu Bar</b> </div>
                  <br />
                  <p><img height="24" src="../../../../assets/screens/Login3.jpg" width="369" style="margin: 0px 0px 0px 5px;"></p>
                  <p align="justify">Following functions are accessible from each menu option. </p>
                  <p align="justify">Using your mouse, click on the menu items to display drop-down menus and choose the option you require. The drop-down menu may contain further drop-down menu options.</p>
                  <p align="justify">Alternatively you can use the keyboard to display these options by pressing the [Alt] key and at the same time, press the underlined character from the menu. E.g. [Alt+F] to activate the File Menu</p>
                  <p align="justify" style="margin-left: 200">
                    <u>File</u>
                  </p>
                  <p align="justify" style="margin-left: 240"><img src="../../../../assets/screens/Login4.jpg" width="211" height="244" style="margin: 0px 0px 0px 5px;"></p>
                  <p align="justify" style="margin-left: 240"><u>Preview/Print</u></p>
                  <p align="justify" style="margin-left: 280">
                    <img height="130"
                         src="../../../../assets/screens/Login10New.jpg" width="258" style="margin: 0px 0px 0px 5px;">
                  </p>
                  <p align="justify" style="margin-left: 200"><u>Edit</u></p>
                  <p align="justify" style="margin-left: 240"><img height="104" src="../../../../assets/screens/Login6.jpg" width="119" style="margin: 0px 0px 0px 5px;"></p>
                  <p align="justify" style="margin-left: 200"><u>View</u></p>
                  <p align="justify" style="margin-left: 240"><img height="172" src="../../../../assets/screens/Login7.jpg" width="190" style="margin: 0px 0px 0px 5px;"></p>
                  <p align="justify" style="margin-left: 240">Tax Forms</p>
                  <p align="justify" style="margin-left: 285"><img height="196" src="../../../../assets/screens/Login8.jpg" width="241" style="margin: 0px 0px 0px 5px;"></p>
                  <p align="justify" style="margin-left: 280">SA800, Partnership Statement and Attached Files options will always be displayed on the menu. Display of other forms will be based on the supplementary forms you have selected.</p>
                  <p align="justify" style="margin-left: 240">
                    Reports
                  </p>
                  <p align="justify" style="margin-left: 280">
                    <img height="52" src="../../../../assets/screens/Login9.jpg" width="201" style="margin: 0px 0px 0px 5px;">
                  </p>
                  <p align="justify" style="margin-left: 320">
                    Tax returns
                  </p>
                  <p align="justify" style="margin-left: 360">
                    <img height="130" src="../../../../assets/screens/Login10New.jpg" width="258"
                         style="margin: 0px 0px 0px 5px;">
                  </p>
                  <p align="justify" style="margin-left: 200"><u>Activity</u></p>
                  <p align="justify" style="margin-left: 240">
                    <img alt="" src="../../../../assets/screens/Login11.jpg" style="margin: 0px 0px 0px 5px;">
                  </p>
                  <p align="justify" style="margin-left: 200">
                    <u>Tools</u>
                  </p>
                  <p align="justify" style="margin-left: 240">
                    <img height="196" src="../../../../assets/screens/Login12.jpg" width="170" style="margin: 0px 0px 0px 5px;">
                  </p>
                  <p align="justify" style="margin-left: 240">
                    Security
                  </p>
                  <p align="justify" style="margin-left: 280">
                    <img height="76" src="../../../../assets/screens/Login13.jpg" width="202" style="margin: 0px 0px 0px 5px;">
                  </p>
                  <p align="justify" style="margin-left: 240">
                    Imports
                  </p>
                  <p align="justify" style="margin-left: 280">
                    <img src="../../../../assets/screens/Import2023new.png" height="52" width="276">
                  </p>
                  <p align="justify" style="margin-left: 200">
                    <u>
                      Wizard
                    </u>
                  </p>
                  <p align="justify" style="margin-left: 200">
                    Menu option is currently 'greyed
                    out'
                  </p>
                  <p align="justify" style="margin-left: 200">
                    <u>
                      Internet
                    </u>
                  </p>
                  <p align="justify" style="margin-left: 240">
                    <img height="82" src="../../../../assets/screens/Login14.jpg" width="204" style="margin: 0px 0px 0px 5px;">
                  </p>
                  <p align="justify" style="margin-left: 200">
                    <u>Help</u>
                  </p>
                  <p align="justify" style="margin-left: 240">
                    <img src="../../../../assets/screens/Login15.jpg" style="margin: 0px 0px 0px 5px;" width="204" height="76">
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="HorizontalToolbar1" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Horizontal Toolbar</b> </div>
                  <br />
                  <p><img src="../../../../assets/screens/Login16.jpg"></p>
                  <p align="justify">The Toolbar will be activated by default when the software is installed. You can deactivate / reactivate the Toolbar from the Menu options by selecting View <span style="font-family: Wingdings">à</span> Toolbar.</p>
                  <p align="justify">Hover your mouse pointer on the icons to see a tool tip showing the Activities or processes performed by clicking on the Icon.</p>
                  <p><img src="../../../../assets/screens/Login17.jpg"></p>
                  <p align="justify">Horizontal toolbar can be used to select a group of Activities or processes you want to run. The following list explains what each icon on the horizontal toolbar represents:</p>
                  <table border="1" cellpadding="5" cellspacing="0" style="margin:0 0 0 50px;">
                    <tr>
                      <td width="20%" align="center"><img height="23" src="../../../../assets/screens/Login18.jpg" width="24" style="margin:0px"></td>
                      <td width="80%">
                        <p>Creates New Partnership's record.</p>
                      </td>
                    </tr>
                    <tr>
                      <td align="center"><img height="23" src="../../../../assets/screens/Login19.jpg" width="24" style="margin:0px"></td>
                      <td>
                        <p>Open another Partnership's record.</p>
                      </td>
                    </tr>
                    <tr>
                      <td align="center"><img height="23" src="../../../../assets/screens/Login20.jpg" width="26" style="margin:0px"></td>
                      <td>
                        <p>Saves entries in the screen currently open.</p>
                      </td>
                    </tr>
                    <tr>
                      <td align="center"><img height="24" src="../../../../assets/screens/Login21.jpg" width="24" style="margin:0px"></td>
                      <td>
                        <p>Move to previous window</p>
                      </td>
                    </tr>
                    <tr>
                      <td align="center"><img height="24" src="../../../../assets/screens/Login22.jpg" width="25" style="margin:0px"></td>
                      <td>
                        <p>Move to next window</p>
                      </td>
                    </tr>
                    <tr>
                      <td align="center"><img height="22" src="../../../../assets/screens/Login23.jpg" width="27" style="margin:0px"></td>
                      <td>
                        <p>File by Internet</p>
                      </td>
                    </tr>
                    <tr>
                      <td align="center"><img height="23" src="../../../../assets/screens/Login24.jpg" width="25" style="margin:0px"></td>
                      <td>
                        <p>View and print forms</p>
                      </td>
                    </tr>
                    <tr>
                      <td align="center"><img height="24" src="../../../../assets/screens/Login25.jpg" width="26" style="margin:0px"></td>
                      <td>
                        <p>Links to Andica Web site</p>
                      </td>
                    </tr>
                    <tr>
                      <td align="center"><img height="23" src="../../../../assets/screens/Login26.jpg" width="25" style="margin:0px"></td>
                      <td>
                        <p>Clears data from fields in the current window</p>
                      </td>
                    </tr>
                    <tr>
                      <td align="center"><img height="25" src="../../../../assets/screens/Login27.jpg" width="22" style="margin:0px"></td>
                      <td>
                        <p>Opens Attachments screen</p>
                      </td>
                    </tr>
                    <tr>
                      <td align="center"><img height="24" src="../../../../assets/screens/Login28.jpg" width="25" style="margin:0px"></td>
                      <td>
                        <p>Software Help files</p>
                      </td>
                    </tr>
                  </table>
                  <p align="justify">When you first access the software, the 'Forms' vertical toolbar will only display a greyed-out SA800 form icon. The SA800 icon will be enabled once you have opened a Partnership record.</p>
                </div>
              </div>
            </div>
          </div>
          <div id="VerticalToolbars1" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Vertical Toolbars</b> </div>
                  <br />
                  <table border="1" cellpadding="5" cellspacing="0" width="80%">
                    <tr>
                      <td valign="top" width="25%">
                        <p><b>Forms vertical toolbar</b></p>
                      </td>
                      <td valign="top">
                        <p>&nbsp;</p>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <p><img height="327" src="../../../../assets/screens/Login29.jpg" width="168"></p>
                      </td>
                      <td valign="top">
                        <p>There are three vertical toolbar bars, 'Forms', 'Status' and 'Tools', each listing the 'Tasks' within them.</p>
                        <p>&nbsp;</p>
                        <p>The 'Forms' toolbar displays 'SA800', 'Partnership Statement' and 'Attached Files' icons. Icons for other forms will also be displayed based on the supplementary forms selected while completing the SA800 form.</p>
                        <p>&nbsp;</p>
                        <p>'Status' toolbar provides a tree view of tax return forms and sections within those forms as shown below.</p>
                        <p>&nbsp;</p>
                        <p>'Status' and 'Tools' vertical toolbar can be activated by clicking on the 'strip bar' at the base of the toolbar.</p>
                        <p>&nbsp;</p>
                        <p>All the tasks listed within the vertical toolbars can also be accessed from other areas of the software.</p>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <p>&nbsp;</p>
                      </td>
                      <td valign="top">
                        <p>&nbsp;</p>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <p><b>Status vertical toolbar</b></p>
                      </td>
                      <td valign="top">
                        <p>&nbsp;</p>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <p><img height="194" src="../../../../assets/screens/Login30.jpg" width="169"></p>
                      </td>
                      <td valign="top">
                        <p>Displays a tree-view of forms selected and the status of each section is represented by the icons:</p>
                        <table border="1" cellpadding="5" cellspacing="0" width="90%" style="margin:5px 5px 5px 5px">
                          <tr>
                            <td valign="top">
                              <p><img height="18" src="../../../../assets/screens/Login31.jpg" width="18"></p>
                            </td>
                            <td valign="top">
                              <p>Check box indicates that the information has been entered within the form and sections of the form. If no information is necessary within a section and the form has been completed, all sections will be marked as completed.</p>
                              <p>&nbsp;</p>
                            </td>
                          </tr>
                          <tr>
                            <td valign="top">
                              <p><img height="19" src="../../../../assets/screens/Login32.jpg" width="22"></p>
                            </td>
                            <td valign="top">
                              <p>Indicates that the form has been enabled but not accessed yet.</p>
                              <p>&nbsp;</p>
                            </td>
                          </tr>
                          <tr>
                            <td valign="top">
                              <p><img height="18" src="../../../../assets/screens/Login33.jpg" width="20"></p>
                            </td>
                            <td valign="top">
                              <p>Indicates that this form and sections have been enabled but not completed.</p>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td><img height="142" src="../../../../assets/screens/Login34.jpg" width="167"></td>
                      <td>Other tasks are listed here.</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div id="StatusBar1" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Status Bar</b> </div>
                  <br />
                  <p>Status bar is visible at the bottom of Andica SA800 Partnership Software</p>
                  <p><img src="../../../../assets/screens/Login35.jpg" width="566" height="117"></p>
                </div>
              </div>
            </div>
          </div>
          <div id="RecordsListView1" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Records List View</b> </div>
                  <br />
                  <p style="margin-left: 20">This gives you an overview of the current state of your returns at a glance.</p>
                  <p><img src="../../../../assets/screens/Login38.jpg" width="600" height="61"></p>
                  <p><b>The 'Records List View' shows following basic information:</b></p>
                  <table border="1" cellpadding="5" cellspacing="0" width="80%" style="margin-left:20px;">
                    <tr>
                      <td width="20%" valign="top">Partnership name:</td>
                      <td>Partnership's trading name</td>
                    </tr>
                    <tr>
                      <td>Contact:</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td valign="top">Progress:</td>
                      <td>
                        <p>Indicates an estimated percentage of processes you have completed so far and varies based on forms selected. This will go to 100% once you have reviewed your reports and successfully submitted your returns using 'Live submission'.</p>
                        <p>The percentage may go down if you access the data again or make any amendments to the data.</p>
                        <p>As long as you have completed the forms as you require and done a successful &#39;Live submission&#39;, you can ignore the progress percentage if it does not show 100%.</p>
                        <p>(Progress column not available from version 2011) </p>
                      </td>
                    </tr>
                    <tr>
                      <td>Submitted on:</td>
                      <td>
                        <p>
                          Date when you have attempted your
                          submission.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>Submission:</td>
                      <td>
                        <p>Indicates the status of your last online submission attempt to deliver returns to HMRC.</p>
                        <p><u>Success:</u> Live submission was successfully acknowledged.</p>
                        <p><u>Failed:</u> Live submission failed HMRC validation and was rejected,</p>
                        <p><u>Test Success:</u> 'Test submission' attempt passed validations.</p>
                        <p><u>Test Failed:</u> 'Test submission' attempt failed HMRC validations.</p>
                      </td>
                    </tr>
                  </table>
                  <br />
                  <p><img src="../../../../assets/screens/Login39.jpg" width="345" height="201"></p>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (Progress column not available from version 2011)</p>
                </div>
              </div>
            </div>
          </div>
          <div id="UsingHelp1" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Using Help</b> </div>
                  <br />
                  <p>You can access the On-screen Help using any of the following methods</p>
                  <ol type="a">
                    <li>
                      <p align="justify">Press [F1]. Pressing [F1] while in the SA forms will display context sensitive help where available, for example, pressing [F1] when in Box 9.1, details relating to this box will be displayed, if available.</p>
                    </li>
                    <li>
                      <p align="justify">Click the 'Help' Icon on Horizontal Toolbar</p>
                    </li>
                    <li>
                      <p align="justify">Select the Help <span style="font-family: Wingdings">à</span>&nbsp;Help Topics option from the Menu</p>
                    </li>
                    <li>
                      <p align="justify">Click 'Help' Button if provided on any form. This will open the Help file.</p>
                    </li>
                    <p>HMRC's guides and notes for Partnership tax returns and other useful leaflets can be obtained from HMRC and <a href="http://www.gov.uk" class="link" target="_blank">www.gov.uk</a> websites</p>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div id="details1" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Tax adviser details (Agent)</b> </div>
                  <br />
                  <p>
                    If you are a Tax Adviser or an agent handling clients&#39; tax affairs under Self
                    Assessment and are authorised by your client to submit their Partnership business
                    tax returns, you can use Andica SA800 Software to submit your clients Partnership
                    business tax returns over the internet.
                  </p>
                  <p>
                    Working as an Agent (Tax Adviser), you are likely to be registered with the HMRC
                    to submit tax returns for your clients. Enter your details in the 'Tax adviser details'
                    form before you start creating Partnership records, these details will appear in
                    the relevant fields within the returns and can be amended. If a Partnership record
                    is created before entering agent details, you can enter the details while processing
                    the returns for that Partnership.
                  </p>
                  <p>
                    To record 'Tax Adviser details' within the software select the 'Tax adviser details'
                    option on the 'File' menu
                  </p>
                  <p align="justify">
                    <img src="../../../../assets/screens/Login36.jpg">
                  </p>
                  <p>
                    This will open a 'Tax adviser' form where you can record your personal (agent) and
                    Company details.
                  </p>
                  <p>
                    <img height="405" src="../../../../assets/screens/Login40.png" style="margin: 0px 0px 0px 0px;" width="709">
                  </p>
                  <p>
                    In the 'Agent details' section enter details as appropriate.
                  </p>
                  <table border="1" cellpadding="5" cellspacing="0" style="text-align: justify;" width="80%">
                    <tr>
                      <td valign="top" width="25%">
                        Title<br>
                        Forename<br>
                        Surname
                      </td>
                      <td valign="top" width="75%">
                        Enter Agent's name. * These details are required if submitting returns as an agent.
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        Company name
                      </td>
                      <td valign="top">
                        If trading as a company, enter Agent's company name.
                      </td>
                    </tr>
                    <tr>
                      <td valign="top" align="left">
                        Adviser Address and Postcode
                      </td>
                      <td valign="top">
                        <p>
                          Enter Agent's address and postcode. Postcode should be entered in UK postcode format.
                          Where the Partnership has a non-UK address, leave the postcode field blank and provide
                          the foreign postcode / Zip code as part of the address field.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        Telephone
                      </td>
                      <td valign="top">
                        Enter Agent's telephone number.
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        Agent ID with HMRC
                      </td>
                      <td valign="top">
                        <p>
                          This is the agent's reference number provided by the HMRC when you registered as
                          an agent. It is not a Gateway Login ID.
                        </p>
                      </td>
                    </tr>
                  </table>
                  <br>
                  <p>
                    In the 'Gateway Authentication details' section enter details as appropriate.
                  </p>
                  <table border="1" cellpadding="5" cellspacing="0" style="text-align: justify;" width="80%">
                    <tr>
                      <td valign="top" width="25%">
                        Agent Gateway ID
                      </td>
                      <td valign="top" width="75%">
                        This is the Agent's Gateway ID that is used to log and submit clients returns to
                        the HMRC via Government Gateway
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        Agent Gateway password
                      </td>
                      <td valign="top">
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        Re-enter password
                      </td>
                      <td valign="top">
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        Special validation messages
                      </td>
                      <td valign="top">
                        'Do you want to enable special validation messages?'
                        <p>
                          This is a trial feature which can only be used by authorised agents (Tax advisers)
                          who are submitting returns for and on behalf of their clients. It is subject to
                          acceptance of the trial terms. (Full explanation is provided below)
                        </p>
                        <p>
                          Select this box if you wish to participate in the trial.
                        </p>
                      </td>
                    </tr>
                  </table>
                  <p align="justify">
                    Where this information is already setup prior to creating partnership record, some
                    information within the tax returns forms will be updated automatically, specifically
                    adviser's details in SA800.
                  </p>
                  <p align="justify">
                    Where Tax adviser's details have been entered in SA800, the 'File By Internet' process
                    will also include an option for 'Agent'. Agent's Gateway ID and Gateway Password
                    stored here will appear in the Gateway Login screen of 'File By Internet' process
                    at the time of submission.
                  </p>
                  <p align="justify">
                    As a Tax adviser, when submitting client's returns by internet, use the Gateway
                    User ID and Password allocated to you (as an agent) by the HMRC. This will be different
                    from the Gateway User ID and Password provided to the Nominated Partner.
                  </p>
                  <p align="justify">
                    If you (The Agent) are submitting the tax return using your (Agent's) User ID and
                    Password, you should obtain your clients confirmation of the accuracy of the information
                    submitted. Andica SA800 Partnership software provides a 'Declaration and Client
                    Copy' report that prints a standard declaration form and report that contains tax
                    return details with the IR Mark printed on all the pages. These pages are sequentially
                    numbered and must have the same IR Mark.
                  </p>
                  <p align="justify">
                    IR Mark is a unique identity code generated based on a number of fields on the return
                    and provides another security layer when transmitting information. Before a tax
                    return message can be sent to the HMRC Internet Service the software generates this
                    unique identity when the values in the return are entered and saved. IR Mark is
                    included in the submission to HMRC where it is regenerated and checked against the
                    submission.
                  </p>
                  <br />
                </div>
              </div>
            </div>
          </div>
          <div id="Attachments1" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Attachments</b> </div>
                  <br />
                  <p>
                    HMRC suggest that in the majority of cases, it should not be necessary
                    for you to attach accompanying material to your return. If you want
                    to provide more information in support of your entries, you can enter
                    this in the &#39;Additional Information&#39; box in SA800 or where appropriate,
                    the 'Additional information' text boxes within the relevant supplementary
                    forms.
                  </p>
                  <p>
                    There may be some situations, however, where HMRC require you to
                    send additional supporting documentation with online Self Assessment
                    return.
                  </p>
                  <p>
                    One example of where HMRC would expect additional documentation is
                    in the case of &#39;Capital Gains&#39;, where a claim for gifts hold-over relief
                    is being made. In these circumstances, you would be required to attach
                    a claim for this relief to your Self Assessment return.
                  </p>
                  <p>
                    You can use this feature to 'attach' the PDF documents that you want
                    to submit to support your tax return data.
                  </p>
                  <p>The attachments feature must not be used to submit</p>
                  <ul>
                    <li>A further Self Assessment Tax Return </li>
                    <li>An amendment to any Return </li>
                    <li>
                      PDF (Portable Document Format) file versions of supplementary
                      forms or pages, whether copied from original HMRC forms or otherwise.
                      This is because the attachment service is intended only for accompanying
                      documents and will not process the data in this kind of attachment.
                    </li>
                  </ul>
                  <p>
                    Please note: Only attachments in Portable Document Format (PDF) are
                    supported. Documents that are scanned on some scanners and saved as
                    PDF files are not recognised as standard PDF formats and these will
                    be rejected by HMRC during the submission.
                  </p>
                  <p>What is PDF (Portable Document Format)?</p>
                  <p align="justify">
                    PDF is a widely used standard for creating electronic
                    documents, it allows electronic documents to be viewed and printed using
                    a personal computer that has a PDF Reader software installed. There
                    are various PDF applications available. An Internet Search for &#39;PDF&#39;
                    should provide links to the various products available, some of these
                    are free, but it is the responsibility of the customer to decide which
                    one is most suitable for them.
                  </p>
                  <p>
                    <strong>Important:</strong> If you intend to send any PDF files as attachments, we
                    strongly recommend that you first complete the data entry process and
                    attach the files <strong>just before</strong> you run the 'File By Internet' process
                    for online submission. Attaching large PDF files will significantly
                    increase your data file size and slow down the software.
                  </p>
                  <p>Select the 'Attachments' function to attach PDF document,</p>
                  <p><img src="../../../../assets/screens/attachements.jpg" style="margin: 0px 0px 0px 0px;"></p>
                  <p>
                    To attach a PDF document, select the 'Yes' option for 'Do you want
                    to attach any PDF documents to support your tax return?'. This will
                    activate the 'Attach' button.
                  </p>
                  <p>Click 'Attach' and in the Document screen, enter the File Details:</p>
                  <table border="1" cellpadding="3" cellspacing="0" width="80%">
                    <tr>
                      <td align="left" valign="top" width="25%">
                        <p>Description:</p>
                      </td>
                      <td width="75%">
                        <p>
                          Enter brief description of the document you attaching. Description
                          can be used to describe the content of the .PDF document e.g.
                          'Accounts'
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="top">
                        <p>Select File:</p>
                      </td>
                      <td>
                        <p>
                          Click the 'Browse' button and select the PDF document you
                          want to attach.
                        </p>
                        <p class="MsoNormal">
                          <span lang="EN-GB">
                            Attachments must be within the accessible folders of the
                            computer&#39;s hard disk drive, they should not be &#39;buried&#39; deep in long layers of
                            folders and sub-folders.
                            <!--<o:p></o:p>-->
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="top">&nbsp;</td>
                      <td>
                        <p>Click 'OK' to attach.</p>
                      </td>
                    </tr>
                  </table>
                  <p>
                    To remove an attached document, select the item that you want to
                    remove and click the 'Delete' button.
                  </p>
                  <img height="180" src="../../../../assets/screens/attachements1.jpg" width="505">
                  <p>
                    HMRC have imposed few restrictions for submitting attachments. Your
                    submission will be rejected by HMRC if any of these fail:
                  </p>
                  <ol>
                    <li>
                      Ensure Description and File name do not contain unacceptable
                      characters – refer to '<i>CESA Characters</i>' in the user guide.
                    </li>
                    <li>
                      The system allows for multiple attachments but the total file
                      size of all the attachments must not exceed 5 MB
                    </li>
                    <li>
                      Document should be in a valid PDF format created using PDF writer
                      software.
                    </li>
                    <li>Do not attached scanned images saved as PDF.</li>
                    <li>Attached files must be free of computer viruses.</li>
                  </ol>
                  <p>
                    For further guidance on PDF formats acceptability by HMRC or whether
                    an attachment is appropriate can be obtained by calling HMRC tax office
                    or the Self Assessment Helpline. Brief information is also published
                    on
                    <a class="link" href="https://www.gov.uk/hmrc-internal-manuals/self-assessment-manual/sam126040" target="_blank">
                      https://www.gov.uk/hmrc-internal-manuals/self-assessment-manual/sam126040
                    </a>
                  </p>
                  <p class="MsoNormal">
                    <span lang="EN-GB">
                      Documents attached within the software cannot be printed
                      using any print function within the software.
                      <!--<o:p></o:p>-->
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--Using Data Entry Forms starts here....................-->
          <div id="Forms1" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Using Data Entry Forms</b> </div>
                  <br />
                  <p align="justify">The data entry forms replicates questions and data entry boxes in HMRC Partnership forms with some variations with automated calculations and the ability to enter detailed information and schedules where necessary. Some questions and data fields may be hidden or disabled based on your response to the entries in the earlier data entry fields.</p>
                  <p><b>Entering data</b></p>
                  <p align="justify">Once you have logged into the software, data entry screens will NOT be active until you created and opened a Partnership record. Open a Partnership record through 'File' <span class="arrow">à</span> 'Open' or click on the 'Open' icon on the top toolbar. Once the record is open you can start entering the data.</p>
                  <p align="justify">Partnership name will be visible on the software title bar at the top and the status bar at the bottom.</p>
                  <p align="justify">Software title bar showing Partnership's name:</p>
                  <p><img height="49" src="../../../../assets/screens/UsingDataEntryForms1.jpg" width="301"></p>
                  <p>Software status bar showing Partnership's name:<br><img height="25" src="../../../../assets/screens/UsingDataEntryForms2.jpg" width="183"></p>
                  <p>If the data entry screen is closed, you can access the forms through one of the following methods:</p>
                  <p>Select SA800 icon in the 'Forms' vertical tool 'Task' list, <img height="22" src="../../../../assets/screens/UsingDataEntryForms3.jpg" width="146" style="margin:0px;"> or</p>
                  <p>From the menu select 'View'<span class="arrow">à</span> 'Tax Forms' <span class="arrow">à</span> 'SA800'.</p>
                  <p>
                    <img height="185" src="../../../../assets/screens/UsingDataEntryForms4.jpg" width="415">
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="Forms2" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b> Navigating</b> </div>
                  <br />
                  <p>You can navigate within the Forms using one of the following methods:</p>
                  <p><b>Using buttons at the bottom</b></p>
                  <p><img height="34" src="../../../../assets/screens/UsingDataEntryForms5.jpg" width="453"></p>
                  <ol type="a">
                    <li>
                      <p align="justify">
                        '<b>Help</b>' – Opens software
                        help files that also provide HMRC's style Tax Returns Guide and Notes.
                        Information provided within the software is for reference only, it is
                        not meant to provide advice on how to complete your tax returns. Latest
                        HMRC guides and notes can be downloaded from HMRC and
                        <a class="link" href="http://www.gov.uk" target="_blank">
                          www.gov.uk
                        </a> websites
                      </p>
                    </li>
                    <li>
                      <p align="justify">
                        '<b>Back</b>' – Go back to
                        previous screen, this will not save any information you have already
                        entered in the fields on the current screen.
                      </p>
                    </li>
                    <li>
                      <p align="justify">
                        '<b>Next</b>' – All information
                        entered in the fields on the current screen will be saved and move forward
                        to the next set of questions.
                      </p>
                    </li>
                    <li>
                      <p align="justify">
                        '<b>Close</b>' – Closes the
                        data entry screens, all information entered till the previous screen
                        will be saved
                      </p>
                    </li>
                    <p>
                      On some forms you may see additional
                      buttons:
                    </p>
                    <li>
                      <p align="justify">
                        '<b>Go to/Delete</b>' – Where
                        you have completed more than one supplementary pages for similar income
                        groups (for example income from two employments), click the button to
                        navigate between separate records or delete a record.
                      </p>
                    </li>
                    <li>
                      <p align="justify">
                        '<b>Add New</b>' – Click this
                        button to complete a separate copy of the supplementary form where you
                        have more than one source of similar income that requires separate supplementary
                        forms to be completed.
                      </p>
                    </li>
                    <li>
                      <p align="justify"><strong>'Finish'</strong> – Click this button to complete the section or Form. If you do not 'Finish', then the status of this section or form will be incomplete hence when you try to run a process that requires all forms to be completed, e.g. printing reports or online submission, a warning “…form(s) are incomplete, remove the form if not required” or other similar 'forms incomplete' messages will be displayed.</p>
                    </li>
                  </ol>
                  <p>
                    <u>
                      <b>
                        Using the Status Tree View
                      </b>
                    </u>
                  </p>
                  <p align="justify">
                    <img height="348" src="../../../../assets/screens/UsingDataEntryForms6.jpg" width="180">
                  </p>
                  <p align="justify">Click the 'Status' strip at the bottom of the vertical toolbar to display a Tree View of the Partnership Forms and section groups and sub-groups. Clicking on any group or sub group will reveal the relevant Self Assessment form questions and data entry fields.</p>
                  <p>Where an option to enter Multiple Income forms is available, numbers shown in bracket represents the number of supplementary forms you have created for that type of income.</p>
                  <p>
                    <u>
                      <b>
                        Using the 'Back' and 'Forward'
                        buttons on top toolbar
                      </b>
                    </u>
                  </p>
                  <p align="justify">
                    <img height="38" src="../../../../assets/screens/UsingDataEntryForms7.jpg" width="84">
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="Forms3" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Entering Partnership Information</b> </div>
                  <br />
                  <p align="justify">
                    Partnership Information entered when creating a Partnership
                    record is displayed here. To amend the information select 'Tools'
                    <span class="arrow">à</span> <span>'Security' </span>
                    <span class="arrow">à</span><span>
                      'Partnership Information' from the
                      menu or select 'Tools'
                    </span><span class="arrow">à</span><span>
                      'Partnership
                      Information' on the vertical toolbar.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="Forms4" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b> Entering HMRC references</b> </div>
                  <br />
                  <p>
                    Enter information as found on:<br>Main Tax Returns form SA800 Page
                    1 or <br>Notice to complete a tax return
                  </p>
                  <p>			<img height="275" src="../../../../assets/screens/UsingDataEntryForms7new.png" width="438"></p>
                  <table border="1" cellpadding="8" cellspacing="0" width="90%">
                    <tr>
                      <td valign="top" width="25%">
                        <p>Partnership taxpayer ref.</p>
                      </td>
                      <td>
                        <p>
                          Enter Unique taxpayer reference (UTR) for the Partnership as shown on the Tax Return form SA800 or Notice to complete a tax return issued by the HMRC. This is a 10 digit number. If you do not have the
                          UTR, please contact the HMRC office.
                        </p>
                        <p>
                          <b>
                            Your online submission will fail if you do not enter a
                            valid number.
                          </b>
                        </p>
                        <p>
                          Details below are optional and are not used for online submission
                          of returns.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top" width="25%">
                        <p>Date of Issue</p>
                      </td>
                      <td>
                        <p>Date as printed on Page 1 of SA800 form or on SA316.</p>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top" width="25%">
                        <p>Officer in Charge</p>
                      </td>
                      <td>
                        <p>
                          Field can be left blank. Title 'Area Director' is automatically
                          printed on the SA800 Substitute Return form.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top" width="25%">
                        <p>HMRC office address</p>
                      </td>
                      <td>
                        <p>
                          Enter the HMRC office address shown on the Page 1 of SA800,
                          SA316 and SA300. DO NOT enter the words 'Area Director'
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top" width="25%">
                        <p>Tax office telephone</p>
                      </td>
                      <td>
                        <p>HMRC office's telephone number.</p>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top" width="25%">
                        <p>For</p>
                      </td>
                      <td>
                        <p>
                          Enter this information if it is displayed on SA800 form.
                          If no information is available leave blank.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top" width="25%">
                        <p>Reference</p>
                      </td>
                      <td>
                        <p>
                          Enter this information if it is displayed on SA800 form.
                          If no information is available leave blank.
                        </p>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div id="Forms5" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Supplementary pages</b> </div>
                  <br />
                  <p align="justify">
                    Select supplementary pages for other types of income
                    and gains that apply to you. For example, if you have income from letting
                    a UK property, select SA801 – UK Properties form.
                  </p>
                  <p align="justify">
                    The supplementary forms selection options are deliberately
                    organised based on the type of information required, therefore the supplementary
                    forms may not be in numeric order.
                  </p>
                  <p align="justify">Following supplementary forms are supported:</p>
                  <ul>
                    <li><a href="SA800.htm">SA800</a> – Partnership Tax Return pages</li>
                    <li><a href="SA800PS.htm">SA800(PS)</a> – Partnership Statement Full and Partnership Statement Short</li>
                    <li><a href="SA800TP.htm">SA800(TP)</a> – Partnership Trading and Professional Income</li>
                    <li><a href="SA801.htm">SA801</a> – Partnership UK Property</li>
                    <li><a href="SA802.htm">SA802</a> – Partnership Foreign </li>
                    <li><a href="SA803.htm">SA803</a> – Partnership Disposal of Chargeable Assets</li>
                    <li><a href="SA804.htm">SA804</a> – Partnership Savings, Investments and Other Income</li>
                  </ul>
                  <p align="justify">
                    Once you have selected the supplementary pages you
                    require and clicked 'Back' or 'Next' buttons, icons for the selected
                    supplementary page will be displayed on the vertical toolbar and the
                    Status tree-view will be updated.
                  </p>
                  <p align="justify">
                    Information processed will be lost on deselecting
                    the supplementary pages and clicking 'Back' or 'Next' buttons.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="Forms6" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Partnership Statement SA800(PS)</b> </div>
                  <br />
                  <p>Where the partnership business has generated income or losses, then this must first be declared by selecting at least one of Q1 to Q7 of SA800, as applicable, and completing that form to indicate where the income or losses has come from. Only then you can distribute the income or losses to individual partners.</p>
                  <p>When completing a nil return, select one of the Q1 to Q4 or Q7 as appropriate for your partnership - where the partnership intended to earn income from - and complete any mandatory fields in that supplementary form.</p>
                  <p align="justify">Partnership Statement should be one of the last supplementary forms to be completed. Most of the Partnership Statement values will be automatically populated based on data you have entered in SA800 and other supplementary pages (except the SA800PS).</p>
                  <p>Once you have entered your income, expenditure and other details in SA800 and other supplementary pages (except the SA800PS), create 'Individual partner details' within 'Partnership Statement'.</p>
                  <p>Partners share of income, losses and other values can be allocated within 'Individual partner details' screen using one of the following methods:</p>
                  <p><u>Partner's share %:</u>Where the income, losses and other values are shared as a percentage of a total value, enter a percentage of Partner's share and the software automatically allocates the amounts in the relevant boxes. Total %'age of all the partners should add up to 100%.</p>
                  <p><u>Amounts: </u>If the values of each item are not shared as a percentage of the total value, leave the Partner's share % field blank and enter values for each relevant field. The software will provide a message box “The default value has been changed, do you want continue with the new value?”, select 'Yes' to continue.</p>
                  <p>Sum of all occurrences of values reported for each box in the Individual Partner Details should add up to the total amount reported in the corresponding box within Partnership Statement. HMRC server will reject the submission if the values don't add up to within £1.</p>
                  <p>If the Partnership Statement does not display any values, you may be accessing the Partnership Statement section before entering income and expenses data in supplementary forms first. </p>
                  <p>You must select one of Q1 to Q7 of SA800 that applies to your business and complete that supplementary form first. After opening the Partnership record (File - Open), click the SA800 icon on the left toolbar. When the SA800 'Partnership Information' screen opens, click the 'Next' button at the bottom of the page and navigate to Q1 to Q7 screen.</p>
                  <p>For example, if your partnership had any trade or professional activity you may want to tick box Q3 of SA800, this will activate Trading and Professional Income boxes 3.1 to 3.117, once you have entered the values in these boxes, if business had made a profit the software will automatically update Box 11 (or box 12 if there is a loss).</p>
                  <p>If you have manually entered amounts in Individual Partner details screen and now want to revert to a Partner's share % method, click Edit to open each Partner's details. Enter the Partner's share % and click the 'Recalculate' button. Click 'Next' to the end and 'Finish'. Once you have checked all Partner's details, on the main Partnership Statement screen, click 'Next' to the end and 'Finish'.</p>
                  <p>HMRC's online server validations require values from supplementary forms to match values in Partnership Statement and values apportioned to individual partners must add up and cross match values across the return. For example:</p>
                  <p>Value of net profit in Box 3.83 of 'Trading and Professional' must be equal to value of Box 11 in Partnership Statement which must equal to total of Box 11 for each partner. Hence, if:</p>
                  <p style="margin-left:100px;">
                    Box 3.83 – Net Profit for accounting period is say £ 5263.75, then<br>
                    Box 11 – Partnership Statement Profit from trade or profession should be £ 5263.75, and<br>
                    Share of each individual partner reported in Box 11 of individual partner's statement
                  <p style="margin-left:150px;">
                    1<sup>st</sup> partner's share of profit is say 45% or £ 2368.69<br>
                    2<sup>nd</sup> partner's share of profit is say 30% or £ 1579.13<br>
                    3<sup>rd</sup> partner's share of profit is say 25% or £ 1315.94<br>
                    Total of Box 11 for all individual partners is £ 5263.76 <br>
                    (A difference of up to £1 is allowed)
                  </p>
                  <p>
                    Initial selection of Partnership Statements Short or Full is automated based on type and values of income. In line with HMRC guidance, a 'short' abridged version for
                    partnership statement only caters for (Q3) trading or professional income, or (Q7) interest or alternative finance receipts with tax deducted, from banks,
                    building societies or other deposit takers. A 'full' unabridged version has to be used for all the possible types of partnership income.
                  </p>
                  <p><strong>Resetting forms and clearing data</strong></p>
                  <p>Tax return forms for a partnership can be reset and all data cleared to enable you to start the data entry process afresh. This can only be done for each individual record. Open a partnership record from which you want to reset or remove entries and use one of the following methods:</p>
                  <ol style="list-style-type:lower-alpha;">
                    <li>
                      Clear Forms:<br>
                      Select 'Tools' <span class="arrow">à</span> 'Clear forms' from vertical toolbar.<br>
                      This will clear all tax return entries and remove all supplementary forms
                    </li>
                    <li>
                      Reset all forms: <br>
                      Select 'Activity' <span class="arrow">à</span> 'Reset all forms' from menu bar.<br>
                      Similar to 'Clear Forms', this will clear all tax return entries and remove all supplementary forms
                    </li>
                    <li>
                      Remove individual supplementary form: <br>
                      To deselect the supplementary form that you do not require, click the SA800 icon on the left toolbar. When the SA800 'Partnership Information' screen opens, click the 'Next' button at the bottom of the page and navigate to Q1 to Q7 screen. Deselect the supplementary form that you do not require and click 'Next/Finish'. This will remove that form and all data within it.
                    </li>
                    <li>
                      Clear Fields:<br />
                      Select 'Activity' <span class="arrow">à</span> 'Clear Fields' from menu bar. <br>
                      Use this function to remove and reset entries from all fields within the tax return screen that is currently open.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <!--File By Internet starts here...................-->
          <div id="File1" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b> File By Internet</b> </div>
                  <br />
                  <p><b>Online filling of Tax Return</b></p>
                  <p>
                    The software includes an online filing feature to securely submit
                    tax returns to the HMRC over the internet. Some of the benefits of online
                    submissions are:
                  </p>
                  <ol style="list-style-type:lower-alpha;">
                    <li>
                      The process is secure and information is encrypted using 128-bit
                      encryption and data is transferred directly from your PC to the HMRC systems.
                    </li>
                    <li>
                      Immediate acknowledgement of successful submission is sent by
                      HMRC.
                    </li>
                    <li>Faster repayment if a tax refund is due.</li>
                  </ol>
                  <p>
                    Prior registration with the HMRC is required before returns can be
                    submitted online.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="File2" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b> File By Internet</b> </div>
                  <br />
                  <p><b>Register for online filing</b></p>
                  <p>
                    To register for online filing, visit
                    <a class="link" href="https://www.gov.uk/set-up-business-partnership" target="_blank">https://www.gov.uk/set-up-business-partnership</a>
                    <br>You will not be able to use the online services immediately as the HMRC
                    may send you some activation details by post, therefore allow sufficient
                    time before the final submission deadlines.
                  </p>
                  <p>
                    You will require your Partnership Unique
                    Taxpayer Reference (UTR) number and your postcode.
                  </p>
                  <p>
                    <span lang="EN-GB" style="font-size:10.0pt;font-family:
                     &quot;Arial&quot;,sans-serif;mso-fareast-font-family:&quot;Times New Roman&quot;;mso-ansi-language:
                     EN-GB;mso-fareast-language:EN-GB;mso-bidi-language:AR-SA">
                      UTR can be found on the Tax Return
                      form, a Notice to complete a Tax Return or on your Statement of Account. It may
                      also be on other documents. Alternatively, your local tax office will be able to
                      tell you your UTR, though they will need to ask you a few questions for security
                      purposes
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="File3" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b> File By Internet</b> </div>
                  <br />
                  <p><b>Using File By Internet</b></p>
                  <p>
                    Once you have completed all the entries for
                    your tax return and are satisfied that the information provided is accurate
                    as required by the HMRC, you can now submit the returns online. You are
                    responsible for accuracy of all information provided and submitted.
                  </p>
                  <p>When returns are submitted through the software, data is transmitted directly from customer's computer to HMRC server. Customer tax returns data does not pass our servers so we will not have access to customer's returns.</p>
                  <p>To start the submission process, either:</p>
                  <ol>
                    <li>
                      Select 'Internet' 			<span class="arrow">à</span>
                      'File By Internet' on the menu<br>
                      <img border="0" height="102" src="../../../../assets/screens//FileByInternet01.jpg" width="204">
                    </li>
                    <li>Press [Ctrl + U] keys on the keyboard</li>
                    <li>
                      Click the File by Internet icon on the
                      toolbar
                      <img border="0" height="25" src="../../../../assets/screens/FileByInternet02.jpg" width="26" style="margin: 0px 0px 0px 5px;">
                    </li>
                  </ol>
                  <p>
                    Andica software provides a warning if it
                    encounters unacceptable CESA characters. Further details are described in
                    the CESA Characters chapter of the user guide and help files.
                  </p>
                  <p>
                    If any sections of your tax returns are not
                    completed, the software will give you a message box like this:
                  </p>
                  <p>
                    <img border="0" src="../../../../assets/screens/FileByInternet03.jpg" width="452" height="126">
                  </p>
                  <p>This usually happens when:</p>
                  <ol style="list-style-type:lower-alpha;">
                    <li>
                      You have either activated a supplementary
                      form and not entered any data
                    </li>
                    <li>
                      Made amendments to your data and have
                      left the status of 'form incomplete'
                    </li>
                    <li>Any other reason</li>
                  </ol>
                  <p>
                    You can either deselect the forms listed
                    if it is not required or access SA800 and click 'Next' all the way through
                    to the end. When you click 'Finish' if appropriate, select 'form completed'
                    option when you get a screen similar to the one below
                  </p>
                  <p>
                    <img border="0" src="../../../../assets/screens/FileByInternet04.jpg" width="115" height="66">
                  </p>
                  <p>
                    &nbsp;
                  </p>
                  <p><b>Gateway Login and Submission type</b></p>
                  <p>
                    In the File By Internet window enter the
                    Gateway login details:
                  </p>
                  <p>
                    <img border="0" src="../../../../assets/screens/FileByInternet05.jpg" width="353" height="318">
                  </p>
                  <table border="1" cellpadding="5" cellspacing="0" width="90%">
                    <tr>
                      <td align="left" valign="top" width="30%">
                        <p>UTR (from SA800)</p>
                      </td>
                      <td width="70%">UTR entered in SA800 form will be displayed here</td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="30%">
                        <p>Gateway User ID</p>
                      </td>
                      <td width="70%">Enter your HMRC Government Gateway User ID (without spaces)</td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="30%">
                        Gateway Password
                      </td>
                      <td width="70%">Enter your HMRC Government Gateway password</td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="30%">
                        Are you submitting as an Submission
                        type
                      </td>
                      <td width="70%">
                        Select from dropdown,
                        Field defaults to 'Partnership'. If you have entered Tax adviser's
                        details, then the field will display an option for 'Agent'
                        <p>
                          '<b>Live Submission</b>' – Select this
                          option to submit your returns to the HMRC. You must run the 'Live
                          submission' for your returns to be filed in the HMRC system.
                        </p>
                        <p>
                          '<b>Test Submission</b>' – Select
                          this option to do a test submission of the returns to the HMRC systems.
                          Returns are not submitted, this process checks for any data validation
                          within the submission files (but not the accuracy of the data) to
                          see whether or not the HMRC systems can process the file successfully.
                        </p>
                        <p><b>You must run the 'Live submission' for your returns to be filed in the HMRC system.</b></p>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="30%">
                        Tick if this is an amended return
                      </td>
                      <td width="70%">If you have already successfully submitted your returns earlier using 'Live submission' and are now submitting an amendment to the original submission, select this box.</td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="30%">
                        Save login credential
                      </td>
                      <td width="70%">Tick box to save your user name and password.</td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="30%">&nbsp;</td>
                      <td width="70%">
                        Click 'Submit' button
                        to submit your returns.<br>Click 'Cancel' button to close the submission
                        File By Internet window.
                      </td>
                    </tr>
                  </table>
                  <p>On clicking 'Submit' software will start communicating with the HMRC servers.</p>
                  <p>A ‘File By Internet’ submission status window will appear showing Upload Status, Response received from the HMRC system and Correlation ID. You would normally have a total of four processes starting with 'submit request' and ending with a final response of ‘delete response’ if the submission goes successfully on first attempt.</p>
                  <p>
                    <img border="0" src="../../../../assets/screens/FileByInternet06.jpg" width="680" height="278">
                  </p>
                  <p><b>Constant 'Upload Status' of 'submit_poll</b></p>
                  <p>
                    If you are continuously getting ‘submit polling’ messages during the submission process, the HMRC system may be busy or in maintenance. You can cancel the process and try again later.
                  </p>
                  <p>If you are continuously getting ‘submit polling’ messages during the submission process, the HMRC system may not be working as expected and it may be busy or in maintenance. HMRC service is simply not responding to the software to confirm your submission. As can be seen from the Upload Status process the software is trying to get the response from HMRC server and the HMRC service is acknowledging the request but not responding with confirmation of receipt or rejection of your submission. This is an issue with HMRC service and cannot be controlled by Andica Software. You can cancel the process and try again later. The software will continue to Poll HMRC server till a confirmation or an error message is received.</p>
                  <p>The 'submit poll' process will stop after 50 attempts. </p>
                  <img border="0" src="../../../../assets/screens/FileByInternet06_1.jpg" width="680" height="278">
                  <p>
                    A submission that had successfully started but had been interrupted
                    part way through the process, for example, in the event the internet
                    gets disconnected, etc., can be restarted and completed from the point
                    where it failed. The process to restart submission is the same as the
                    one described above, only the remaining status and response will be
                    displayed ending with the 'delete response'.
                  </p>
                  <p>When you attempt the submission process again, one of the following outcomes is possible:</p>
                  <ol style="list-style-type:lower-alpha;">
                    <li>The submission process will continue from the last stage and finish without any errors message returned by HMRC server.</li>
                    <li>If HMRC have cleared the polling history from their server for the Correlation ID, they will return a message relating to an invalid Correlation ID. You can thereafter attempt submission again.</li>
                    <li>If the original submission was initiated and recorded in HMRC systems, the server will respond with a message 'Error 5015 Known Facts Check', which suggests that the original submission has been recorded in HMRC system. Software will then display submission error message for this latest attempt. If you want to you can submit an amended return as specified in '<a href="#Submitting Amended Returns"><u><font color="#0000FF">Submitting Amended Returns</font></u></a>'.</li>
                  </ol>
                  <p><b>Successful Submission</b></p>
                  <p>
                    Once the 'File By Internet' routine has successfully completed the
                    process and HMRC servers have acknowledged receipt of 'Test Submission'
                    or 'Live Submission', the software will display a receipt. A 'Test Submission'
                    is not recorded by HMRC as a valid submission. You must run the 'Live
                    submission' for your returns to be filed in the HMRC system.
                  </p>
                  <img border="0" src="../../../../assets/screens/FileByInternet07.jpg">
                  <p>
                    Once successfully submitted using 'Live Submission', you should not
                    resubmit the returns unless you want to submit an amended return using
                    the 'Tick if this is an amended return' option. Any attempt to resubmit
                    the returns without selecting the amended return option will result
                    in HMRC server rejecting the resubmission.
                  </p>
                  <p>
                    Print your file copy and FBI receipt as described in the Printing
                    Reports chapter of the user guide.
                  </p>
                  <p>Once returns have been successfully submitted using 'Live submission' option in the 'File By Internet' process, HMRC receive and record return immediately. If HMRC have an email address on their records, they may send an automated email to confirm receipt. HMRC will update their web site with submission status in approximately 48 hours.</p>
                  <p><b>Submission count</b></p>
                  <p>The software will count the number of successful live submissions sent through the software and you can only submit returns up to the maximum number of returns your licence permits. Additional licenses can be purchased in blocks of 5 or higher.</p>
                  <p><b>Submitting Amended Returns</b></p>
                  <p>Andica SA software includes feature to submit amendment to the returns already submitted to HMRC, subject to HMRC's limitations and deadlines. You will have to submit a full set of returns including the amendments you want to make.</p>
                  <p>HMRC impose time limits for submitting amended returns to correct mistakes on tax returns. Tax returns can be amended within 12 months of the normal 31 January filing deadline.</p>
                  <p>When you have successfully submitted your returns using 'Live submission' and HMRC have recorded the original submission in their system and thereafter you want to submit an amendment to the earlier returns, you can resubmit by selecting the 'Tick if this is an amended return' option.</p>
                  <p>Amended return should only be submitted if you have previously successfully submitted your original returns using 'Live submission' and HMRC have accepted and recorded the original submission in their system. You will need to check this with the HMRC.</p>
                  <p>Amend your data as you want in the same way as you entered the data in the first place, through the SA800 and supplementary forms, clicking 'Next' and 'Finish' as you go along. In the Partnership Statement, 'Edit' Partners details and click 'Recalculate' if necessary, click 'Next' and 'Finish' as you go along.</p>
                  <p>
                    When you are ready to submit this amended data, access the 'File
                    by Internet' process and confirm your actions on the message box that
                    appears.
                  </p>
                  <p>A message box warning of the earlier submission will be displayed.</p>
                  <img border="0" src="../../../../assets/screens/FileByInternet08.jpg" width="443" height="133">
                  <p>
                    Click 'OK' to display FBI receipt and proceed to 'Clear' the submission
                    history or 'Cancel' to close the message box.
                  </p>
                  <p>
                    Clear the submission history on the 'File by internet details' screen.
                    For details on clearing the submission history, please refer to 'Clearing
                    submission history' section.
                  </p>
                  <p>
                    Follow the screen prompt, select the 'Tick if this is an amended
                    return' option on the 'File By Internet' Login screen. Click 'Submit'
                    to start the submission process as detailed in the <a class="link" href="#1p0"> 'Using File By Internet'</a>
                    section.
                  </p>
                  <p>
                    HMRC impose a time limit for submission of amended returns, hence
                    the function is available subject to HMRC window for amended returns.
                  </p>
                  <p>HMRC have limitations on the number of amended returns you can submit online. Currently up to 9 amended returns can be submitted for a specific UTR for a tax year of return. Where this limitation is exceeded, HMRC will not accept any further attempts to file online amended returns.</p>
                  <p><strong>Summary of process to submit amended returns:</strong></p>
                  <ol>
                    <li>	Start the software and open the tax return which you want amend.</li>
                    <li>	Amend entries as you require retaining other part of returns as they should be. A full set of returns must be resubmitted. Navigate by clicking 'Next' and 'Finish' on all sections and review your returns and all reports.</li>
                    <li>	Start the 'File By Internet' routine and select the 'Tick if this is an amended return' option.</li>
                    <li>
                      Steps 4.a and 4.b. are only applicable if you had used this software for submission of the 'Original' return and the submission was successfully accepted by HMRC,
                      <ol style="list-style-type:lower-alpha;">
                        <li>You will get a message "Your tax return may have already been submitted using the Live submission...” Click OK.</li>
                        <li>	A 'File by internet details' screen will be displayed with submission receipt of the last submission. Click 'Clear' to clear the submission history of the previous submission.</li>
                      </ol>
                    </li>
                    <li>	Proceed to submit amended returns.</li>
                  </ol>
                  <img border="0" src="../../../../assets/screens/FBI_Receipt01.jpg">
                  <p><b>File By Internet Receipt</b></p>
                  <p>
                    File By Internet Receipt provides useful information relating to
                    your submission. Print a copy of File By Internet Receipt using the
                    'Print' button on the 'File By Internet' screen or as described in the
                    Printing Reports chapter of the user guide. The receipt can also be
                    accessed through reports as described in '<i>Printing FBI receipt</i>' section.
                  </p>
                  <p><b>Failed submission</b></p>
                  <p>
                    If the 'File By Internet' routine was not successful in submitting
                    your returns due to any failures reported by the HMRC servers, a failure
                    message will be displayed with FBI Status of 'Submission was not successful'.
                  </p>
                  <p>
                    Error message provided by the HMRC indicate the location and cause
                    for the data validation failure. You will need to correct your entries
                    and resubmit the returns again.
                  </p>
                  <img border="0" src="../../../../assets/screens/FileByInternet09.jpg">
                  <p>
                    Click 'Show errors' button to view details of errors reported by
                    HMRC system. You can print or save details of the error(s), correct
                    your tax return data and attempt submission again.
                  </p>
                  <img alt="" src="../../../../assets/screens/FileByInternet10.jpg" height="525" width="684">
                  <p>
                    Details of errors that HMRC systems have identified on your tax returns
                    are listed in the 'Show errors' screen. Important details displayed
                    include:
                  </p>
                  <table border="1" cellpadding="5" cellspacing="0" style="text-align: justify;" width="80%">
                    <tr>
                      <td valign="top" width="25%">Error Number</td>
                      <td valign="top" width="75%">
                        This is a HMRC error code. HMRC
                        Error Numbers are specific to a particular validation in their
                        servers and is only raised when that validation fails. However,
                        HMRC servers do sometimes raise a few general errors.
                      </td>
                    </tr>
                    <tr>
                      <td valign="top" width="25%">Error Location</td>
                      <td valign="top" width="75%">
                        This information specifies the
                        location where the validation failure has occurred.
                      </td>
                    </tr>
                    <tr>
                      <td valign="top" width="25%">Error Text</td>
                      <td valign="top" width="75%">
                        Description of the error or additional
                        information which should be of assistance in correcting the
                        problem.
                      </td>
                    </tr>
                  </table>
                  <p>
                    You may need to contact HMRC Online Services helpdesk on Telephone
                    0300 200 3600  for assistance with these errors.
                  </p>
                  <p><b>Gateway Authentication failure </b></p>
                  <p>
                    When submitting returns online, Government Gateway will authenticate
                    your Gateway login credentials before the submission continues, Andica
                    Software cannot validate the accuracy of credentials you have entered.
                  </p>
                  <p>
                    Gateway authentication failure message is relayed back from the HMRC
                    (Government Gateway) server when one of the login checks fails. Andica
                    Software will read the Authentication Failure message relayed from Government
                    Gateway servers and display a message box. Please ensure that all of
                    the following details have been entered correctly.
                  </p>
                  <ol>
                    <li>Partnership Business Tax Ref</li>
                    <li>Gateway ID (for the business) provided by the HMRC or Government Gateway</li>
                    <li>Gateway password ((for the business)</li>
                    <li>Ensure that you have enrolled for HMRC's Self Assessment (SA) for Partnership Service and have activated the service on HMRC website.</li>
                  </ol>
                  <p>
                    Please check that you have entered these details in the software
                    correctly and attempt the submission again.
                  </p>
                  <p>
                    If the problem persists, please contact the HMRC Online Services
                    helpdesk on Tel 0300 200 3600.
                  </p>
                  <p><b>Configuring Firewall and Anti-virus software</b></p>
                  <p>
                    Andica SA800 Partnership software provides File By Internet (FBI) feature
                    that requires access to send and receive data from Government Gateway using
                    your Internet access. The software may occasionally include reports or functions
                    that run scripts. Your computer, either stand-alone or connected to a Local
                    Area Network (LAN) is very likely to be protected by Firewall, Anti-virus
                    software and other security devices and/or software.
                  </p>
                  <p>In most cases you will not need to make changes to your system settings as the software will attempt to pass through system settings and attempt to complete a task. Occasionally, your system security may deny the software permission to complete those tasks. You or your system administrator may need to configure your system security to allow the software to run these functions.</p>
                  <p><b>Configuring Firewall</b></p>
                  <p>When activating the software and using File By Internet (FBI) feature to submit the tax returns online or using other functions and links that require internet access from within Andica Software™, the software has to communicate online with servers including Government Gateway (HMRC) servers through your Windows internet access, passing through your system security. In rare instances firewall on your system and routers and some system settings may block the software from communicating and establishing connection with the servers. In this case, you will need to configure your firewall to allow the software to send and receive data over the Internet.</p>
                  <p>The function of computer to computer communication is different from using a web browser on your computer to access websites. While you may be able to access HMRC's website using your web browser, the software can still be denied access to HMRC servers by your computer settings.</p>
                  <p>When the software is blocked from sending and receiving data, or access is denied by a firewall, you may see error similar to the ones displayed below:</p>
                  <img border="0" height="119" src="../../../../assets/screens/FileByInternet11.jpg" width="390"><br>
                  <br>
                  <img border="0" height="146" src="../../../../assets/screens/FileByInternet12.jpg" width="408">
                  <p>You may also get other messages such as: </p>
                  <p>"Activation/File by Internet process cannot access the internet. Either the Firewall is blocking access or the internet is not connected. Please check your settings and repeat this process."</p>
                  <ul style="list-style:none;">
                    <li>&quot;The download of the specified resource has failed.”</li>
                    <li>“cannot allocate socket&quot; or </li>
                    <li>&quot;connection timeout&quot; or </li>
                    <li>&quot;Dial up network is not configured&quot; or </li>
                    <li>
                      &quot;Unable to connect to server, check if the server URL is correct&quot;
                      or
                    </li>
                    <li>
                      “Attempted to read or write protected memory. This is often
                      an indication that other memory is corrupt.”
                    </li>
                  </ul>
                  <p>These will occur when your computer security settings such as a firewall blocks the software from sending and receiving data over the internet using your existing broadband (or other) internet connection. These are not caused by the software, the software is simply reporting the issue to you. Please consult your firewall provider for configuration. </p>
                  <p>You will need to check the following and adjust your system accordingly (this is not an exhaustive list):</p>
                  <p>Is your computer sitting behind a corporate firewall?</p>
                  <p>Does the firewall in your internet router block access for computer to computer communication?</p>
                  <p>
                    In rare circumstances, you may need to configure the firewall to allow the software to communicate with the following domains and their subdomains, including http and https protocols should be enabled:<br>andica.com (only used to test
                    connectivity)<br>
                    <span class="contentDiv">andica.co.uk			</span><br>hmrc.gov.uk (used to send and receive data)<br>
                    gateway.gov.uk
                    <br />
                    <a href="http://gov.uk" target="_blank" class="link">gov.uk</a>
                    <br />
                    <a href="https://transaction-engine.tax.service.gov.uk " target="_blank" class="link">https://transaction-engine.tax.service.gov.uk </a>
                    <br />
                    <a href="https://transaction-engine.tax.service.gov.uk/submission" target="_blank" class="link">https://transaction-engine.tax.service.gov.uk/submission</a>
                    <br />
                    <a href="https://transaction-engine.tax.service.gov.uk/poll" target="_blank" class="link">https://transaction-engine.tax.service.gov.uk/poll</a>
                  </p>
                  <p>
                    If your internet is accessed through a Proxy server, check your Proxy
                    settings in Internet Explorer and try to submit with or without these
                    Proxy settings in Internet Explorer. Where you have multiple brands
                    of internet browsers installed on your computer, you may need to temporarily
                    set Microsoft Internet Explorer to be your default browser.
                  </p>
                  <p>
                    Where the system date is not the current date, some systems and internet/firewall
                    routers may prevent the submission process and display firewall warnings.
                    Please check and adjust your system date if it is not set to the current
                    date and time.
                  </p>
                  <p>Are you using an old router with old router driver that your Windows Operating System does not support (contact your ISP for advice).</p>
                  <p>
                    If you are getting a message relating to &#39;server certificate&#39; or
                    &#39;The download of the specified resource has failed.&#39;, untick the &#39;Check
                    for server certificate revocation&#39; option in your browser&#39;s Internet
                    Options - Advanced tab.
                  </p>
                  <p>
                    If Microsoft Internet Explorer is not your current browser, start
                    it and temporarily set it to be your default browser. Here&#39;s how to
                    do this: <a href="http://windows.microsoft.com/en-US/windows7/Change-your-default-web-browser" target="_blank" class="link">http://windows.microsoft.com/en-US/windows7/Change-your-default-web-browser</a>
                  </p>
                  <p>
                    In Internet Explorer browser, change the &#39;Check for server certificate
                    revocation&#39; option.
                  </p>
                  <ul style="list-style: lower-alpha;">
                    <li>
                      Double-click the icon for Internet Explorer on your desktop
                      to open the program.
                    </li>
                    <li>Click on the Tools drop down and select &quot;Internet Options.&quot;</li>
                    <li>Go to the Advanced tab.</li>
                    <li>
                      Scroll to the Security section under Settings and look for the
                      &quot;Check for Server Certificate Revocation&quot; option.
                    </li>
                    <li>Remove tick in the box to disable the option.</li>
                  </ul>
                  <p>
                    Most internet routers also have some form of firewall, change the
                    certificate revocation option in there as well.
                  </p>
                  <p>
                    Configurations vary for all types/make of firewall. You should consult
                    your firewall providers' user guide. If your computer is connected to
                    the Local Area Network (LAN), consult your system administrator or network
                    security consultant.
                  </p>
                  <p>
                    Following are the website links and URL's to the most commonly used firewall
                    software. These sites may provide information on how to configure their
                    firewall software to allow access to certain programs. We have provided
                    this information without any obligations and liabilities. We do not
                    provide support and do not have any control on the contents of the websites.
                    (Links are provided for reference only, the site owners may remove or
                    amend the web pages to which these links point)
                  </p>
                  <p>
                    <b>Microsoft</b><br>
                    <a class="link" href="http://windows.microsoft.com/en-us/windows7/Allow-a-program-to-communicate-through-Windows-Firewall" target="_blank">
                      Windows 7™
                    </a><br>
                    <a class="link" href="http://windows.microsoft.com/en-us/windows7/Allow-a-program-to-communicate-through-Windows-Firewall" target="_blank">
                      http://windows.microsoft.com/en-us/windows7/Allow-a-program-to-communicate-through-Windows-Firewall
                    </a>
                  </p>
                  <p>
                    <b>Zone Labs</b><br>
                    <a class="link" href="http://www.zonelabs.com/store/content/support/support.jsp">
                      Zone Alarm
                    </a><br>
                    <a class="link" href="http://www.zonelabs.com/store/content/support/support.jsp">
                      http://www.zonelabs.com/store/content/support/support.jsp
                    </a>
                  </p>
                  <p>
                    <b>Symantec</b><br>
                    <a class="link" href="http://service1.symantec.com/SUPPORT/nip.nsf/3897bf24c622c3ef85256eda00456216/d58faf5df2c9574b88256f6c0040d19f?OpenDocument&amp;prod=Norton%20Internet%20Security&amp;ver=2005&amp;src=sg&amp;pcode=nis&amp;svy=&amp;csm=no">
                      Norton Internet Security 2005
                    </a><br>
                    <a class="link" href="http://service1.symantec.com/SUPPORT/nip.nsf/3897bf24c622c3ef85256eda00456216/d58faf5df2c9574b88256f6c0040d19f?OpenDocument&amp;prod=Norton%20Internet%20Security&amp;ver=2005&amp;src=sg&amp;pcode=nis&amp;svy=&amp;csm=no">
                      http://service1.symantec.com/SUPPORT/nip.nsf/3897bf24c622c3ef85256eda00456216/d58faf5df2c9574b88256f6c0040d19f?OpenDocument&amp;prod=Norton%20Internet%20Security&amp;ver=2005&amp;src=sg&amp;pcode=nis&amp;svy=&amp;csm=no
                    </a>
                  </p>
                  <p>
                    <b>MacAfee</b><br>
                    <a class="link" href="http://www.mcafeehelp.com/">http://www.mcafeehelp.com/</a><br>
                    <br>
                    <a class="link" href="http://service.mcafee.com/faqdocument.aspx?id=TS100406&amp;lang=en_GB&amp;prior_tid=2&amp;AnswerID=16777221" target="_blank">
                      http://service.mcafee.com/faqdocument.aspx?id=TS100406&amp;lang=en_GB&amp;prior_tid=2&amp;AnswerID=16777221
                    </a><br>
                    <br>
                    <a class="link" href="http://service.mcafee.com/faqdocument.aspx?id=TS100813&amp;lang=en_GB&amp;prior_tid=2&amp;AnswerID=16777223" target="_blank">
                      http://service.mcafee.com/faqdocument.aspx?id=TS100813&amp;lang=en_GB&amp;prior_tid=2&amp;AnswerID=16777223
                    </a>
                  </p>
                  <p>
                    <b>Tiny</b><br>
                    <a class="link" href="http://www.tinysoftware.com/home/tiny2?s=5375286922906437303A3&amp;&amp;pg=resources&amp;book_name=tpf2005_manual&amp;intro=tf6std_ovw">
                      Tiny Firewall 2005 Support
                    </a><br>
                    <a class="link" href="http://www.tinysoftware.com/home/tiny2?s=5375286922906437303A3&amp;&amp;pg=resources&amp;book_name=tpf2005_manual&amp;intro=tf6std_ovw">
                      http://www.tinysoftware.com/home/tiny2?s=5375286922906437303A3&amp;&amp;pg=resources&amp;book_name=tpf2005_manual&amp;intro=tf6std_ovw
                    </a>
                  </p>
                  <p>
                    <b>CA Personal Firewall</b><br>
                    <a class="link" href="http://shop.ca.com/Support/techsupport/firewall.aspx">
                      http://shop.ca.com/Support/techsupport/firewall.aspx
                    </a><br>
                    <a class="link" href="http://cainternetsecurity.net/KB/KD.aspx?KDId=932" target="_blank">http://cainternetsecurity.net/KB/KD.aspx?KDId=932</a><br>
                    <a class="link" href="http://cainternetsecurity.net/KB/KD.aspx?KDId=862" target="_blank">
                      http://cainternetsecurity.net/KB/KD.aspx?KDId=862
                    </a>
                  </p>
                  <p>
                    <b>
                      Warning: You should not disable the firewall.<br><br>Configuring
                      Anti-virus software
                    </b>
                  </p>
                  <p>
                    Security settings in most anti-virus software will have the Script
                    Blocking options enabled. When you process reports within Andica Software,
                    the anti-virus software may prevent the script from running or provide
                    you with an alert similar to the one shown below.
                  </p>
                  <p>
                    You should authorise the Andica Software scripts either at all times
                    or at the time when it is processed.
                  </p>
                  <p>
                    Generally you should retain your anti-virus software's recommended
                    settings, however if these are set to stealth mode where all suspicious
                    scripts are blocked, you may need to temporarily change the settings.<br>
                    <img border="0" height="378" src="../../../../assets/screens/FileByInternet13.jpg" width="435">
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="Characters1" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>CESA Characters</b> </div>
                  <br />
                  <p>
                    <b>
                      HMRC Computer Environment for Self Assessment
                      (CESA) service
                    </b>
                  </p>
                  <p align="justify">
                    HMRC
                    Online filing services for Self Assessment will reject any returns that
                    contain characters that are not acceptable by their system.
                  </p>
                  <p align="justify">
                    Following
                    is a list of characters which will be accepted by the HM Revenue &amp; Customs
                    Computer Environment for Self Assessment (CESA) service.
                  </p>
                  <p align="justify">
                    A
                    to Z (upper case)<br>a to z (lower case)<br>0 to 9 (numeric)<br>space &amp;
                    ' ( ) * , - . / &#64; £
                  </p>
                  <p align="justify">
                    Note
                    that the most common characters that users tend to use but are not allowable
                    while submitting returns online are dollar ($), percentage (%), hash (#)
                    and punctuation marks.
                  </p>
                  <p align="justify">
                    Typical
                    locations where the invalid characters may have been entered are:
                  </p>
                  <ul>
                    <li>
                      Additional Information sections:
                      <ul>
                        <li>Box 3.116 of Partnership Trading and Professional Income</li>
                        <li>Boxes for 'Additional information' within any other supplementary forms</li>
                      </ul>
                    </li>
                    <li>Address fields</li>
                    <li>Description and any other text fields</li>
                  </ul>
                  <p align="justify">
                    This
                    is not a complete list of locations. There are many other areas where it
                    is possible to enter text containing unacceptable characters.
                  </p>
                  <p>
                    Where an unacceptable character has been
                    entered a message box will be displayed when you proceed to the next screen.
                  </p>
                  <p>		<img height="125" src="../../../../assets/screens/CESACharacters1.jpg" width="587"></p>
                  <p>
                    Please check and correct the data. In some
                    instances data from the field will be cleared when you click 'OK' on the
                    message box.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--Printing reports starts here............-->
          <div id="reports1" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Printing reports</b> </div>
                  <br />
                  <p><b>Printing Tax Returns report</b></p>
                  <p align="justify">
                    'Substitute'
                    Tax Return forms are no longer available to print from the software at this
                    time, we will review this feature if feasible in future. The reason for
                    removing this is due to implementation of Lord Carter of Coles' Review of
                    HMRC Online Services, as a result of this HMRC no longer accept paper substitute
                    tax returns, taxpayers must either file online or use the official HMRC
                    forms.
                  </p>
                  <p align="justify">
                    However, we
                    have continued to provide similar reports that you can print for your record
                    and for your clients.
                  </p>
                  <p align="justify">
                    Once you have
                    completed all the entries for your tax return, you can view and print the
                    reports. You should not send these reports to the HMRC.
                  </p>
                  <p align="justify">
                    If you need
                    to send paper returns to HMRC, you can complete your processing and calculations
                    through the software and copy the information onto the forms that HMRC have
                    sent you. You can also download forms from HMRC's website. You will need
                    to confirm with them if those forms are acceptable by them.
                  </p>
                  <p align="justify">
                    To view and
                    print the tax returns report, either:
                  </p>
                  <ol>
                    <li>
                      <p>
                        Select 'View' <span style="font-family: Wingdings">à</span> 'Reports'
                        <span style="font-family: Wingdings">à</span>
                        'Tax returns' <span style="font-family: Wingdings">à</span>
                        'Client copy' on the menu
                      </p>
                    </li>
                  </ol>
                  <p>
                    <img height="214" src="../../../../assets/screens/PrintingReport1.png" width="629">
                    <ol start="2">
                      <li>
                        <p>
                          Click the 'Print' icon on horizontal toolbar
                          <img height="25" src="../../../../assets/screens/PrintingReport2.jpg" width="25" style="margin: 0px 0px 0px 5px;">
                        </p>
                      </li>
                    </ol>
                  <p align="justify">
                    Andica software provides
                    a warning if it encounters unacceptable CESA characters. Further details
                    are described in the <i>CESA Characters</i> chapter of the user guide and
                    help files.
                  </p>
                  <p>Reports in version 2016 and later versions of the software are different from earlier versions 2015 and prior versions.</p>
                  <p>Tax return form as shown in the image below will be displayed showing details entered.</p>
                  <p>If a report you have selected is not displayed, close the on-screen report, go to the form’s data entry screens and check that the data entry process was completed by navigating through all screens within the forms entry routine.</p>
                  <p><img src="../../../../assets/screens/PrintingReport10.png" width="800" height="600"></p>
                  <p><u>In versions 2015 and prior versions:</u></p>
                  <p align="justify">
                    Tax return form as shown
                    in the image below will be displayed showing details entered. Select the
                    form to view using the 'Form' dropdown and navigate using the 'Record
                    No.' dropdown options at the top of the page or 'Back' and 'Next'
                    buttons on toolbar.
                  </p>
                  <p align="justify">
                    A dropdown for 'Form' lists
                    the Main return and all supplementary forms you have selected to complete.
                  </p>
                  <p align="justify">
                    If a report you have selected
                    is not displayed, close the on-screen report, go to the form's data entry
                    screens and check that the data entry process was completed by navigating
                    through all screens within the forms entry routine.
                  </p>
                  <p><img src="../../../../assets/screens/PrintingReport3.jpg" width="958" height="714"></p>
                  <p>
                    Navigate the forms using the
                    dropdown options at the top of the page or the buttons at the toolbar.
                  </p>
                  <table border="1" cellpadding="5" cellspacing="0" width="70%">
                    <tr>
                      <td width="20%">
                        <p>Forms</p>
                      </td>
                      <td>
                        <p>Dropdown listing the Main returns form and all forms completed.</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>Record No.</p>
                      </td>
                      <td>
                        <p>Number of records (additional forms) created for a supplementary form. E.g. if you have completed more than 1 'Trading and Professional' form.</p>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div id="reports2" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Printing reports</b> </div>
                  <br />
                  <p><b>Printing Declaration and Client copy</b></p>
                  <p align="justify">
                    Client copy
                    printouts should not be sent to the HMRC as your tax returns forms.
                  </p>
                  <p align="justify">
                    Where the tax
                    returns have been submitted online using 'File By Internet', this report
                    can be printed as a file copy for the Partner and / or person completing
                    the report on behalf of the Partnership.
                  </p>
                  <p align="justify">Where you are submitting the forms online on behalf of a Partnership, it is strongly advised that the declaration is signed and dated by the Designated Partner.</p>
                  <p align="justify">
                    Please ensure
                    that all the appropriate forms and page numbers have been printed.
                  </p>
                  <p align="justify">
                    To view and
                    print the Declaration and Client copy, either:
                  </p>
                  <ol>
                    <li>
                      <p>
                        Select 'View'&nbsp;<span style="font-family: Wingdings">à</span>&nbsp;'Reports'&nbsp;<span style="font-family: Wingdings">à</span>&nbsp;'Tax returns'&nbsp;<span style="font-family: Wingdings">à</span>&nbsp;'Declaration Client copy' on the menu
                      </p>
                    </li>
                  </ol>
                  <p><img src="../../../../assets/screens/PrintingReport1.png" width="629" height="214"></p>
                  <ol start="2">
                    <li>
                      <p>
                        Press [Ctrl + P] keys on
                        the keyboard
                      </p>
                    </li>
                  </ol>
                  <p align="justify">Client copy tax return form as shown in the image below will be displayed showing details entered. Select the form to view using the 'Form' dropdown and navigate using the 'Record No.' dropdown options at the top of the page or 'Back' and 'Next' buttons on toolbar.</p>
                  <p align="justify">
                    A dropdown for 'Form' lists
                    the Main return and all supplementary forms you have selected to complete.
                  </p>
                  <p align="justify">
                    If a report you have selected
                    is not displayed, close the on-screen report, go to the form's data entry
                    screens and check that the data entry process was completed by navigating
                    through all screens within the forms entry routine.
                  </p>
                  <p><img height="714" src="../../../../assets/screens/PrintingReport5.jpg" width="958"></p>
                  <p>
                    Navigate the forms using the dropdown options
                    at the top of the page or the buttons on toolbar.
                  </p>
                  <table border="1" cellpadding="5" cellspacing="0" width="70%">
                    <tr>
                      <td width="20%">
                        <p>Forms</p>
                      </td>
                      <td>
                        <p>Dropdown listing the Main returns form and all forms completed.</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>Record No.</p>
                      </td>
                      <td>
                        <p>Number of records (additional forms) created for a supplementary form. E.g. if you have completed more than 1 'Trading and Professional' form.</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>Declaration</p>
                      </td>
                      <td>
                        <p>Click to view and print the declaration. This will be numbered page 1 of nn, where nn is the total number of pages within the client copy return forms.</p>
                      </td>
                    </tr>
                  </table>
                  <p><b>Printing Substitute forms</b></p>
                  <p>Substitute forms can be printed on colour printers. HMRC have strict guidelines on colours and formats. We cannot guarantee that HMRC will accept printed paper forms as valid returns.</p>
                  <p>To view and print the Declaration and Client copy, either:</p>
                  <ol>
                    <li>
                      <p>Select 'View'&nbsp;<span style="font-family: Wingdings">à</span>&nbsp;'Reports'&nbsp;<span style="font-family: Wingdings">à</span>&nbsp;'Tax returns'&nbsp;<span style="font-family: Wingdings">à</span>&nbsp;'Substitute forms' on the menu</p>
                    </li>
                  </ol>
                  <p><img src="../../../../assets/screens/PrintingReport1.png" width="629" height="214"></p>
                  <p>Older software version 2015 and earlier:</p>
                  <p>‘Substitute’ Tax Return forms were not available to print from the software in some of the older versions. This was due to implementation of Lord Carter of Coles’ Review of HMRC Online Services, as a result of this HMRC no longer accepted paper substitute tax returns.</p>
                </div>
              </div>
            </div>
          </div>
          <div id="reports3" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Printing reports</b> </div>
                  <br />
                  <p><b>Report toolbar (Version 2016 and later versions)</b></p>
                  <p>Some reports will include a toolbar with various functions on it.</p>
                  <img border="1" src="../../../../assets/screens/PrintingReportsImage11.jpg"
                       style="margin: 15px 0px 15px 0px;" width="664" height="50">
                  <p>Listed here are some of the useful functions</p>
                  <table border="1" cellpadding="5" cellspacing="0" style="text-align: justify;" width="80%">
                    <tr>
                      <td valign="top" width="25%">
                        <img border="0" style="margin: 15px 0px 15px 0px;" src="../../../../assets/screens/PrintingReportsImage12.jpg">
                      </td>
                      <td valign="top" width="75%">
                        Export Report<br>You can use this feature to export your report to various file formats. The most useful format is the PDF which retains the report layout as intended and reports can be viewed using a PDF reader. Export to other file format may distort the report layout. No support is provided for this feature.
                        <p>To save the report in PDF format, click the ‘PDF’ button and in ‘Save as type:’ select ‘Adobe Acrobat (*.pdf)’ from the dropdown list’ and location where you want to save the report.
                      </td>
                    </tr>
                    <tr>
                      <td valign="top" width="25%">
                        <img border="0" style="margin: 15px 0px 15px 0px;" src="../../../../assets/screens/PrintingReportsImage13.jpg">
                      </td>
                      <td valign="top" width="75%">Print Report</td>
                    </tr>
                    <tr>
                      <td valign="top" width="25%">
                        <img border="0" style="margin: 15px 0px 15px 0px;" src="../../../../assets/screens/PrintingReportsImage14.jpg">
                      </td>
                      <td valign="top" width="75%">
                        Page navigation.
                        <br />
                        Go to First Page,
                        Previous Page, Next Page, Last Page.
                      </td>
                    </tr>
                  </table>
                  <p><b>Report toolbar (Version 2015 and prior versions)</b></p>
                  <p>Some reports will include a toolbar with various functions on it.</p>
                  <img border="0" height="28" src="../../assets/screens/PrintingReport1.png" style="margin: 15px 0px 15px 0px;" width="360">
                  <p>Listed here are some of the useful functions</p>
                  <table border="1" cellpadding="5" cellspacing="0" style="text-align: justify;" width="80%">
                    <tr>
                      <td valign="top" width="25%">
                        <img border="0" height="28" src="../../assets/screens/PrintingReportsImage3.jpg"
                             style="margin: 15px 0px 15px 0px; text-align: center;" width="29">
                      </td>
                      <td valign="top" width="75%">
                        Export Report<br>You can use this
                        feature to export your report to various file formats. The most
                        useful format is the PDF which retains the report layout as
                        intended and reports can be viewed using a PDF reader. Export
                        to other file format may distort the report layout. No support
                        is provided for this feature.
                        <p>To save the report in PDF format, click the 'Export Report' button and in 'Save as type:' select 'Adobe Acrobat (*.pdf)' from the dropdown list'
                      </td>
                    </tr>
                    <tr>
                      <td valign="top" width="25%">
                        <img border="0" height="28" src="../../../../assets/screens/PrintingReportsImage4.jpg" style="margin: 15px 0px 15px 0px;" width="31">
                      </td>
                      <td valign="top" width="75%">Print Report</td>
                    </tr>
                    <tr>
                      <td valign="top" width="25%">
                        <img border="0" height="28" src="../../../../assets/screens/PrintingReportsImage5.jpg" style="margin: 15px 0px 15px 0px;" width="110">
                      </td>
                      <td valign="top" width="75%">
                        Page navigation. Go to First Page,
                        Previous Page, Next Page, Last Page.
                      </td>
                    </tr>
                    <tr>
                      <td valign="top" width="25%">
                        <img border="0" height="28" src="../../../../assets/screens/PrintingReportsImage6.jpg" style="margin: 15px 0px 15px 0px;" width="31">
                      </td>
                      <td valign="top" width="75%">
                        Go to Page – Goes to page number
                        you specify.
                      </td>
                    </tr>
                  </table>
                  <p><b>IRMark message box:</b></p>
                  <p>If any of the forms status is 'Incomplete', when you attempt to run a report, the software will generate a message box:</p>
                  <blockquote>
                    <p style="line-height:1; margin-left:35px; margin-right:0px; margin-top:10px; margin-bottom:10px">IRMark has not been generated as some of the forms are incomplete. Hence reports can be viewed but cannot be printed.</p>
                    <p style="line-height:1; margin-left:35px; margin-right:0px; margin-top:10px; margin-bottom:10px">Ensure all tax return forms are complete before printing reports.</p>
                    <p style="line-height:1; margin-left:35px; margin-right:0px; margin-top:10px; margin-bottom:10px">Refer to 'Printing Reports' section of user guide for details.</p>
                  </blockquote>
                  <p>Check the 'Status' of forms and complete or remove the forms. Selecting the relevant section or Form and clicking 'Next' all the way through and 'Finish' at the end will update the 'Status' and clear this IRMark message. Details are provided in 'Using Data Entry Forms' section.</p>
                </div>
              </div>
            </div>
          </div>
          <div id="reports4" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Printing reports</b> </div>
                  <br />
                  <p><b>Printing FBI receipt</b></p>
                  <p align="justify">
                    File By Internet
                    (FBI) receipt details information relating to online submission process
                    of the tax return confirming the Correlation ID, IR Mark and Date and time
                    of submission. It is advised that this report be printed and attached to
                    the Declaration and Client copy as a confirmation of the submission.
                  </p>
                  <p align="justify">
                    To view and
                    print the FBI receipt:
                  </p>
                  <ol>
                    <li>
                      <p>
                        Select 'View'&nbsp;<span style="font-family: Wingdings">à</span>&nbsp;'Reports'&nbsp;<span style="font-family: Wingdings">à</span>&nbsp;'FBI receipt' on the menu
                      </p>
                    </li>
                  </ol>
                  <p>
                    <img height="180" src="../../../../assets/screens/PrintingReport6.jpg" width="376">
                  </p>
                  <ol start="2">
                    <li>
                      <p>
                        Press [Ctrl + I] keys on the
                        keyboard
                      </p>
                    </li>
                  </ol>
                  <p align="justify">
                    FBI receipt
                    screen as shown on the image below will also display date and time for each
                    of the submission process. FBI start time obtained from the submitting computer
                    and FBI end time is obtained from the HMRC (Government Gateway) server.
                  </p>
                  <p align="justify">In case of a failed submission, a 'Show errors' button will be active. Selecting the failed submission record on the tree view and clicking 'Show errors' will open a log file listing errors reported by HMRC Server. Further information is provided in the 'File by Internet' section.</p>
                  <p><img height="424" src="../../../../assets/screens/PrintingReport7.jpg" width="613"></p>
                </div>
              </div>
            </div>
          </div>
          <div id="reports5" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Printing reports</b> </div>
                  <br />
                  <p><b>Clearing submission history</b></p>
                  <p align="justify">Submission history stored within the software should be cleared to enable you to resubmit your returns. </p>
                  <p align="justify">When you attempt to access the 'File by Internet' feature for the Partnership record for which you have already submitted returns for the tax year supported by the software and you have confirmed your action on the message box “Your tax return may have already been submitted using the Live submission...”  a 'File by Internet details' screen is displayed.</p>
                  <p align="justify">If you want to proceed with submission of an amended return, you can clear the history to enable you to run a 'Live Submission' again. </p>
                  <p align="justify">Select the submission entry on the left Pane of File By Internet (FBI) details screen, FBI receipt will be displayed on the right Pane, click on the 'Clear' button and select an appropriate option on the warning message:</p>
                  <p><img height="126" src="../../../../assets/screens/PrintingReport8.jpg" width="585"></p>
                  <p>Once the tax return has successfully been submitted using 'Live submission', you should not submit your tax return again as HMRC will reject any attempts to resubmit the returns unless you are submitting an Amended return as described in 'File By Internet' section.</p>
                  <p>If the File By Internet Receipt does not come up when you are resubmitting, please go to View - Report - FBI Receipt on the menu. On left pane of the FBI Receipt screen select the Partnership whose submission history you want to clear and click &#39;Clear&#39; at the bottom of the screen.</p>
                  <p>Thereafter attempt resubmission.</p>
                </div>
              </div>
            </div>
          </div>
          <!--Backup and Restore starts here........-->
          <div id="Backup1" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Backup and Restore</b> </div>
                  <br />
                  <p><b>Backup data files.</b></p>
                  <p>
                    It is a good practice to backup the data files at regular intervals.
                    From the menu select 'File' <span class="arrow">à</span> 'Backup &amp; Restore'.
                  </p>
                  <p>Although the software provides this backup feature, we strongly recommend that the software installation folders are fully backed up as part of your regular system backups.</p>
                  <img border="0" height="262" src="../../../../assets/screens/BackupAndRestore01.jpg" width="213">
                  <p>
                    Or press CTRL+B on the keyboard. This will open the Backup and Restore
                    screen.
                  </p>
                  <img border="0" src="../../../../assets/screens/BackupAndRestore02.jpg">
                  <p>
                    The system will display a list of all Partnerships and their data
                    file location with the tabs for 'Backup' and 'Restore' and the following
                    additional functions and fields:
                  </p>
                  <table id="AutoNumber1" border="1" cellpadding="5" cellspacing="0" width="90%">
                    <tr>
                      <td align="left" valign="top" width="25%">
                        <p>
                          <img border="0" height="16" src="screens/BackupAndRestore03.jpg" style="margin: 0px 0px 0px 5px;" width="104">
                        </p>
                      </td>
                      <td width="70%">
                        Select this option to backup the Partnership's
                        variable tax return details only.
                        <p>Deselect to backup all data files, including</p>
                        <p align="justify" style="margin-left: 30pt">
                          Partnership's variable
                          tax return details<br>Partnership's static information<br>Software
                          users login details<br>User's history.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="25%">
                        <p>
                          <img border="0" height="22" src="../../../../assets/screens/BackupAndRestore04.jpg" style="margin: 0px 0px 0px 5px;" width="99">
                        </p>
                      </td>
                      <td width="70%">
                        Click on 'Select/Deselect' and choose one of
                        the options provided:
                        <p>
                          Select All – selects all the Partnerships
                          in the list.<br>Deselect All – clears all the selections.<br>
                          Inverse – swaps the selection.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="25%">Source database location</td>
                      <td width="70%">This is the path where your data files are currently located. This path will be displayed automatically.</td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="25%">Destination location</td>
                      <td width="70%">
                        Path to the drive or folder where the backup
                        file will be saved. Enter the location for the backup or
                        select 	the
                        <img border="0" height="21" src="../../../../assets/screens/BackupAndRestore05.jpg" style="margin: 0px 0px 0px 5px;" width="23">
                        button to browse to the backup location.  It is recommended
                        that you use the backup file name that appears on screen.
                        <p>
                          Backup location and
                          file name will be displayed once the above information has been
                          entered. It must include drive letter and backup file name ending with extension .acb
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="25%">Files to backup</td>
                      <td width="70%"></td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="25%">Taxpayer data files only</td>
                      <td width="70%">This option is always selected</td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="25%">XML Data Submission messages files</td>
                      <td width="70%">Select this option if you want to backup submission history XML Data folder and XML files.</td>
                    </tr>
                    <tr>
                      <td align="left" valign="top">&nbsp;</td>
                      <td>Click 'Start' to create a backup file.</td>
                    </tr>
                  </table>
                  <p>
                    Before commencing the backup, ensure that all users are logged
                    out of the software and Partnership's records are not open. Following
                    message will be displayed if a Partnership record selected for backup
                    is being accessed / updated by a user.
                  </p>
                  <img border="0" height="126" src="../../../../assets/screens/BackupAndRestore06.jpg" width="366">
                </div>
              </div>
            </div>
          </div>
          <div id="Backup2" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Backup and Restore</b> </div>
                  <br />
                  <p><b>Restoring data files.</b></p>
                  <p>In the event that you need to restore the Andica Self Assessment data files, it is recommended that the current data files are backed up prior to restoring earlier data.</p>
                  <p>
                    From the menu select 'File' <span class="arrow">à</span>
                    'Backup &amp; Restore'.
                  </p>
                  <p><img border="0" height="262" src="../../../../assets/screens/BackupAndRestore01.jpg" width="213"></p>
                  <p>Or press CTRL+B on the keyboard. This will open the Backup and Restore screen. Select the Restore tab.</p>
                  <p>
                    <img border="0" src="../../../../assets/screens/BackupAndRestore07.jpg">
                  </p>
                  <p>The Restore system will display a list of all Partnerships.</p>
                  <p>In the 'Restore description' field, select the backup that you want to restore from a dropdown list displaying descriptions of previous backups. Once selected, Partnerships that were backed-up in the selected 'Restore description' will be selected (ticked) and other options will also be selected automatically.</p>
                  <table id="AutoNumber1" border="1" cellpadding="5" cellspacing="0" width="90%">
                    <tr>
                      <td align="left" valign="top" width="25%">
                        <p>
                          <img border="0" height="16" src="screens/BackupAndRestore03.jpg" width="104" style="margin: 0px 0px 0px 5px;">
                        </p>
                      </td>
                      <td width="70%">Automatically displays the option selected during the backup that is now being restored.</td>
                    </tr>
                    !
                    <tr>
                      <td align="left" valign="top" width="25%">
                        <p>
                          <img border="0" height="22" src="../../../../assets/screens/BackupAndRestore04.jpg" width="99" style="margin: 0px 0px 0px 5px;">
                        </p>
                      </td>
                      <td width="70%">Button is disabled.</td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="25%">Source location</td>
                      <td width="70%">
                        Provide the path to the drive or folder by entering the location
                        for the
                        backup file and enter the backup file name or click the                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       <img src="screens/BackupAndRestore08.jpg" width="23" height="21" style="margin:0px;"> button to browse to the location where the backup has been saved and select a backup file that you want to restore.<br>
                        <br>
                        Backup location and file name will be displayed once the above information has been entered.
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="25%">Destination database location</td>
                      <td width="70%">
                        Automatically displays the location of the original database. This is the database that will be updated. Location cannot be changed.
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="25%">Files to restore</td>
                      <td width="70%">
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="25%">Taxpayer data files only</td>
                      <td width="70%">
                        This option is always selected
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="25%">XML Data Submission messages files</td>
                      <td width="70%">
                        Select this option if you want to restore submission history XML Data folder and XML files. XML Data will only be restored if the backup contains those files.
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="top">&nbsp;</td>
                      <td>Click 'Start' to restore the data.</td>
                    </tr>
                  </table>
                  <p>Warning: Existing data will be overwritten.</p>
                  <p>
                    Before commencing the restore process, ensure that all users are logged out of the software and Partnership's records
                    are not open.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!--Data Import starts here......-->
          <div id="DataImport1" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Data Import</b> </div>
                  <br />
                  <p>
                    <b>Importing Previous Year's Data Files</b>
                  </p>
                  <p>
                    Andica SA800 Partnership Software provides a feature to enable users to import data
                    from last year's version into this current version.
                  </p>
                  <p>
                    The import routine requires a full set of data files (MDB and all XML files) to
                    be on the same computer and in the exact path (drive letter and folders) they were
                    in when records were created in that version. Import routine will not work where
                    data files have been moved through Windows Explorer.
                  </p>
                  <p>
                    <b>Important:</b> In case you have moved data files, import facilities will not
                    work. In these instances we cannot provide any technical advice. Import screen will
                    clearly show data file location where it expects to see the data being imported.
                    Placing data back in that location should enable you to import.
                  </p>
                  <p>
                    Software provides two features, one that imports and creates records and static data such as partnership’s name, address, UTR, etc. from last year’s version into this current version and another feature that imports supplementary forms and data as it was entered last year. For Data import routine in earlier versions, refer to the guide provided for that version.
                  </p>
                  <p>
                    <strong>Importing Partnership records</strong>
                  </p>
                  <p>
                    To import and create record with static data such as partnership's name, address,
                    UTR, etc., select 'Tools' <span class="arrow">à</span> 'Imports' <span class="arrow">
                      à
                    </span> 'Tax year 2021-22 data' from the menu bar.
                  </p>
                  <img src="../../../../assets/screens/import21-22.png">
                  <p>
                    Import partnership details screen will appear.
                  </p>
                  <img src="../../../../assets/screens/Importingnew11.png">
                  <p>
                    To import data from an earlier version:
                  </p>
                  <ol>
                    <li>
                      Select the check box 'Do you want to import Partnership details from last year's
                      software?'<br>
                      <img alt="" border="0" height="26" src="../../../../assets/screens/importImage003.jpg" width="515">
                    </li>
                    <li>Click 'Browse' button</li>
                    <li>
                      Navigate to last year’s data folder, this is usually:
                      <p class="MsoNormal">
                        <span lang="EN-GB">
                          C:\Users\Public\Documents\Andica\Andica SA800 Partnership
                          202x\
                          <!--<o:p></o:p>-->
                        </span>
                      </p>
                      <p class="MsoNormal">
                        <span lang="EN-GB">
                          (If you are using
                          <span lang="EN-GB" style="font-size:10.0pt;font-family:
                              &quot;Arial&quot;,sans-serif;mso-fareast-font-family:&quot;Times New Roman&quot;;mso-ansi-language:
                              EN-GB;mso-fareast-language:EN-GB;mso-bidi-language:AR-SA">Windows </span>10
                          <span lang="EN-GB" style="font-size:10.0pt;font-family:
                              &quot;Arial&quot;,sans-serif;mso-fareast-font-family:&quot;Times New Roman&quot;;mso-ansi-language:
                              EN-GB;mso-fareast-language:EN-GB;mso-bidi-language:AR-SA">or Windows 11</span>) – Note: Older versions of
                          Windows are not supported by Andica.
                          <p> 202x</p>
                        </span>
                      </p>
                      <p>If you are using a network version, navigate to your shared data folder on the server.</p>
                    </li>
                    <li>
                      Locate a database file named AndicaSA800.mdb in last year's installation folder.
                      Depending on your Windows® 'Folder Options' setting, you may not be able to see
                      the file extension .mdb
                    </li>
                    <li>
                      Select AndicaSA800.mdb file. Ensure that you have selected last year's data file.<br>
                      <br>
                      <img alt="" border="0" src="../../../../assets/screens/importImage004.jpg">
                    </li>
                    <li>Click 'Open'. This will return you back to the 'Import tax details' screen.</li>
                    <li>
                      Click 'OK' and the software will display a list of all Partnerships stored in the
                      previous version. Select Partnership records that you want to import by placing
                      a tick against the relevant Partnership name. To import all records, you can click
                      'Select all' button.<br>
                      <br>
                      <img alt="" border="0" src="../../../../assets/screens/importImage005.jpg">
                      <br />
                      Import screen will clearly show data file location where it expects to see the data
                      being imported. In case you have moved data files, import facilities will not work.
                      In these instances we cannot provide any technical advice. Placing data back in
                      that location should enable you to import.
                    </li>
                    <li>Click 'Next'</li>
                    <li>
                      Once you have clicked 'Next' in the step above, a prompt will appear to 'enable'
                      supplementary forms and import data and values from last year's returns. Further
                      details are provided in <a class="link" href="DataImport_Importing FormsDataandvaluesfromlastyear.htm"
                                                 target="_blank">'Importing Forms, Data and values from last year'</a>
                      section.<br>
                      <br>
                      To by-pass this routine and only import partnership records, do not select any options,
                      just click 'OK' or 'Cancel'.<br>
                      <br>
                      Subsequently, when you open a partnership record that was imported for the first
                      time, you will get an option to enable supplementary forms and import values.<br>
                      <br>
                      This routine may take a long time to complete based on the number of records and
                      volume of data to be imported.<br>
                      <br>
                      <img alt="" border="0" src="../../../../assets/screens/importImage006.jpg"><br>
                      <br>
                    </li>
                    <li>
                      Once the files are successfully imported, following message will be displayed.<br>
                      <br>
                      <img src="../../../../assets/screens/importImage011.jpg"><br>
                      <br>
                      Click 'OK'.
                    </li>
                    <li>
                      Static data has now been imported and the software is ready for you to start processing
                      tax returns for the current reporting year. Please check and amend static data as
                      necessary.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div id="DataImport2" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Data Import</b> </div>
                  <br />
                  <p>
                    <b>Importing Forms, Data and values from last year</b>
                  </p>
                  <p>
                    This routine can be used to enable supplementary forms that were used in last year's
                    tax return for each partnership and copy across data and values as it was entered
                    in last year's returns.
                  </p>
                  <p>
                    This import function is optional. It is only provided for your convenience to assist
                    you save some time with data entry. The main function of this software is for you
                    to enter data, complete and submit tax returns.
                  </p>
                  <p>
                    <strong>Important</strong>: It is your responsibility to ensure accuracy and completeness
                    of the returns and data imported. You must check all information in the tax return
                    to ensure it is relevant for the current tax year's returns you are processing.
                    Responsibilities for contents and submission of returns remains solely with the
                    partnership, partners and/or their representatives, Andica does not take any responsibility
                    for the tax returns and submissions.
                  </p>
                  <p>
                    While the software will attempt to import most data, there will be some records
                    and data items that will not be imported. You will need to check all imported data
                    and change entries as it will contain data pertaining to last year and you will
                    need to update with current year information. Look out for entries such as dates
                    or year referenced in text fields, etc.
                  </p>
                  <p>
                    Methods to enable supplementary forms and copy across data and values from last
                    year's return are accessible in either one of the following ways:
                  </p>
                  <ol style="list-style-type: lower-alpha;">
                    <li>
                      During the process to import Partnership records (as described in <a class="link"
                                                                                           href="DataImport_PreviousYearData.htm" target="_blank">'Importing Partnership records'</a>
                      section)<br>
                      <br>
                      A screen prompt will be displayed with options to enable supplementary forms and
                      copy data and values. To by-pass this routine without importing supplementary forms
                      and values and to only import and create partnership's records, do not select any
                      options, click 'OK' or 'Cancel'.<br>
                      <br>
                      You will be able to import supplementary forms and values later.<br>
                      <br>
                      To import tax returns data, select the options as appropriate.<br>
                      <br>
                      <img src="../../../../assets/screens/importImage012.jpg" style="margin: 0px;"><br>
                      <br>
                      Tick the option 'Do you want to enable supplementary form(s) as per previous year
                      for selected Partnership(s)?' to enable and use the supplementary forms similar
                      to ones used on last year's tax return for each of the partnership,<br>
                      <br>
                      Selecting this option only enables supplementary forms without copying data and
                      values.<br>
                      <br>
                      To import (copy) last year's tax return data and values as well, tick the option
                      'Do you also want to import data and values for the selected form(s) as per previous
                      year?'
                      <br>
                      <br>
                      This option will remain greyed out unless the first option is selected.<br>
                      <br>
                      Once you have selected one of the options, click 'OK' to continue with import.<br>
                      <br>
                      Clicking 'OK' when none of the options have been selected will proceed to create
                      partnership records only.<br>
                      <br>
                      Click 'Cancel' to skip the routine to import supplementary form(s) from previous
                      year.<br>
                      <br>
                      This routine may take a long time to complete based on the number of records and
                      volume of data to be imported.
                    </li>
                    <li>
                      When opening a partnership record for the first time – if that record was imported
                      but forms and values were not imported at that time.<br>
                      <br>
                      After importing partnership records as described in <a class="link" href="DataImport_PreviousYearData.htm"
                                                                             target="_blank">'Importing Partnership records'</a>, if the 'Forms,
                      Data and values' import routine was skipped at that time, when you open a partnership
                      record for the first time, an option will be provided to select supplementary forms
                      and import data and values as they were selected in last year's version.<br>
                      <br>
                      <img src="../../../../assets/screens/importImage013.jpg" style="margin: 0px;"><br>
                      <br>
                      A list of Forms that were used in previous year returns will be displayed. Forms
                      will initially be greyed out till you select 'Do you want to enable supplementary
                      forms as per previous year?'<br>
                      <br>
                      Outline of the process:<br>
                      <ol style="list-style-type: lower-roman;">
                        <li>Tick option for 'Do you want to enable supplementary forms as per previous year?'</li>
                        <li>Tick the forms that you want to import, or click 'Select/Deselect' button</li>
                        <li>
                          In the supplementary forms with multiple records marked with [+] sign, select records
                          you wish to import
                        </li>
                        <li>Click 'Import' to start the process.</li>
                      </ol>
                      <br>
                      <br>
                      Forms marked in blue are already present in the partnerships returns. SA800 main
                      tax form will always be present at the start. Do not import a form if you have already
                      entered data for the current year. Import routine will overwrite and replace data
                      in all selected forms.<br>
                      <br>
                      All selected forms, data and values will be imported for the partnership from last
                      year's returns into this year's returns.
                    </li>
                    <li>
                      Through menu 'Tools' <span class="arrow">à</span> 'Imports' <span class="arrow">
                        à
                      </span> 'Forms, Data and values from last year'<br>
                      <br>
                      <img src="../../../../assets/screens/importForms21-22.png" style="margin: 0px;"><br>
                      <br>
                      This feature can be used to import Forms, data and values from last year's return
                      for a partnership whose record is currently open. It can be run at any time and
                      will overwrite all tax return data that you may have already entered with data imported
                      from last year's return.<br>
                      <br>
                      <img src="../../../../assets/screens/importImage015.jpg" style="margin: 0px;"><br>
                      <br>
                      <p>
                        Forms
                      </p>
                      <p>
                        Outline of the process:
                      </p>
                      <ol style="list-style-type: lower-roman;">
                        <li>Tick option for 'Do you want to enable supplementary forms as per previous year?'</li>
                        <li>Tick the forms that you want to import, or click 'Select/Deselect' button</li>
                        <li>
                          In the supplementary forms with multiple records marked with [+] sign, select records
                          you wish to import
                        </li>
                        <li>Click 'Import' to start the process.</li>
                      </ol>
                      <p>
                        Forms marked in blue are already present in the partnership returns. SA800 main
                        tax form will always be present at the start. Do not import a form if you have already
                        entered data for the current year. Import routine will overwrite and replace data
                        in all selected forms.
                      </p>
                      <p>
                        All selected forms, data and values will be imported for the partnership from last
                        year's returns into this year's returns.
                      </p>
                      <p>
                        To cancel the process, do not tick any items and click 'Cancel'
                      </p>
                      <p>
                        In case you want to reset all or some forms and clear tax return entries, you can
                        do so as described in 'Resetting forms and clearing data' section.
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div id="DataImport3" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <div class="contentDiv">
                  <div class="contentheader"> &nbsp;<b>Data Import</b> </div>
                  <br />
                  <p><b>Data comparison with last year</b></p>
                  <p>Once data has been imported, when you access a field where you had entered data in last year's tax return, the software shows a comparison of the value entered last year.</p>
                  <img alt="" border="0" height="72" src="../../../../assets/screens/importImage007.jpg" width="296">
                  <p>Even though you may not have imported the Partnership records using the import routine, you can still 'Link' last year's tax data so that you can benefit from using the data comparison feature of the software.</p>
                  <p>To link last year's tax data files select 'Activity' <span class="arrow">à</span> 'Link last year's data' from the menu.</p>
                  <img alt="" border="0" height="119" src="../../../../assets/screens/importImage008.jpg" width="183">
                  <p>Ensure the 'Show Bubble' is also selected.</p>
                  <p><strong>Copy last year's data (F6)</strong></p>
                  <p>Once you have enabled the 'Show Bubble' feature, you are then able to copy across last year's data into the field where the cursor is positioned by pressing F6 function key on the keyboard.</p>
                  <p><b>Exporting Partnership Statement data and Importing into Andica SA100 software</b></p>
                  <p>This feature can only be used if you are using Andica SA100 Self Assessment Software to prepare returns for an Individual Tax payer who is a Partner in the business for whom you are preparing Partnership business tax return.</p>
                  <p>To import Andica SA800 Partnership Statement data for an individual partner into SA104:</p>
                  <p><u>Export from Andica SA800 software:</u></p>
                  <ol type="a">
                    <li>Log into SA800 software and go to Partnership Statement</li>
                    <li>On the list of 'Individual Partner details', tick to select the Partner's data to export.</li>
                    <li>
                      Click 'Export to SA100' and save the XML file generated (Make a note of the file name and location where the file has been saved)
                    </li>
                  </ol>
                  <p><u>Import into Andica SA100 software:</u></p>
                  <ol type="a">
                    <li>Log into Andica SA100 software and open the Tax payers return</li>
                    <li>Select Q3 for Partnership form and the relevant option Short or Full</li>
                    <li>Access Partnership form</li>
                    <li>Click 'Import from SA800' and select the XML fileExported from SA800</li>
                    <li>Review and complete the Partnership form. </li>
                  </ol>
                  <p>
                    If any message boxes appear, follow the information provided on them.
                </div>
              </div>
            </div>
          </div>
          <!--SA800-Main Return starts here........-->
          <div id="SA800" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <p style="text-align: right;">
                  <b>
                    <span style="font-size: 25px;" class="headingSA">Partnership Tax Return Guide</span>
                    <br>
                    <span style="font-size: 15px;" class="headingSA">Tax year 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}} ({{this.userService.Currenttax_shortyear}}) </span>
                  </b>
                </p>
                <table id="AutoNumber1" border="0" bordercolor="#111111" cellpadding="0" cellspacing="0" style="border-collapse: collapse" width="98%">
                  <tr>
                    <td align="right" width="93%">
                    </td>
                    <td width="7%">&nbsp;</td>
                  </tr>
                  <tr>
                    <td align="right" bgcolor="#00ABBC" width="93%">&nbsp;</td>
                    <td bgcolor="#ABA000" width="7%">&nbsp;</td>
                  </tr>
                </table>
                <br>
                <table cellpadding="3" cellspacing="0" style="border-collapse: collapse" width="98%">
                  <tr>
                    <td valign="top" width="25%">
                      <a class="link" href="help#bq1">Box Q1</a>
                    </td>
                    <td valign="top" width="25%"><a class="link" href="help#2p0">Box Q2</a></td>
                    <td valign="top" width="25%"><a class="link" href="help#3p0">Box Q3</a></td>
                    <td valign="top" width="25%"><a class="link" href="help#4p0">Box Q4</a></td>
                  </tr>
                  <tr>
                    <td valign="top" width="25%"><a class="link" href="help#5p0">Box Q5</a></td>
                    <td valign="top" width="25%"><a class="link" href="help#6p0">Box Q6</a></td>
                    <td valign="top" width="25%"><a class="link" href="help#6p1">Box Q6.1</a></td>
                    <td valign="top" width="25%"><a class="link" href="help#7p0">Box Q7</a></td>
                  </tr>
                  <tr>
                    <td valign="top" width="25%"><a class="link" href="help#8p0">Box Q8</a></td>
                    <td valign="top" width="25%"><a class="link" href="help#9p0">Box Q9</a></td>
                    <td valign="top" width="25%"> <a class="link" href="help#9p1-3"> Boxes 9.1 to 9.3</a></td>
                    <td valign="top" width="25%"><a class="link" href="help#10p0">Box Q10</a></td>
                  </tr>
                  <tr>
                    <td valign="top" width="25%"><a class="link" href="help#10p1">Box 10.1</a></td>
                    <td valign="top" width="25%"> <a class="link" href="help#10p2-3"> Boxes10.2 and 10.3</a></td>
                    <td valign="top" width="25%"><a class="link" href="help#tenfour" *ngIf="this.userService.Present_year < '2024'">Box 10.4</a> </td>
                    <td valign="top" width="25%"><a class="link" href="help#11p0">Box Q11</a></td>
                  </tr>
                  <tr>
                    <td valign="top" width="25%"> <a class="link" href="help#elevenfour">Boxes 11.1 to 11.4</a> </td>
                    <td valign="top" width="25%"><a class="link" href="#12Ap0">Box 12A</a></td>
                  </tr>
                  
                </table>
                <hr>
            
                <p class="headingSA">Filling in the Partnership Tax Return</p>
                <p><b>What makes up the Partnership Tax Return?</b></p>
                <p>
                  Every partnership is sent the first 8 pages covering
                  some types of income. Answer all the questions.
                  They will help you to decide which boxes to fill in
                  and whether you need any of the supplementary
                  pages for other types of income and disposals
                  of chargeable assets. In some circumstances you
                  may also need additional sets of pages to return
                  information for more than one period.
                </p>
                <p>
                  The Partnership Tax Return includes a 'short'
                  Partnership Statement on pages 6 and 7 for
                  summarising the profits, losses or income
                  allocated to the partners. Fill in this or the 'full'
                  unabridged Partnership Statement (available
                  separately, go to <a href="http://www.gov.uk/taxreturnforms" target="_blank" class="link">www.gov.uk/taxreturnforms</a>), as appropriate, and then
                  provide each partner with the information they
                  need to fill in their personal tax return.
                </p>
                <h4><b>Changes in the membership of a partnership</b></h4>
                <p>
                  For tax purposes, the business carried on by a
                  partnership is regarded as continuous, despite
                  a change in the members of the partnership,
                  provided there is at least one partner who is a
                  member of both sides of the change.
                </p>
                <p>
                  You do not need to fill in a separate set of pages
                  simply because of a change in the membership of
                  the partnership (although you may prefer to do
                  so). However, you should confirm that, where
                  a partner has only been a member of the
                  partnership for a part of the period covered by
                  the Partnership Tax Return this fact is correctly
                  reflected in the partner details section and
                  profit share information provided in the
                  Partnership Statement.
                </p>
                <h4><b>Tax due on shares of partnership income</b></h4>
                <p>
                  We’ll use the information in the Partnership
                  Tax Return to check that the partners pay the
                  correct tax and Class 4 National Insurance contributions (NICs)due on their share
                  of the partnership's profits. Each partner is liable
                  only up to the tax due on their share of the
                  partnership profit.
                </p>
                <p>
                  We’ll usually have 12 months from the date HMRC
                  received the return to decide whether an enquiry
                  is necessary to check the accuracy of the figures in
                  the Partnership Tax Return.
                </p>
                <h4><b>Types of partnership</b></h4>
                <p>A partnership for the purposes of the Partnership Tax Return includes:</p>
                <ul>
                  <li>a partnership governed by the Partnership Act 1890</li>
                  <li>
                    a limited partnership registered under the Limited Partnership Act
                    1907
                  </li>
                  <li>
                    a limited liability partnership (LLP) registered under the Limited
                    Liability Partnership Act 2000 unless the LLP
                    <ul style="list-style-type:none;">
                      <li>— does not carry on a business with a view to profit</li>
                      <li>— is being formally wound up</li>
                    </ul>
                    in which case the LLP may need to make a
                    Corporation Tax Return.
                  </li>
                </ul>
                <p>
                  It also includes any foreign entity which is
                  regarded as a partnership for the purposes of the
                  UK Taxes Acts.
                </p>
                <p>
                  Partnerships can be made up of persons some
                  of whom are liable to Income Tax and some of
                  whom are liable to Corporation Tax. Where a
                  partnership consists only of persons liable to
                  Corporation Tax this is referred to as a
                  'CT Partnership'. A partnership, which consists
                  of some members who are liable to Income
                  Tax and others to Corporation Tax, is not a
                  'CT Partnership'.
                </p>
                <h4><b>Return period for partnerships other than 'CT Partnerships'</b></h4>
                <p><b>Trading and professional income</b></p>
                <p>
                  Return details of the partnership&#39;s trading and professional income and
                  expenditure for the accounting period, or periods, ended on a date in the period
                  6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}.
                  If the partnership ceased during this period you
                  should return details of the partnership's income
                  and expenditure to the date of cessation even if
                  the business was carried on after that time by one
                  of the partners alone.
                </p>
                <p><b>Savings, investments and other income</b></p>
                <p>
                  Return all taxed income for the period 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}. If accounts
                  are made up for any other period, you should apportion figures in the sets of
                  accounts which between them cover the period 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}} (if apportionment gives a reasonable
                  approximation of the actual figures for that period)
                  or provide the actual figures themselves.
                </p>
                <p>
                  &nbsp;Return all untaxed income by entering the untaxed income of the accounting
                  period(s) ended in the period 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}.
                </p>
                <p><b>Foreign income</b></p>
                <p>
                  Return all foreign income which has had UK tax taken off (taxed income) for
                  the period 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}.
                </p>
                <p>
                  Return all foreign income which has had no UK tax taken off (untaxed income)
                  for the accounting period(s) ended in the period 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}.
                </p>
                <p><b>UK property income</b></p>
                <p>
                  Return all UK property income for the accounting period(s) ended in the period 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}.
                </p>
                <p><b>Disposal proceeds on chargeable assets</b></p>
                <p>
                  Return details of disposal proceeds on chargeable assets for the period  6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}.
                </p>
                <p><b>Investment partnerships</b></p>
                <p>
                  Where, exceptionally, a partnership does not carry on a trade or profession,
                  return all income including untaxed income and income from property, for the
                  period 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}.
                </p>
                <p>
                  If accounts are made up for any other period, you should apportion figures in the
                  sets of accounts that between them cover the period 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}, (if apportionment
                  gives a reasonable approximation of the actual
                  figures for that period) or provide the actual
                  figures themselves.
                </p>
                <p><b>Return period for &#39;CT Partnerships&#39;</b></p>
                <p>
                  If the partnership is a &#39;CT Partnership&#39; return details for all classes of the
                  partnership&#39;s income and so on (both untaxed and taxed) for the partnership&#39;s
                  accounting period (or periods) ending on a date in the period 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}.
                </p>
                <p><b>Particulars to be supplied by a &#39;CT Partnership&#39;</b></p>
                <p>
                  In the case of a 'CT Partnership' you should
                  supply the same particulars, accounts and tax
                  computations and fill in the same pages and boxes
                  as for a partnership which has members who
                  are individuals.
                </p>
                <h4>
                  <b>
                    Management expenses and loan relationships
                    and so on
                  </b>
                </h4>
                <p><b>Management expenses</b></p>
                <p>
                  If the partnership carries on an investment
                  business, not amounting to a trade, and a
                  member of the partnership is liable to
                  Corporation Tax, the partnership will need to
                  calculate the amount of the management expenses
                  it has incurred and to allocate a share of those
                  expenses (by reference to the partnership's
                  commercial profit sharing arrangements for the
                  tax return period) to the relevant partner(s).
                  You should set out your computation of the
                  partnership's management expenses and the
                  amount allocated to the relevant partner(s) in
                  box 3.116 'Additional information', on page 3
                  of the Partnership Tax Return.
                </p>
                <p><b>Loan relationships and so on</b></p>
                <p>
                  If the partnership has any profits, losses, income
                  or expenses from loan relationships, including
                  exchange fluctuations, or from certain derivative
                  contracts, and any member of the partnership is
                  liable to Corporation Tax, each company member
                  should calculate its own share of these amounts
                  separately from the other profits and losses of
                  the partnership business. Each company should
                  compute its share as if the whole loan and so on
                  was owed by or to that member, and not by or to
                  the partnership, then allocate itself a share of the
                  overall profit or loss according to the commercial
                  profit sharing ratio for the relevant period.
                  You should set out amounts so allocated to the
                  company partner(s) in box 3.116, 'Additional
                  information',on page 3 of the Partnership
                  Tax Return.
                </p>
                <p><b>Tonnage Tax</b></p>
                <p>
                  If the partnership carries on a shipping business
                  and any partner is a 'tonnage tax company' then
                  the partnership must fill in form CT600F on the
                  basis that the whole partnership business is carried
                  on by a tonnage tax company. This form must
                  accompany the Partnership Tax Return.
                </p>
                <h4>
                  A change in the residence status of
                  a partner
                </h4>
                <p>
                  Where an individual carrying on a business in
                  a partnership wholly or partly abroad becomes
                  or ceases to be UK resident, us treat this person
                  as having ceased and immediately recommenced
                  as a partner. The partnership profit must be
                  apportioned to and from the date of change of
                  residence, and, for the period of non-residence, the
                  partnership profit must be apportioned between
                  that arising in the UK and that arising overseas –
                  read the notes for Question 5.
                </p>
                <p>
                  For advice on this and other foreign aspects of
                  partnership taxation go to
                  <a href="http://www.gov.uk" target="_blank" class="link">www.gov.uk</a>&nbsp;
                  and search for &#39;HS380&#39;
                </p>
                <hr>
                <a href=""></a>
                <div id="bq1">
                  <b>Question 1</b>
                  <p>
                    <b>
                      Did the partnership receive any rent
                      or other income from UK property?
                    </b>
                  </p>
                  <p>If you do not tick the 'Yes' box, go to Question 2.</p>
                  <p>
                    Fill in the Partnership UK Property Pages if the partnership received
                    income from:
                  </p>
                  <ul>
                    <li>UK land and property</li>
                    <li>furnished holiday lettings in the UK or European Economic Area (EEA).</li>
                  </ul>
                  <p>
                    Go to <a href="http://www.hmrc.gov.uk/forms/sa801.pdf" target="_blank" class="link">
                      www.gov.uk/forms/
                      sa801.pdf
                    </a>
                  </p>
                </div>
                <p id="2p0"><b>Question 2</b></p>
                <p><b>Did the partnership have any foreign income?</b></p>
                <p>If you do not tick the 'Yes' box, go to Question 3.</p>
                <p>Fill in the Partnership Foreign Pages if the partnership received any:</p>
                <ul>
                  <li>interest</li>
                  <li>dividends</li>
                  <li>rental income</li>
                  <li>other income</li>
                </ul>
                <p>
                  from overseas savings and investments.Go to <a href="http://www.hmrc.gov.uk/forms/sa802.pdf" target="_blank" class="link">
                    www.gov.uk/forms/
                    sa802.pdf
                  </a>
                </p>
                <p id="3p0"><b>Question 3</b></p>
                <p>
                  <b>
                    Did the partnership business include a trade or profession at any time between
                    6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}} ?
                  </b>
                </p>
                <p>If you do not tick the 'Yes' box, go to Question 4.</p>
                <p>
                  Otherwise fill in boxes 3.1 to 3.117 as
                  appropriate. The notes starting on page PTRG 7
                  will help you.
                </p>
                <p id="4p0"><b>Question 4</b></p>
                <p> <b>Did the partnership dispose of any chargeable assets?</b> </p>
                <p>If you do not tick the 'Yes' box, go to Question 5.</p>
                <p>
                  Fill in the Partnership Disposals of Chargeable
                  Assets pages if the partnership disposed of any
                  chargeable assets unless they were 'exempt' assets.
                  <br>
                  Assets which are exempt from Capital Gains
                  Tax include:
                </p>
                <ul>
                  <li>motor cars</li>
                  <li>UK government stocks and certain corporate bonds</li>
                  <li>
                    life assurance policies and deferred annuity
                    contracts, unless at any time acquired for
                    actual consideration.
                  </li>
                </ul>
                <p>
                  Go to <a href="http://www.hmrc.gov.uk/forms/sa803.pdf" target="_blank" class="link">
                    www.gov.uk/forms/
                    sa803.pdf
                  </a>
                </p>
                <hr>
                <a name="5X"></a><a name="5Y"></a><a name="5Z"></a><a name="5p0">
                </a>
                <p id="5p0"><b>Question 5</b></p>
                <p> <b> During the return period has the partnership included any member who is: </b> </p>
                <ul>
                  <li>a company</li>
                  <li>not resident in the UK</li>
                  <li>
                    a partner in a business controlled and managed
                    abroad and who is not domiciled in the UK or is
                    not ordinarily resident in the UK?
                  </li>
                </ul>
                <p>If you do not tick the 'Yes' box, go to Question 6.</p>
                <p>
                  If your partnership includes any member who
                  is a company, any share of partnership profits
                  allocated to that member must be a share of
                  profits calculated using Corporation Tax rules.
                  Similarly, any share of profits allocated to any
                  member who is not a UK resident must be a share
                  of profits calculated using the rules appropriate
                  to non-residents.
                </p>
                <p>
                  For a mixed partnership, for example, a
                  partnership whose members include individuals
                  and companies, UK residents and non-residents,
                  or tonnage tax companies as well as other
                  partners, you may need 2 (or more) sets of
                  Partnership Statements and the appropriate pages.
                  For example, one set based on Income Tax rules
                  and the other based on Corporation Tax rules.
                  Shares of profit allocated to the individuals will be
                  allocated using the set based on Income Tax rules.
                  Shares of profit allocated to the companies will
                  be allocated using the set based on Corporation
                  Tax rules.
                </p>
                <p>
                  New rules in Finance Act 2014 made changes
                  to the way that mixed membership partnerships
                  (those comprising both individual and
                  non-individual members) should calculate their
                  profit or loss allocations for tax purposes.
                  HMRC has published detailed guidance, including
                  examples. Go to
                  <a href="http://www.gov.uk" target="_blank" class="link">www.gov.uk</a> and search
                  for ‘mixed membership partnerships’. If you're not sure about the application of these
                  new rules to your partnership then ask us or speak
                  to your tax adviser.
                </p>
                <p>
                  Where the partnership includes a non-resident
                  partner, generally you will need 2 sets of
                  Partnership Statements, one of worldwide profits and one of UK profits. However, if the partnership
                  is managed and controlled abroad, please return
                  the UK profits only (although a resident partner
                  will need to know their share of the overseas
                  profit). For more guidance go to <a href="http://www.hmrc.gov.uk/helpsheet380" target="_blank" class="link">www.gov.uk/helpsheet380</a>.
                </p>
                <p>
                  However, it may be that, given the particular
                  circumstances of your partnership, different sets
                  of calculations made in this way do not, in fact,
                  result in different figures of partnership profit.
                  Where this is the case you need to fill in only
                  one set of the relevant pages. Explain in
                  box 3.116 'Additional information', on page 3 of
                  the Partnership Tax Return, why the calculation
                  makes no difference to the overall partnership
                  profit. If you are completing this form on behalf
                  of a mixed partnership, speak to your tax adviser
                  before you do so.
                </p>
                <hr>
                <a name="6p0"></a>
                <p id="6p0"><b>Question 6</b></p>
                <p>
                  <b>
                    Are you completing this tax return
                    on behalf of a European Economic Interest
                    Grouping (EEIG)?
                  </b>
                </p>
                <p>
                  Although an EEIG is not generally constituted as
                  a partnership, its taxation treatment is similar.
                  Like a partnership, an EEIG is not itself liable
                  for UK tax on its profits, the profits are instead
                  taxable on the members. Accordingly, the
                  Partnership Tax Return has been prescribed for
                  completion by a grouping and you should take
                  references to partnerships as including groupings,
                  and references to partners as including members
                  of a grouping.
                </p>
                <p>
                  Where the grouping is registered in the UK, or
                  has an establishment registered in the UK, its
                  manager must make its tax return. Where there
                  is no registration in the UK of an EEIG or an
                  EEIG establishment, the member to whom the
                  Partnership Tax Return is addressed, should fill
                  it in.
                </p>
                <hr>
                <a name="6p1"></a>
                <p id="6p1"><b>Question 6.1</b></p>
                <p>
                  <strong>
                    During the return period, did the partnership export goods and or
                    services to individuals, enterprises or other organisations outside the UK?
                  </strong>
                </p>
                <p>&nbsp;</p>
                <p>
                  If the partnership exported goods and or services to individuals, enterprises or
                  other organisations outside the UK, tick the appropriate box or boxes. Responses
                  to this question will be collected by HMRC then shared with, and processed
                  solely by, the Department for International Trade (DIT).
                </p>
                <p>
                  HMRC will not use your response to this question
                  for any other purpose. The partnership’s business name and address will also be
                  shared by HMRC with DIT for communication purposes. For more information on
                  DIT,go to <a href="http://www.gov.uk/dit" class="link" target="_blank">www.gov.uk/dit</a>
                </p>
                <hr>
                <a name="7p0"></a><a name="7p0A"></a>
                <p id="7p0A"><b>Question 7</b></p>
                <p>
                  <b>
                    Did the partnership receive any other income which you have not already
                    included elsewhere in the Partnership Tax Return?
                  </b>
                </p>
                <p>
                  If you do not tick the 'Yes' box, go to the
                  Partnership Statement on page 6 of the
                  Partnership Tax Return.
                </p>
                <p>
                  Otherwise, check the following lists to see whether
                  you should include the income in the Partnership
                  Tax Return.
                </p>
                <p>Exclude:</p>
                <ul>
                  <li>Premium Bond, National Lottery and gambling prizes.</li>
                  <li>
                    Accumulated interest on National Savings & Investments
                    Certificates, including index-linked certificates.
                  </li>
                  <li>
                    Interest awarded by a UK court as part of an award of
                    damages for personal injury or death.
                  </li>
                  <li>
                    Receipts under a permanent health insurance policy.
                    Only include those that are trading receipts because
                    they are to meet the sick pay of employees.
                  </li>
                  <li>
                    Gains on UK life assurance policies, life annuities or
                    capital redemption policies, even if the policy or contract
                    was effected by, or is in, the partnership's name.
                    The special rules for taxing these gains mean that each
                    partner's share of the gain should be shown on their
                    personal tax return. If you need more help, ask us or
                    your tax adviser.
                  </li>
                  <li>Accrued income on transfer of securities.</li>
                  <li>Profits from selling certificates of deposit.</li>
                </ul>
                <p>Include:</p>
                <ul>
                  <li>
                    Interest, including interest in kind and alternative finance
                    receipts from UK banks or building societies.
                  </li>
                  <li>
                    Interest and alternative finance receipts from savings held
                    at an overseas branch of a UK bank or building society.
                  </li>
                  <li>
                    Interest distributions from UK authorised unit trusts,
                    open-ended investment companies and investment trusts.
                  </li>
                  <li>
                    National Savings & Investments First Option Bonds,
                    (no longer on sale) Guaranteed Income Bonds and
                    Guaranteed Growth Bonds.
                  </li>
                  <li>
                    Income from other National Savings & Investments
                    products (except accumulated interest on National
                    Savings & Investments Certificates).
                  </li>
                  <li>
                    Other savings income, disguised interest, annuities,
                    deeply discounted securities.
                  </li>
                  <li>
                    Dividends from UK companies, UK authorised unit trusts,
                    open-ended investment companies and investment trusts.
                  </li>
                  <li>
                    Property income distributions ('PIDs') from UK Real Estate
                    Investment Trusts ('REITs') and Property Authorised
                    Investment Funds ('PAIFs').
                  </li>
                  <li>Other distributions</li>
                  <li>UK stock dividends</li>
                  <li>
                    Other income of the partnership which is not included elsewhere
                    in the Partnership Tax Return.
                  </li>
                </ul>
                <p>If the partnership has:</p>
                <ul>
                  <li>
                    received cash as a result of a merger of two or more building societies,
                    or
                  </li>
                  <li>
                    received cash, or been issued with shares, or received both cash
                    and shares, as a result of either<ul style="list-style-type:none;">
                      <li>— a conversion of a building society to a company, or</li>
                      <li>— a take-over of a building society by a company</li>
                    </ul>
                  </li>
                </ul>
                <p>
                  there may be liability to either Income Tax or
                  Capital Gains Tax. The building society may be
                  able to tell you whether there is any tax liability.
                  If not, you should ask HMRC or your tax adviser.
                </p>
                <p>If the partnership received cash, then:</p>
                <ul>
                  <li>
                    if the payment is liable to Income Tax (which is
                    likely if it received it following a building society
                    merger), enter it in boxes 7.7 to 7.9
                    (or boxes 7.7A to 7.9A). If you are not sure
                    whether the amount is liable to Income Tax,
                    include it in boxes 7.7 to 7.9 (or boxes 7.7A
                    to 7.9A) then tick box 10.1 on page 8 of the
                    Partnership Tax Return and give full details
                    of the payment in box 3.116 'Additional
                    information'
                  </li>
                  <li>
                    if the payment is liable to Capital Gains Tax
                    (which is likely if the partnership received it
                    following a conversion or takeover of a building
                    society), details of that sum should be given in
                    the Partnership Disposal of Chargeable
                    Assets pages.
                  </li>
                </ul>
                <p>
                  If the partnership received shares following a
                  building society takeover or conversion, then you
                  may need to supply details only when it disposes
                  of those shares. Ask HMRC for details.
                </p>
                <p><b>Box 7.9A</b></p>
                <p>
                  Copy the figure in box 7.9A to box 13 of the
                  Partnership Statement on page 6.
                </p>
                <hr>
                <a name="8p0"></a>
                <p id="8p0"><b>Question 8</b></p>
                <p><b>Are the details on the front of the Partnership Tax Return wrong?</b></p>
                <p>If you do not tick the 'Yes' box, go to Question 9.</p>
                <p>
                  If you tick the 'Yes' box, make the corrections on
                  the front of the Partnership Tax Return.
                </p>
                <p>
                  Tell us of a change of address within the last 12
                  months by putting the words ‘NEW ADDRESS’
                  alongside the address box on page 1 of the
                  Partnership Tax Return. Alternatively, you
                  can provide your new address in box 3.116,
                  ‘Additional Information’ on page 3 of the
                  Partnership Tax Return.
                </p>
                <hr>
                <a name="9p0"></a>
                <p id="9p0"><b>Question 9</b></p>
                <p>
                  <strong>
                    Please give a daytime phone
                    number in boxes 9.1 and 9.2
                  </strong>
                </p>
                <a name="9p1"></a><a name="9p2"></a><a name="9p3"></a><a name="9p1-3">
                </a>
                <p><b>Boxes 9.1 to 9.3</b></p>
                <p>
                  Please put your, or your adviser's, phone number
                  in the appropriate box. Please include the area
                  code. (If you give your adviser's phone number
                  please also give the adviser's name and address
                  in box 9.3.)
                </p>
                <hr>
                <a name="10p0"></a>
                <p id="10p0"><b>Question 10</b></p>
                <p><b>Other Information</b></p>
                <p><b>Provisional figures</b></p>
                <a name="10p1"></a>
                <p><b>Box 10.1</b></p>
                <p>
                  Do not delay sending the Partnership Tax
                  Return to us just because you do not have all the
                  information you need. You must do your best to
                  obtain the information, but if you cannot provide
                  final figures by the time you need to send the
                  Partnership Tax Return, then provide provisional
                  amounts. It is important that us receive the
                  Partnership Tax Return by the due date.
                </p>
                <p>
                  Tick box 10.1 and say in box 3.116 'Additional
                  information', on page 3 of the Partnership Tax
                  Return, which figures are provisional
                  (refer to appropriate box numbers in the
                  Partnership Tax Return). It would also help if,
                  in box 3.116, you:
                </p>
                <ul>
                  <li>say why you could not give final figure(s), and</li>
                  <li>
                    give an approximate date on which you expect
                    to give your final figures.
                  </li>
                </ul>
                <p>
                  If you use provisional figures you must have
                  taken all reasonable steps to get the final figures,
                  and make sure that they are sent as soon as they
                  are available.We do not regard pressure of
                  work either on you or your tax adviser, or the
                  complexity of the partnership's affairs, as reasons
                  for using a provisional figure.
                </p>
                <p>
                  You must make sure that any provisional figures
                  that you do include are reasonable and take
                  account of all information available to you. If you
                  carelessly submit a provisional figure that is either
                  inaccurate or unnecessary, each partner may have
                  to pay a penalty.
                </p>
                <p><b>Estimates (including valuations)</b></p>
                <p>
                  In some situations you may need to provide an
                  estimated figure or a valuation that you do not
                  intend to amend at a later date. Broadly, this will
                  be the case where:
                </p>
                <ul>
                  <li>
                    a valuation is required (for example, of an asset at a certain date
                    for the purposes of calculating Capital Gains Tax liability)
                  </li>
                  <li>
                    there is inadequate information to enable you to arrive at a reliable
                    figure (for example, where the records concerned have been lost or destroyed)
                  </li>
                  <li>
                    although there is inadequate information to
                    arrive at a precise figure, a reliable estimate
                    can be made (for example, where the private
                    proportion of an expense such as motoring
                    expenses has been calculated on the basis of
                    the detailed records of a representative
                    sample period).
                  </li>
                </ul>
                <p>
                  Identify any valuations that you have
                  used, either by ticking the appropriate box in the
                  Partnership Tax Return, and providing the details
                  which the notes ask for, or by identifying the
                  figure in box 3.116 'Additional information' on
                  page 3 of the Partnership Tax Return and giving
                  details of the valuation. Do not tick box 10.1.
                </p>
                <p>
                  Also identify any figures in the Partnership Tax Return which
                  may not be very reliable, explaining, where appropriate, how the figure
                  has been arrived at. But if you are including an estimate which, while not
                  a precise figure, is sufficiently reliable to enable you to make an accurate
                  Return, there is no need to mention this.
                </p>
                <hr>
                <p><b>Disclosure of tax avoidance schemes</b></p>
                <a name="10p2"></a><a name="10p3"></a><a name="10p2-3"></a>
                <p id="10p2-3"><b>Boxes 10.2 and 10.3</b></p>
                <p>
                  Enter in box 10.2  promoter reference number (PRN) for the monitored promoter
                  of any scheme or arrangement the partnership
                  has used to obtain a tax or National Insurance
                  contributions (NICs) advantage now or in the future. In most cases you’ll have received
                  the PRN from the scheme promoter but in some cases you may have received the PRN
                  from an intermediary or from another client of the promoter.
                </p>
                <p>
                  Enter in box 10.2 the scheme reference number (SRN) of any scheme or arrangement
                  the partnership has used to obtain a tax or NICs advantage now or in the future. In most cases you will have received the SRN
                  from the scheme promoter on form AAG6, '‘Disclosure of Tax Avoidance Schemes -
                  Notification of Scheme Reference Number’, but in some cases you may have
                  received the SRN from HM Revenue &amp; Customs.
                </p>
                <p>Advantage here means either:</p>
                <ul>
                  <li>relief or increased relief from</li>
                  <li>repayment or increased repayment of</li>
                  <li>the avoidance or reduction of a charge to</li>
                  <li>the avoidance of an assessment or a possible assessment to</li>
                  <li>
                    the deferral of any payment or the advancement of any repayment
                    of
                  </li>
                  <li>
                    the avoidance of any obligation to deduct or
                    account for Income Tax or National
                    Insurance contributions.
                  </li>
                </ul>
                <p>
                  Put each number (up to 3) on a separate line. If you have both PRNs and SRNs, put your PRN
                  in the first row and your SRN(s) below.
                </p>
                <p>
                  If you were party to more than 3 schemes, you
                  must report details of additional schemes:
                </p>
                <ul>
                  <li>
                    for which you have been given SRNs using
                    form AAG4
                  </li>
                  <li>
                    for which you have been given PRNs relating
                    to the scheme promoter using form AAG4(PRN)
                  </li>
                </ul>
                <p>
                  Forms AAG4 and AAG4 are available from
                  <a href="http://www.gov.uk/government/collections/tax-avoidance-schemes-forms" target="_blank" class="link">www.gov.uk/government/collections/tax-avoidance-schemes-forms</a>.
                </p>
                <p>
                  HMRC never approves tax avoidance schemes. If
                  you fail to tell us the SRN or PRN when required
                  to do so, you’ll have to pay a penalty.
                </p>
                <p>
                  If a tax or National Insurance contributions advantage arose in the {{this.userService.Previous_year}} to {{this.userService.Present_year}}
                  tax year, put {{this.userService.Present_year}}
                  in box 10.3. If no advantage arose in {{this.userService.Previous_year}} to {{this.userService.Present_year}} but an advantage is expected to arise in
                  a later year, put the earliest future year in which
                  that advantage is expected to arise in
                  box 10.3, for example 2024. Even if you’ve
                  reported the SRN or PRN in a previous return,
                  you must continue to report it until there’s no
                  longer an advantage (for example, until losses
                  produced by the scheme have been used up).
                </p>
                <p>
                  For more information on the rules for disclosure
                  of tax avoidance schemes and arrangements, go
                  to
                  <a href="http://www.gov.uk/government/publications/promoters-of-tax-avoidance-schemes-guidance" target="_blank" class="link">
                    www.gov.uk/government/publications/promoters-of-
                    tax-avoidance-schemes-guidance
                  </a>.
                </p>
                <p>
                  You can find more information about monitored
                  promoters at <a href="http://www.gov.uk/government/publications/promoters-of-tax-avoidance-schemes-guidance" target="_blank" class="link">www.gov.uk/government/publications/promoters-of-tax-avoidance-schemes-guidance</a>.
                </p>
                <div *ngIf="this.userService.Present_year < '2024'">
                  <p><strong>Coronavirus support scheme payments declaration</strong></p>
  <a name="10p4"></a>
  <p id="tenfour"><strong>Box 10.4</strong></p>
                </div>
               
                <p>
                  If this partnership received any coronavirus support scheme payments put a tick
                  in box 10.4 to confirm that these payments have been included as taxable income
                  in the relevant boxes of this tax return for the purposes of calculating the
                  partnership profits.
                </p>
                <p>
                  Read the notes in this guide or in the notes for the relevant supplementary pages
                  of the Partnership Tax Return for more details about where to include these
                  payments.
                </p>
                <hr>
                <a name="11p0"></a>
                <p id="11p0"><b>Question 11</b></p>
                <p><b>Declaration</b></p>
                <a name="11p1"></a><a name="11p2"></a><a name="11p3"></a><a name="11p4">
                </a><a name="11p1-4"></a>
                <p id="elevenfour"><b>Boxes 11.1 to 11.4</b></p>
                <p>
                  Read the Declaration carefully. Tick the boxes
                  opposite the pages you have filled in. Enter in
                  box 11.1 the number of additional Partnership
                  Statements you are sending as part of the
                  Partnership Tax Return. You must complete a
                  summary for each partner who was a member
                  of the partnership during each of the periods for
                  which information is included in the Partnership
                  Tax Return. Enter in box 11.2 the number of
                  partners who were in this partnership during the
                  period for which information has been returned.
                </p>
                <p><b>If you have filled in the Partnership Tax Return</b></p>
                <p>
                  Sign and date the Declaration in box 11.3 and
                  print your name under your signature. Send back
                  any supplementary pages that you have filled in.
                </p>
                <p>
                  <b>
                    If you have had the Partnership Tax Return
                    filled in for you by someone else
                  </b>
                </p>
                <p>
                  If you have had the Partnership Tax Return filled
                  in for you by someone else acting on your behalf,
                  you must still sign the return yourself to confirm
                  to us that, to the best of your knowledge, it is
                  correct and complete. This applies whether you
                  have paid for the services of an accountant or
                  other tax practitioner, or have simply had help
                  from a friend or relative.
                </p>
                <p>
                  Always allow sufficient time for
                  checking and signing the return if it has been
                  filled in by someone on your behalf (particularly
                  if you are likely to be abroad near the deadline for
                  sending the return back to us). Failure to make
                  appropriate arrangements could mean that you
                  miss the deadline and that We charge you and
                  your partners penalties and interest on any tax
                  paid late.
                </p>
                <p>
                  <b>
                    Exceptional circumstances in which someone
                    other than the taxpayer can sign a Partnership
                    Tax Return
                  </b>
                </p>
                <p>
                  For persons who are mentally incapable of
                  understanding the Partnership Tax Return it
                  may be filled in and signed on their behalf by the
                  following authorised persons:
                </p>
                <ul>
                  <li>a Receiver appointed by the Court of Protection (England and Wales)</li>
                  <li>
                    a Curator Bonis appointed by the Office of the Accountant of Court
                    (Scotland)
                  </li>
                  <li>
                    a Controller appointed by the Court of Care and Protection (Northern
                    Ireland)
                  </li>
                  <li>
                    an Attorney appointed under an Enduring Power, registered in the appropriate
                    court
                  </li>
                  <li>any person so authorised by any of the above courts.</li>
                </ul>
                <p>
                  If you have not previously provided us with
                  evidence of your appointment, please enclose the
                  relevant documentation with the return.
                </p>
                <hr>
                <h4><b>Payment of tax on partnership profits</b></h4>
                <p>
                  Each partner is individually responsible for paying
                  tax due on their share of the partnership profits.
                  Payment has to be made to the individual partners
                  Self Assessment account. Usually partners will pay
                  their own liability.
                </p>
                <p>
                  Exceptionally a partnership may wish to make one
                  payment on behalf of some or all of the partners.
                  When making a partnership payment you must
                  provide full details of how the payment is to be
                  apportioned between the individual partners.
                </p>
                <h4><b>Paying HMRC</b></h4>
                <p>You can pay by one of the following methods.</p>
                <ul>
                  <li>Online or telephone banking (Faster Payments)</li>
                  <li>CHAPS</li>
                  <li>Direct or credit card online</li>
                  <li>at your bank or building society</li>
                  <li>Bacs</li>
                  <li>
                    Direct Debit (if you’ve set one up with
                    HMRC before)
                  </li>
                  <li>
                    by cheque through the post – A maximum of 99
                    payslips can accompany each cheque, a totalled
                    list would also be helpful
                  </li>
                </ul>
                <p>
                  We recommend that you make your payment
                  electronically as this is more efficient and secure.
                  For more information, go to <a href="http://www.gov.uk/paytaxbill" target="_blank" class="link">www.gov.uk/paytaxbill</a>
                </p>
               
                  <div *ngIf="this.userService.Present_year < '2024'">
                  <p><b>Direct Debit</b></p>
  <p>
    To set up a Direct Debit payment, go to
    <a href="http://www.hmrc.gov.uk/payinghmrc/selfassessment.htm" target="_blank" class="link">hmrc.gov.uk/payinghmrc/selfassessment.htm</a> and select
    Paying by Direct Debit.
  </p>
  <p>
    If you don't have access to Self Assessment Online you will first have
    to register.
  </p>
  <p>
    You can set up a Direct Debit to make a single payment
    for 31 January and 31 July or set up a Budget Payment
    Plan to make regular instalments towards a
    future liability.
  </p>
  <p>
    <b>Important</b> – The partner setting up the Direct Debit arrangement
    must be able to authorise Direct Debits from the bank/building society account
    on their own.
  </p>
  <p><b>Debit or credit card over the Internet using BillPay</b></p>
  <p>
    Pay over the Internet using BillPay at
    <a class="link" href="http://www.billpayment.co.uk/hmrc" target="_blank">
      www.billpayment.co.uk/hmrc
    </a>
  </p>
  <p>
    Please note there is a non-refundable transaction charge for payments
    by credit card and HMRC do not accept American Express or Diners Club cards.
  </p>
  <p><b>BACS Direct Credit, Internet or phone banking</b></p>
  <p>
    You will need to provide the following bank account details if you pay
    to the Accounts Office:
  </p>

  <table width="350" border="0" cellspacing="0" cellpadding="0">
    <tr align="left" valign="middle">
      <td height="25" colspan="2"><strong>Accounts Office Cumbernauld</strong></td>
    </tr>
    <tr align="left" valign="middle">
      <td width="50%" height="25">Sort code</td>
      <td width="50%" height="25">08 32 10</td>
    </tr>
    <tr align="left" valign="middle">
      <td width="50%" height="25">Account number</td>
      <td width="50%" height="25">12001039</td>
    </tr>
    <tr align="left" valign="middle">
      <td width="50%" height="25">Account name</td>
      <td width="50%" height="25">HMRC Cumbernauld</td>
    </tr>
    <tr align="left" valign="middle">
      <td height="35" colspan="2"><strong>Accounts Office Shipley</strong></td>
    </tr>
    <tr align="left" valign="middle">
      <td width="50%" height="25">Sort code</td>
      <td width="50%" height="25">08 32 10</td>
    </tr>
    <tr align="left" valign="middle">
      <td width="50%" height="25">Account number</td>
      <td width="50%" height="25">12001020</td>
    </tr>
    <tr align="left" valign="middle">
      <td width="50%" height="25">Account name</td>
      <td width="50%" height="25">HMRC Shipley</td>
    </tr>
  </table>

  <p>
    Please make sure that you show the individual partner's
    Self Assessment reference also referred to as the UTR.
    Each partner can find this on the payslip at the foot of
    the Statement of Account or Self Assessment reminder.
    The reference number is made up of 10 digits followed
    by the letter 'K', for example 2345678654K.
  </p>
  <p>
    You can find more information and help on
    paying HMRC at <a href="http://www.hmrc.gov.uk/payinghmrc/" target="_blank" class="link">hmrc.gov.uk/payinghmrc</a> or by
    phoning the Payment Enquiry Helpline on
    0845 366 7816.<br>
    <br>
    Please see below for the different ways you
    can pay.
  </p>
  <p><b>Bank Giro</b></p>
  <p>
    Take your personal payslip with payment to a branch of your own bank.
    Cheques should be made payable to 'HM Revenue &amp; Customs only' followed by
    your individual Self Assessment reference number.
  </p>
  <p><b>At a Post Office</b></p>
  <p>
    Take your personal payslip and payment (including debit card) to any
    participating Post Office. Cheques should be made payable to 'Post Office
    Ltd'.
  </p>
  <p><b>CHAPS</b></p>
  <p>
    If you would like to use this payment method, please email your Accounts
    Office at:
  </p>
  <ul>
    <li><b>Accounts Office Cumbernauld</b> aoc.directpayments&#64;hmrc.gsi.gov.uk</li>
    <li><b>Accounts Office Shipley</b> aos.directpayments&#64;hmrc.gsi.gov.uk</li>
  </ul>
  <p>who will then contact you with further guidance.</p>
  <p><b>By post</b></p>
  <p>
    If exceptionally you are unable to pay by one of the
    methods shown above, you can send to HMRC a cheque with
    your payslip. If the cheque is for more than one partner,
    you need to include separate payslips for each
    individual partner.
  </p>

                </div>

                <hr>
                <p><b>What happens if the Return is incorrect?</b></p>
                <p>
                  As nominated partner you are responsible for the accuracy of the Partnership
                  Tax Return.
                </p>
                <p>If it is incorrect and:</p>
                <ul>
                  <li>
                    the partners have paid too much tax, we’ll repay it to them plus interest on
                    the amount overpaid
                  </li>
                  <li>
                    the partners have not paid enough tax, we’ll
                    ask for further tax – we’ll may require them to
                    pay interest from the original due date and a late
                    payment penalty
                  </li>
                </ul>
                <p>
                  If you send the Partnership Tax Return and then
                  find out that you made a mistake, let us know
                  at once.
                </p>
                <p>
                  If the Partnership Tax Return is incorrect because
                  you have failed to take reasonable care, each
                  partner may face a maximum penalty ranging
                  from 30% to 100% of the difference between
                  the correct tax due on that partner's share of
                  partnership profits and the amount due on figures
                  the partnership has provided. This could be up to
                  200% if the income or gains not being declared
                  arose outside the UK. In some circumstances
                  We may also prosecute the partners for
                  deliberate errors.
                </p>
                <h4><b>If We gave you notice to make the Partnership Tax Return after 31 July {{this.userService.Present_year}}</b></h4>
                <p>
                  If we gave you the notice requiring you to make
                  the Partnership Tax Return after 31 July {{this.userService.Present_year}}&nbsp;
                  but on or before 31 October {{this.userService.Present_year}} (you may
                  have slightly longer if the partnership includes a
                  company as a partner), we must receive it from
                  you by the later of:
                </p>
                <ul>
                  <li>
                    3 months from the date we gave you the notice
                    for a paper Partnership Tax Return, or
                  </li>
                  <li>
                    31 January {{this.userService.Next_year}} for an online Partnership
                    Tax Return.
                  </li>
                </ul>
                <p>
                  If we gave you the notice requiring you to make
                  the Partnership Tax Return after 31 October {{this.userService.Previous_year}}
                  (you may have slightly longer if the partnership
                  includes a company as a partner), we must receive
                  it from you 3 months from the date we&nbsp; gave the
                  notice whether you send HMRC a paper Partnership
                  Tax Return or an online Partnership Tax Return.
                </p>
                <p>
                  The notice requiring you to make the Partnership
                  Tax Return is 'given' on the day it is delivered to
                  you. We will normally assume, for example, for
                  the purpose of charging automatic penalties for
                  the late submission of the Partnership Tax Return,
                  that delivery will have taken place no more than
                  7 days after the date of issue shown on the front
                  of the return.
                </p>
                <hr>
                <p>
                  <b>
                    Filing dates if the partnership includes a
                    company as a partner
                  </b>
                </p>
                <p>
                  If a partnership includes a company as a partner,
                  the filing date for the Partnership Tax Return will
                  depend on the relevant period. This is normally
                  the period or periods to which the partnership
                  makes up accounts and will end on the accounting
                  date(s) of the partnership ending in the tax year.
                </p>
                <p>The relevant period will be the same as the tax year:</p>
                <ul>
                  <li>where the partnership makes up accounts to 5 April, </li>
                  <li>where there are no partnership accounts ending in the tax year,</li>
                  <li>for investment partnerships that do not carry on a trade or profession.</li>
                </ul>
                <p>
                  <b>
                    If your partnership includes any member
                    who is a partnership
                  </b>
                </p>
                <p>
                  If your partnership includes any member who
                  is a partnership, to enable the partners of that
                  partnership (the ‘indirect’ partners of your
                  partnership) to complete their own tax returns,
                  apart from the exception detailed below, you must
                  calculate their allocation of profits and losses on
                  the basis that each of the following assumptions
                  apply. That the partner is a:
                </p>
                <ul>
                  <li>
                    (a) UK resident individual
                  </li>
                  <li>
                    (b) non-UK resident individual
                  </li>
                  <li>
                    (c) UK resident company
                  </li>
                  <li>
                    (d) non-UK resident company
                  </li>
                </ul>
                <p>
                  An exception to this is where you provide the
                  name of every indirect partner during any part
                  of the period and details of whether that partner
                  was an individual, company, partnership (or
                  something else) and their residence status. In that
                  case only those calculations under (a) to (d) above
                  that are relevant to those partners need be carried
                  out.
                </p>
                <p>
                  When completing your partnership statements,
                  if more than one of (a) to (d) apply, complete a
                  partnership statement for that partnership member
                  to show one set of calculations and submit an
                  attachment showing the profits and losses arising
                  from the other calculations.
                </p>
                <p>
                  A copy of the partnership statement and
                  any attachment, should be provided to that
                  partnership member.
                </p>
                <p>
                  For the exception to apply, the names and details
                  should be provided in the ‘Additional information’
                  box, box 3.116, or as an attachment to your return.
                </p>
                <p>
                  Where the income of a partnership is wholly
                  investment and the partnership is unable to provide
                  all the indirect partner information required under
                  12AB (1C) TMA70 and the reporting partnership
                  has no indirect non-UK resident members that have
                  a UK tax liability on this income, they do not have
                  to provide calculations on either of the non-resident
                  bases to HMRC.
                </p>
                <p>
                  If one or more partnership statements covering
                  non-resident calculations are not provided, the
                  partnership’s tax return will be regarded as
                  reporting nil UK taxable profits on the base(s)
                  for which statements are not provided.
                </p>
                <p>
                  This does not affect the ongoing requirements in
                  respect of direct partners, or (where full information
                  is not available in respect of the indirect partners)
                  the requirements to provide partnership statements
                  covering the calculations on both UK resident bases
                  (as if the partnership business were carried on by a
                  UK resident individual, and as if it were carried on
                  by a UK resident company).
                </p>
                <p>
                  <b>
                    If your partnership is a member of
                    another partnership
                  </b>
                </p>
                <p>
                  If your partnership is a member of another
                  partnership, any profits or losses that you receive
                  from that partnership must be treated as received
                  from a separate source to your own partnership
                  profits and losses for the purposes of completing
                  partnership statements.
                </p>
                <p>
                  Carry out the necessary calculations of the profits
                  and losses for your own business (see above, and
                  also Question 5 on PTRG 6, for further details on
                  the application of Income Tax rules, Corporation
                  Tax rules and non-residency) and complete
                  partnership statements for each partner to include
                  their allocation of those profits or losses.
                </p>
                <p>
                  Also complete additional partnership statements
                  showing their allocation of the profits or losses
                  from the separate source, using the relevant
                  calculations provided by the partnership of which
                  your partnership is a member.
                </p>
                <h4>Relevant periods ending on or between 6 April {{this.userService.Previous_year}} and 31 January {{this.userService.Present_year}}</h4>
                <p>The filing date for the Partnership Tax Return will be: </p>
                <ul>
                  <li>
                    31 October {{this.userService.Present_year}} for a paper Partnership Tax
                    Return
                  </li>
                  <li>
                    31 January {{this.userService.Next_year}} for an online Partnership
                    Tax Return
                  </li>
                </ul>
                <h4>Relevant periods ending on or between 1 February {{this.userService.Present_year}} and 5 April {{this.userService.Next_year}}</h4>
                <p>The filing date for the Partnership Tax Return will be: </p>
                <ul>
                  <li>
                    9 months from the end of the relevant period
                    for a paper Partnership Tax Return
                  </li>
                  <li>
                    the first anniversary of the end of the relevant
                    period for an online Partnership Tax Return
                  </li>
                </ul>
                <p>
                  You will always have at least 3 months from
                  the date HMRC give the notice to file the Partnership
                  Tax Return.
                </p>
                <hr>
                <table border="0" cellpadding="5" cellspacing="0" style="text-align: justify; border: 1px #000 solid;" width="90%">
                  <tr>
                    <td>
                      These notes are for guidance only, and reflect the position
                      at the time of writing. They do not affect any rights of appeal.
                      Any subsequent amendments to these notes can be found at
                      <a class="link" href="http://www.hmrc.gov.uk" target="_blank">
                        www.hmrc.gov.uk
                      </a>
                    </td>
                  </tr>
                </table>

              </div>
            </div>
          </div>
          <!--SA800TP starts here......-->
          <div id="SA800TP" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <table cellpadding="0" cellspacing="0" style="border-collapse: collapse" width="98%">
                  <tr>
                    <td align="right" width="93%">
                      <p style="text-align: right;">
                        <span style="font-size: 25px;" class="headingSA">TRADING AND PROFESSIONAL INCOME</span><br />
                        <span style="font-size: 15px;" class="headingSA"> Tax year 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}({{this.userService.Currenttax_shortyear}}) </span>
                      </p>
                    </td>
                    <td width="7%">
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td align="right" bgcolor="#00ABBC" width="93%">
                      &nbsp;
                    </td>
                    <td bgcolor="#004C86" width="7%">
                      &nbsp;
                    </td>
                  </tr>
                </table>
                <br />
                <table cellpadding="2" cellspacing="0" width="98%">
                  <tr>
                    <td valign="top" width="23%">
                      <a name="top"></a><a class="link" href="help#3p2">Box 3.2</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p4-5">Boxes 3.4 and 3.5</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p7">Box 3.7</a>
                    </td>
                    <td valign="top" width="27%">
                      <a class="link" href="help#3p8">Box 3.8</a>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" width="27%">
                      <a class="link" href="help#3p9">Box 3.9</a>
                    </td>
                    <td valign="top" width="23%">
                      <a class="link" href="help#3p10">Box 3.10</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p11">Box 3.11</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p12-13">Boxes 3.12 and 3.13</a>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p13a-70">Boxes 3.13A to 3.23, 3.68 and 3.70</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p14">Box 3.14 and 3.14A</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p16">Box 3.16</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p16A">Box 3.16A</a>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p18-18A">Box 3.18, 3.18A And 3.18B</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p20">Box 3.20</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p24">Box 3.24</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p25">Box 3.25</a>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p26">Box 3.26</a>
                    </td>
                    <td valign="top" width="27%">
                      <a class="link" href="help#3p27-28">Boxes 3.27 and 3.28</a>
                    </td>
                    <td valign="top" width="23%">
                      <a class="link" href="help#3p29">Box 3.29</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p30-63">Boxes 3.30 to 63</a>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p30">Box 3.30</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p31">Box 3.31</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p32">Box 3.32</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p33">Box 3.33</a>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p34">Box 3.34</a>
                    </td>
                    <td valign="top" width="27%">
                      <a class="link" href="help#3p35">Box 3.35</a>
                    </td>
                    <td valign="top" width="23%">
                      <a class="link" href="help#3p36">Box 3.36</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p37">Box 3.37</a>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p38">Box 3.38</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p39">Box 3.39</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p40">Box 3.40</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p41">Box 3.41</a>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p42">Box 3.42</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p43">Box 3.43</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p44">Box 3.44</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p45">Box 3.45</a>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p46">Box 3.46</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p47">Box 3.47</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p48">Box 3.48</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p50">Box 3.50</a>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p51">Box 3.51</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p52">Box 3.52</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p53">Box 3.53</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p54">Box 3.54</a>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p55">Box 3.55</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p56">Box 3.56</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p57">Box 3.57</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p58">Box 3.58</a>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p59">Box 3.59</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p60">Box 3.60</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p61">Box 3.61</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p62">Box 3.62</a>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p63">Box 3.63</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p66">Box 3.66</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p67">Box 3.67</a>
                    </td>
                    <td valign="top" width="23%">
                      <a class="link" href="help#3p71">Box 3.71</a>
                    </td>

                  </tr>
                  <tr>
                    <td valign="top" width="25%"><a class="link" href="#3p82">Box 3.82</a></td>
                    <td valign="top" width="27%">
                      <a class="link" href="help#3p83-93">Boxes 3.83, 3.84 and 3.93</a>
                    </td>
                    <td valign="top" width="23%">
                      <a class="link" href="help#3p97">Box 3.97</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p98">Box 3.98</a>
                    </td>

                  </tr>
                  <tr>
                    <td valign="top" width="27%">
                      <a class="link" href="help#3p99-115">Boxes 3.99 to 3.115</a>
                    </td>
                    <td valign="top" width="25%">
                      <a class="link" href="help#3p117">Box 3.117</a>
                    </td>
                    <td valign="top" width="27%" *ngIf="this.userService.Present_year < '2024'">
                      <a class="link" href="help#3p118">Box 3.118</a>
                    </td>
                  </tr>
                </table>
                <hr />
                <p>
                  <b>Filling in the Partnership Trading Pages</b>
                </p>
                <p>
                  You must fill in the Partnership Trading pages (pages 2 to 5 of the Partnership
                  Tax Return) if, at any time in the period 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}, the partnership
                  carried on a trade or profession.
                </p>
                <p>
                  In some circumstances you may have to fill in more than one set of Partnership Trading
                  pages. Read the notes on return period, starting on page PTRG 5 to identify the
                  return period (or periods) appropriate to your partnership before attempting to
                  fill in the Partnership Trading pages.
                </p>
                <p>
                  If the partnership carries on a farming or similar business, go to <a href="http://www.gov.uk"
                                                                                        target="_blank" class="link">www.gov.uk</a> and search for ‘HS224’. It explains
                  the methods of farm stock valuation that we accept.
                </p>
                <p>
                  The partnership should have records of all its business transactions. You must keep
                  these until at least 31 January 2030 and show them to us if you are asked to do
                  so. For more information about record keeping go to <a href="http://www.gov.uk/businesstaxrecords"
                                                                         target="_blank" class="link">www.gov.uk/businesstaxrecords</a>
                </p>
                <p>
                  <b>If the partnership had more than one trade or profession</b>
                </p>
                <p>
                  You must fill in a set of Partnership Trading pages for each trade or profession
                  carried on by the partnership. Either photocopy blank pages that you already have
                  or go to <a href="http://www.gov.uk/taxreturnforms" target="_blank" class="link">www.gov.uk/taxreturnforms</a>
                  for more copies.
                </p>
                <p>
                  <b>Before you start</b>
                </p>
                <p>
                  The business profit for any business is the difference between:
                </p>
                <ul>
                  <li>the income of the business and</li>
                  <li>allowable business expenses.</li>
                </ul>
                <p>
                  Most smaller businesses can choose to record their business income and expenses
                  (over the tax year) in one of the following ways:
                </p>
                <ul>
                  <li>
                    <strong>Cash basis</strong> – record money when it actually comes in and goes out
                    of your business
                  </li>
                  <li>
                    <strong>Traditional accounting</strong> (accruals basis) – record income when it's
                    earned and expenses when they're incurred
                  </li>
                </ul>
                <p>
                  Any business not eligible for the cash basis must use traditional accounting (accruals
                  basis).
                </p>
                <p>
                  There's also some information on eligibility for the cash basis in the notes to
                  box 3.9 of this guide.
                </p>
                <p>
                  For more information on the cash basis and who can or cannot use it, go to <a href="http://www.gov.uk"
                                                                                                target="_blank" class="link">www.gov.uk</a> and search for ‘HS222’.
                </p>
                <p>
                  These Partnership Trading pages will help you to work out your taxable business
                  profit and will provide us with the information we need to process the Partnership
                  Tax Return.
                </p>
                <p>
                  Work through the following steps for each business:
                </p>
                <p>
                  <b>Step 1</b> Work out the return period for the business using the notes starting
                  on page PTRG 4 of this guide.
                </p>
                <p>
                  <b>Step 2</b> Work out how many 'accounts' fall within that period.
                </p>
                <p>
                  <b>Step 3</b> For each set of Partnership Trading pages:
                </p>
                <ul>
                  <li>provide business details in boxes 3.1 to 3.13</li>
                  <li>
                    fill in boxes 3.13A to 3.23 if capital allowances and balancing charges are to be
                    included in boxes 3.25 and 3.24 or 3.70 and 3.68
                  </li>
                  <li>
                    if your annual turnover was below £85,000 (or would have been if you had traded
                    for a whole year), give details of income and total expenses and work out the partnership’s
                    taxable profit in boxes 3.24 to 3.26 (except if you're within the Managing Serious
                    Defaulters (MSD) programme, see page PTRG 15)
                  </li>
                  <li>
                    if your annual turnover was between £85,000 and £15million (or would have been if
                    you had traded for a whole year), fill in boxes 3.27 to 3.73.Also register for VAT
                  </li>
                  <li>
                    if your annual turnover was more than £15million, show the turnover, allowable expenses
                    and net profit in boxes 3.24, 3.25 and 3.26. Also attach the partnership accounts
                    and computations and send them with the return
                  </li>
                  <li>in all cases, fill in box 3.83 or 3.84 and the other boxes on page 5 as appropriate</li>
                  <li>
                    if you have a balance sheet, provide information about your business assets or liabilities
                    in boxes 3.99 to 3.115 unless your annual turnover exceeded £15million and you are
                    therefore attaching accounts and computations
                  </li>
                </ul>
                <p>
                  The notes will help you. They use some technical terms such as ‘trade’, and so on.
                  They explain these terms as fully as possible, but they're not a comprehensive guide
                  in all circumstances.
                </p>
                <p>
                  If you are in doubt about the correct tax treatment of a particular item, ask or
                  your tax adviser. There is also a glossary of terms page PTRG 23 and 24. There is
                  a helpful factsheet on what you need to know about keeping records go to <a href="http://www.gov.uk/government/publications/keeping-records-for-your-tax-return-rk-bk1"
                                                                                              target="_blank" class="link">www.gov.uk/ government/publications/keeping-records-for-your-tax-return-rk-bk1</a>.
                </p>
                <p style="text-align: right">

                </p>
                <p>
                  <b>Providing details of income and expenses</b>
                </p>
                <p>
                  For most businesses the information on the Partnership Trading pages will enable
                  you to present a full and fair picture of your business. If there are any points
                  needing further explanation, provide details in box 3.116 ‘Additional information’,
                  on page 3 of the Partnership Tax Return. Do not send accounts.
                </p>
                <p>
                  In some larger or more complex businesses additional information given on the Partnership
                  Trading pages may not be enough to provide a full and fair picture of your business.
                  You may consider the submission of further information, including perhaps accounts
                  or supporting calculations, as necessary, for example, where:
                </p>
                <ul>
                  <li>a large business has a substantial turnover</li>
                  <li>a business is complex (perhaps because it is a highly specialised trade)</li>
                  <li>accounts or computations are required for a proper understanding of the figures.</li>
                </ul>
                <p>
                  If your annual turnover was between £85,000 and £15million (or would have been if
                  you had traded for a whole year), you must fill in page 4 of the Partnership Tax
                  Return as well, and page 5 as applicable. Also register for VAT.
                </p>
                <p>
                  If your annual turnover exceeds £15million read the note page PTRG 14 'Income and
                  expenses – annual turnover more than £15million'.
                </p>
                <p>
                  <b>If you do not have accounts</b>
                </p>
                <p>
                  Even if you do not have accounts prepared for your business each year, still work
                  out your taxable profit using either the cash basis (if eligible) or traditional
                  accounting (accruals). These notes will help. For more advice on how us tax your
                  profits, what to include as business income and what expenditure is allowable for
                  tax, go to <a href="http://www.gov.uk" target="_blank" class="link">www.gov.uk/helpsheets/hs222.pdf</a>
                  and search for ‘HS222’.
                </p>
                <p>
                  You'll come across the terms ‘accounting period’ and ‘accounting date’ in both the
                  notes to the Partnership Tax Return and certain helpsheets.
                </p>
                <p>
                  If you do not have accounts prepared for your business, read:
                </p>
                <ul>
                  <li>
                    'accounting period' to mean the period for which you provide details of your business
                    income and expenses
                  </li>
                  <li>'accounting date' to mean the date on which that period ends</li>
                </ul>
                <p>
                  <b>If you do have accounts</b>
                </p>
                <p>
                  Accounts are prepared for a variety of reasons and in a variety of reasons and in
                  a variety of ways and it may not be immediately obvious where, in the Partnership
                  Tax Return, to enter some of your figures.
                </p>
                <p>
                  Helpsheet 229, ‘Information from your accounts’ gives practical help on filling
                  in the Partnership Trading pages, including some worked examples. Go to <a href="http://www.gov.uk" target="_blank" class="link">
                    www.gov.uk/helpsheet/hs229
                  </a>
                  and search for ‘HS229’.
                </p>
                <p>
                  In some situations you may need to combine or divide the figures to fit the standard
                  format.
                </p>
                <p>
                  It is quite possible that there may be more than one acceptable way of doing so.
                  Whichever method is adopted, be consistent from one year to the next. If you want
                  to explain any figures in more detail make a note in box 3.116 ‘Additional information’,
                  on page 3 of the Partnership Tax Return.
                </p>
                <p>
                  Make sure that you transfer all the entries from your accounts, and that you include
                  them once only. Do not bring in any amounts which are not included in your accounts
                  unless they're needed to calculate your taxable profit or were excluded in error
                  from your accounts; include any such amount, other than partners’ personal expenses,
                  in box 3.71, and explain why the entry is necessary in box 3.116 ‘Additional information’
                  on page 3 of the Partnership Tax Return. (For the treatment of partners’ personal
                  expenses, read the notes starting on page PTRG 11.)
                </p>
                <p>
                  If the partnership has a single set of accounts which cover more than one business,&nbsp;
                  transfer the figures to one set of Partnership Trading pages, but then deduct the
                  income and disallow the expenses relating to any business other than the main business
                  and include that income and expenses in a separate set of Partnership Trading pages
                  for each of your other businesses.
                </p>
                <p style="text-align: right">

                </p>
                <p>
                  <b>Provisional figures</b>
                </p>
                <p>
                  We would normally expect you to fill in the income and expenses section of the Partnership
                  Trading pages with the final and correct figures of income and expenses. If, despite
                  your best efforts, you’re unable to do so, please read the notes on page PTRG 31
                  of this guide which explain the exceptional circumstances in which returns containing
                  provisional figures may be accepted.
                </p>
                <p>
                  If you need to use one or more provisional figure,&nbsp; still fill in all relevant
                  boxes in the Partnership Trading pages, including the accounts information. If it's
                  actually impossible to provide final or even provisional accounts information from
                  which your taxable profit is to be calculated before the appropriate filing deadline
                  for the Partnership Tax Return, provide one provisional figure for your taxable
                  profit in box 3.83 and tick box 3.93. We would expect there to be very few of these
                  circumstances. The one common circumstance would be where, in the case of a newly
                  commenced business, the first accounting period does not end until close to, or
                  after, the statutory filing date. By 'close to' we mean within 3 months of the filing
                  date.
                </p>
                <p>
                  If you've included any provisional figures, tick box 10.1 on page 8 of your Partnership
                  Tax Return, and explain why you cannot provide final figures in box 3.116 'Additional
                  information'on page 3 of your Partnership Tax Return. Give a date by which you expect
                  to do so.
                </p>
                <p>
                  <b>Estimates (including valuations)</b>
                </p>
                <p>
                  In some situations you may need to provide an estimated figure or valuation that
                  you do not intend to amend at a later date. If so, read the notes on page PTRG 30
                  of this guide.
                </p>
                <p>
                  <b>Partnership and business details</b>
                </p>
                <p>
                  provide details of the partnership’s income and expenses for the accounting period(s)
                  ended in the period 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}.
                </p>
                <p>
                  <b>If the partnership had more than one account ended in {{this.userService.Previous_year}} to {{this.userService.Present_year}}</b>
                </p>
                <p>
                  You may need to fill in a set of Partnership Trading pages for each period of account.
                </p>
                <p>
                  Always fill in an additional set of pages if the reason for the additional accounting
                  period is a change in the partnership’s annual accounting date. Either photocopy
                  blank pages that you already have, go to <a href="http://www.gov.uk/taxreturnforms"
                                                              target="_blank" class="link">www.gov.uk/taxreturnforms</a> for more copies.
                </p>
                <p>
                  Where the annual accounting date is unchanged the accounting periods, when added
                  together, will cover a normal 12-month period. In such circumstances you may, if
                  you want, fill in a single set of pages for that 12-month period by combining the
                  accounts information required at boxes 3.24 to 3.26 or boxes 3.27 to 3.73. Otherwise
                  fill in a separate set of pages for each accounting period.
                </p>
                <p>
                  If the partnership is a subcontractor in the construction industry and you have
                  to fill in more than one set of Partnership Trading pages make sure that you fill
                  in box 3.97 (for CIS deductions) on the pages for the most recent set of accounts.
                </p>
                <p style="text-align: right">

                </p>
                <p>
                  <b>If no accounts end in {{this.userService.Previous_year}} to {{this.userService.Present_year}}</b>
                </p>
                <p>
                  Make sure that there’s at least one accounting period ending in {{this.userService.Previous_year}} to {{this.userService.Present_year}}. If
                  you do not, the partners may have to use estimates to calculate their tax liability
                  for {{this.userService.Previous_year}} to {{this.userService.Present_year}} and could end up being charged interest if the estimates are too
                  low.
                </p>
                <p>
                  If no accounts end in {{this.userService.Previous_year}} to {{this.userService.Present_year}} you should:
                </p>
                <ul>
                  <li>
                    provide details of the partnership’s income and expenses for the period 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}
                  </li>
                  <li>enter 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}} in boxes 3.4 and 3.5</li>
                </ul>
                <p>
                  <b>Changing between self-employment and partnership</b>
                </p>
                <p>
                  If, during the year ended 5 April {{this.userService.Present_year}} a trade or profession carried on in partnership
                  which was previously or is subsequently carried on by one of the members of the
                  partnership as a sole trader, fill in boxes 3.24 to 3.26 or boxes 3.13A to 3.23
                  and boxes 3.27 to 3.73, boxes 3.82 to 3.117, as appropriate, in this Partnership
                  Tax Return for any period of account ending in the year to 5 April {{this.userService.Present_year}} during any
                  part of which the business was carried on in partnership. This will enable you to
                  make the allocation of partnership profits or losses in the Partnership Statement.
                </p>
                <p>
                  If the partnership ceased between the accounting date in {{this.userService.Previous_year}} to {{this.userService.Present_year}} and 5 April {{this.userService.Present_year}},
                  and:
                </p>
                <ul>
                  <li>
                    one of the members of the partnership carried on the business thereafter as a sole
                    trader
                  </li>
                  <li>
                    accounts covering the period up to the date the partnership ceased were drawn up
                    to a date after 5 April {{this.userService.Present_year}}
                  </li>
                </ul>
                <p>
                  in addition to completing a set of Partnership Trading pages for the accounting
                  period ended in {{this.userService.Previous_year}} to {{this.userService.Present_year}}, also fill in a set of Partnership Trading pages for
                  the accounting period ended in  {{this.userService.Present_year}} to {{this.userService.Next_year}} which covers both the period to the
                  date the partnership ceased and the period thereafter when the business was carried
                  on by a sole trader.
                </p>
                <p>
                  For any accounting periods ended in {{this.userService.Previous_year}} to {{this.userService.Present_year}} during which this business was
                  carried on exclusively by a sole trader, fill in boxes 9 to 30 on the Self-employment
                  (short) pages (or boxes 15 to 65 and boxes 83 to 99 of the Self-employment (full)
                  pages) of that person’s tax return.
                </p>
                <p>
                  Where the partnership’s business was previously or is subsequently carried on by
                  one of the partners on their own, enter the date of the change in box 3.7 or box
                  3.8 of the Partnership Tax Return, as appropriate.
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="3p2"></a>
                <h4>
                  How to fill in the pages
                </h4>
                <p>
                  <b id="3p2">Box 3.2</b>
                </p>
                <p>
                  Make sure that you fill in this box for each set of Partnership Trading pages that
                  you need to submit.
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="3p4"></a><a name="3p5"></a><a name="3p4-5"></a>
                <p>
                  <b id="3p4">Boxes 3.4 and 3.5</b>
                </p>
                <p>
                  Enter the details of the period to which the information at boxes 3.24 to 3.26,
                  or alternatively boxes 3.27 to 3.73, will relate.
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="3p7"></a>
                <p>
                  <b>Box 3.7</b>
                </p>
                <p>
                  If the partnership trade or profession started after 5 April {{this.userService.Previous_year}}, you should enter
                  the start date. If your accounting date has changed since then, go to <a href="http://www.gov.uk"
                                                                                           target="_blank" class="link">www.gov.uk/helpsheet222</a> and search for ‘HS222’.
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="3p8"></a>
                <p>
                  <b>Box 3.8</b>
                </p>
                <p>
                  If the partnership trade or profession was sold or closed down before 6 April {{this.userService.Present_year}},
                  enter the date it ended in box 3.8. If this is not the same as the date in box 3.5,
                  you must fill in another set of Partnership Trading pages to show the trading results
                  for the remaining period.
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="3p9"></a>
                <p>
                  <b>Box 3.9</b>
                </p>
                <p>
                  Tick box 3.9 if the partnership trade or profession used the cash basis to calculate
                  its income and expenses.
                </p>
                <p>
                  The cash basis is a simpler way of working out your business profits or losses.
                  You add up all the income received and take off any allowable expenses paid in the
                  accounting period. You do not include money the partnership owes or that's owed
                  to the partnership at the end of year date.
                </p>
                <p>
                  You can use, or may already be using, the cash basis if the partnership business
                  income does not exceed £150,000.
                </p>
                <p>
                  Most business partnerships can use cash basis if their total income makes them eligible.
                  If you're a partner in a partnership that you control and you have separate trading
                  activities, you'll need to add together the receipts from all your businesses to
                  those of the partnership to find out if you can use the cash basis. If you use cash
                  basis for one of your businesses you must use it for all of them.
                </p>
                <p>
                  Limited liability partnerships and the following specific types of businesses cannot
                  use cash basis:
                </p>
                <ul>
                  <li>Partnerships with one or more corporate partners</li>
                  <li>Lloyd's underwriters.</li>
                  <li>Farming businesses with a current herd basis election.</li>
                  <li>Farming and creative businesses with a section 221 ITTOIA profit averaging election.</li>
                  <li>Businesses that have claimed Business Premises Renovation Allowance.</li>
                  <li>Businesses that carry on a mineral extraction trade.</li>
                  <li>Businesses that have claimed Research and Development Allowance.</li>
                </ul>
                <p>
                  If the partnership uses cash basis:
                </p>
                <ul>
                  <li>only record income when it's received</li>
                  <li>record expenses when they're paid</li>
                  <li>
                    capital expenditure is allowed as a deduction, unless the expenditure is specifically
                    disallowed
                  </li>
                  <li>any losses can not be set off against other income</li>
                  <li>
                    the partnership cannot use capital allowances (read notes to boxes 3.13A to 3.23
                    of this guide) for anything except cars.
                  </li>
                </ul>
                <p>
                  For more information about the cash basis, go to <a href="http://www.gov.uk" target="_blank"
                                                                      class="link">www.gov.uk/helpsheet222</a> and search for ‘HS222’.
                </p>
                <hr />
                <a name="3p10"></a>
                <p>
                  <b>Box 3.10</b>
                </p>
                <p>
                  Tick box 3.10 if the partnership has succeeded to a business previously carried
                  on by a sole trader and that person has included the accounts information in their
                  tax return (read the notes on page PTRG 10 of this guide on ‘Changing between self-employment
                  and partnership’). If you tick box 3.10 you do not need to fill in boxes 3.14 to
                  3.93 and boxes 3.99 to 3.115.
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="3p11"></a>
                <p>
                  <b>Box 3.11</b>
                </p>
                <p>
                  Tick box 3.11 if the partnership’s accounts do not cover the period from the last
                  accounting date or if no accounts end in {{this.userService.Previous_year}} to {{this.userService.Present_year}}. Explain why in box 3.116 ‘Additional information’, on page 3 of the Partnership Tax Return.
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="3p12"></a><a name="3p13"></a><a name="3p12-13"></a>
                <p>
                  <b>Boxes 3.12 and 3.13</b>
                </p>
                <!--<p>
    There are special rules where a partnership changes its
    accounting date. Tick box 3.12 if your accounting date has changed and this is a
    permanent change which you wish to count for tax. Tick box 3.13 (as well as box
    3.12) if this is the second or further change in the partnership’s accounting
    date since 5 April 2010 and explain
    why this change has been made in box 3.116 ‘Additional information’, on page 3 of
    the Partnership Tax Return. The special rules are explained in Helpsheet 222, ‘How
    to calculate your taxable profits’. Go to <a href="http://www.gov.uk" target="_blank"
                                                 class="link">www.gov.uk/helpsheet222</a>.
  </p>-->
                <p>
                  These boxes are not in use. For more information on how accounting dates affect your tax calculation, see helpsheet HS222. Go to  <a href="http://www.gov.uk" target="_blank"
                                                                                                                                                       class="link">www.gov.uk/helpsheet222</a> and search for ‘HS222’.
                </p>
                <p>
                  <b>Partners' &#39;personal&#39; expenses</b>
                </p>
                <p>
                  In some types of partnership, for example medical practices, partners will often
                  incur expenditure personally, while carrying on the trade or profession on behalf
                  of the partnership. For example, motoring expenses or rental costs. Because these
                  sums are not directly reimbursed from partnership funds they do not appear in the
                  partnership accounts. But the partnership agreement will provide that the profit-sharing
                  arrangement should take into account these ‘personal’ expenses.
                </p>
                <p>
                  If relief is to be given for any expenses incurred under this type of agreement,
                  you must include the expenses in the relevant entries made in box 3.25 or boxes
                  3.51 to 3.63 where necessary by aggregation with similar expenditure met from partnership
                  funds.
                </p>
                <p>
                  It will not be possible for an individual partner to claim relief for the expenditure
                  in their personal tax return. But you can make sure that the partner receives the
                  benefit due under the partnership agreement by making a ‘fixed adjustment’ when
                  allocating shares of profit (read the notes on pages PTRG 26 to PTRG 29 of this
                  guide ).
                </p>
                <p>
                  Similarly, capital allowances may be due on an asset which is owned by a partner
                  but which is used in the partnership trade or profession (unless the asset is leased
                  to the partnership).
                </p>
                <p>
                  Again, you must include these allowances in the entries made in boxes 3.13A to 3.23
                  and reflect them in boxes 3.24 and 3.25 or boxes 3.68 and 3.70. (You may also need
                  to make corresponding adjustments to the entries in boxes 3.112 to 3.114 – read
                  the notes starting on page PTRG 20 of this guide.)
                </p>
                <br />
                <table id="AutoNumber3" bgcolor="#DEE2EE" border="0" bordercolor="#111111" cellpadding="5"
                       cellspacing="0" style="border-collapse: collapse" width="60%">
                  <tr>
                    <td colspan="2" width="100%">
                      <b>Example 1</b>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" width="100%">
                      Dr Robert is a member of a partnership carrying on business as medical practitioners.
                      He incurs the following expenses when conducting the partnership's business:
                    </td>
                  </tr>
                  <tr>
                    <td width="40%">
                      • use of home as office
                    </td>
                    <td width="60%">
                      £1,000
                    </td>
                  </tr>
                  <tr>
                    <td width="40%">
                      • motor expenses
                    </td>
                    <td width="60%">
                      £2,000
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" width="100%">
                      Also, a capital allowance of £750 is due on a car which he owns but which he uses
                      for the business. Include the figure of £1,000 should be included in box 3.52; £2,000
                      in box 3.55 and £750 in box 3.14 or box 3.70. Make a&nbsp; corresponding 'fixed
                      adjustment' (minus £3,750) should be made when allocating profit for Dr Robert (read
                      the notes on pages PTRG 26 to PTRG 29 of this guide).
                    </td>
                  </tr>
                </table>
                <p style="text-align: right">

                </p>
                <hr />
                <p>
                  <strong>
                    Tax allowances for vehicles, equipment and buildings (capital allowances)
                  </strong>
                </p>
                <a name="3p13a"></a><a name="3p15"></a><a name="3p14a"></a><a name="3p15a"></a><a name="3p16"></a><a name="3p17"></a><a name="3p18"></a><a name="3p19"></a><a name="3p20">
                </a><a name="3p21"></a><a name="3p22"></a><a name="3p23"></a><a name="3p68">
                </a><a name="3p70"></a><a name="3p13a-70"></a>
                <p>
                  For the capital allowances Helpsheet, go to www.gov.uk and search for ‘HS252’.
                </p>
                <p>
                  <b>Boxes 3.13A to 3.23, boxes 3.68 and 3.70</b>
                </p>
                <p>
                  In working out the partnership’s taxable profits you must not deduct:
                </p>
                <ul>
                  <li>the cost of buying, altering or improving fixed assets </li>
                  <li>depreciation or any losses which arise when the partnership sells them</li>
                </ul>
                <p>
                  Instead, the partnership can claim tax allowances called capital allowances. You
                  deduct these in working out the partnership’s profits and include them in box 3.70
                  (or box 3.25).
                </p>
                <p>
                  Do not claim capital allowances if you are using cash basis. The
                  only exception for those using the cash basis is cars. In such
                  circumstances, a partnership may claim capital allowances on cars, or alternatively
                  may use simplified expenses.
                </p>
                <!--<p>
    If the partnership has previously claimed capital allowances for a car used in its
    business, do not use simplified expenses. The partnership may continue to claim
    the allowance and any business part of the actual running costs as a business expense
    in box 3.55. You cannot use flat rates.
  </p>-->
                <p>If the partnership has previously claimed capital allowances for a car used in its business, the partnership may continue to claim the allowance. Include any business part of the actual running costs as a business expense in box 3.55. You cannot use flat rates. If you’ve never claimed capital allowances for the car, you can choose to use the flat rate, or claim capital allowances. </p>
                <!--<p>
    An adjustment, known as a balancing charge, may arise when the partnership sells
    an item, gives it away or stops using it in its business. Add these to the partnership’s
    profits by including them in box 3.68 (or box 3.24). Fill in a separate series of
    boxes 3.13A to 3.23 for each set of Partnership Trading pages completed. Separate
    capital allowances calculations are needed for each of the partnership’s accounting
    periods.
  </p>-->
                <p>
                  An adjustment, known as a balancing charge, may arise when the partnership sells an item, gives it away or stops using it in its business.
                  If the partnership sells an item that they’ve claimed capital allowances on, and the sale proceeds or value of the item is more than the pool value or cost,
                  the partnership will have to pay tax on the difference (a ‘balancing charge’). This includes items where the pool value is nil, because you claimed all of the cost previously.
                  Add the total of all balancing charges to the partnership’s profits by including them in box 3.68 (or box 3.24).
                </p>
                <p>
                  If the partnership has a tax adviser, ask how to calculate capital allowances and balancing charges. If the partnership does not have a tax adviser, or you want to check your calculation, go to www.gov.uk/business-tax/capital-allowances Fill in a separate series of boxes 3.13A to 3.23 for each set of Partnership Trading pages completed. Separate capital allowances calculations are needed for each of the partnership’s accounting periods.
                </p>
                <p>
                  <strong>Fixtures</strong>
                </p>
                <p>
                  Under rules introduced in 2012 and 2014, a purchaser of a second-hand business property
                  containing fixtures (such as kitchen fittings, electrical or heating systems) will
                  not usually be entitled to claim allowances unless the past owner has ‘pooled’ its
                  qualifying expenditure and has fixed the value of the fixtures.
                </p>
                <p>
                  ‘Pooling’ includes making a claim for first year allowance or annual investment
                  allowance for the expenditure. It is not necessary for the last owner to claim writing
                  down allowances. As a rule, the past owner is the last person who was entitled to
                  claim capital allowances on fixtures.
                </p>
                <p>
                  Normally, the value will be fixed by means of a joint section 198 CAA 2001 election,
                  which must be notified to HMRC within 2 years of the property transaction. The amount
                  the seller can bring in to any pool as the disposal value will be the same amount
                  as the amount the buyer can bring in as the acquisition value for capital allowances
                  purposes.
                </p>
                <p>
                  <b>Box 3.13A </b>
                </p>
                <p>
                  Annual Investment Allowance (AIA) can be claimed for the purchase of equipment (but
                  not cars) up to a maximum annual amount of £1million. If the equipment is used for
                  both business and private use, reduce the AIA claimed by the private use proportion.
                  For information about AIA, go to <a href="http://www.gov.uk/capital-allowances/annual-investment-allowance"
                                                      target="_blank" class="link">www.gov.uk/capital-allowances/annual-investment-allowance</a>

                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="3p14"></a>
                <p>
                  <b>Box 3.14</b>
                </p>
                <p>
                  Claim the full cost of new, not second hand, zero-emission goods vehicles in box
                  3.14. If you use the vehicle for non-business purposes, for example 50% of the
                  time, you must reduce the amount of the claim by 50%.
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="3p14A"></a>
                <p>
                  <b>Box 3.14A</b>
                </p>
                <p>
                  You can claim 18% writing down allowance (WDA) on the balance of expenditure on
                  equipment in the main pool and any unrelieved amount is carried forward to your
                  next period.
                </p>
                <!--<p>
    Such expenditure includes cars bought
    on or after 6 April 2022 with
    CO2 emissions between 0g/km and 50g/km.&nbsp; Read the guidance ‘How to
    calculate a capital allowances pool balance’.&nbsp; You can
    claim 18% WDA on the final balance. If the final balance of qualifying
    expenditure before you claim WDA, is £1,000 or less, you can claim the small
    pools allowance of up to the whole amount, instead of the 18% WDA, using box
    3.14A.&nbsp;
    Read the guidance ‘How to calculate a capital allowances pool balance’ on page PTRG
    13. You can claim 18% WDA on the final balance. If the final balance of qualifying
    expenditure before you claim WDA, is £1,000 or less, you can claim the small pools
    allowance of up to the whole amount, instead of the 18% WDA, using box 3.14A.
  </p>-->
                <p>
                  Such expenditure includes cars bought on or after 6 April 2022 with CO2 emissions above 0g/km and not exceeding 50g/km. Read the guidance ‘How to calculate a capital allowances pool balance’. If the final balance of qualifying expenditure before you claim WDA, is £1,000 or less, you can claim the small pools allowance of up to the whole amount, instead of the 18% WDA, using box 3.14A.
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="#3p16"></a>
                <p> <b>Box 3.16</b></p>
                <p>
                  You can claim 6% writing down allowance
                  (WDA) a year on the balance of expenditure in the
                  special rate pool. Such expenditure includes:
                </p>
                <ul>
                  <li> cars bought on or after 6 April 2021 with CO2 emissions of more than 50g/km</li>
                  <li>integral features of a building or structure, such as electrical and water systems, lighting, lifts and escalators</li>
                  <li>solar panels</li>
                  <li>insulation that you have added to an existing building</li>
                  <li>assets or equipment with a life expectancy of more than 25 years from when they were new</li>
                </ul>
                <p>
                  For more information on CO2 emissions, go to
                  <a href="http://www.gov.uk/capital-allowances/business-cars" target="_blank" class="link">
                    www.gov.uk/capital-allowances/business-cars
                  </a>
                  Read the guidance ‘How to calculate a capital
                  allowances pool balance’.
                </p>
                <p>
                  If the final balance of qualifying expenditure in
                  the special rate pool, before you claim WDA,
                  is £1,000 or less, you can claim a small pools
                  allowance of up to the whole amount, instead of
                  the 6% WDA, using box 3.16.
                </p>
                <p>
                  <strong>How to calculate a capital allowances pool balance</strong>
                </p>
                <ul>
                  <li>start with the unrelieved expenditure in the pool at the end of the previous chargeable period </li>
                  <li>add the balance of qualifying expenditure for which a claim to a first year allowance (FYA) was made in the previous chargeable period (this will be nil for a 100% FYA) </li>
                  <li> add all qualifying expenditure for the pool incurred in this chargeable period, exclude expenditure for which a claim to an FYA is made </li>
                  <li> deduct the claim made to AIA </li>
                  <li> deduct the total of all disposal values (usually receipts) for assets from the pool that are no longer used in the business </li>
                  <li> the result is the pool’s balance of qualifying expenditure </li>
                </ul>
                <p>
                  You can use your (AIA) wholly or partly against main pool and special rate pool
                  expenditure (not cars).
                </p>
                <p>
                  For help with capital allowances and balancing charges, go to
                  <a href="http://www.gov.uk/business-tax/capital-allowances"
                     target="_blank" class="link">www.gov.uk/business-tax/capital-allowances</a>
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="#3p16A"></a>
                <p>
                  <b id="3p16A">Box 3.16A</b>
                </p>
                <p>
                  Claim the 100% first year allowance (the full cost) of any new and unused
                  zero-emission or electric cars in this box.
                </p>
                <p>
                  If you use a car outside of your business you must reduce the claim in
                  proportion to the non-business use.
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="#3p18-18A"></a><a name="3p18"></a>
                <p>
                  <b id="3p18-18A">Box 3.18</b>
                </p>
                <p>
                  You can claim 100% first year capital allowances for expenditure invested in the
                  acquisition and installation of new and unused electric charge-points for electric
                  vehicles. Put the amount of expenditure incurred in box 3.18.
                </p>
                <p>
                  <b>Box 3.18A</b>
                </p>
                <p>
                  If the partnership is eligible to claim the Structures and Buildings Allowance (SBA),
                  put the amount of the claim in box 3.18A. If claiming for the first time for a particular
                  structure or building, use the ‘Additional information’ box, box 3.116, to record
                  the:
                </p>
                <ul>
                  <li>
                    date the building first came into qualifying use or if later, the date the qualifying
                    expenditure was incurred
                  </li>
                  <li>total amount of qualifying expenditure incurred</li>
                </ul>
                <p>
                  To check if and how much you can claim, go to <a href="https://www.gov.uk/guidance/claiming-capital-allowances-for-structures-and-buildings"
                                                                   target="_blank" class="link">www.gov.uk/guidance/claiming-capital-allowances-for-structures-and-buildings</a>.
                </p>
                <p>
                  <b>Box 3.18B</b>
                </p>
                <p>
                  If the partnership is eligible to claim the enhanced Structures and Buildings
                  Allowance for Freeport tax sites, put the amount of the claim in box 3.18B. If
                  claiming for the first time for a particular structure or building use the
                  ‘Additional information’ box, box 3.116 on the SA800, to record the:
                </p>
                <ul>
                  <!--<li>Freeport to which the claim relates </li>-->
                  <li>Freeport and Investment Zone to which the claim relates </li>
                  <li>address or location of the structure or building</li>
                  <li>
                    date the building first came into qualifying use, or if later, the date the
                    qualifying expenditure was incurred
                  </li>
                  <li>total amount of qualifying expenditure incurred</li>
                  <li>date the first contract for construction was entered into</li>
                </ul>
                <p>
                  To check if, and how much you can claim, go to
                  <a href="https://www.gov.uk/guidance/check-if-you-can-claim-enhanced-structures-and-buildings-allowance-relief-in-freeport-tax-sites" target="_blank" class="link">
                    www.gov.uk/guidance/check-if-you-can-claimenhanced-structures-and-buildings-allowancerelief-in-freeport-tax-sites
                  </a>
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="#3p20"></a>
                <p>
                  <b id="3p20">Box 3.20</b>
                </p>
                <p>
                  You can claim 100% capital allowances for equipment for
                  refuelling vehicles with natural gas, biogas or hydrogen fuel.
                </p>
                <ul *ngIf="this.userService.Present_year < '2024'">
    <li>certain energy-saving or water-efficient equipment used in your business </li>
    <li>
      a new, unused car bought on or after 1 April 2021 with CO2 emissions of 0g/km or
      electric
    </li>
    <li>
      a new, unused car bought between 1 April 2018 and 31 March 2021 with CO2 emissions
      of not more than 50g/km
    </li>
    <li>
      equipment for refuelling vehicles with natural gas, biogas or hydrogen fuel
    </li>
  </ul>
                <p>
                  To check if you can claim and how much, go to <a href="https://www.gov.uk/capital-allowances/first-year-allowances"
                                                                   target="_blank" class="link">www.gov.uk/capital-allowances/first-year-allowances</a>.
                </p>
                <p>
                  <b>Business Premises Renovation Allowance</b>
                </p>
                <p>
                  Business Premises Renovation Allowance (BPRA) ended on 5 April 2017. Put any BPRA
                  claims for expenditure incurred before 6 April 2017 in box 3.20 and any BPRA balancing
                  events in box 3.21. For more information about BPRA, go to <a href="https://www.gov.uk/guidance/business-premises-renovation-allowance-bpra"
                                                                                target="_blank" class="link">www.gov.uk/guidance/business-premises-renovation-allowance-bpra</a>.
                </p>
            <p *ngIf="this.userService.Present_year < '2024'">
    <b>
      Balancing charge on sales of assets or on the cessation of business use (including
      where Business Premises Renovation Allowance has been claimed) for example, where
      you have disposed of assets for more than their tax value
    </b>
  </p>
  <p *ngIf="this.userService.Present_year < '2024'">
    Balancing charges arise following a disposal or balancing event, such as the sale,
    loss or destruction of assets or on the cessation of business use, where the proceeds
    from the event are more than their tax value. If you sell an item you have claimed
    capital allowances on, and the sale proceeds or value of the item is more than the
    pool value or cost, you will have to pay tax on the difference (a ‘balancing charge’).
    This includes items where the pool value is nil because you claimed all of the cost
    previously.
  </p>
  <p *ngIf="this.userService.Present_year < '2024'">
    If the partnership has a tax adviser, ask how to calculate capital allowances and
    balancing charges.
  </p>
  <p *ngIf="this.userService.Present_year < '2024'">
    If the partnership does not have a tax adviser, or you want to check your calculation,
    go to <a href="https://www.gov.uk/business-tax/capital-allowances" target="_blank"
             class="link">www.gov.uk/business-tax/capital-allowances</a>.
  </p>
                <p>
                  <b>Income and expenses – annual turnover below £85,000</b>
                </p>
                <p *ngIf="this.userService.Present_year < '2024'">
    If the annual turnover (including coronavirus support scheme payments but excluding
    any balancing charges) is below £85,000 for a full year, you may fill in boxes 3.24
    to 3.26 on page 3 of the Partnership Tax Return instead of boxes 3.27 to 3.73 on
    page 4 (except if you’re within the Managing Serious Defaulters (MSD) programme).
  </p>
                <p>If the annual turnover excluding any balancing charges) is below £85,000 for a full year, you may fill in boxes 3.24 to 3.26 on page 3 of the Partnership Tax Return instead of boxes 3.27 to 3.73 on page 4 (except if you’re within the Managing Serious Defaulters (MSD) programme). </p>

                <p>

                  If the turnover was for a period of less than 12 months, reduce the figure of £85,000
                  proportionately. For example, if the partnership only traded for 6 months you must
                  fill in boxes 3.27 to 3.73 if the turnover was more than: 6/12 x £85,000 = £42,500.
                  But you must fill in one section or the other, and fill in boxes 3.15 to 3.23 if
                  you have any balancing charges, and boxes 3.13A to 3.22 if you are claiming capital
                  allowances.But you must fill in one section or the other, and fill in boxes 3.15
                  to 3.23 if you have any balancing charges, and boxes 3.13A to 3.22 if you are claiming
                  capital allowances.
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="3p24"></a>
                <p>
                  <b id="3p24">Box 3.24</b>
                </p>
                <p>
                  Enter your business income in box 3.24. If you are using traditional accounting,
                  you’ll also need to include the normal selling price of all goods which the partners
                  have taken out of the business for their personal use or for their families or friends
                  minus any sum paid into the business for the goods taken out. This is because any
                  sum paid into the business should already be included in the turnover figure, like
                  other sales. If you’re using the cash basis, you do not need to include the normal
                  selling price but include the disallowable amount (normally the cost of the goods
                  taken out). Include any balancing charges (from box 3.23).
                </p>
                <div *ngIf="this.userService.Present_year < '2024'">
                  <p>
                    Coronavirus support scheme payments are taxable and include payments from the Coronavirus
                    Job Retention Scheme (CJRS), Eat Out to Help Out Scheme and any other applicable
                    HMRC coronavirus support scheme and payments the partnership was entitled to receive
                    from local authorities or devolved administrations.
                  </p>
                  <p>
                    If the partnership received coronavirus support scheme payments, include in box
                    3.24 the amount the partnership received.
                  </p>
                  <p>
                    If any of the partners received an assessment issued by an officer of HMRC in respect
                    of a coronavirus support scheme payment incorrectly claimed only include in box
                    3.24 the amount the partnership retained. Do not include in box 3.24 the amount
                    of any sum that has been assessed which results in the repayment of coronavirus
                    support scheme payments that the partnership was not entitled to.
                  </p>
                  <p>
                    If the partnership received Eat Out to Help Out Scheme payments and are VAT registered,
                    exclude VAT from the amount you include in box 3.24.
                  </p>
                  <p>
                    If the partnership received a CJRS, Eat Out to Help Out Scheme or any other applicable
                    HMRC coronavirus support scheme payment that it was not entitled to and it has not
                    been voluntarily paid back to HMRC or none of the partners have received an assessment
                    issued by an officer of HMRC in respect of the incorrectly claimed payment, put
                    the incorrectly claimed amount in box 3.118
                  </p>
                  <p>
                    If exceptionally any partner was required by the partners to account for a payment
                    received under the Self-Employment Income Support Scheme (SEISS) or other applicable
                    coronavirus support scheme payment to the partnership, include that amount as turnover
                    in this box. The partner’s share of that payment should be included in the partner’s
                    share of profit (or loss).
                  </p>
                </div>


                <hr />
                <a name="3p25"></a>
                <p>
                  <b id="3p25">Box 3.25</b>
                </p>
                <p>
                  Enter your allowable business expenses in box 3.25. Include wages funded by payments
                  under the CJRS. Make sure that you do not include in your expenses any items which
                  are not allowable against tax (the ‘Table of disallowable expenses’ on page PTRG
                  17 of this guide will help you to decide). From 2013 to 2014, partnerships (other
                  than partnerships with one or more corporate partners) may opt to use flat rates
                  instead of working out their actual business expenses for certain types of business
                  expenditure – read the notes on ‘Simplified expenses’ on page PTRG 19 of this guide
                  for more information. Include any capital allowances (from box 3.22)
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="3p26"></a>
                <p id="3p26">
                  <b>Box 3.26</b>
                </p>
                <p>
                  Subtract the figure in box 3.25 from the figure in box 3.24 and put the result in
                  box 3.26 (put a loss in brackets).
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <p>
                  <strong>Income and expenses – annual turnover between £85,000 and £15 million</strong>
                </p>
                <p>
                  If the annual turnover (including coronavirus support scheme payments) was between
                  £85,000 and £15million (or would have been if you had traded for a whole year),
                  you must fill in boxes 3.27 to 3.73 on page 4. You must also fill in page 5 as applicable,
                  any relevant supplementary pages and a Partnership Statement.
                </p>
                <p>
                  <b>Income and expenses – annual turnover more than £15 million</b>
                </p>
                <p *ngIf="this.userService.Present_year < '2024'">
    If the combined annualised turnover (including coronavirus support scheme payments)
    from all of your activities was more than £15million, fill in boxes 3.13A to 3.23
    and boxes 3.24 to 3.26 instead of page 4, and send the partnership accounts and
    computations with the Partnership Tax Return. You must also fill in page 5 as applicable,
    any relevant supplementary pages and a Partnership Statement.
  </p>
                <p>
                  If the combined annualised turnover from all of your activities was more than £15 million, fill in boxes 3.13A to 3.23 and boxes 3.24 to 3.26 instead of page 4,
                  and send the partnership accounts and computations with the Partnership Tax Return.
                  You must also fill in page 5 as applicable, any relevant supplementary pages and a Partnership Statement.
                </p>
                <p>
                  If the partnership has been told that they’re within the Managing Serious Defaulters
                  (MSD) programme, fill in all applicable boxes from box 3.27 through to box 3.117,
                  and not boxes 3.24 to 3.26. If the partnership has been told that they’re the subject
                  of the additional reporting requirements, you must also send the detailed partnership
                  accounts, balance sheet and computations with the Partnership Tax Return.
                </p>
                <p>
                  These should identify and explain the nature and amount of any figures contained
                  in those accounts that cannot be vouched by physical or electronic records made
                  at the time that the underlying transactions took place, or written confirmation
                  that no such figures are included. In all cases fill in box 3.83 or box 3.84 on
                  page 5 and other applicable boxes on page 5.
                </p>
                <p>
                  <b>Value Added Tax</b>
                </p>
                <a name="3p27"></a><a name="3p28"></a><a name="3p27-28"></a>
                <p>
                  <b id="3p27">Boxes 3.27 and 3.28</b>
                </p>
                <p>
                  If the partnership is not registered for VAT, your sales figure will not include
                  any VAT. Expenses in boxes 3.30 to 3.64 should include VAT. Do not tick either box
                  3.27 or box 3.28.
                </p>
                <p>
                  If the partnership is registered for the VAT Agricultural Flat Rate Scheme, include
                  any flat rate additions charged to customers in the sales figure. Expenses should
                  include VAT. Do not tick either box 3.27 or box 3.28.
                </p>
                <p>
                  If the partnership is registered for VAT and is not within the Flat Rate Scheme
                  (see below), you may enter details of your business income and allowable expenses
                  either all net of VAT or all inclusive of VAT. If the partnership is VAT registered
                  and using cash basis, details of the income and expenses would typically include
                  VAT.
                </p>
                <p>
                  Where the partnership adopts the latter approach, include either the net VAT payment
                  to us as an expense in box 3.63 or any net VAT repayment it receives from us as
                  a taxable receipt in box 3.50.
                </p>
                <p>
                  Where an item is not deductible as an expense for income tax purposes but the VAT
                  is recoverable, add the recoverable VAT to the expense reported in box 3.63, or
                  deduct it from the VAT repayment reported in box 3.50 and add a note in box 3.116
                  ‘Additional information’ on page 3 of the Partnership Tax Return.
                </p>
                <p>
                  Do not include recoverable VAT when working out Capital Allowances on qualifying
                  capital expenditure.
                </p>
                <p>
                  Add the recoverable VAT that’s excluded from the capital allowances computation
                  to the expense reported in box 3.63, or deduct it from the VAT repayment in box
                  3.50 and add a note in box 3.116 ‘Additional information’ on page 3 of the Partnership
                  Tax Return.
                </p>
                <p>
                  Tick either box 3.27 or box 3.28 to show whether entries in boxes 3.29 to 3.64 include
                  or exclude VAT.
                </p>
                <p>
                  If the partnership registered for VAT during the period, the expenses up to that
                  date should include VAT regardless of whether later sales and expenses are recorded
                  VAT inclusive or exclusive. Tick box 3.27 and include the following details in box
                  3.116 ‘Additional information’, on page 3 of the Partnership Tax Return:
                </p>
                <ul>
                  <li>a note that the partnership registered for VAT during the period </li>
                  <li>the date of registration </li>
                  <li>
                    whether sales and expenses from the registration date are VAT inclusive or exclusive
                  </li>
                </ul>
                <p>
                  If the partnership is registered for VAT and the goods you supply are zero-rated
                  (so that your sales figure does not include any VAT), tick either box 3.27 or box
                  3.28 to show whether entries in boxes 3.30 to 3.64 include or exclude VAT.
                </p>
                <p>
                  Similar action is required if the VAT registration was cancelled during the period,
                  except that the details to appear in box 3.116 ‘Additional information’, should
                  include the date of deregistration and whether sales and expenses before that date
                  are VAT inclusive or exclusive. Expenses from the deregistration date should include
                  VAT.
                </p>
                <p>
                  If the partnership is registered for the VAT Flat Rate Scheme you may enter details
                  of your business income and allowable expenses either:
                </p>
                <ul>
                  <li>all net of VAT (that is, with the VAT figure taken off) – method 1 </li>
                  <li>all inclusive of VAT – method 2 </li>
                </ul>
                <p>
                  If you use method 1, include:
                </p>
                <ul>
                  <li>
                    at box 3.50 any balance on your VAT account that is not paid over to us (that’s,
                    the amount of VAT on your income which exceeds the VAT that you’ve paid on your
                    expenses plus the payment under the Flat Rate Scheme)
                  </li>
                  <li>
                    at box 3.63 any balance on your VAT account that you cannot recover from us (that’s,
                    the VAT on your expenses plus the payment under the Flat Rate Scheme minus the VAT
                    on your income)
                  </li>
                </ul>
                <p>
                  If you use method 2, include the net payment to us under the Flat Rate Scheme as
                  an expense at box 3.63.
                </p>
                <p>
                  Tick either box 3.27 or box 3.28 to show whether the entries in boxes 3.29 to 3.64
                  include or exclude VAT.
                </p>
                <p>
                  If the partnership is registered for VAT but we treat it as partly exempt, for the
                  purposes of calculating the taxable profits, business expenditure includes any input
                  tax which is not claimable. Where you fill in boxes 3.29 to 3.64 on a VAT inclusive
                  basis the inclusion of your net payment to, or net repayment from, us in boxes 3.63
                  and 3.50 respectively will reflect this. However, if you fill in the boxes on a
                  VAT exclusive basis please make sure that the figures of expenses you enter include
                  any relevant input tax not claimed for VAT purposes.
                </p>
                <p>
                  The computation of the net payment to, or repayment from, us may have included VAT
                  on capital items purchased during the year. For example, on assets or rights which
                  are of lasting use to the business and which are not bought or sold as part of the
                  ordinary trading operations. Examples might include business premises, plant, machinery,
                  vehicles and trade rights.
                </p>
                <p>
                  If you enter details of your income and expenses inclusive of VAT, make a note of
                  the VAT on those capital items in box 3.116 ‘Additional information’ on page 3 of
                  the Partnership Tax Return. Include the amount of VAT that you’ve paid on the capital
                  items in the calculations of capital allowances summarised at boxes 3.13A to 3.23.
                </p>
                <p>
                  If you’re in any doubt about the correct treatment of VAT, please contact us or
                  your tax adviser.
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <p>
                  <b>Sales/business income (turnover)</b>
                </p>
                <a name="3p29"></a>
                <p>
                  <b id="3p29">Box 3.29</b>
                </p>
                <p>
                  Enter the amount of your business income in box 3.29. If it includes income from
                  which tax has been taken off, enter in box 3.97 or box 3.117, as appropriate, the
                  total tax taken off between6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}.
                </p>
                <div *ngIf="this.userService.Present_year < '2024'">
                  <p>
                    If exceptionally any partner was required by the partners to account for a payment
                    received under the Self-Employment Income Support Scheme (SEISS) or other applicable
                    coronavirus support scheme payment to the partnership, include that amount as turnover
                    in this box. The partner’s share of that payment should be included in the partner’s
                    share of profit (or loss).
                  </p>
                </div>
                <hr />
                <p>
                  <b>Business expenses</b>
                </p>
                <a name="3p30-63"></a>
                <p>
                  <b id="3p30-63">Boxes 3.30 to 3.63</b>
                </p>
                <p>
                  Include all your business expenses in boxes 3.46 to 3.48 and boxes 3.51 to 3.63.
                </p>
                <p>
                  You can only claim for expenses which are incurred wholly and exclusively for business
                  purposes. Some of the amounts you put in boxes 3.46 to 3.63 may not be allowable
                  for tax. As you enter them, put any disallowable amounts in boxes 3.30 to 3.45.
                  Enter in box 3.66 the total of these disallowable amounts.
                </p>
                <p>
                  From 2013 to 2014, partnerships (other than partnerships with one or more corporate
                  partners) may opt to use flat rates instead of working out their actual business
                  expenses for certain types of business expenditure – read the notes on ‘Simplified
                  expenses’ on page PTRG 19 of this guide for more information.
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <tr>
                  <p>
                    <b>Disallowable expenses</b>
                  </p>
                  <a name="3p30"></a>
                  <p>
                    <b id="3p30">Box 3.30</b>
                  </p>
                  <p>
                    Fuel expenses attributable to non-business use of vehicles.
                  </p>
                  <p style="text-align: right">

                  </p>
                  <hr />
                  <a name="3p31"></a>
                  <p>
                    <b id="3p31">Box 3.31</b>
                  </p>
                  <p>
                    Any payments made relating to non-business work.
                  </p>
                  <p style="text-align: right">

                  </p>
                  <hr />
                  <a name="3p32"></a>
                  <p>
                    <b id="3p32">Box 3.32</b>
                  </p>
                  <p>
                    Depreciation of fixed plant.
                  </p>
                  <p style="text-align: right">

                  </p>
                  <hr />
                  <a name="3p33"></a>
                  <p id="3p33">
                    <b>Box 3.33</b>
                  </p>
                  <p>
                    Employment costs that are not paid within 9 months of the end of the period of account,
                    or any payments made for non-business work.
                  </p>
                  <p style="text-align: right">

                  </p>
                  <hr />
                  <a name="3p34"></a>
                  <p>
                    <b id="3p34">Box 3.34</b>
                  </p>
                  <p>
                    Non-business part of premises costs used partly for business. Costs of acquiring
                    premises.
                  </p>
                  <p style="text-align: right">

                  </p>
                  <hr />
                  <a name="3p35"></a>
                  <p>
                    <b id="3p35">Box 3.35</b>
                  </p>
                  <p>
                    Cost of repairs of non-business parts of premises and equipment used partly for
                    business. Costs of alteration, improvements or replacements to business premises.
                  </p>
                  <p style="text-align: right">

                  </p>
                  <hr />
                  <a name="3p36"></a>
                  <p>
                    <b id="3p36">Box 3.36</b>
                  </p>
                  <p>
                    Partners’ private and personal expenses. Non-business part of costs used partly
                    for business, payments to political parties. Most payments to clubs, charities or
                    churches. The partners’ insurance.
                  </p>
                  <p style="text-align: right">

                  </p>
                  <hr />
                  <a name="3p37"></a>
                  <p>
                    <b id="3p37">Box 3.37</b>
                  </p>
                  <p>
                    Non-business motoring. Travel between home and business. Costs of buying vehicles.
                    Parking and other fines.
                  </p>
                  <p style="text-align: right">

                  </p>
                  <hr />
                  <a name="3p38"></a>
                  <p>
                    <b id="3p38">Box 3.38</b>
                  </p>
                  <p>
                    Meals (except the reasonable cost of meals on overnight business trips).
                  </p>
                  <p style="text-align: right">

                  </p>
                  <hr />
                  <a name="3p39"></a>
                  <p>
                    <b id="3p39">Box 3.39</b>
                  </p>
                  <p>
                    Entertaining and hospitality (except gifts of up to £50 a person a year advertising
                    your business and are neither food nor drink, and the costs of entertaining staff).
                  </p>
                  <p style="text-align: right">

                  </p>
                  <hr />
                  <a name="3p40"></a>
                  <p>
                    <b id="3p40">Box 3.40</b>
                  </p>
                  <p>
                    Costs of settling tax disputes, legal costs of buying fixed assets (these are treated
                    as part of the cost of the fixed asset). Costs and fines or penalties for breaking
                    the law.
                  </p>
                  <p style="text-align: right">

                  </p>
                  <hr />
                  <a name="3p41"></a>
                  <p>
                    <b id="3p41">Box 3.41</b>
                  </p>
                  <p>
                    General bad debts reserve. Debts that were not taxed when they arose, for example,
                    because they relate to a sale of a fixed asset. Not relevant if your business is
                    using the cash basis.
                  </p>
                  <p style="text-align: right">

                  </p>
                  <hr />
                  <a name="3p42"></a>
                  <p>
                    <b id="3p42">Box 3.42</b>
                  </p>
                  <p>
                    Repayment of the loan, alternative finance arrangement or overdraft. In addition,
                    if your business is using the cash basis, the maximum allowable amount for interest
                    on the loan is £500 – disallow any amounts in excess of this.
                  </p>
                  <p style="text-align: right">

                  </p>
                  <hr />
                  <a name="3p43"></a>
                  <p>
                    <b id="3p43">Box 3.43</b>
                  </p>
                  <p>
                    Repayment of the loan, alternative finance arrangement or overdraft.
                  </p>
                  <p style="text-align: right">

                  </p>
                  <hr />
                  <a name="3p44"></a>
                  <p>
                    <b id="3p44">Box 3.44</b>
                  </p>
                  <p>
                    Generally, depreciation and losses on assets are not allowable for tax, and profits
                    on assets are not taxable receipts. Cancel any figure in box 3.62 by putting the
                    same figure in box 3.44. However, to the extent that any of these items are attributable
                    to assets held under finance leases, a different treatment may be appropriate. If
                    you’re in any doubt about the correct treatment, ask us or your tax adviser.
                  </p>
                  <p style="text-align: right">

                  </p>
                  <hr />
                  <a name="3p45"></a>
                  <p>
                    <b id="3p45">Box 3.45</b>
                  </p>
                  <p>
                    The non-business part of expenses in box 3.63. Ordinary, everyday clothing even
                    if bought specially for business use.
                  </p>
                </tr>
                <p style="text-align: right">

                </p>
                <hr />
                <p>
                  <b>Total expenses</b>
                </p>
                <a name="3p46"></a>
                <p id="3p46">
                  <b>Box 3.46</b>
                </p>
                <p>
                  If the business involves the resale or consumption of raw materials, enter here the cost of the goods used; that’s, purchases plus opening stock/work in progress minus closing stock/work in progress. So, for example, subcontractors in the construction industry need to include the cost of any materials supplied. And taxi drivers, minicab drivers and so on, and those in the road haulage industry need to enter fuel expenditure in this box rather than elsewhere, unless they’re claiming mileage rate.
                </p>
                <p>
                  If the business is using the cash basis, include the cost of expenses when they’re
                  paid for. It’s not necessary to calculate opening and closing stock/ work-in-progress
                  to calculate your expenses when using the cash basis.
                </p>
                <p>
                  Businesses providing services commonly adjust their business profits to reflect
                  work-in-progress at the start and end of the period of account. If an adjustment
                  of this sort is appropriate, (it will not be if you’re using the cash basis), make
                  it here; otherwise leave the box blank. If the figure is negative, enter it in brackets.
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="3p47"></a>
                <p id="3p47">
                  <b>Box 3.47</b>
                </p>
                <p>
                  Include all payments to subcontractors in the construction industry. Enter the gross
                  amount before deduction if any payments have been made to subcontractors paid under
                  deduction.
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="3p48"></a>
                <p id="3p48">
                  <b>Box 3.48</b>
                </p>
                <p>
                  Expenses deducted to arrive at gross profit, for example, discounts allowed, commissions
                  payable, carriage and, in manufacturing businesses, the costs of producing goods
                  sold such as direct labour costs, depreciation of fixed plant, machine hire, small
                  tools and consumables. If the business provides services, it may incur rechargeable
                  expenses, which are deducted to arrive at a figure of gross profit.
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="3p51"></a>
                <p id="3p51">
                  <b>Box 3.51</b>
                </p>
                <p>
                  Salaries, wages, (include wages funded by payments under the CJRS), bonuses, pensions,
                  benefits, employer’s NICs and so on for permanent, temporary and casual employees,
                  and other staff-related costs such as canteen expenses and recruitment agency fees
                  and so on. Any subcontract labour costs, including locum fees, not included elsewhere,
                  include them here. Do not include the partner’s employment costs (for example, salaries,
                  drawings, pension payments and other benefits or National Insurance contributions).
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="3p52"></a>
                <p id="3p52">
                  <b>Box 3.52</b>
                </p>
                <p>
                  Rent, business rates, water rates, light, heat, power, property insurance, security
                  and other similar expenses. If accounts contain an amount for ‘use of home’, include
                  that figure here.
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="3p53"></a>
                <p id="3p53">
                  <b>Box 3.53</b>
                </p>
                <p>
                  Repairs and general maintenance of business premises and machinery.
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="3p54"></a>
                <p id="3p54">
                  <b>Box 3.54</b>
                </p>
                <p>
                  Phone, fax, postage, stationery and printing costs, courier services, general office
                  expenses, the costs of trade or professional journals and subscriptions and costs
                  of insurance not included elsewhere and other similar recurring costs which arise
                  in running the business.
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="3p55"></a>
                <p id="3p55">
                  <b>Box 3.55</b>
                </p>
                <p>
                  Insurance, servicing, repairs, vehicle licence, petrol or diesel hire and leasing
                  charges, parking charges, motoring organisation membership.
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="3p56"></a>
                <p id="3p56">
                  <b>Box 3.56</b>
                </p>
                <p>
                  All travel costs other than those included in motor expenses, such as rail, air
                  and taxi fares, together with hotel accommodation costs and subsistence or similar
                  costs.
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="3p57"></a>
                <p id="3p57">
                  <b>Box 3.57</b>
                </p>
                <p>
                  The ordinary day-to-day costs of advertising and promoting the business goods or
                  services, such as newspaper advertisements, mailshots and the distribution of free
                  samples of the goods dealt in. Also include entertainment costs here.
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="3p58"></a>
                <p id="3p58">
                  <b>Box 3.58</b>
                </p>
                <p>
                  Accountant’s, solicitor’s, surveyor’s, architect’s, stocktaker’s and other similar
                  costs, together with professional indemnity insurance premiums and the like.
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="3p59"></a>
                <p id="3p59">
                  <b>Box 3.59</b>
                </p>
                <p>
                  The amount of money owed to the partnership for goods sold or work done that has
                  been included in turnover but remains unpaid at the accounting date and which you
                  consider you’ll never receive. If, unexpectedly, you do recover the amount in a
                  later year, make sure that you include it in box 3.50 in that later year’s Partnership
                  Tax Return. This will not apply if you’re using the cash basis.
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="3p60"></a>
                <p id="3p60">
                  <b>Box 3.60</b>
                </p>
                <p>
                  Interest on bank and other loans (including overdrafts) and alternative finance
                  payments. If you’re using the cash basis, the maximum amount you can enter here
                  is £500.
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="3p61"></a>
                <p id="3p61">
                  <b>Box 3.61</b>
                </p>
                <p>
                  Bank charges, credit card charges, hire purchase interest, and leasing payments,
                  alternative finance payments, together with other similar costs not included elsewhere.
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="3p62"></a>
                <p id="3p62">
                  <b>Box 3.62</b>
                </p>
                <p>
                  Add together depreciation and losses on sales of assets, and deduct profits on sales
                  of assets that are included in your accounts and enter the resulting figure in the
                  box. Where the profit on disposal of an asset exceeds the total of any losses on
                  disposal and depreciation added together, show this figure in brackets. A figure
                  in brackets is to be deducted when you add up your total expenses.
                </p>
                <p>
                  If you sold assets at a profit, consider whether you need to enter a disposal in
                  the Partnership Disposal of Chargeable Assets pages.
                </p>
                <p style="text-align: right">

                </p>
                <hr />
                <a name="3p63"></a>
                <p id="3p63">
                  <b>Box 3.63</b>
                </p>
                <p>
                  Add up all the expenses not included elsewhere and enter the total figure.
                </p>
                <p>
                  The disallowable amounts may be expenses which are not allowable at all for tax
                  (such as entertainment expenses and depreciation of fixed assets) or the disallowable
                  part of expenses (such as motor expenses, if the vehicle is used for private as
                  well as business mileage).
                </p>

                <br />
                <div *ngIf="this.userService.Present_year < '2024'">
                <table id="AutoNumber4" bgcolor="#DEE2EE" border="0" bordercolor="#111111" cellpadding="5"
         cellspacing="0" style="border-collapse: collapse" width="60%">
    <tr>
      <td colspan="2" width="100%">
        <b>Example 2</b>
      </td>
    </tr>
    <tr>
      <td colspan="2" width="100%" align="justify">
        The total motor expenses included in box 3.55 were £3,000 and one-third of the mileage
        is private. You can only claim against tax two-thirds of the cost, that is £2,000.
        Put the private use proportion of £1,000 in box 3.37.
      </td>
    </tr>
  </table>
                  </div>
                <p>
                  If any of the amounts in boxes 3.46 to 3.48 and boxes 3.51 to 3.63 are
                  recoverable under an insurance, include these amounts in the disallowable
                  expenses to be entered in boxes 3.30 to 3.45. However, there’s no need to
                  do this if you’ve included that insurance recovery in turnover (box 3.29)
                  or under other income  (box 3.50).
                </p>
                <b>Simplified expenses</b>
                <p>
                  From 2013–14, you may use a scheme of simplified expenses for calculating certain
                  types of business expenditure. You do not have to use simplified expenses. You can
                  decide if it suits the partnership business. You do not have to be using the cash
                  basis in order to use simplified expenses.
                </p>
                <p>
                  Partnerships with one or more corporate partners cannot use the simplified expenses
                  scheme.
                </p>
                <p>
                  With simplified expenses, you can use flat rates instead of working out your actual
                  business expenses. You can use simplified expenses for:
                </p>
                <ul>
                  <li>
                    business costs for vehicles (a flat rate for mileage instead of the actual costs
                    you paid for buying and maintaining the vehicle) and either
                  </li>
                  <li>
                    business use of your home (a flat rate based on the hours you work from home each
                    month or
                  </li>
                  <li>
                    private use of business premises as a home (deduct from your total expenses a monthly
                    flat rate based on the number of occupants each month)
                  </li>
                </ul>
                <p>
                  All other expenses must be calculated in the usual way.
                </p>
                <p>
                  Helpsheet 222 gives more information, including details of the flat rate to be used
                  in {{this.userService.Previous_year}} to {{this.userService.Present_year}} if claiming simplified expenses, go to <a href="http://www.gov.uk" target="_blank"
                                                                                                                                       class="link">www.gov.uk/helpsheet222</a> and search for ‘HS222’.
                </p>
                <!--<p>
    <b>Other income/profits</b>
  </p>-->
                <a name="3p50"></a>
                <p id="3p50">
                  <b>Box 3.50</b>
                </p>
                <p>
                  Enter in box 3.50 any business income which you did not include as turnover in
                  box 3.29.
                </p>
                <div *ngIf="this.userService.Present_year < '2024'">
                  <p>Coronavirus support scheme payments are taxable and include payments
    from the Coronavirus Job Retention Scheme (CJRS), Eat Out to Help Out Scheme and
    any other applicable HMRC coronavirus support scheme and payments the
    partnership was entitled to receive from local authorities or devolved
    administrations.
  </p>
  <p>
    If the partnership received coronavirus support scheme payments, include in box
    3.50 the amount the partnership received.
  </p>
  <p>
    If any of the partners received an assessment issued by an officer of HMRC in
    respect of a coronavirus support scheme payment incorrectly claimed only include
    in box 3.50 the amount the partnership retained. Do not include in box 3.50 the
    amount of any sum that has been assessed which results in the repayment of
    coronavirus support scheme payments that the partnership was not entitled to.
  </p>
  <p>
    If the partnership received Eat Out to Help Out Scheme payments and are VAT
    registered, exclude VAT from the amount you include in box 3.50.

  </p>
  <p>
    If the partnership received a CJRS, Eat Out to Help Out Scheme or any other
    applicable HMRC coronavirus support scheme payment that it was not entitled to
    and it has not been voluntarily paid back to HMRC or none of the partners have
    received an assessment issued by an officer of HMRC in respect of the
    incorrectly claimed payment, put the incorrectly claimed amount in box 3.118.
  </p>
                  </div>
                  <p>
                    Other examples might include rental income, interest and alternative finance
                    receipts from a business bank or building society account, discounts received,
                    non-arm’s length reverse premiums and so on. Reverse premiums are payments or
                    benefits which are received as an inducement to take a lease of any property
                    other than your only or main residence. If the leased property is to be occupied
                    for the partnership’s trade, profession or vocation the reverse premium will be
                    a taxable receipt. If you have any doubt about the proper tax treatment of a
                    reverse premium, ask us or your tax adviser.
                  </p>
                  <p>
                    If you’re including this income in a different place on the Partnership Tax
                    Return make sure that you deduct it at box 3.71. For example, do not include
                    bank interest in the net business profit or loss entered in box 3.73 if you
                    intend to include it in Question 7, on page 8 of the return.
                  </p>
                  <p>
                    <b>Partnership charges</b>
                  </p>
                  <p>
                    <b>Box 3.63</b>
                  </p>
                  <p>
                    Amounts paid under an annuity or covenant are not allowable as an expense for tax
                    purposes, even if paid for wholly commercial reasons in connection with the partnership
                    trade or profession. Disallow any of these amounts by making an appropriate entry
                    in box 3.45.
                  </p>
                  <p>
                    However, individual partners can claim
                    relief for their shares of any trade charges paid during the period 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}} (but only charges paid for wholly
                    commercial reasons in connection with the partnership trade or profession). Read
                    the notes for box 3.117 on page PTRG 22 of this guide.
                  </p>
                  <p>
                    <b>Other expenses</b>
                  </p>
                  <p>
                    Add up all the expenses in the accounts not included elsewhere and enter the total
                    figure.
                  </p>
                  <p>
                    <b>Additional information (box 3.116)</b>
                  </p>
                  <p>
                    Use box 3.116on page 3 of the Partnership Tax Return if you want to explain any
                    of your figures in more detail. For example:
                  </p>
                  <ul>
                    <li>
                      particulars of any significant or unusual items (either income or expenses) included
                      in the figures
                    </li>
                    <li>
                      details of receipts or expenses connected with the business which for any reason
                      are not included in the figures
                    </li>
                    <li>
                      an explanation of any tax adjustment to the net profit where the reason is not apparent
                      from these figures
                    </li>
                    <li>
                      an explanation of any items which are not included in Standard Accounts Information,
                      but which affect the taxable profits.
                    </li>
                  </ul>
                  <p>
                    This may avoid any unnecessary enquiries being made. More guidance is in Helpsheet
                    229,‘Information from your accounts’.Go to <a href="http://www.gov.uk" target="_blank"
                                                                  class="link">www.gov.uk/helpsheet229</a> and search for ‘HS229’.
                  </p>
                  <p style="text-align: right">

                  </p>
                  <hr />
                  <p>
                    <b>Tax adjustments to net profit or loss for this accounting period</b>
                  </p>
                  <a name="3p66"></a>
                  <p>
                    <b>Box 3.66</b>
                  </p>
                  <p>
                    Enter in box 3.66 the total of disallowable expenses included in boxes 3.30 to 3.45.
                  </p>
                  <p style="text-align: right">

                  </p>
                  <hr />
                  <a name="3p67"></a>
                  <p>
                    <b>Box 3.67</b>
                  </p>
                  <p>
                    Also make adjustments for goods which the partners have taken out of the business
                    for their personal use or for their families or friends. Enter in box 3.67 the normal
                    selling price of all goods taken out for such use, minus any sum paid into the business
                    for the goods and which you have already included in the turnover in box 3.29.
                  </p>
                  <p>
                    Enter any amounts treated as profit by the trading income provided through third
                    party rules should be entered in this box.
                  </p>
                  <p style="text-align: right">

                  </p>
                  <hr />
                  <a name="3p71"></a>
                  <p>
                    <b id="3p71">Box 3.71</b>
                  </p>
                  <p>
                    Make adjustments (deduct from a profit or add to a loss) in box 3.71 for any
                    amounts you took into account in arriving at the partnership’s net profit or
                    loss but which are either not taxable receipts or are not taxed as profits from
                    the partnership’s trade or profession. You must include any taxable income that
                    you include in box 3.71 in the appropriate part of the Partnership Tax Return.
                  </p>
                  <p>
                    <b>
                      The provision of personal services through a partnership where the client is not
                      a public authority – deemed employment payment
                    </b>
                  </p>
                  <a name="3p82"></a>
                  <!--<p><b>Box 3.82</b></p>-->
                  <p id="3p82">
                    Special rules about tax and National Insurance contributions (NICs) may apply if
                    the partnership provides a partner’s services or the services of others to clients,
                    and the partner and partnership can answer ‘yes’ to both the following questions.
                  </p>
                  <ul>
                    <li>
                      If the partner did not work through the partnership, would they be an employee of
                      the client?
                    </li>
                    <li style="line-height: 30px;">
                      Does the partnership the partner works through meet one
                      of the following conditions?<br />
                      — The partner (or their family) is entitled to 60% or more of the partnership profits<br />
                      — More or all of the partnership’s profits come from providing services to a single
                      client<br />
                      — The partnership’s profit-sharing arrangements ensure that the partner receives
                      an amount based upon the amounts received for their services to clients
                    </li>
                  </ul>
                  <p>
                    If the rules apply, the partner may have to pay additional tax and NICs at the end
                    of the tax year or earlier if they stopped being a partner. The partner will need
                    to fill in an SA102 Employment page to show both the amount received, and any tax
                    taken off.
                  </p>
                  <p>
                    Because the additional amount (a deemed payment) is treated as income from employment,
                    the partnership has to apply PAYE and NICs. The partnership can take off the (deemed)
                    payment, and any secondary Class 1 NICs paid, when working out the partnership profits.
                  </p>
                  <p>
                    You can only use the deduction once as a tax adjustment when working out the taxable
                    income of the partnership. Any relief is given against the profits for the accounting
                    period in which the (deemed) payment is made (normally 5 April) and must not be
                    split between the partnership’s accounting periods where the partnership makes up
                    its accounts to a date other than 5 April.
                  </p>
                  <p>
                    The amount of the deduction allowed when working out the profits is limited to the
                    amount that reduces those profits to nil. This means that the deemed payment and
                    the secondary NICs on that payment cannot make a loss.
                  </p>
                  <p>
                    Where the partnership expenses paid for doing the work exceed the allowed expenses
                    and the 5% flat-rate allowance, any excess amount (when working out the deemed payment)
                    is left out of the taxable profits.
                  </p>
                  <p>
                    Put any adjustment for disallowable expenses in box 3.66 and the amount of the deemed
                    payment and any secondary Class 1 NICs in box 3.71 on the Partnership Tax Return.
                    If the accounts already include a deduction for secondary Class 1 NICs, for example,
                    under employee costs, include this amount in box 3.66.
                  </p>
                  <p>
                    You must tell us how you worked out your figures in 'Additional information', box
                    3.116 on page 3 of the Partnership Tax Return.
                  </p>

                  <table width="800" border="0" cellpadding="5" cellspacing="0" bgcolor="#DEE2EE" style="line-height: 29px;">
                    <tr align="center" valign="middle">
                      <td height="35" colspan="2">
                        <h4>
                          Example – Deemed payment calculation
                        </h4>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <p>
                          Mr and Mrs Jones are in partnership and make up their accounts to 5 April {{this.userService.Present_year}}.
                          All the partnership’s income comes from contracts covered by the new rules. Profits
                          are split equally but Mrs Jones carries out the services
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <strong>Partnership accounts year ended 5 April {{this.userService.Present_year}} </strong>
                      </td>
                    </tr>
                    <tr>
                      <td width="697" align="left" valign="middle">
                        Income
                      </td>
                      <td width="83" align="right">
                        &pound;20,000
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" bordercolor="#111111">
                        Expenses (A)
                      </td>
                      <td align="right">
                        &pound;5,000
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="middle">
                        Profit
                      </td>
                      <td align="right" style="border-top: solid 1px #666;">
                        &pound;15,000
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Allocated to Mrs Jones &pound;7,500 and Mr Jones &pound;7,500 each.
                      </td>
                      <td>
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Of the partnership's expenses, only &pound;2,000 of the &pound;5,000<br />
                        would be allowable under employment income rules.
                      </td>
                      <td>
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Calculation of deemed payment on 5 April {{this.userService.Present_year}}</strong>
                      </td>
                      <td>
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Income from relevant engagements
                      </td>
                      <td align="right">
                        &pound;20,000
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Minus
                      </td>
                      <td align="right">
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td>
                        5% flat-rate allowance (&pound;20,000 x 5%) (B)
                      </td>
                      <td align="right">
                        &pound;1,000
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Employee expenses (C)
                      </td>
                      <td align="right">
                        &pound;2,000
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Secondary Class 1 NICs on deemed payment
                      </td>
                      <td align="right">
                        &pound;£1,183
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Deemed payment
                      </td>
                      <td align="right" style="border-top: solid 1px #666;">
                        = &pound;15,817
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Recalculation of partnership's taxable profit</strong>
                      </td>
                      <td align="right">
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Partnership profit (enter at box 3.65)
                      </td>
                      <td align="right">
                        &pound;15,000
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Add
                      </td>
                      <td align="right">
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Disallowed expenses (A) minus (B + C)
                      </td>
                      <td align="right">
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td>
                        &pound;5,000 minus (&pound;1,000 + &pound;2,000)
                      </td>
                      <td align="right">
                        &pound;2,000
                      </td>
                    </tr>
                    <tr>
                      <td>
                        (enter at box 3.66)
                      </td>
                      <td align="right" style="border-top: solid 1px #666;">
                        = &pound;17,000
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Please note:</strong> The disallowed expenses are the excess of the partnership
                        expenses in the accounts (A) over the sum of the 5% flat-rate allowance (B) and
                        employee expenses (C).
                      </td>
                      <td align="right">
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Minus
                      </td>
                      <td align="right">
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Secondary Class 1 NICs on deemed payment &pound;1,183
                      </td>
                      <td align="right">
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Deemed payment (enter at box 3.71) &pound;15,817
                      </td>
                      <!--<td align="right">
        &pound;17,000
      </td>-->
                    </tr>
                    <tr>
                      <td>
                        Taxable trading profit (enter at box 3.73)
                      </td>
                      <td align="right" style="border-top: solid 1px #666;">
                        0
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Please note:</strong> The deemed payment amount is always restricted to
                        an amount that reduces the trading profit to nil.
                      </td>
                      <td align="right">
                        &nbsp;
                      </td>
                    </tr>
                  </table>

                  <p>
                    <strong>
                      The provision of personal services to a public authority through a partnership
                      - deemed employment payment
                    </strong>
                  </p>
                  <p>
                    From 6 April 2017, new rules were introduced for the provision of personal services
                    to a public authority. The deemed employment calculation rules are different to
                    those not involving a public
                  </p>
                  <p>
                    For information about supplying services through a partnership to a public authority,
                    go to <a href="http://www.gov.uk/topic/business-tax/ir35" target="_blank" class="link">
                      www.gov.uk/topic/business-tax/ir35
                    </a>
                  </p>
                  <p>
                    <strong>Salaried members of limited liability partnerships</strong>
                  </p>
                  <p>
                    Salaried members of a LLP are treated as employees rather than self-employed for
                    income tax and National Insurance contributions (NICs) purposes. This is because
                    salaried members are engaged on terms that are closer to employment than self-employment.
                  </p>
                  <p>
                    There are 3 conditions that all have to be satisfied for a person to be a salaried
                    member. If those conditions are satisfied the LLP must operate PAYE and deduct income
                    tax and Class 1 NICs from the salaried member’s earnings as they would with any
                    other employee.
                  </p>
                  <p>
                    For more detailed guidance, including examples, go to <a href="http://www.gov.uk"
                                                                             target="_blank" class="link">www.gov.uk</a> and search for ‘salaried members’.
                  </p>
                  <p>
                    If you are unsure whether the new rules apply to your LLP, ask us or consult your
                    tax adviser.
                  </p>
                  <a name="3p83"></a><a name="3p84"></a><a name="3p93"></a><a name="3p83-93"></a>
                  <p id="3p83">
                    <strong>Taxable profit or loss for this accounting period</strong>
                  </p>
                  <p>
                    <b>Boxes 3.83, 3.84, 3.93 and 3.94</b>
                  </p>
                  <p>
                    Fill in box 3.83 or box 3.84 in all cases. If box 3.73 is a profit, enter the amount
                    of the profit in box 3.83 and ‘0’ in box 3.84.
                  </p>
                  <p>
                    If box 3.73 is a loss, enter ‘0’ in box 3.83 and the amount of the loss in box 3.84.
                  </p>
                  <p>
                    If you are unable to complete the income and expenses section
                    because it is impossible to prepare the figures to arrive at the taxable profit
                    before the date for sending the Partnership Tax Return, provide an estimate of
                    the taxable profit or loss in boxes 3.83 or 3.84 and tick box 3.93. Read the
                    notes on pages PTRG 9 and PTRG 31
                    of this guide, and tick box 10.1 on page 8 of the Partnership Tax Return. It would
                    also help if you say in box 3.116 ‘Additional information’, on page 3 of the Partnership
                    Tax Return:
                  </p>
                  <ul>
                    <li>why you cannot give a final figure in box 3.83 or box 3.84</li>
                    <li>an approximate date on which you expect to give your final figure</li>
                    <!--<li>
      if box 3.83 or 3.84 include income received from a disguised remuneration scheme,
      tick box 3.94
    </li>-->
                  </ul>
                  <p style="text-align: right">

                  </p>
                  <hr />
                  <p>
                    <b>Subcontractors in the construction industry</b>
                  </p>
                  <a name="3p97"></a>
                  <p id="3p97">
                    <b>Box 3.97</b>
                  </p>
                  <p>
                    If the partnership is a subcontractor in the construction
                    industry, it may have received payments made under the Construction Industry
                    Scheme. If it has, enter in box 3.97 the total of the deductions made on account
                    of tax from payments made to the partnership during the period 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}} (read page PTRG
                    5 of this guide if you are a ‘CT Partnership’). If you are completing more than
                    one set of Partnership Trading pages enter this information on the pages for the
                    most recent set of accounts.
                  </p>
                  <p>
                    Deductions are shown on ‘payment and deduction statements’ which the partnership
                    should have received from the contractor(s) for whom it worked. Contractors must
                    give these statements to subcontractors who are paid under deduction. Please do
                    not send these statements with the Partnership Tax Return. If you have not received
                    payment and deduction statements, you should Page PTRG 19 ask the contractor(s)
                    for whom the partnership worked to provide them.
                  </p>
                  <p>
                    If you cannot get a statement, please give the following details:
                  </p>
                  <ul>
                    <li>name and address of the contractor</li>
                    <li>month payment(s) made to you</li>
                    <li>amount of the gross payment</li>
                    <li>amount deducted on account of tax</li>
                  </ul>
                  <p>
                    in box 3.116, ‘Additional information’, on page 3 of the Partnership Tax Return.
                    If you were given a statement but you have lost your copy, ask the contractor to
                    give you another copy.
                  </p>
                  <p style="text-align: right">

                  </p>
                  <hr />
                  <p>
                    <b>Tax taken off trading income</b>
                  </p>
                  <a name="3p98"></a>
                  <p id="3p98">
                    <b>Box 3.98</b>
                  </p>
                  <p>
                    Enter in box 3.98 any tax taken off amounts returned as trading income (excluding
                    deductions made by contractors on account of tax) between 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}
                    (read page PTRG 5 of this guide if you are a ‘CT Partnership’). If you are
                    completing more than one set of Partnership Trading pages enter this information
                    on the pages for the most recent set of accounts.
                  </p>
                  <p style="text-align: right">

                  </p>
                  <hr />
                  <p>
                    <b>Summary of balance sheet for this accounting period</b>
                  </p>
                  <a name="3p99"></a><a name="3p100"></a><a name="3p101"></a><a name="3p102"></a><a name="3p103"></a><a name="3p104"></a><a name="3p105"></a><a name="3p106"></a>
                  <a name="3p107"></a><a name="3p108"></a><a name="3p109"></a><a name="3p110"></a>
                  <a name="3p111"></a><a name="3p112"></a><a name="3p113"></a><a name="3p114"></a>
                  <a name="3p115"></a><a name="3p99-115"></a>
                  <p>
                    <b>Boxes 3.99 to 3.115</b>
                  </p>
                  <p>
                    If the partnership’s accounts include a balance sheet, copy the entries to the appropriate
                    boxes. If the partnership does not have a balance sheet, leave these boxes blank.
                    If the partnership’s annual turnover was more than £15million, leave these boxes
                    blank and send the full accounts and computations with the return.
                  </p>
                  <p>
                    Make sure that you have transferred all the figures to the summary in boxes 3.99
                    to 3.115 and that each is included once only. Do not bring in any figures not appearing
                    in the balance sheet (unless making an adjustment for partners’ personal expenses
                    – read the note starting on page PTRG 12 of this guide).
                  </p>
                  <p>
                    Use your judgement to transfer the figures from the accounts to the most appropriate
                    boxes. Depending on the circumstances of the business, certain elements in the balance
                    sheet may appear as assets or as liabilities. For example, a bank account with business
                    funds in it will be an asset while an overdrawn account will be a liability. For
                    the former put the balance in box 3.103, while if the account is overdrawn you should
                    put that balance in box 3.107.
                  </p>
                  <p>
                    Other elements which might be affected in this way are most commonly the Capital
                    Account balances and the net profit or loss. Where a balance on the Capital Account
                    is overdrawn or the business made a net loss in the year you should enter the amount
                    in brackets. You should give the total figures for all the partners here.
                  </p>
                  <p>
                    The figure of net profit or loss appearing in the balance sheet should be the same
                    as that you entered in box 3.65 for the same period. The figure for net business
                    assets in box 3.110 should equal the figure for the balance of the Capital Account
                    at the end of the period (box 3.115).
                  </p>
                  <p>
                    Where partners’ personal expenses have been included in arriving at the figure of
                    net taxable profit or loss, and these expenses have not been included in the partnership’s
                    accounts, you should either:
                  </p>
                  <ul>
                    <li>make corresponding adjustments to figures returned in boxes 3.112 to 3.114</li>
                    <li>
                      use the accounts figures in boxes 3.112 to 3.114 and provide a reconciliation in
                      box 3.116 ‘Additional information’, on page 3 of the Partnership Tax Return
                    </li>
                  </ul>
                  <p style="text-align: right">

                  </p>
                  <hr />
                  <p>
                    <b>Partnership trade charges</b>
                  </p>
                  <a name="3p117"></a>
                  <p id="3p117">
                    <b>Box 3.117</b>
                  </p>
                  <p>
                    Although amounts paid under an annuity or covenant are not allowable as a partnership
                    expense for tax purposes, individual partners can claim relief for their shares
                    of any trade charges actually paid during the tax year.
                  </p>
                  <p>
                    Enter in box 3.117 the net amount of charges paid for wholly
                    commercial reasons in connection with the partnership trade or profession during
                    the period 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}
                    (read page PTRG 5 of this guide if you are a ‘CT Partnership’).
                    That is the net amount paid after tax is taken off.
                  </p>
                  <p>
                    You must separately identify the amount entered in box 3.117 in the Partnership
                    Statement.
                  </p>
                  <p style="text-align: right">

                  </p>
                  <hr />
                  <div *ngIf="this.userService.Present_year < '2024'">
                    <p>
                      <b>Incorrectly claimed coronavirus support scheme payments </b>
                    </p>
                    <a name="3p118"></a>
                    <p id="3p118">
                      <b>Box 3.118</b>
                    </p>
                    <p>
                      Only complete this section if the partnership incorrectly claimed any payments from
                      the Coronavirus Job Retention Scheme (CJRS), Eat Out to Help Out Scheme or from
                      any other applicable HMRC coronavirus support scheme during the year ended 5 April
                      2023 and:
                    </p>
                    <ul>
                      <li>the partnership has not already told HMRC about these amounts</li>
                      <li>
                        the partners have not received an assessment issued by an officer of HMRC for these
                        amounts
                      </li>
                    </ul>
                    <p>
                      lf the partnership received a CJRS, Eat Out to Help Out or any other applicable
                      HMRC coronavirus support scheme payment that it was entitled to, do not include
                      them here. Instead, include them in the relevant boxes of the Partnership Tax Return
                      or supplementary pages.
                    </p>
                    <p>
                      If the partnership was not entitled to some or all of the payments received and
                      it has not told HMRC, we have the right to assess and recover the full amount of
                      any incorrectly claimed grant by making an ‘officer’s assessment’ for the amount
                      that it was not entitled to and have not repaid.
                    </p>
                    <p>
                      If we have already contacted any of the partners to raise an assessment, you do
                      not need to declare the overpaid amounts in this section. For more information about
                      receiving grants you were not entitled to and penalties, see factsheets CC/FS48
                      and CC/FS11. Go to www.gov.uk and search for ‘CC/FS48’ and ‘CC/FS11’.
                    </p>
                    <p>
                      If the partnership made an error in a claim that has resulted in receiving too much
                      of an HMRC coronavirus support scheme payment, that payment must be paid back to
                      HMRC. If the partnership has not put that right already by making a voluntary adjustment
                      or repayment that was agreed by HMRC, put the incorrectly claimed amount in box
                      3.118. One of the partners should then include this amount in their own self assessment
                      for the year ended 5 April 2023. Do not include any payments the partnership was
                      entitled to or has already repaid or any of the partners have been assessed on.
                    </p>
                  </div>
                  <p>
                    <b>Foreign income and tax credits</b>
                  </p>
                  <p>
                    If any of the income included in the entries in box 3.26 or box 3.73 includes amounts
                    which have been taxed abroad, the partners may be able to claim a credit against
                    their UK tax bill. An essential feature of the allowance of relief for foreign tax
                    paid is the need to separately identify each and every item of the partnership’s
                    overseas income.
                  </p>
                  <br />
                  <table id="AutoNumber5" bgcolor="#DEE2EE" border="0" bordercolor="#111111" cellpadding="5"
                         cellspacing="0" style="border-collapse: collapse; height: 174px; width: 60%;">
                    <tr>
                      <td colspan="3" width="100%">
                        <b>Example 2</b>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" width="100%">
                        An entry in box 3.73 showing UK taxable trading profits of £150,000 may include:
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="46%">
                        <b>Nature of income</b>
                      </td>
                      <td align="center" valign="top" width="27%">
                        <b>Foreign profits</b>
                      </td>
                      <td align="center" valign="top" width="27%">
                        <b>Foreign tax paid or suffered</b>
                      </td>
                    </tr>
                    <tr>
                      <td width="46%">
                        Trading profits (Country A)
                      </td>
                      <td align="center" width="27%">
                        £25,000
                      </td>
                      <td align="center" width="27%">
                        £8,500
                      </td>
                    </tr>
                    <tr>
                      <td width="46%">
                        Trading profits (Country B)
                      </td>
                      <td align="center" width="27%">
                        £15,000
                      </td>
                      <td align="center" width="27%">
                        £4,000
                      </td>
                    </tr>
                    <tr>
                      <td width="46%">
                        Trading profits (Country C)
                      </td>
                      <td align="center" width="27%">
                        £17,500
                      </td>
                      <td align="center" width="27%">
                        £5,000
                      </td>
                    </tr>
                  </table>
                  <p>
                    The amount of foreign income may be estimated, for example, in proportion to turnover,
                    or a more precise calculation may be made using the actual expenses incurred in
                    earning overseas receipts.
                  </p>
                  <p>
                    Each partner will have to be allocated a share of each item of foreign income (and
                    the associated foreign tax credit) in proportion to their share in the total profit
                    shown at box 3.26 or box 3.73.
                  </p>
                  <br />
                  <table id="AutoNumber6" bgcolor="#DEE2EE" border="0" bordercolor="#111111" cellpadding="5"
                         cellspacing="0" style="border-collapse: collapse" width="60%">
                    <tr>
                      <td colspan="3" width="100%">
                        <b>Example 3</b>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" width="100%">
                        If a particular partner had a one-fifth share in trading profits the partner's share
                        of the items shown above would be:
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" width="40%">
                        <b>Nature of income</b>
                      </td>
                      <td align="center" valign="top" width="27%">
                        <b>Share of foreign profits</b>
                      </td>
                      <td align="center" valign="top" width="33%">
                        <b>Share of foreign tax paid or suffered</b>
                      </td>
                    </tr>
                    <tr>
                      <td width="40%">
                        Trading profits (Country A)
                      </td>
                      <td align="center" width="27%">
                        £5,000
                      </td>
                      <td align="center" width="33%">
                        £1,700
                      </td>
                    </tr>
                    <tr>
                      <td width="40%">
                        Trading profits (Country B)
                      </td>
                      <td align="center" width="27%">
                        £3,000
                      </td>
                      <td align="center" width="33%">
                        £800
                      </td>
                    </tr>
                    <tr>
                      <td width="40%">
                        Trading profits (Country C)
                      </td>
                      <td align="center" width="27%">
                        £3,500
                      </td>
                      <td align="center" width="33%">
                        £1,000
                      </td>
                    </tr>
                  </table>
                  <p>
                    If the partnership has any foreign trading or professional income which has been
                    taxed abroad, give a detailed analysis in box 3.116 ‘Additional information’, on
                    page 3 of the Partnership Tax Return.
                  </p>
                  <div *ngIf="this.userService.Present_year < '2024'">
                    <p style="text-align: right">

                    </p>
                    <p><b>Accounting changes for service contracts - UITF 40</b></p>
                    <p>
                      If the partnership is carrying on a trade,
                      profession or vocation and has received income
                      from contracts for professional or other services
                      then you will need to change the way in which
                      you calculate turnover in your accounts. This
                      is because there was a change in the accounting
                      guidance on service contracts for accounting
                      periods ending on or after 22 June 2005. This
                      guidance, issued by the Accounting Standards
                      Board, is called Urgent Issues Task Force
                      Abstract 40 (UITF 40).
                    </p>
                    <p>
                      If the guidance applies to you, you may also need
                      to include a tax adjustment in this return.
                    </p>
                    <p>
                      Helpsheet 238 Revenue recognition in service
                      contracts – UITF 40 tells you more about the new
                      accounting guidance, why it affects your tax and
                      how to work out any tax adjustment.
                    </p>
                    <p>
                      When you have read the helpsheet and worked
                      out the amount of the adjustment charged to tax
                      this year, put it in box 3.82. Please also put the
                      service contract adjustment amount in box 3.116
                      'Additional information'.
                    </p>
                    <p>
                      If a partner left the partnership in the previous
                      tax year they may still be taxable on a share of
                      the adjustment in the current year. However, you
                      should not include this share in box 3.82.
                      See Helpsheet 238 for more help in completing
                      the return.
                    </p>
                    <p><b>Intermediaries legislation - IR35</b></p>
                    <p>
                      If the partnership provides a partner's services, or
                      the services of others, to clients in circumstances
                      where that partner would be an employee if
                      they worked for the client directly and not
                      through the partnership, then rules about tax
                      and National Insurance contributions introduced
                      from 6 April 2000 may apply. Read the section
                      headed 'The provision of personal services
                      through a partnership – deemed employment
                      payment' in Helpsheet 222 How to calculate your
                      taxable profits. It tells you how to work out the
                      adjustments to make to the partnership's taxable
                      profits and where to enter these on the Partnership
                      Tax Return.
                    </p>
                  </div>
                  <p style="text-align: right"></p>
                  <p>
                    <b>Transfer of information to the Partnership Statement</b>
                  </p>
                  <p>
                    The partners need to know their share of certain entries in the Partnership Trading
                    pages when calculating the tax due on their shares of trade and professional income.
                  </p>
                  <p>
                    Transfer the amount of:
                  </p>
                  <ul>
                    <li>
                      any ‘adjustment’ in box 3.82 to box 11A; only adjustment income allocated to those
                      who were partners during the return period should be transferred to the Partnership
                      Statement
                    </li>
                    <li>any net profit in box 3.83 to box 11</li>
                    <li>any net loss in box 3.84 to box 12</li>
                    <li>any CIS deductions made on account of tax in box 3.97 to box 24</li>
                    <li>any tax taken off trading income in box 3.98 to box 24A</li>
                    <li>any partnership charges in box 3.117 to box 29.</li>
                    <li *ngIf="this.userService.Present_year < '2024'">
                      any amount of incorrectly claimed HMRC coronavirus support scheme payments which
                      were received in the year ended 5 April 2023 to box 12B
                    </li>
                  </ul>
                  <p>
                    If you want to allocate these figures between the partners at the same time, read
                    pages PTRG 24 to PTRG 27 of this guide before you do so. Otherwise move on to Question
                    7.
                  </p>
                  <div *ngIf="this.userService.Present_year < '2024'">
                    <p>
                      Transfer the declaration of disguised remuneration income from box 3.94 to box 12A.
                    </p>
                  </div>
                  <p>
                    <b>Glossary</b>
                  </p>
                  <p>
                    <b>Accounting date</b><br />
                    The date to which the partnership accounts are drawn up or, if you do not have accounts,
                    the date to which you have provided details of your business income and expenditure.
                  </p>
                  <p>
                    <b>Accounting period</b>
                    <br />
                    The period for which the partnership accounts are drawn up or, if you do not have
                    accounts, the period for which you have provided details of your income and expenditure.
                  </p>
                  <p>
                    <b>Balancing charges</b>
                    <br />
                    Withdrawal of some or all of the capital allowances previously given. They arise
                    when fixed assets stop being used in your business.
                  </p>
                  <p>
                    <b>Capital allowances</b>
                    <br />
                    Allowances against tax for the cost of certain fixed assets.
                  </p>
                  <p>
                    <strong>Cash basis</strong>
                  </p>
                  <p>
                    A simpler way of working out your business profits or losses using income received
                    and expenses paid.
                  </p>
                  <p>
                    <b>First year allowance</b>
                    <br />
                    A capital allowance at a rate higher than the usual writing down allowance. It is
                    given for the year in which an asset is acquired and is given instead of a writing
                    down allowance.
                  </p>
                  <p>
                    <b>Fixed assets</b>
                    <br />
                    Assets such as buildings, plant and machinery, vehicles, and so on that you use
                    in the business but do not buy and sell as part of your ordinary trading operations.
                    For example, if the partnership carried on the business of plumbing, the van and
                    your tools are fixed assets but your pipes, boilers, etc are not (they are stock).
                    The cost of buying fixed assets is called capital expenditure.
                  </p>
                  <p>
                    <b>Stock</b>
                    <br />
                    Raw materials used in your business and goods bought for resale which you have on
                    hand.
                  </p>
                  <p>
                    <b>Trade</b>
                    <br />
                    Any commercial operation supplying goods or services to a customer for profit is
                    likely to be regarded as a trade. If you are in doubt about whether you carried
                    on a trade, profession or vocation during {{this.userService.Previous_year}} to {{this.userService.Present_year}}, ask us or your tax adviser.
                  </p>
                  <p>
                    <b>Traditional accounting (accruals basis)</b>
                    <br />
                    A way of working out your business profits or losses using generally accepted accounting
                    practice. Include in your turnover all money when it is earned, even if the partnership
                    did not receive the money until later. Amounts that you have earned but not received
                    by the accounting date should therefore be counted as turnover. This will include
                    goods the partnership had delivered by the accounting date, even if the partnership
                    had not issued a bill by then. But make sure that you do not count money the partnership
                    received in this period that was included as turnover in an earlier period when
                    it was earned.
                  </p>
                  <p>
                    <b>Turnover</b><br />
                    The income of your business before deducting any business expenses. Turnover is
                    the money <strong>received </strong>by your business if you use ‘cash basis’ or
                    the total takings, fees, sales or money earned by your business if you use traditional
                    accounting (accruals basis). It includes receipts in cash or in kind for goods sold
                    or work done, commission, fees receivable, tips, insurance proceeds for stock and
                    loss of profits etc. Do not include amounts received from the sale of capital items,
                    that is, assets which are of lasting use to the business, such as business premises,
                    plant, machinery and vehicles.
                  </p>
                  <p>
                    <b>Work in progress</b>
                    <br />
                    Partially manufactured stock the partnership has on hand.
                  </p>
                  <p style="text-align: right">

                  </p>
                  <hr />
                  <table border="0" cellpadding="5" cellspacing="0" style="text-align: justify; border: 1px #000 solid;"
                         width="90%">
                    <tr>
                      <td>
                        These notes are for guidance only, and reflect the position at the time of writing.
                        They do not affect any rights of appeal. Any subsequent amendments to these notes
                        can be found at <a class="link" href="http://www.hmrc.gov.uk" target="_blank">www.hmrc.gov.uk</a>
                      </td>
                    </tr>
                  </table>

                </div>
              </div>
          </div>
          <!--SA801 starts here......-->
          <div id="SA801" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <table id="AutoNumber1" border="0" bordercolor="#111111" cellpadding="0" cellspacing="0" style="border-collapse: collapse" width="98%">
                  <tr>
                    <td align="right" width="93%">
                      <p style="text-align: right;">
                        <span style="font-size: 25px;" class="headingSA">
                          Partnership UK property
                        </span><br>
                        <span style="font-size: 15px;" class="headingSA"><b>Tax year 6 April {{this.userService.Previous_year}}  to 5 April {{this.userService.Present_year}} ({{this.userService.Currenttax_shortyear}})</b></span>
                      </p>
                    </td>
                    <td width="7%">&nbsp;</td>
                  </tr>
                  <tr>
                    <td align="right" bgcolor="#00ABBC" width="93%">&nbsp;</td>
                    <td bgcolor="#666666" width="7%">&nbsp;</td>
                  </tr>
                </table>
                <br>
                <table cellpadding="3" cellspacing="0" style="border-collapse: collapse" width="98%">
                  <tr>
                    <td valign="top" width="20%">
                      <a name="top"></a>
                      <a class="link" href="help#uk-one">Boxes 1.1 and 1.2</a>
                    </td>
                    <td valign="top" width="20%"><a class="link" href="help#1p3">Box 1.3</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#1p3A">Box 1.3A</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#1p4">Box 1.4</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#1p5">Box 1.5</a></td>
                  </tr>
                  <tr>
                    <td valign="top" width="20%"><a class="link" href="help#1p6">Box 1.6</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#1p7">Box 1.7</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#1p8">Box 1.8</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#1p9">Box 1.9</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#1p12">Box 1.12</a></td>
                  </tr>
                  <tr>
                    <td valign="top" width="20%"><a class="link" href="help#1p13-15">Boxes 1.13 and 1.15</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#1p13a">Box 1.13A</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#1p13b">Box 1.13B</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#1p15b">Box 1.15B</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#1p18">Box 1.18</a></td>
                  </tr>
                  <tr>
                    <td valign="top" width="20%"><a class="link" href="help#1p19">Box 1.19</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#1p20">Box 1.20</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#1p21">Box 1.21</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#1p22">Box 1.22</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#1p22A">Box 1.22A</a></td>
                  </tr>
                  <tr>
                    <td valign="top" width="20%"><a class="link" href="help#1p23">Box 1.23</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#1p23a">Box 1.23A</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#1p25">Box 1.25</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#1p26">Box 1.26</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#1p27">Box 1.27</a></td>
                  </tr>
                  <tr>
                    <td valign="top" width="20%"><a class="link" href="help#1p28">Box 1.28</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#1p29">Box 1.29</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#1p30">Box 1.30</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#1p33">Box 1.33</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#1p34-36">Boxes 1.34 and 36</a></td>
                  </tr>
                  <tr>

                    <td valign="top" width="20%"><a class="link" href="help#1p35b">Box 1.35A and 1.35B</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#1p35c">Box 1.35C</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#1p35d">Box 1.35D</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#1p35e">Box 1.35E</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#1p37">Box 1.37</a></td>
                  </tr>
                  <tr>
                    <!--<td valign="top" width="20%"><a class="link" href="help#1p36a">Box 1.36A</a></td>-->

                    <td valign="top" width="20%"><a class="link" href="help#1p40">Box 1.40</a></td>
                  </tr>
                </table>
                <hr>
                <p><b>Filling in the 'Partnership UK Property' Pages</b></p>
                <p>
                  The 'Partnership UK property' pages apply both to rental businesses with numerous
                  properties, and to partnerships with one&nbsp; property to rent.
                </p>
                <p>Please gather together your records of rent received and expenses.</p>
                <p>Complete these Pages if the partnership has:</p>
                <ul>
                  <li>rental income and other receipts from land and property in the UK.</li>
                  <li>chargeable premiums arising from leases of land in the UK </li>
                  <li>furnished holiday lettings (FHL) in the UK or European Economic Area (EEA</li>
                  <li>a reverse premium </li>
                </ul>
                <div *ngIf="this.userService.Present_year < '2024'">
                  <p><b>Coronavirus support scheme payments</b></p>
                  <p>
                    You must include amounts of taxable coronavirus support scheme payments that the partnership received,
                    subject to further guidance in these notes.
                  </p>
                  <p>These include the following:</p>
                  <ul>
                    <li>Coronavirus	Job	Retention Scheme (CJRS)</li>
                    <li> any other applicable HMRC coronavirus support scheme </li>
                    <li>
                      payments that the partnership was entitled to receive from local authorities or
                      devolved administrations
                    </li>
                  </ul>
                  <p>
                    For more information about coronavirus support schemes, go to
                    <a href="https://www.gov.uk/government/statistics/coronavirus-support-schemes-grants-and-loans" target="_blank" class="link">
                      www.gov.uk/government/statistics/coronavirus-support-schemes- grants-and-loans
                    </a>
                  </p>
                  <p>
                    If the partnership received a CJRS or any other applicable HMRC coronavirus
                    support scheme payment that it was not entitled to and it has not been
                    voluntarily paid back to HMRC or none of the partners have received an
                    assessment issued by an officer of HMRC in respect of the incorrectly claimed
                    payment, put the incorrectly claimed amounts in box 3.118 of the Partnership Tax
                    Return. &nbsp;
                  </p>
                </div>
                <p>
                  Use the ‘Partnership foreign’ pages for income from land or property overseas,
                  except for FHL income from properties in the EEA. Go to <a href="http://www.gov.uk" target="_blank" class="link">www.gov.uk</a>
                  and search
                  for ‘SA802’ for the ‘Partnership foreign’ pages.
                </p>
                <p>
                  There are certain receipts that arise out of the use of land that cannot be
                  included in these pages. If the partnership runs the following type of business,
                  you must fill in the ‘Partnership trading’ pages:
                </p>
                <ul>
                  <li>canals, inland navigations and docks </li>
                  <li>mines and quarries, including sandpits, gravel pits and brickfields </li>
                  <li>rights of markets and fairs, tolls, bridges and ferries </li>
                </ul>
                <p>
                  This list is not comprehensive. If you do not know whether to include a
                  particular sum, ask us or your tax adviser.
                </p>
                <p>
                  If the partnership owns property which is let as a furnished holiday letting in
                  the UK or EEA, follow the guidance starting on page PLN 3. If the partnership
                  lets property other than furnished holiday lettings, follow the guidance
                  starting on page PLN 7 about other taxable income from land and property in the
                  UK. Everyone who fills in the ‘Partnership UK property’ pages should read the
                  general guidance starting on page PLN 2.
                </p>
                <p><b>Return period</b></p>
                <a name="1p1"></a><a name="1p2"></a><a name="1p1-2"></a>
                <p id="uk-one"><b>Boxes 1.1 and 1.2</b></p>
                <p>
                  For all partnerships (except investment partnerships other than ‘CT
                  Partnerships’, see below) the ‘Partnership UK property’ pages should be
                  completed for the profits of the partnership’s rental business for its
                  accounting periods ended in the {{this.userService.Previous_year}} to {{this.userService.Present_year}}
                  tax year. In these circumstances,
                  the dates shown in boxes 1.1 and 1.2 must be the same as those you’ve entered in
                  boxes 3.4 and 3.5 on page 2 of the Partnership Tax Return.
                </p>
                <p><b>Return period for investment partnerships</b></p>
                <p>
                  Where, exceptionally, a partnership does not carry on a trade or profession, the
                  ‘Partnership UK property’ pages should be completed for the profits of the
                  rental business in the period 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}, unless the
                  partnership is a ‘CT Partnership’ (see page PTRG 5 of the Partnership Tax Return
                  Guide).
                </p>
                <p>
                  If accounts are made up for any other period, apportion figures in the sets of
                  accounts that between them cover the period 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}} (if
                  apportionment gives a reasonable approximation of the actual figures for that
                  period), or provide the actual figures themselves.
                </p>
                <p>Enter ‘6/4/{{this.userService.Previous_year}}’ in box 1.1 and ‘5/4/{{this.userService.Present_year}}’ in box 1.2. </p>
                <p><b>More than one accounting period</b></p>
                <p>
                  Where a Partnership Tax Return is required for the accounting period ended in the
                  period 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}} and there’s more than one such accounting
                  period, you may have to complete more than one set of ‘Partnership UK property’
                  pages. (See the notes on page PTRG 9 of the Partnership Tax Return Guide.)
                </p>
                <p><b>Providing details of income and expenditure</b></p>
                <p>
                  Accounts are prepared for a variety of reasons and in a variety of ways. It may
                  not be immediately obvious where to enter some of your figures. The notes which
                  follow are designed to give guidance in the most difficult areas but they are
                  not intended to be hard and fast rules.
                </p>
                <p>
                  In some instances you may need to combine or apportion your figures to fit the
                  Partnership Tax Return. If you include an expense under one heading where
                  another may be equally appropriate, you must be consistent from one year to the
                  next.
                </p>
                <p><b>Accounting methods</b></p>
                <p>
                  For partnerships with only individuals as partners, you can choose one of 2
                  methods.
                </p>
                <p><b>Method 1 – traditional accounting</b></p>
                <p>You record income and expenses by the date you invoiced or were billed. </p>
                <p><b>Method 2 – cash basis accounting</b></p>
                <p>
                  Businesses with an income of £150,000 or less can use cash basis reporting. With
                  this method, you only record income or expenses when you receive money or pay a
                  bill.
                </p>
                <p>
                  For guidance on deciding if cash basis or traditional accounting is the right
                  option to use, go to
                  <a href="http://www.gov.uk/guidance/income-tax-when-you-rent-out-a-property-working-out-your-rental-income" target="_blank" class="link">
                    www.gov.uk/guidance/income-tax-when-you-rent-out-a-property-working-out-your-rental-income
                  </a>
                </p>
                <p>
                  We assume that if your receipts are £150,000 or less for the tax year that you’ll
                  use cash basis rather than traditional accruals accounting.
                </p>
                <p>
                  You need to tell us if you have used traditional accruals accounting by
                  completing the election boxes 1.3A and or 1.22A.
                </p>
                <p>
                  Make sure that you transfer all the entries in your accounts and that you include
                  them once only. Complete the relevant information on all pages even if the
                  partnership does not have accounts. If you need help, ask us or your tax
                  adviser.
                </p>
                <p>
                  If the partnership has been told that they’re within the Managing Serious
                  Defaulters (MSD) programme, complete all applicable boxes on pages PL 1 and PL
                  2. If the partnership has been told that they’re the subject of the additional
                  reporting requirements you must also send the detailed partnership accounts,
                  balance sheet and computations with the Partnership Tax Return. Identify and
                  explain the nature and amount of any figures contained in those accounts that
                  cannot be vouched by physical or electronic records made at the time that the
                  underlying transactions took place, or written confirmation that no such figures
                  are included.
                </p>
                <hr>
                <p>Furnished holiday lettings in the UK or European Economic Area (EEA) </p>
                <p>
                  Properties that are let as furnished holiday lettings are treated differently
                  from other let properties for tax purposes and have certain tax advantages. For
                  more information about this, read Helpsheet 253, ‘Furnished holiday lettings’.
                </p>
                <p>
                  If the partnership has furnished holiday lettings, start by filling in the first
                  of the ‘Partnership UK property’ pages. Then fill in the second page. Details of
                  other rental and business income and expenses go on the second page (see the
                  notes on page PLN 7).
                </p>
                <p><b>What is a furnished holiday letting?</b></p>
                <p>
                  We normally regard a letting as a furnished holiday letting if it’s a property in
                  the UK or EEA that is:
                </p>
                <ul>
                  <li>furnished</li>
                  <li>
                    available for holiday letting to the public on a commercial basis for 210 days
                    or more during the year (the availability condition)
                  </li>
                  <li>
                    actually let commercially as holiday accommodation for 105 days or more during
                    the year (the letting condition)
                  </li>
                </ul>
                <p>
                  There are other rules on long-term lets. If you think your property may qualify
                  as a furnished holiday letting, please read Helpsheet 253.
                </p>
                <p><b>EEA businesses</b></p>
                <p>
                  If you have an FHL business in the EEA, complete boxes 1.3 to 1.20 following the
                  guidance below and put an ‘X’ in box 1.19. If you have both a UK FHL business
                  and an EEA FHL business, complete a separate page for each business and put an
                  ‘X’ in box 1.19 for the EEA business.
                </p>
                <hr>
                <p>&nbsp;</p>
                <a name="1p3"></a>
                <p id="1p3"><b>Box 1.3 Income from furnished holiday lettings</b></p>
                <p>
                  Enter the gross amount of income from properties that qualify as holiday lettings
                  in the UK or EEA in box 1.3. This includes all the rents that relate to the year
                  ended 5 April {{this.userService.Present_year}} and any monies obtained from the provision of any services to
                  tenants. Non-resident landlords must show income here before deduction of tax.
                  The tax will be included at box 1.22.
                </p>
                <div *ngIf="this.userService.Present_year < '2024'">
                  <p>
                    Coronavirus support scheme payments are taxable and include payments from the
                    Coronavirus Job Retention Scheme (CJRS), any other applicable HMRC coronavirus
                    support scheme and payments the partnership was entitled to receive from local
                    authorities or devolved administrations.
                  </p>
                  <p>
                    If the partnership received coronavirus support scheme payments, include in box
                    1.3 the amount the partnership received.
                  </p>
                  <p>
                    If any of the partners received an assessment issued by an officer of HMRC in
                    respect of a coronavirus support scheme payment incorrectly claimed only include
                    in box 1.3 the amount the partnership retained.
                  </p>
                  <p>
                    Do not include in box 1.3 the amount of any sum that has been assessed which
                    results in the repayment of coronavirus support scheme payments that the
                    partnership was not entitled to.
                  </p>
                  <p>
                    If the partnership received a CJRS or any other applicable HMRC coronavirus
                    support scheme payment that it was not entitled to and it has not been
                    voluntarily paid back to HMRC or none of the partners have received an
                    assessment issued by an officer of HMRC in respect of the incorrectly claimed
                    payment, put the incorrectly claimed amount in box 3.118 of the Partnership Tax
                    Return.
                  </p>
                </div>
                <a name="1p3A"></a>
                <p id="1p3A"><b>Box 1.3A&nbsp;&nbsp; Traditional accounting </b></p>
                <p>
                  Tick box 1.3A if you used traditional
                  accounting instead of cash basis. Only partnerships
                  that just have individuals as partners can use the
                  cash basis.Put ‘X’ in box 1.3A if you used traditional accounting instead of cash basis.
                  Only partnerships that just have individuals as partners can use the cash basis.
                </p>
                <p>
                  If your receipts from your property business (UK and UK FHL or overseas and EEA
                  FHL) are over £150,000 for the tax year, you must use traditional accounting.
                </p>
                <p>If you have both UK FHL and UK property income, put ‘X’ in boxes 1.3A and 1.22A. </p>
                <p>
                  If you have both EEA FHL and overseas property income, put ‘X’ in boxes 1.3A and
                  2.8A on the SA802.
                </p>
                <p><b>Cash basis</b></p>
                <p>
                  Cash basis is a simpler way of working out your property business profits or
                  losses. Only partnerships that just have individuals as partners can use the
                  cash basis. You add up all your property income received and take off any
                  allowable expenses paid in the year.
                </p>
                <p>
                  If you use cash basis do not include money you owe or money you are owed at the
                  end of the year.
                </p>
                <p>
                  You cannot claim capital allowances if you’re using cash basis. The only
                  exception is cars.
                </p>
                <p>
                  For guidance on deciding if cash basis or traditional accounting is the right
                  option for you, go to
                  <a href="http://www.gov.uk/guidance/income-tax-when-you-rent-out-a-property-working-out-your-rental-income" target="_blank" class="link">
                    www.gov.uk/guidance/income-tax-when-you-rentout-a-property-working-out-your-rental-income
                  </a>
                </p>
                <p>
                  You can decide each year if cash basis is the best option for you and you must
                  tell us each year if you used traditional accounting and not cash basis.
                </p>
                <p>
                  If you have both UK FHL and UK property income, you must use the same basis for
                  both. Boxes 1.3A and 1.22A must be the same.
                </p>
                <p>
                  If you have both EEA FHL and overseas property income, you must use the same
                  basis for both. Boxes 1.3A and 2.8A on the SA802 must be the same.
                </p>
                <p><b>Transitional adjustments</b></p>
                <p>
                  If you change accounting practice, you may need to make a transitional
                  adjustment. For guidance on the adjustments you’ll need to make, go to
                  <a href="http://www.gov.uk/guidance/income-tax-when-you-rent-out-a-property-working-out-your-rental-income" target="_blank" class="link">
                    www.gov.uk/guidance/income-tax-when-you-rent-out-a-property-working-out-your-rental-income
                  </a>
                  All transitional receipts must be included in box 1.3 and all transitional
                  expenses must be included in box 1.9.
                </p>
                <hr>
                <p><b>Expenses</b></p>
                <p>
                  If the partnership’s total property income in the year, including furnished
                  holiday letting income, before expenses is less than £85,000 annually, you do
                  not have to list expenses separately. Instead, put total expenses in box 1.9.
                </p>
                <div *ngIf="this.userService.Present_year < '2024'">
                  <!--<p>Include wages funded by payments under the CJRS. </p>-->
                </div>
                <p>
                  The following guidelines give an indication of the main types of expenses likely
                  to arise from holiday lettings and what usually cannot or can be claimed as a
                  deduction.
                </p>
                <p><b>Non-allowable expenses:</b></p>
                <ul>
                  <li>A partner’s personal expenses (see the notes about box 1.12 on page PLN 6). </li>
                  <li>
                    Capital costs, such as expenses relating to the purchase of the land or property
                    it intends to let, or the cost of purchasing machinery, furnishings or
                    furniture.
                  </li>
                  <li>Any loss made on the sale of a property.</li>
                </ul>
                <p><b>Allowable expenses:</b></p>
                <ul>
                  <li>In general any costs incurred for the sole purpose of earning business profits.</li>
                  <li>
                    You may be able to claim capital allowances on the cost of buying a capital
                    asset.
                  </li>
                </ul>
                <p>
                  The expenses must be allocated to the correct
                  return period and it may be necessary to apportion
                  certain expenses to arrive at the correct amount.
                  If you include any expenses in boxes 1.4 to 1.9,
                  make sure that you don’t include them again in
                  boxes 1.25 to 1.30 on page PL 2.
                </p>
                <hr>
                <p>&nbsp;</p>
                <a name="1p4"></a>
                <p id="1p4"><b>Box 1.4 Rent, rates, insurance, ground rents</b></p>
                <p>
                  Any rent paid under a lease of a property let to someone else as a furnished
                  holiday letting can be deducted in working out business profits.
                </p>
                <p>
                  Other expenses connected with the property such as business rates, Council Tax,
                  water rates, or ground rents are also allowable. Enter the total of these
                  expenses incurred in the period in respect of all properties in the business in
                  box 1.4.
                </p>
                <p>
                  Include any expenses the partnership must incur as landlord to insure the
                  furnished holiday letting and its contents in box 1.4.
                </p>
                <p>
                  Insurance against loss of rents is also an allowable cost but you must include in
                  box 1.3 any income you receive as a result of taking out such an insurance.
                  Insurances that extend beyond the lettings business, such as personal policies
                  or those insuring the partner’s private belongings, are not allowable costs.
                </p>
                <hr>
                <a name="1p5"></a>
                <p>&nbsp;</p>
                <p id="1p5"><b>&nbsp;&nbsp; Box 1.5&nbsp; Repairs and maintenance</b></p>
                <p>
                  Expenses that prevent the property from deteriorating can be deducted as a
                  repair. Examples include exterior and interior painting, stone-cleaning, damp
                  treatment, roof repairs, furniture repairs and repairs to lifts and other
                  machines that form part of the property.
                </p>
                <p>
                  You cannot deduct the cost of capital expenditure incurred on improvements,
                  additions and alterations to the property. Nor can you claim a deduction for the
                  cost of notional repairs that are no longer required as a result of this capital
                  expenditure.
                </p>
                <p>
                  If you’re in doubt whether any work on the property is a repair or maintenance,
                  ask us or your tax adviser.
                </p>
                <p><b>Renewals</b></p>
                <p>
                  The renewals allowance for the cost of replacing items is no longer available.
                  You may be able to claim capital allowances on some of your capital items such
                  as furniture, furnishings, appliances and kitchenware in boxes 1.13 to 1.15. See
                  PIM3230 in the Property Income Manual for more information on the background to
                  this change. Go to <a href="http://www.gov.uk/government/collections/hmrc-manuals" target="_blank" class="link">
                    www.gov.uk/government/collections/hmrc-manuals
                  </a>
                </p>
                <hr>
                <p>&nbsp;</p>
                <a name="1p6"></a>
                <p id="1p6"><b>Box 1.6 Finance charges, including interest</b></p>
                <p>
                  You can deduct the costs of getting a loan or alternative finance to buy a
                  property that you let, and any interest on such a loan or alternative finance
                  payments. You cannot claim the cost of any capital repayments from your
                  mortgage.
                </p>
                <p>
                  You must only include the proportion of the costs that are for the purpose of
                  your FHL properties and not any residential properties.
                </p>
                <p>
                  If you’re not sure whether any financial cost is allowable as a deduction, ask us
                  or your tax adviser.
                </p>
                <hr>
                <p>&nbsp;</p>
                <a name="1p7"></a>
                <p id="1p7"><b>Box 1.7 Legal and professional costs</b></p>
                <p>Below are some examples of expenses you cannot deduct and those you can. </p>
                <p><b>Non-allowable expenses:</b></p>
                <ul>
                  <li>
                    Expenses in connection with the first letting or subletting of a property for
                    more than one year. These include, for example, legal expenses (such as the cost
                    of drawing up the lease), agents’ and surveyors’ fees and commission.
                  </li>
                  <li>
                    Any proportion of the legal, etc costs that relates to the payment of a premium
                    on the renewal of a lease.
                  </li>
                  <li>
                    Fees incurred in obtaining planning permission, or on the registration of title
                    when buying a property.
                  </li>
                </ul>
                <p><b>Allowable expenses:</b></p>
                <ul>
                  <li>Expenses for the let of a year or less.</li>
                  <li>
                    Management fees relating to the ongoing costs of letting (for example, rent
                    collection).
                  </li>
                  <li>
                    The normal legal and professional fees incurred on the renewal of a lease, if
                    the lease is for less than 50 years.
                  </li>
                  <li>
                    Professional fees incurred evicting an unsatisfactory tenant, with a view to
                    reletting, or those on an appeal against a compulsory purchase order.
                  </li>
                  <li>Professional fees in drawing up accounts.</li>
                </ul>
                <p>
                  If you’re not sure whether any legal or professional fee is allowable as a
                  deduction, ask us or your tax adviser.
                </p>
                <hr>
                <p>&nbsp;</p>
                <a name="1p8"></a>
                <p id="1p8"><b>Box 1.8 Costs of services provided, including wages</b></p>
                <p>
                  If, in addition to letting a property, the partnership provides any service to
                  the tenant (such as gardening, porterage, cleaning or even something like
                  communal hot water) that requires a degree of maintenance and expense, you can
                  claim the cost of these services to the extent that they’re provided wholly and
                  exclusively for the purposes of the letting.
                </p>
                <p>
                  Include the total of any expenses for all properties and their associated
                  services in box 1.8. If the partnership receives any income for any service it
                  provides, include this in box 1.3.
                </p>
                <hr>
                <p>&nbsp;</p>
                <a name="1p9"></a>
                <p id="1p9"><b>Box 1.9 Other Expenses</b></p>
                <p>
                  Enter all expenses incurred wholly and exclusively for the purpose of the rental
                  business that are not already included in boxes 1.4 to 1.8 in box 1.9. Examples
                  include the costs of rent collection, advertising for tenants, travelling solely
                  for business purposes, stationery, phone calls and other miscellaneous expenses.
                </p>
                <p>
                  For information, on flat rate expenses for landlords, go to
                  <a href="http://www.gov.uk/hmrc-internal-manuals/property-income-manual/pim2220 " target="_blank" class="link">
                    www.gov.uk/hmrc-internal-manuals/property-income-manual/pim2220
                  </a>
                </p>
                <p>
                  If you’re a non-resident landlord enter any tax taken off your income at box
                  1.22.&nbsp;
                </p>
                <p>
                  However, partners’ personal expenses are not allowable. If the partnership spends
                  money on something only partly used for the property, you must only enter the
                  amount spent for business purposes in box 1.9. Alternatively, enter the whole
                  amount and deduct in box 1.12 the proportion of the cost that represents
                  personal use.
                </p>
                <p>Lease premium relief </p>
                <p>
                  Where the partnership (or an earlier tenant) paid a premium to the landlord when
                  the lease was granted, and the partnership is subletting the property in the
                  property business, you may be able to claim a deduction for part of the premium
                  paid. The relief for premiums paid is only due on amounts taxable on the
                  landlord as income (or would be if the landlord were liable to tax).
                </p>
                <p>
                  If you think you are entitled to a deduction for a premium paid, ask HMRC or your
                  tax adviser. Enter the amount of the allowable deduction in box 1.9. The notes
                  for box 1.23 explain how to calculate the part of the premium taxable on the
                  landlord as income.
                </p>
                <p><b>Tax adjustments</b></p>
                <p>
                  To arrive at the partnership’s income (or the allowable loss) for tax purposes
                  you need to make certain adjustments to the net profit or loss arising in the
                  year.
                </p>
                <hr>
                <p>&nbsp;</p>
                <a name="1p12"></a>
                <p id="1p12"><b>Box 1.12&nbsp; Private use</b></p>
                <p>
                  Private and personal expenses are not allowable. If the partnership spends money
                  on something used only partly for its rental business and partly for a
                  non-business reason you must either:
                </p>
                <ul>
                  <li> enter the amount spent for business purposes in boxes 1.4 to 1.9 </li>
                  <li>
                    enter the whole expenses in those boxes and in box 1.12 deduct the proportion of
                    the cost that represents the personal use or the non-business element
                  </li>
                </ul>
                <p>
                  For example, if the partnership lets a property for only 8 months in a year and
                  it’s used by the partners in the other 4 months, you can put the full annual
                  cost of insuring the property in box 1.4. If you do, add back one third of that
                  cost in box 1.12.
                </p>
                <p>
                  If you’re in any doubt whether a particular expense needs to be apportioned
                  between business and private use, ask us or your tax adviser.
                </p>
                <hr>
                <p>&nbsp;</p>
                <a name="1p13"></a><a name="1p15"></a><a name="1p13-15"></a>
                <p><b>Boxes 1.13, 1.13A,1.13B and 1.15 Capital allowances and balancing charges</b></p>
                <p>
                  In working out the profit from furnished holiday lettings you must not deduct
                  either:
                </p>
                <ul>
                  <li>the cost of buying, altering, building, installing or improving fixed assets </li>
                  <li>depreciation or any losses that arise when you sell them</li>
                </ul>
                <p>
                  Instead, the partnership may be able to claim capital allowances in boxes 1.13A,
                  1.13B and 1.15. These reduce a profit or increase a loss. An adjustment, known
                  as a balancing charge, may arise when the partnership sells an item, gives it
                  away or stops using it in the business. Enter the amount of balancing charges in
                  box 1.13. These increase profits or reduce a loss.
                </p>
                <p>
                  Under rules introduced in 2012 and 2014, a purchaser of a second-hand business
                  property containing fixtures (such as kitchen fittings, electrical or heating
                  systems) will not usually be entitled to claim allowances unless the past owner
                  has ‘pooled’ its qualifying expenditure and has fixed the value of the fixtures.&nbsp;
                  ‘Pooling’ includes making a claim for first year allowance or annual investment
                  allowance for the expenditure. It is not necessary for the past owner to claim
                  writing down allowances. As a rule, the past owner is the last person who was
                  entitled to claim capital allowances on fixtures.
                </p>
                <p>
                  Normally, the value will be fixed by means of a joint section 198 CAA 2001
                  election, which must be notified to HM Revenue and Customs (HMRC) within 2 years
                  of the property transaction. The amount the seller can bring in to any pool as
                  the disposal value will be the same as the amount the buyer can bring in as the
                  acquisition value for capital allowances purposes.
                </p>
                <p><strong>Box 1.13 Balancing charges </strong> </p>
                <p>
                  Balancing charges may arise following a disposal or balancing event, such as the
                  sale, loss or destruction of assets or on the cessation of business use, where
                  the proceeds from the event are more than their tax value. If you sell an item
                  you’ve claimed capital allowances on, and the sale proceeds or value of the item
                  is more than the pool value or cost you’ll have to pay tax on the difference (a
                  ‘balancing charge’). This includes items where the pool value is nil because you
                  claimed all of the cost previously. Put the total balancing charge in box 1.13.
                </p>
                <hr>
                <a name="1p13a"></a>
                <p>&nbsp;</p>
                <p><strong>&nbsp;<b>Box 1.13A</b></strong></p>
                <p>Electric charge-point allowance</p>
                <p>
                  You can claim 100% first year capital allowances for expenditure invested in the
                  acquisition and installation of new and unused electric charge-points for
                  electric vehicles. Put the amount of expenditure incurred in box 1.13A.
                </p>
                <p>
                  Do not claim capital allowances if you’re using cash basis. The only exception is
                  cars.
                </p>
                <hr>
                <a name="1p13b"></a>
                <p>&nbsp;</p>
                <p><strong><b>Box 1.13B</b> Zero-emission car allowance</strong> </p>
                <p>
                  Claim the 100% first year allowance (the full cost) of any new and unused
                  zero-emission or electric cars in this box.
                </p>
                <p>
                  If you use a car outside of your business you must reduce the claim in proportion
                  to the non-business use.
                </p>
                <p> <strong>Box 1.15 All other capital allowances </strong></p>
                <p>
                  If you’re claiming capital allowances for any other equipment or vehicles for
                  your FHL put the amount in box 1.15.
                </p>
                <p>
                  You cannot claim capital allowances if you’re using cash basis. The only
                  exception for those using cash basis is cars.
                </p>
                <p>
                  For more information, go to
                  <a href="http://www.gov.uk/business-tax/capital-allowances" target="_blank" class="link">
                    www.gov.uk/business-tax/capital-allowances
                  </a>
                </p>
                <p>
                  Your tax adviser, if you have one, will tell you how to work out capital
                  allowances and balancing charges.
                </p>
                <hr>
                <a name="1p15b"></a>
                <p>&nbsp;</p>
                <p><b>Box 1.15B&nbsp; Losses</b></p>
                <p>
                  The losses to enter in this box for {{this.userService.Previous_year}} to {{this.userService.Present_year}}
                  will have been allocated between
                  the partners in the previous year. Please review your records and add the
                  allocated losses back together and enter them in this box up to the level of the
                  FHL profits for the year.
                </p>
                <hr>
                <a name="1p18"></a>
                <p><b>Box 1.18</b></p>
                <p>
                  This will be the figure in box 1.17 together with any losses for earlier years
                  not set against this year's profits.
                </p>
                <hr>
                <a name="1p19"></a>
                <p>&nbsp;</p>
                <p><b>Box 1.19&nbsp;&nbsp; EEA business</b></p>
                <p>
                  If you’re filling in the page for an EEA property business put an ‘X’ here. If
                  you have several properties in different EEA states you need only complete one
                  EEA form – you do not have to use a separate page for each state.
                </p>
                <hr>
                <a name="1p20"></a>
                <p>&nbsp;</p>
                <p><b>Box 1.20&nbsp;&nbsp; Period of grace election</b></p>
                <p>If any property qualified as an FHL property in {{this.userService.Previous_Previousyear}} to {{this.userService.Previous_year}} but does not reach the occupation threshold in {{this.userService.Previous_year}} to {{this.userService.Present_year}} put an ‘X’ in box 1.20.</p>
                <p>
                  For more information
                  go to <a href="http://www.gov.uk" target="_blank" class="link">
                    www.gov.uk
                  </a>and search for ‘HS253’.
                </p>
                <p><b>Transferring information to the Partnership Statement</b></p>
                <p>
                  Transfer the amount of the profit at box 1.16 to box 20 in the Partnership
                  Statement. If you want to allocate this figure between the partners at the same
                  time, read pages 15 to 17 of the Partnership Tax Return Guide. Otherwise finish
                  filling in the rest of the ‘Partnership UK property’ pages.
                </p>
                <p><strong>Other property income </strong></p>
                <p>
                  If the partnership owns land or property in the UK and enters into any
                  transaction which produces rents or other receipts from the land or property,
                  it’s treated as operating a rental business.
                </p>
                <p>
                  The concept of a rental business is broad. The rental profit or loss takes
                  account of all rental and similar income (such as coronavirus support scheme
                  payments), and related expenses,&nbsp; resulting from the exploitation of land
                  or property in the UK. All activities by which the partnership derives income
                  from land and property in the same capacity are treated as activities of the one
                  business. It does not matter whether the partnership has a single property or
                  many properties. All form a single business.
                </p>
                <p>
                  If the partnership receives income from way leaves and also receives rents from
                  some or all of the land to which the way leaves relate, include the way leaves
                  in box 1.21. If the partnership is carrying on a trade or profession, include
                  the way leaves as part of the trading or professional income of the partnership
                  at box 3.50 (‘Partnership trading and professional income’ pages). Otherwise,
                  include the way leaves as ‘Other partnership income’ at box 7.26 (‘Partnership
                  savings, investments and other income’ pages).
                </p>
                <p>
                  The guidance below tells you what income and what expenses to include in the
                  profits or losses of the rental business.
                </p>
                <p><b>Income</b></p>
                <p>&nbsp;</p>
                <a name="1p21"></a>
                <p><b>Box 1.21&nbsp; Rents and other income from UK property</b></p>
                <p>
                  Enter the total of all income arising to the partnership business from any land
                  and property you’ve rented out in the UK, except income arising from holiday
                  lettings, chargeable premiums and reverse premiums (see notes on boxes 1.23 and
                  1.23A) in box 1.21.
                </p>
                <div *ngIf="this.userService.Present_year < '2024'">
                  <p>
                    Coronavirus support scheme payments are taxable and include payments from the
                    Coronavirus Job Retention Scheme (CJRS), any other applicable HMRC coronavirus
                    support scheme and payments the partnership was entitled to receive from local
                    authorities or devolved administrations.
                  </p>
                  <p>
                    If the partnership received coronavirus support scheme payments, include in box
                    1.21 the amount the partnership received.
                  </p>
                  <p>
                    If any of the partners received an assessment issued by an officer of HMRC in
                    respect of a coronavirus support payment incorrectly claimed only include in box
                    1.21 the amount the partnership retained.
                  </p>
                  <p>
                    Do not include in box 1.21 the amount of any sum that has been assessed which
                    results in the repayment of coronavirus support scheme payments that the
                    partnership was not entitled to.
                  </p>
                  <p>
                    If the partnership received a CJRS or any other applicable HMRC coronavirus
                    support scheme payment that it was not entitled to and it has not&nbsp; been
                    voluntarily paid back to HMRC or none of the partners have received an
                    assessment issued by an officer of HMRC in respect of the incorrectly claimed
                    payment, put the incorrectly claimed amount in box 3.118 of the Partnership Tax
                    Return.
                  </p>
                </div>
                <p>
                  Income also includes receipts in cash or in kind. When using traditional
                  accounting, rather than cash basis, income is taxed when it’s earned, even if
                  the partnership does not receive the money or goods until later.
                </p>
                <p>
                  Include in box 1.21 any rent the partnership receives (or will receive) after 5
                  April {{this.userService.Previous_year}} which is payment for the year ended 5 April {{this.userService.Previous_year}} (because it’s paid
                  in arrears). Exclude any rent received which relates to any period after 5 April
                  {{this.userService.Previous_year}} (because it’s paid in advance)
                </p>
                <p>
                  It must be included in the income for the year to which it relates. Make sure you
                  do not count money you received in this year if it was included in an earlier
                  year.
                </p>
                <p>
                  Cash basis is a simpler way of working out your property profits or losses. Only
                  partnerships that just have individuals as partners can use the cash basis.
                </p>
                <p>
                  You add up all your property income received and take off any allowable expenses
                  paid in the year. If you use cash basis do not include money you owe or money
                  you are owed at the end of the year.
                </p>
                <p>
                  Do not claim capital allowances if you’re using cash basis. The only exception is
                  cars.
                </p>
                <p>
                  Generally, most income will be rental income from a tenancy, leasing, or
                  licensing agreements over the land or property. Rental income from furnished,
                  unfurnished, commercial and domestic accommodation, and from any land, should
                  all be included in the overall total. If the property is let furnished, any sums
                  that a tenant may pay for the use of furniture will be taxable as income of the
                  business. Add all this income together and put it in box 1.21.
                </p>
                <p><b>Receipts other than rent</b></p>
                <p>Receipts other than rents are also taxable. Some of the main categories are:</p>
                <ul>
                  <li>rent charges and ground rents</li>
                  <li>income arising from the grant of sporting rights</li>
                  <li>income arising from letting others tip waste on the land</li>
                  <li>
                    income from letting someone use the land when no lease or licence is created;
                    for example, receipts from a film crew who pay to film on the land
                  </li>
                  <li>
                    grants received from local authorities towards the cost of repairs of a property
                    – see the notes for box 1.26 for guidance on claiming relief for expenses on
                    repairs
                  </li>
                </ul>
                <p>
                  This list is not comprehensive. If you do not know whether to include a
                  particular sum, ask HMRC or your tax adviser.
                </p>
                <hr>
                <p>&nbsp;</p>
                <a name="1p22"></a>
                <p><b>Box 1.22&nbsp; Tax deducted</b></p>
                <p>
                  Certain income from property is received after tax has been taken off and
                  accounted to us. The main type of income from which tax may be taken off is
                  rental income received by a landlord whose usual place of abode is outside the
                  UK. Include any non-resident landlord tax taken off such income here – see box
                  1.9.
                </p>
                <p>
                  If the income in box 1.21 includes payments to the partnership from which tax
                  has been taken off, enter in box 1.22 the amount of tax taken off.
                </p>
                <p>
                  Make sure that the amount of income entered in box 1.21 includes any tax taken
                  off. Put the total amount received plus the tax that has been taken off, not
                  just the amount received after the tax has been taken off.
                </p>
                <p><strong>Transferring information to the Partnership Statement </strong></p>
                <p>
                  Transfer the amount of tax taken off entered in box 1.22 to box 25 in the
                  Partnership Statement (full). If you want to allocate this figure between the
                  partners at the same time, read pages 15 to 17 of the Partnership Tax Return
                  Guide. Otherwise finish filling in the rest of the ‘Partnership UK property’
                  pages.
                </p>
                <hr>
                <p>&nbsp;</p>
                <a name="1p22A"></a>
                <p><b>Box 1.22A&nbsp; Traditional accounting</b></p>
                <p>
                  Put ‘X’ in box 1.22A if you used traditional accounting instead of cash basis.
                  Only partnerships that just have individuals as partners can use the cash basis.
                </p>
                <p>
                  If your receipts from your UK property business and UK FHL income are £150,000 or
                  more for the tax year, you must use traditional accounting.
                </p>
                <p>If you have both UK FHL and UK property income, put ‘X’ in boxes 1.3A and 1.22A. </p>
                <p><b>Cash basis</b></p>
                <p>
                  Cash basis is a simpler way of working out your property business profits or
                  losses. You add up all your property income received and take off any allowable
                  expenses paid in the year.
                </p>
                <p>
                  If you use cash basis do not include money you owe or money you are owed at the
                  end of the year.
                </p>
                <p>
                  You cannot claim capital allowances if you’re using cash basis. The only
                  exception is cars.
                </p>
                <p>
                  For guidance on deciding if cash basis or traditional accounting is the right
                  option for you, go to <a href="http://www.gov.uk/guidance/income-tax-when-you-rent-out-a-property-working-out-your-rental-income" target="_blank" class="link">
                    www.gov.uk/guidance/income-tax-when-you-rent-out-a-property-working-out-your-rental-income
                  </a>
                </p>
                <p>
                  You can decide each year if cash basis is the best option for you and you must
                  tell us each year if you’re using traditional accounting and not cash basis.
                </p>
                <p>
                  If you have both UK FHL and UK property income, you must use the same basis for
                  both. Boxes 1.3A and 1.22A must be the same.
                </p>
                <p><b>Transitional adjustments</b></p>
                <p>
                  If you change accounting practice, you may need to make a transitional
                  adjustment. For guidance on the adjustments you’ll need to make, go to<a href="https://www.gov.uk/guidance/income-tax-when-you-rent-out-a-property-working-out-your-rental-income" target="_blank" class="link">
                    www.gov.uk/guidance/income-tax-when-you-rent-out-a-property-working-out-your-rental-income
                  </a>
                </p>
                <p>
                  All transitional receipts must be included in box 1.21 and all transitional
                  expenses must be included in box 1.30.
                </p>
                <hr>
                <p>&nbsp;</p>
                <a name="1p23"></a>
                <p><b>Box 1.23&nbsp; Chargeable premiums</b></p>
                <p>
                  The income of the rental business may include premiums paid for the grant of a
                  lease and certain other lump sum payments, and other forms of consideration
                  given in connection with the right to possession of a property. These premiums
                  are taxable on a special basis.
                </p>
                <p>
                  Broadly, for leases over 50 years the entire premium is treated as a capital
                  receipt and so does not form part of the business’s income. For leases of up to
                  50 years the premium is treated as partly capital and partly rent, only the rent
                  is taxable.
                </p>
                <p>Use the Working Sheet below to arrive at the taxable amount.</p>

                <p>
                  If you’re in doubt whether any payment you have received constitutes a premium,
                  ask us or your tax adviser. <br />
                  There can be a premium charge where you’ve assigned a lease but not required the
                  payment of a premium. If you’ve assigned a lease and are not sure of all the
                  consequences of that assignment, ask us or your tax adviser.
                </p>
                <hr>
                <p>&nbsp;</p>
                <a name="1p23a"></a>
                <p><b>Box 1.23A&nbsp; Reverse premiums</b></p>
                <p>
                  If the partnership receives a payment or other benefit as an inducement to take
                  an interest in property for letting, that receipt will be chargeable as income
                  from property. If the partnership receives an inducement in respect of premises
                  from which it’s to trade, see the note for box 3.50 in the Partnership Tax
                  Return Guide. If you’re in any doubt about the proper tax treatment of a reverse
                  premium, ask us or your tax adviser.
                </p>
                <hr>
                <p><b>Expenses</b></p>
                <p>
                  If the total property income in the year, including furnished holiday letting
                  income, before expenses is less than £85,000 annually, you do not have to list
                  expenses separately. Instead, put total expenses in box 1.30.
                </p>
                <p>
                  The following guidelines give an indication of the main types of expenses that
                  are likely to arise in a rental business and what usually cannot or can be
                  claimed as a deduction.
                </p>
                <p><b>Non-allowable expenses:</b></p>
                <ul>
                  <li>A partner's personal expenses (see the notes about box 1.33).</li>
                  <li>
                    Capital costs, such as expenses relating to the purchase of the land or property
                    it intends to let, or the cost of purchasing machinery, furnishings or
                    furniture.
                  </li>
                  <li>any loss made on the sale of a property.</li>
                </ul>
                <p><b>Allowable expenses:</b></p>
                <ul>
                  <li>In general any costs incurred for the sole purpose of earning business profits</li>
                  <li>
                    You may be able to claim the cost of replacing domestic items in residential
                    lettings or capital allowances on the cost of buying a capital asset.
                  </li>
                </ul>
                <p>
                  The expenses must be allocated to the correct return period and it may be
                  necessary to apportion certain expenses to arrive at the correct amount.
                </p>
                <p>
                  Make sure you do not enter in boxes 1.25 to 1.30 any expenses you’ve already
                  included in the entries for boxes 1.4 to 1.9.
                </p>
                <hr>
                <p>&nbsp;</p>
                <a name="1p25"></a>
                <p><b>Box 1.25 Rent, rates, insurance, ground rents, etc</b></p>
                <p>
                  Any rent paid under a lease of a property that’s let to someone else can be
                  deducted in working out the partnership’s profits.
                </p>
                <p>
                  Other expenses connected with the property such as business rates, Council Tax,
                  water rates, or ground rents are also allowable. Enter in box 1.25 the total of
                  any such expenses incurred in the period in respect of all properties comprised
                  in the business.
                </p>
                <p>
                  Include any expenses the partnership must incur as landlord to insure any let
                  property and its contents in box 1.25. Insurance against loss of rents is also
                  an allowable cost but you must include in box 1.21 any income received as a
                  result of taking out such an insurance. Insurances that extend beyond the
                  partnership’s rental business, such as partners’ personal policies or those
                  insuring private belongings, are not allowable costs.
                </p>
                <hr>
                <a name="1p26"></a>
                <p><b>Box 1.26&nbsp; Repairs and maintenance</b></p>
                <p>&nbsp;</p>
                <p>
                  Expenses that prevent the property from deteriorating can be deducted as a
                  repair. Examples include exterior and interior painting, stone-cleaning, damp
                  treatment, roof repairs, furniture repairs and repairs to lifts and other
                  machines that form part of the property.
                </p>
                <p>
                  You cannot deduct the cost of capital expenditure incurred on improvements,
                  additions and alterations to the property. Nor can you claim a deduction for the
                  cost of notional repairs that are no longer required as a result of this capital
                  expenditure.
                </p>
                <p>
                  If you’re in doubt whether any work on the property is a repair or maintenance,
                  ask us or your tax adviser.
                </p>
                <p><b>Renewals</b></p>
                <p>
                  The renewals allowance for the cost of replacing items is no longer available.
                  Use box 1.37 for the cost of replacing domestic items such as furniture,
                  furnishings, appliances and kitchenware.
                </p>
                <hr>
                <p>&nbsp;</p>
                <a name="1p27"></a>
                <p><b>Box 1.27 <strong>Non-residential property finance costs</strong></b></p>
                <p>
                  For non-residential property you can deduct the costs of getting a loan or
                  alternative finance to buy a property that you let, and the full amount of any
                  interest on such a loan or alternative finance payments.&nbsp;
                </p>
                <p>
                  For residential properties, for the tax year 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}
                  non-corporate partners cannot deduct the cost of getting a loan, or alternative
                  finance to buy a property that is let, or any interest on such a loan or
                  alternative finance payments. Interest costs will be used to calculate a
                  reduction in the partners’ Income Tax in box 1.40.
                </p>
                <p>
                  Partnerships with corporate and non-corporate
                  members will need to calculate the costs of getting a loan, or alternative finance
                  to buy a property that is let or any interest on such a loan or alternative
                  finance arrangements separately for non-corporates only..
                </p>
                <p>
                  If interest costs for non-corporate partners refer to residential property in the
                  tax year 6 April {{this.userService.Previous_Previousyear}} to 5 April {{this.userService.Previous_year}} or earlier, a proportion of the interest
                  can be deducted. The remaining proportion should be entered in box 1.40.
                </p>
                <p>
                  If you’re not sure whether any financial cost is
                  allowable as a deduction, ask us or your tax
                  adviser.
                </p>
                <p>
                  For more information on the residential property finance costs restriction,go to
                  <a href="https://www.gov.uk/guidance/changes-to-tax-relief-for-residential-landlords-how-its-worked-out-including-case-studies" target="_blank" class="link">www.gov.uk/guidance/changes-to-tax-relief-for-residential-landlords-how-its-worked-out-including-case-studies</a>
                </p>
                <hr>
                <p>&nbsp;</p>
                <a name="1p28"></a>
                <p><b>Box 1.28 Legal and professional costs</b></p>
                <p>
                  Below are some examples of expenses the partnership cannot deduct and those it
                  can.
                </p>
                <p><b>Non-allowable expenses:</b></p>
                <ul>
                  <li>
                    Expenses in connection with the first letting or subletting of a property for
                    more than one year. These include, for example, legal expenses (such as the cost
                    of drawing up the lease), agents’ and surveyors’ fees and commission
                  </li>
                  <li>
                    Any proportion of the legal etc costs that relates to the payment of a premium
                    on the renewal of a lease.
                  </li>
                  <li>
                    Fees incurred in obtaining planning permission, or on the registration of title
                    when buying a property.
                  </li>
                </ul>
                <p><b>Allowable expenses:</b></p>
                <ul>
                  <li>expenses for the let of a year or less</li>
                  <li>
                    Management fees relating to the ongoing costs of letting (for example, rent
                    collection).
                  </li>
                  <li>
                    The normal legal and professional fees incurred on the renewal of a lease, if
                    the lease is for less than 50 years.
                  </li>
                  <li>
                    Professional fees incurred evicting an unsatisfactory tenant, with a view to
                    reletting, or those on an appeal against a compulsory purchase order.
                  </li>
                  <li>Professional fees in drawing up accounts.</li>
                </ul>
                <p>
                  If you’re not sure whether any legal or professional fee is allowable as a
                  deduction, ask us or your tax adviser.
                </p>
                <hr>
                <p>&nbsp;</p>
                <a name="1p29"></a>
                <p><b>Box 1.29 Costs of services provided, including wages</b></p>
                <p>
                  If, in addition to letting a property, the partnership provides any service to
                  the tenant (such as gardening, porterage, cleaning or even something like
                  communal hot water) that requires a degree of maintenance and expense, you can
                  claim the cost of these services to the extent that they’re provided wholly and
                  exclusively for the purposes of the letting.
                </p>
                <p>
                  Include the total of any expenses for all properties and their associated
                  services in box 1.29. If the partnership receives any income for any service
                  provided, include this in box 1.21.
                </p>
                <hr>
                <p>&nbsp;</p>
                <a name="1p30"></a>
                <p><b>Box 1.30 Other expenses</b></p>
                <p>
                  Enter in box 1.30 all expenses incurred wholly and exclusively for the purpose of
                  the rental business that are not already included in boxes 1.25 to 1.29.
                  Examples include the costs of rent collection, advertising for tenants,
                  travelling solely for business purposes, stationery, phone calls and other
                  miscellaneous expenses.
                </p>
                <p>
                  However, partners’ personal expenses are not
                  allowable. If the partnership spends money on something used only partly for the
                  property, you must enter only the amount spent for business purposes in box
                  1.30. Alternatively, enter the whole amount and in box 1.33 deduct the
                  proportion of the cost that represents personal use.
                </p>
                <p>
                  For information, on flat rate expenses for landlords, go to <a href="http://www.gov.uk/hmrc-internal-manuals/property-income-manual/pim2220" target="_blank" class="link">
                    www.gov.uk/hmrc-internal-manuals/property-income-manual/pim2220
                  </a>
                </p>
                <p><b>Lease premium relief</b></p>
                <p>
                  Where the partnership (or an earlier tenant) paid a premium to the landlord when
                  the lease was granted and the partnership is subletting the property in the
                  property business, it may be able to claim a deduction for part of the premium
                  paid. The relief for premiums paid is only due on amounts which are chargeable
                  on the landlord as income (or would be if the landlord was taxable).
                </p>
                <p>
                  If you think the partnership is entitled to a deduction for a premium paid, ask
                  us or your tax adviser.
                </p>
                <p>
                  Enter the amount of the allowable deduction in box 1.30. The notes for box 1.23
                  explain how to calculate the part of the premium which is taxable on the
                  landlord as income.
                </p>
                <hr>
                <p><b>Tax adjustments</b></p>
                <p>
                  To arrive at the partnership’s income (or the allowable loss) for tax purposes,
                  you need to make certain adjustments to the net profit or loss arising in the
                  year in box 1.32. The main adjustments are listed below.
                </p>
                <p>&nbsp;</p>
                <a name="1p33"></a>
                <p><b>Box 1.33&nbsp; <strong>Private use</strong></b></p>
                <p>
                  Private and personal expenses are not allowable. If the partnership spends money
                  on something used only partly for the rental business and partly for a
                  non-business reason you must either:
                </p>
                <ul>
                  <li>enter the amount spent for business purposes in boxes 1.25 to 1.30 </li>
                  <li>
                    enter the whole expenses in those boxes and in box 1.33 deduct the proportion of
                    the cost that represents the personal use or the non-business element
                  </li>
                </ul>
                <p>
                  For example, if the partnership lets a property for only 8 months in a year and a
                  partner uses it for the other 4 months, you can put the full annual cost of
                  insuring the property in box 1.25. If you do, add back one third of that cost in
                  box 1.33.
                </p>
                <p>
                  If you’re in any doubt whether a particular expense needs to be apportioned
                  between business and private use, ask us or your tax adviser.
                </p>
                <hr>
                <p>&nbsp;</p>
                <a name="1p34"></a><a name="1p35A"></a><a name="1p36"></a>
                <a name="1p34-36"></a>
                <p>
                  <strong>
                    Boxes 1.34, 1.35A, 1.35B, 1.35C, 1.35D, 1.35E and 1.36 Capital allowances
                    and balancing charges
                  </strong>
                </p>
                <p>In working out the rental business profits, do not deduct either: </p>
                <ul>
                  <li>the cost of buying, altering, building, installing or improving fixed assets </li>
                  <li>depreciation or any losses that arise when you sell them </li>
                </ul>
                <p>
                  Instead, the partnership may be able to claim capital allowances in boxes 1.35A
                  to 1.36. These reduce a profit or increase a loss. An adjustment, known as a
                  balancing charge, may arise when it sells an item, gives it away or stops using
                  it in the business.
                </p>
                <p>
                  Enter the amount of balancing charges in box 1.34. These increase the profits or
                  reduce a loss.
                </p>
                <p>
                  However, if the partnership lets a property as a dwelling house in the UK (other
                  than as furnished holiday accommodation), capital allowances are not available
                  on any fixtures, machines, furniture, furnishings or other items for use in the
                  property or the building or any structure to do with the dwelling house.
                  However, see the notes on box 1.37.
                </p>
                <p>
                  Under rules introduced in 2012 and 2014, a purchaser of a second-hand business
                  property containing fixtures (such as kitchen fittings, electrical or heating
                  systems) will not usually be entitled to claim allowances unless the past owner
                  has ‘pooled’ its qualifying expenditure and has fixed the value of the fixtures.
                </p>
                <p>
                  ‘Pooling’ includes making a claim for first year allowance or annual investment
                  allowance for the expenditure. It is not necessary for the past owner to claim
                  writing down allowances. As a rule, the past owner is the last person who was
                  entitled to claim capital allowances on fixtures.
                </p>
                <p>
                  Normally, the value will be fixed by means of a joint section 198 CAA 2001
                  election, which must be notified to HMRC within 2 years of the property
                  transaction. The amount the seller can bring in to any pool as the disposal
                  value will be the same as the amount the buyer can bring in as the acquisition
                  value for capital allowances purposes.
                </p>
                <hr>
                <hr>
                <a name="1p35b"></a>
                <p>&nbsp;</p>
                <p><strong>Box 1.35A</strong>&nbsp; <b><strong>Annual Investment Allowance</strong></b></p>
                <p>
                  Annual Investment Allowance (AIA) can be claimed for the purchase of equipment
                  (but not cars). For the maximum amount of AIA and other guidance, go to
                  <a href="http://www.gov.uk/capital-allowances/ annual-investment-allowance" target="_blank" class="link">
                    www.gov.uk/capital-allowances/ annual-investment-allowance
                  </a>
                </p>
                <p>
                  If the equipment is used for both business and private use, reduce the AIA
                  claimed by the private use proportion.
                </p>
                <p>&nbsp;</p>
                <p><strong>Box 1.35B Electric charge-point allowance</strong></p>
                <p>
                  You can claim 100% first year capital allowances for expenditure invested in the
                  acquisition and installation of new and unused electric charge-points for
                  electric vehicles. Put the amount of expenditure incurred in box 1.35B.
                </p>
                <hr>
                <hr>
                <a name="1p35c"></a>
                <p>&nbsp;</p>
                <p><strong>&nbsp;<b>Box 1.35C </b>The Structures and BuildingsAllowance</strong></p>
                <p>
                  If the partnership is eligible to claim the Structures and Buildings Allowance
                  (SBA), put the amount of the claim in box 1.35C (unless claiming the enhanced
                  rate of SBA for Freeport tax sites, in which case, put the amount of the claim
                  in box 1.35D). If claiming for the first time in respect of an amount of
                  qualifying expenditure, use the ‘Additional information’ box, box 3.116 on the
                  SA800, to record the:
                </p>
                <ul>
                  <li>
                    date the building first came into qualifying use or, if later, the date the
                    qualifying expenditure was incurred
                  </li>
                  <li>
                    total amount of new qualifying expenditure incurred
                  </li>
                </ul>
                <p>
                  To check if and how much you can claim, go to
                  <a href="http://www.gov.uk/guidance/claiming-capital-allowances-for-structures-and-buildings" target="_blank" class="link">
                    www.gov.uk/guidance/claiming-capital-allowances-for-structures-and-buildings
                  </a>
                </p>
                <hr>
                <a name="1p35d"></a>
                <p>&nbsp;</p>
                <p><strong><b>Box 1.35D</b>&nbsp;&nbsp;  Freeports and Investment zones Structures and Buildings Allowance </strong></p>
                <p>
                  If you’re eligible to claim the enhanced Structures and Buildings Allowance for
                  Freeport tax sites put the amount of the claim in box 1.35D.
                </p>
                <p>
                  If claiming for the first time in respect of an amount of qualifying expenditure
                  use the ‘Any other information’ box, box 3.116 on the SA800, to record the:
                </p>
                <ul>
                  <li>
                    freeport to which the claim relates
                  </li>
                  <li>&nbsp;address or location of the structure or building</li>
                  <li>
                    date the building first came into qualifying use, or if later, the date the
                    qualifying expenditure was incurred
                  </li>
                  <li>total amount of new qualifying expenditure incurred</li>
                  <li>date the first contract for construction was entered into</li>
                </ul>
                <p>
                  To check if and how much you can claim, go to
                  <a href="http://www.gov.uk/guidance/check-if-you-can-claim-enhanced-structures-and-buildings-allowance-relief-in-freeport-tax-sites" target="_blank" class="link">www.gov.uk/guidance/check-if-you-can-claim-enhanced-structures-and-buildings-allowance-relief-in-freeport-tax-sites</a>
                </p>
                <p>
                  Business Premises Renovation Allowance (BPRA) is no longer available for
                  expenditure incurred from 6 April 2017. Put any BPRA claims for expenditure
                  incurred before 6 April 2017 in box 1.36 and any BPRA balancing events in box
                  1.34.
                </p>
                <p>
                  For information about BPRA, go to
                  <a href="http://www.gov.uk/guidance/business-premises-renovation-allowance-bpra" target="_blank" class="link">
                    www.gov.uk/guidance/business-premises-renovation-allowance-bpra
                  </a>
                </p>
                <p>Do not claim capital allowances if using cash basis. The only exception is cars. </p>
                <hr>
                <a name="1p35e"></a>
                <p><strong>Box 1.35E</strong>&nbsp;&nbsp; <strong>Zero-emission car allowance</strong></p>
                <p>
                  Claim the 100% first year allowance (the full cost) of any new and unused
                  zero-emission or electric cars in this box.
                </p>
                <p>
                  If you use a car outside of your business, you must reduce the claim in
                  proportion to the non-business use.
                </p>
                <p><strong>Box 1.36 All other capital allowances </strong></p>
                <p>
                  The type of capital allowance and amount that you can claim will depend on the
                  cost, type of asset and other circumstances. For example, you can only claim
                  capital allowances for furniture and fixtures or other equipment for use in a
                  dwelling house if it qualifies as a Furnished Holiday Letting (FHL). Put your
                  total capital allowances not claimed elsewhere in box 1.36.
                </p>
                <p>You may be able to claim some of these allowances: </p>
                <ul>
                  <li>
                    18% writing down allowance (WDA) on the balance of your purchase after deducting
                    any Annual Investment Allowance (AIA) if your total costs were more than the AIA
                    maximum amount
                  </li>
                  <li>
                    6% WDA for ‘special rate’ equipment such as electrical systems and cars with
                    higher CO2 emissions
                  </li>
                </ul>
                <p>
                  For more information, go to
                  <a href="http://www.gov.uk/business-tax/capital-allowances" target="_blank" class="link">
                    www.gov.uk/business-tax/capital-allowances
                  </a>
                </p>
                <p>
                  Your tax adviser, if you have one, will tell you how to calculate capital
                  allowances and balancing charges.
                </p>
                <p>
                  If you do not have a tax adviser, or want more information on capital
                  allowances, go to  <a href="http://www.gov.uk/business-tax/capital-allowances" target="_blank" class="link">
                    www.gov.uk/business-tax/capital-allowances
                  </a>
                </p>
                <hr>
                <div *ngIf="this.userService.Present_year < '2024'">
                  <hr>
    <a name="1p36a"></a>
    <hr>
    <p><b>Landlord&#39;s energy saving allowance</b></p>
    <a name="1p36b"></a>
    <p><b>Box 1.36B</b></p>
    <p>
      Enter in box 1.36B the amount the partnership
      is claiming for installing:
    </p>
    <ul>
      <li>loft, floor, cavity wall or solid wall insulation</li>
      <li>
        draughtproofing and insulation for hot
        water systems
      </li>
    </ul>
    <p>
      in residential property which it lets. You cannot claim the allowance
      for any property which is used for furnished holiday lettings. The maximum
      amount you can claim for the total expenditure on these items is £1,500
      for each property.
    </p>
    <p>
      If the partnership has installed the insulation or
      draughtproofing in a single building which only
      partly comprises let residential property, you
      should only claim for the part of the expenditure
      incurred (or of £1,500 if the total expenditure
      exceeded that amount) which relates to the let
      residential property in the building.
    </p>
    <p>
      If the partnership owns the building with other
      persons (or it and other persons have different
      interests or rights in the same building), you
      should only claim for the partnership's share
      of the expenditure which has been incurred
      in respect of the let residential property in the
      building (or for its share of the part of the £1,500
      maximum which relates to the let residential
      property in the building).
    </p>
</div>
                  <hr>
                  <p>&nbsp;</p>
                  <a name="1p37"></a>
                  <p><b>Box 1.37 Costs of replacing domestic items</b></p>
                  <p>
                    If the partnership lets any residential accommodation (such as a house or flat)
                    in the UK (other than a furnished holiday letting), capital allowances are not
                    available on expenditure on assets in the dwelling house.
                  </p>
                  <p>
                    Instead, the partnership can claim the cost of any replacement domestic items for
                    use in the accommodation by putting an amount in box 1.37.
                  </p>
                  <p>This cost can be claimed where:</p>
                  <ul>
                    <li>
                      the cost is actually incurred on purchasing a replacement domestic item – you
                      cannot claim the initial cost for an item provided in the accommodation for the
                      first time
                    </li>
                    <li>
                      the new item is provided solely for the use of the tenants in the accommodation
                      and the old item is no longer available for use
                    </li>
                    <li>
                      if the new item is an improvement on the old item, the cost you can claim is
                      limited to the cost of purchasing an equivalent replacement to the old item –
                      the new item is an improvement if there is a substantial upgrade in the
                      function, materials or quality over the old item, unless this is due to the new
                      item being the nearest available modern equivalent
                    </li>
                  </ul>
                  <p>Domestic items include:</p>
                  <ul>
                    <li>moveable furniture, for example, beds, free-standing wardrobes </li>
                    <li>furnishings, for example, curtains, linens, carpets, floor coverings</li>
                    <li>household appliances, for example, televisions, fridges, freezers </li>
                  </ul>
                  kitchenware, for example, crockery, cutlery Capital allowances can be claimed if
                  the partnership let furnished, but not residential, accommodation. If capital
                  allowances can be claimed, you cannot claim the cost of replacing domestic
                  items.
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <a name="1p40"></a>
                  <p><b>Box 1.40&nbsp; Residential property finance costs</b></p>
                  <p>Put residential property finance costs for non-corporate partners in box 1.40. </p>
                  <p>This amount will be used to calculate a reduction in partner’s Income Tax. </p>
                  <p>
                    For information on the residential property finance costs restriction, go to
                    <a href="http://www.gov.uk/government/news/changes-to-tax-relief-for-residential-landlords " target="_blank" class="link">
                      www.gov.uk/government/news/changes-to-tax-relief-for-residential-landlords
                    </a>
                  </p>
                  <p><strong>Transfer of information to the Partnership Statement (full) </strong></p>
                  <p>
                    Transfer any profit figure in box 1.39 to box 19 in the Partnership Statement
                    (full). Similarly, if you have not already done so, transfer any figure in box
                    1.22 to box 25.
                  </p>
                  <p>
                    Remember, you’ll need to complete more than one Partnership Statement if you’ve
                    completed more than one set of ‘Partnership UK property’ pages for this tax
                    return.
                  </p>
                  <p>
                    If you want to allocate these amounts between the partners now, read how to
                    allocate profit to the partners in the Partnership Tax Return Guide.
                  </p>
                  <hr>


                </div>
              </div>
          </div>
          <!--SA802 starts here............-->
          <div id="SA802" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <table cellpadding="0" cellspacing="0" width="98%">
                  <tr>
                    <td align="right" width="93%">
                      <p style="text-align:right;">
                        <span style="font-size: 25px;" class="headingSA">
                          <b>Partnership Foreign notes</b>
                        </span><b>
                          <span style="font-size: 15px;" class="headingSA">Tax year 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}} ({{this.userService.Currenttax_shortyear}}) </span>
                        </b>
                      </p>
                    </td>
                    <td width="7%">&nbsp;</td>
                  </tr>
                  <tr>
                    <td align="right" bgcolor="#00ABBC" width="93%">&nbsp;</td>
                    <td bgcolor="#ABA000" width="7%">&nbsp;</td>
                  </tr>
                </table>
                <br>
                <table cellpadding="3" cellspacing="0" style="border-collapse: collapse" width="98%">
                  <tr>
                    <td valign="top" width="20%"><a name="top"></a><a class="link" href="help#2p4-5">Boxes 2.4 and 2.5</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#2p8A">Box 2.8A</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#2p9">Box 2.9</a>
                    <td valign="top" width="20%"><a class="link" href="help#2p10A">Box 2.10A</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#2p11">Box 2.11</a></td>
                  </tr>
                  <tr>
                    <td valign="top" width="20%"><a class="link" href="help#2p11a">Box 2.11A</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#2p11b">Box 2.11B</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#2p12-17">Boxes 2.12 to 2.17</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#2p12">Box 2.12</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#2p13">Box 2.13</a></td>
                  </tr>
                  <tr>
                    <td valign="top" width="20%"><a class="link" href="help#2p14">Box 2.14</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#2p15">Box 2.15</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#2p16">Box 2.16</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#2p17">Box 2.17</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#2p20">Box 2.20</a></td>
                  </tr>
                  <tr>
                    <td valign="top" width="20%"><a class="link" href="help#2p21-23">Boxes 2.21 and 2.23</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#2p21a">Boxes 2.21A </a></td>
                    <td valign="top" width="20%"><a class="link" href="help#2p21b">Boxes 2.21B </a></td>
                    <td valign="top" width="20%"><a class="link" href="help#2p21c">Boxes 2.21C </a></td>
                    <td valign="top" width="20%"><a class="link" href="help#2p24">Box 2.24</a></td>
                  </tr>
                  <tr>
                    <td valign="top" width="20%"><a class="link" href="help#2p26-27">Boxes 2.26 and 2.27</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#2p28-29">Boxes 2.28 and 2.29</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#2p28">Box 2.28</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#2p29">Box 2.29</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#2p30">Box 2.30</a></td>
                  </tr>
                  <tr>
                    <td valign="top" width="20%"><a class="link" href="help#2p30A">Box 2.30A</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#2p31-33">Boxes 2.31 to 2.33</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#2p32">Box 2.32</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#2p33">Box 2.33</a></td>
                    <td valign="top" width="20%"><a class="link" href="help#2p33A">Box 2.33A</a></td>
                  </tr>
                  <tr>
                    <td valign="top" width="20%"><a class="link" href="help#2p34">Box 2.34</a></td>
                  </tr>
                </table>
                <hr>
                <p><b>Filling in the 'Partnership Foreign' Pages</b></p>
                <div style="padding: 5 0 0 10px;">
                  Gather together the material you need, such as:
                </div>
                <ul>
                  <li>overseas dividend vouchers</li>
                  <li>bank statements for overseas accounts</li>
                </ul>
                <div *ngIf="this.userService.Present_year < '2024'">

                  <p><b>Coronavirus support scheme payments </b></p>
                  <p>
                    You must include amounts of taxable coronavirus support scheme payments
                    that the partnership received, subject to further guidance in these notes.
                  </p>
                  <p>
                    &nbsp;These include the following:
                  </p>
                  <ul>
                    <li>
                      Coronavirus Job Retention Scheme (CJRS)
                    </li>
                    <li>
                      any other applicable HMRC coronavirus support scheme
                    </li>
                    <li>
                      payments that the partnership was entitled to receive from local authorities or
                      devolved administrations
                    </li>
                  </ul>
                  <p>
                    For more information about coronavirus support schemes, go to<a href="http://www.gov.uk/coronavirus/business-support" target="_blank" class="link">
                      www.gov.uk/coronavirus/business-support
                    </a>
                  </p>
                  <p>
                    If the partnership received a CJRS or any other applicable HMRC coronavirus
                    support scheme payment that it was not entitled to and it has not been
                    voluntarily paid back to HMRC or none of the partners have received an
                    assessment issued by an officer of HMRC in respect of the incorrectly claimed
                    payment, put the incorrectly claimed amount in box 3.118 of the Partnership Tax
                    Return.
                  </p>
                </div>


                <p>These notes tell you how to complete the 'Partnership Foreign' pages. </p>
                <p>Fill in pages PF 1 to PF 4 to give details of foreign income and gains:</p>
                <ul>
                  <li>page PF 1 is for foreign savings (including income from offshore funds)</li>
                  <li>
                    page PF 2 is for income from land and property, chargeable premiums and
                    disposals of holdings in offshore funds
                  </li>
                  <li>
                    pages PF 3 and PF 4 are for foreign let property except for furnished holiday
                    lettings in the European Economic Area (EEA) – summarise the income on page PF 2
                  </li>
                  <li>page PF 4 is also for any additional information</li>
                </ul>
                <p>
                  If you’re a &#39;CT Partnership&#39; please see page PTRG 5 of the Partnership Tax Return
                  Guide.
                </p>
                <p>
                  These notes are only a simple introduction to the rules about the taxation of
                  foreign income. If you’re in any doubt about the information you need to
                  provide, ask us or your tax adviser.
                </p>
                <p>
                  You may need to allocate shares of foreign income to the partners on an
                  item-by-item basis before you can complete the Partnership Statement.
                </p>
                <p>
                  &nbsp;Even if you do not need to do this to complete the Partnership Statement,
                  the partners will need this additional information so that they can complete
                  their own tax returns. This will be the case if any partner wants to claim
                  relief by way of credit for foreign tax paid (Foreign Tax Credit Relief), or if
                  the partner has a share in any foreign income that the partnership has been
                  unable to remit to the UK.
                </p>
                <table width="500" align="center" cellpadding="0" cellspacing="0">
                  <tr>
                    <td colspan="3" width="98%"><b>Example 1</b></td>
                  </tr>
                  <tr>
                    <td colspan="3" width="98%">
                      Jim is a member of a partnership and
                      is entitled to 40% of the profits. The partnership receives two
                      dividends,
                      <br />
                      as follows:
                    </td>
                  </tr>
                  <tr>
                    <td width="50%" height="25">&nbsp;</td>
                    <td align="right" width="25%">Gross</td>
                    <td align="right" width="25%">Foreign tax</td>
                  </tr>
                  <tr>
                    <td width="50%" height="25">Country A</td>
                    <td align="right" width="25%">£5,000</td>
                    <td align="right" width="25%">£1,000</td>
                  </tr>
                  <tr>
                    <td width="50%" height="25">Country B</td>
                    <td align="right" width="25%">£2,500</td>
                    <td align="right" width="25%">£250</td>
                  </tr>
                  <tr>
                    <td width="50%" height="25">Totals</td>
                    <td align="right" width="25%">£7,500</td>
                    <td align="right" width="25%">£1,250</td>
                  </tr>
                  <tr>
                    <td width="98%" height="25" colspan="3">
                      To claim Foreign Tax Credit Relief Jim
                      will need to know details of his share of the partnership's foreign
                      income on an item by item basis, as follows:
                    </td>
                  </tr>
                  <tr>
                    <td width="50%" height="25">&nbsp;</td>
                    <td align="right" width="25%">Share of gross</td>
                    <td align="right" width="25%">Share of foreign tax </td>
                  </tr>
                  <tr>
                    <td width="50%" height="25">Country A</td>
                    <td align="right" width="25%">£2,000</td>
                    <td align="right" width="25%">£400</td>
                  </tr>
                  <tr>
                    <td width="50%" height="25">Country B</td>
                    <td align="right" width="25%">£1,000</td>
                    <td align="right" width="25%">£100</td>
                  </tr>
                  <tr>
                    <td width="50%" height="25">Totals</td>
                    <td align="right" width="25%">£3,000</td>
                    <td align="right" width="25%">£500</td>
                  </tr>
                </table>
                <p align="LEFT">
                  See the notes on pages PTRG 26 to PTRG 29
                  of the Partnership Tax Return Guide for more
                  details, including another example.
                </p>
                <a name="2p4"></a><a name="2p5"></a><a name="2p4-5"></a>
                <p>&nbsp;&nbsp;</p>
                <p><b>&nbsp;Boxes 2.4 and 2.5&nbsp; Return period - trading or professional partnerships</b></p>
                <p>
                  For all partnerships (except investment partnerships other than &#39;CT
                  Partnerships&#39;, see below) fill in the &#39;Partnership Foreign&#39; pages PF 1 and PF 2
                  to show details of the partnership&#39;s foreign untaxed income of the accounting
                  periods ended in the tax year {{this.userService.Previous_year}} to {{this.userService.Present_year}}. In these circumstances the dates
                  shown in boxes 2.4 and 2.5 must be the same as those you’ve entered in boxes 3.4
                  and 3.5 on page 2 of your Partnership Tax Return.
                </p>
                <p>
                  Where there is more than one such accounting period you may have to complete more
                  than one set of &#39;Partnership Foreign&#39; pages (see the notes on page PTRG 9 of the
                  Partnership Tax Return Guide).
                </p>
                <p>
                  Where there’s no such accounting period you need only complete the &#39;Partnership
                  details&#39; and &#39;Foreign savings&#39; sections on page PF 1 (see the notes on page PTRG
                  9 of the Partnership Tax Return Guide).
                </p>
                <p>
                  <b>
                    Boxes 2.4 and 2.5 Return period – investment
                    partnerships
                  </b>
                </p>
                <p>
                  Where, exceptionally, a partnership does not carry on a trade or profession,
                  pages PF 1 and PF 2 must in all cases be filled in for the profits in the period
                  6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}. Enter &#39;6/4/{{this.userService.Previous_year}}&#39; in box 2.4 and &#39;5/4/{{this.userService.Present_year}}&#39; in box
                  2.5. If you’re a &#39;CT Partnership&#39; see page PTRG 5 of the Partnership Tax Return
                  Guide.
                </p>
                <p>
                  If accounts are made up for any other period, apportion figures in the sets of
                  accounts that between them cover the period  6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}.
                </p>
                <p><b>Returning foreign income</b></p>
                <p>
                  Enter the full amount of income arising from overseas sources on the &#39;Partnership
                  Foreign&#39; pages. For overseas rental income this means the gross income
                  (including any coronavirus support scheme payments) minus allowable expenses.
                  For all other income it’s simply gross income before tax.
                </p>
                <p><b>Unremittable income</b></p>
                <p>
                  If the partnership has income arising outside the UK that it’s unable to remit to
                  the UK because of exchange controls or a shortage of foreign currency in the
                  overseas country, then the members of the partnership can claim that the
                  unremittable income should not be taxed for {{this.userService.Previous_year}} to {{this.userService.Present_year}}.
                </p>
                <p>
                  If you think this applies to the partnership, see below for guidance on how to
                  enter details of the unremittable income.
                </p>
                <p><b>Income becoming remittable</b></p>
                <p>
                  Where income was not taxed in an earlier year because it was unremittable but it
                  can now be remitted to the UK, it’s treated as arising in {{this.userService.Previous_year}} to {{this.userService.Present_year}}. Income
                  may become remittable if, for example, exchange controls are lifted. Include any
                  income to which this applies whether or not it is actually remitted to the UK.
                  The amount of the income and any foreign tax charged on it must be converted to
                  sterling using the exchange rate at the time the income becomes remittable.
                </p>
                <p><b>Foreign income: change to pounds sterling</b></p>
                <p>
                  Income taxable in the UK must be converted to sterling at the rate of exchange
                  prevailing at the time when the income arose. We can help if you’re not sure
                  which exchange rate to apply.
                </p>
                <p><b>Filling in pages PF 1 and PF 2 of the 'Partnership Foreign' pages </b></p>
                <p>
                  <strong>Important:</strong> if the partnership has more than one item of income
                  or gains in any particular category (for example, dividends from more than one
                  overseas company) each such item must be entered separately. But see below for
                  how to deal with unremittable income.
                </p>
                <p>For each item in:</p>
                <ul>
                  <li><strong>column A</strong>, enter the country in which the item of income arose </li>
                  <li>
                    <strong>column B</strong>, enter the amount of income before deducting any UK or
                    foreign tax but after deducting unremittable income
                  </li>
                  <li>
                    <strong>column D</strong>, enter the amount of foreign tax paid on the income
                    entered in column B
                  </li>
                </ul>
                <p>These notes will help you decide what to put in each column. </p>
                <p><b>Entering unremittable income</b></p>
                <p>
                  Enter details of unremittable income, and the corresponding tax, in the
                  &#39;Partnership Foreign&#39; pages in the appropriate foreign currency.
                </p>
                <p>
                  For each item of income (excluding income from land and property abroad, see the
                  note on page PFN 5) that cannot be remitted to the UK in the tax year {{this.userService.Previous_year}} to {{this.userService.Present_year}}:
                </p>
                <ul>
                  <li>
                    <strong>in column A</strong>, enter the country in which the item of income
                    arose
                  </li>
                  <li>tick the box between columns <strong>A and B</strong></li>
                  <li>
                    <strong>in column B</strong>, , enter the amount of the unremittable income, in
                    its foreign currency (cross out the £ signs)
                  </li>
                  <li>
                    <strong>in column D</strong> enter the amount of the foreign tax, if any,
                    suffered on the unremittable income in its foreign currency (cross out the £
                    signs)
                  </li>
                </ul>
                <p>
                  Make sure you exclude details of unremittable income, and the corresponding tax,
                  from the totals boxes at the foot of each column.
                </p>
                <p><b>Foreign savings</b></p>
                <p><b>Interest and other income from overseas savings and investments</b></p>
                <p><b>column A</b></p>
                <p>Enter the name of the country where the income arose. </p>
                <p><b>columns B to D</b></p>
                <p>
                  Treat each source of interest (including, for example, different accounts with
                  the same bank) separately and enter details of each on a separate line.
                </p>
                <p><b>Interest from overseas unit trusts and investment funds</b></p>
                <p>
                  The information you need to put in these columns will be shown on the
                  partnership&#39;s unit trust or fund voucher. If you do not have a tax voucher, ask
                  the unit trust or fund administrator for one. Where the income of the unit trust
                  or fund is automatically reinvested in the fund, you may still have to enter the
                  appropriate income arising to you and any tax deducted. There are some instances
                  where dividend distributions from offshore funds must be returned as interest
                  received, see &#39;Dividends from foreign companies&#39; on page PFN 4.
                </p>
                <p><b>Interest from other overseas sources</b></p>
                <p>
                  Enter the amounts of interest that the partnership received from foreign bank
                  accounts, or from foreign company loan stocks, or loans to individuals or other
                  organisations outside the UK. If the payer of the interest has deducted foreign
                  withholding tax and accounted for that tax to the overseas tax authority on your
                  behalf, this will normally be shown on a certificate of tax paid. Company
                  partners should see the note &#39;Loan relationships and so on&#39; of the Partnership
                  Tax Return Guide.
                </p>
                <p><b>Other income from overseas sources</b></p>
                <p>
                  Enter any other income that the partnership received, or was entitled to, from
                  overseas savings and investments. Include gains on life assurance policies, life
                  annuities and capital redemption policies. Do not include income from land and
                  property abroad (see page PFN 5 of these notes).
                </p>
                <p><b>Dividends and distributions from overseas sources</b></p>
                <p>
                  For each year in which the partnership carries on a trade, any dividends and
                  distributions the partnership receives from overseas sources are taxable in the
                  same basis period as the partnership’s trading income (unlike dividends received
                  from UK companies, which are taxable by reference to the tax year). For example,
                  if the partnership has a basis period of 1 January to 31 December, any dividends
                  and distributions from overseas sources will share that basis period.
                </p>
                <p><b>Income not to include in the 'Partnership Foreign' Pages</b></p>
                <p>Exclude:</p>
                <ul>
                  <li>distributions made in the course of the liquidation of a foreign company</li>
                  <li>
                    distributions that constitute a return of the partnership&#39;s capital interest in
                    a foreign company
                  </li>
                  <li>distributions made by a foreign company in the form of its own stocks and shares </li>
                  <li>stock dividends from foreign companies </li>
                  <li>bonus shares from a stock dividend issue made by a foreign company</li>
                </ul>
                <p>
                  If you’re not sure whether distributions the partnership has received fall into
                  any of the above categories, ask us or your tax adviser for advice.
                </p>
                <p><b>Column A</b></p>
                <p>Enter the name of the country where the dividend or distribution arose. </p>
                <p><b>Columns B to D</b></p>
                <p>
                  Treat each source of income separately and enter details of each on a separate
                  line.
                </p>
                <p><b>Dividends from foreign companies</b></p>
                <p>Include dividends from:</p>
                <ul>
                  <li>foreign companies </li>
                  <li>
                    offshore funds constituted as companies – your dividend voucher will provide the
                    information needed
                  </li>
                </ul>
                <p>
                  There are specific rules for dividends from offshore funds which are
                  substantially invested in interest-bearing assets (commonly known as &#39;bond
                  funds&#39;). Where an offshore fund holds more than 60% of assets in
                  interest-bearing (or economically similar) form, any distribution received by
                  the partnership is treated as a payment of yearly interest.
                </p>
                <p><b>Other distributions from overseas sources</b></p>
                <p>
                  If the partnership has received a distribution from a foreign company other than
                  in the form of a cash dividend, for example, if the company has released some of
                  its assets (such as shares it holds in another company) to its shareholders,
                  enter the currency value of such assets at the date of distribution, unless the
                  assets are released on liquidation or represent a return of capital.
                </p>
                <p>
                  Where a distribution was not made in shares and you’ve accepted an option from a
                  foreign company to receive cash instead of shares, the cash is taxable and you
                  should include it on page PF 1.
                </p>
                <p><b>Dividend tax credit</b></p>
                <p align="LEFT">
                  Dividends, including dividends from non-UK companies, paid before 6
                  April 2016 carried a notional tax credit equal to one ninth of the dividend
                  actually paid. That tax credit has been abolished for dividends paid on or after
                  6 April 2016. From 6 April 2016, each partner does not pay tax on the first part
                  of their dividend income that is covered by the dividend allowance, including
                  dividends received by the partnership and dividends from an overseas source. For
                  {{this.userService.Previous_year}} to {{this.userService.Present_year}} , the dividend allowance is <!--<span *ngIf="this.usersService.Present_year!=2024">£2,000</span>--> £1,000.
                </p>
                <hr>
                <a name="2p8A"></a>
                <p>&nbsp;</p>
                <p><b>Box 2.8A&nbsp; Traditional accounting</b></p>
                <p>Put ‘X’ in box 2.8A if you used traditional accounting instead of cash basis.</p>
                <p>Only partnerships that just have individuals as partners can use cash basis. </p>
                <p>
                  If your receipts from your property business (UK and UK FHL or overseas and EEA
                  FHL) are over £150,000 for the tax year, you must use traditional accounting.
                </p>
                <p>
                  If you have both EEA FHL and overseas property income, put ‘X’ in box 2.8A and
                  box 1.3A on the SA801.
                </p>
                <p><b>Cash basis</b></p>
                <p>
                  Cash basis is a simpler way of working out your property profits or losses. You
                  add up all your property income received and take off any allowable expenses
                  paid in the year.
                </p>
                <p>
                  If you use cash basis do not include money you owe or money you are owed at the
                  end of the year.
                </p>
                <p>
                  You cannot claim capital allowances if you’re
                  using cash basis. The only exception is cars.
                </p>
                <p>
                  For guidance on deciding if cash basis or traditional accounting is the right
                  option for you, go to:
                  <a href="http://www.gov.uk/guidance/income-tax-when-you-rent-out-a-property-working-out-your-rental-income" target="_blank" class="link">
                    www.gov.uk/guidance/income-tax-when-you-rentout-a-property-working-out-your-rental-income
                  </a>
                </p>
                <p>
                  You can decide each year whether the cash basis is the best option for you and
                  you must tell us each year if you used traditional accounting and not cash
                  basis.
                </p>
                <p>
                  If you have both EEA FHL and overseas property income, you must use the same
                  basis for both.
                </p>
                <p><b>Transitional adjustments</b></p>
                <p>
                  If you’re changing accounting practice, you may need to
                  make a transitional adjustment. For guidance
                  on the adjustments you’ll need to make, go to <a href="https://www.gov.uk/guidance/income-tax-when-you-rent-out-a-property-working-out-your-rental-income" target="_blank" class="link">www.gov.uk/guidance/income-tax-when-you-rent-out-a-property-working-out-your-rental-income</a>If
                  you change accounting practice, you may need to make a transitional adjustment.
                  For guidance on the adjustments you’ll need to make, go to
                  www.gov.uk/guidance/income-tax-when-you-rentout-a-property-working-out-your-rental-income
                </p>
                <p>
                  All transitional receipts must be included in box 2.11 and all transitional
                  expenses must be included in box 2.17.
                </p>
                <hr>
                <a name="2p9"></a>
                <p><b>Disposals of holdings in offshore funds</b></p>
                <p><b>Box 2.9</b></p>
                <p>
                  The partnership may need to make an entry in this box if it has disposed of an
                  interest in an offshore fund. The rules relating to this can be quite complex,
                  HM Revenue and Customs (HMRC) has published guidance in its Investment Funds
                  Manual, go to <a href="http://www.gov.uk/government/collections/hmrc-manuals" target="_blank" class="link">
                    www.gov.uk/government/collections/hmrc-manuals
                  </a> or ask your tax
                  adviser. The following is a general overview.
                </p>
                <p>
                  The term &#39;offshore fund&#39; is defined in UK tax legislation. Broadly such a fund is
                  an investment scheme of which the trustees or operators are not resident in the
                  UK (for example, unit trusts operated under Jersey laws and Belgian SICAVs are
                  offshore funds). Other than &#39;open ended&#39; investment companies, non-resident
                  companies are generally not offshore funds, check this with the fund manager or
                  your tax adviser.
                </p>
                <p>
                  In certain circumstances, gains on disposals of holdings in offshore funds are
                  charged to tax as income instead of being taxed as capital gains.
                </p>
                <p>
                  Where that’s not the case, return any gain or loss on disposal on the
                  &#39;Partnership Disposal of Chargeable Assets&#39; pages and not in box 2.9. If this
                  applies, you can get those pages from the website. Go to www.gov.uk and search
                  for ‘SA803’.
                </p>
                <p>
                  Enter any income the partnership receives from the offshore fund on page PF 1 or
                  on page PS 1 as appropriate, see &#39;Interest from overseas unit trusts and other
                  investment funds&#39; on page PFN 3 for help.
                </p>
                <hr>
                <p><b>Filling in boxes 2.6 to 2.10</b></p>
                <p>
                  Enter in boxes 2.6 to 2.10 the totals of income remittable to the UK (in pounds
                  sterling) and the corresponding UK and foreign tax (also in pounds sterling).
                </p>
                <hr>
                <p><b>Transfer of information to the Partnership Statement</b></p>
                <p>
                  The partners need to distinguish between the different classes of income and tax
                  credits identified in boxes 2.6 to 2.10 when calculating the tax due on their
                  shares of foreign income.
                </p>
                <p>
                  Transfer the amounts shown in the totals boxes 2.6 to 2.10 to the appropriate
                  boxes in the Partnership Statement.
                </p>
                <p>Transfer the figure in:</p>
                <ul>
                  <li>
                    box 2.6 (total interest and other savings income) to box 14 of the Partnership
                    Statement
                  </li>
                  <li>box 2.6A (total dividend income) to box 14A </li>
                  <li>box 2.7 (total overseas letting income and chargeable premiums) to box 17</li>
                  <li>
                    box 2.8 (foreign tax deducted from the income in boxes 2.6, 2.6A and 2.7) to box
                    28
                  </li>
                  <li>the figure in box 2.9 (offshore funds) to box 18 </li>
                  <li>the figure in box 2.10 (losses on overseas income) to box 21 </li>
                </ul>
                <hr>
                <p>
                  <b>
                    Income from land and property abroad<br>
                    Furnished holiday lettings in the European
                    Economic Area (EEA)
                  </b>
                </p>
                <p>
                  If you have income from the commercial letting of furnished holiday accommodation
                  in the EEA, you can claim the same reliefs that apply to commercially let
                  furnished holiday accommodation in the UK. If you let qualifying holiday
                  accommodation in the EEA, for more information go to www.gov.uk and search for
                  ‘HS253’. You’ll need to complete the &#39;Partnership UK property&#39; pages, go to
                  www.gov.uk and search for ‘SA801’. The notes for those pages explain how to do
                  this.
                </p>
                <p>
                  To enable any partner to claim Foreign Tax Credit Relief or to deduct the foreign
                  tax in computing partnership foreign income or losses, complete page PF 4 of the
                  &#39;Partnership Foreign&#39; pages.
                </p>
                <p>
                  Before you fill in columns A to D on page PF 2, complete pages PF 3 and PF 4.
                  Fill in page PF 3 if the partnership had:
                </p>
                <ul>
                  <li>only one overseas let property </li>
                  <li>more than one overseas let property but they’re all in the same foreign country </li>
                  <li>
                    more than one overseas let property but there has been no foreign tax deducted
                    from the income and all the income is remittable
                  </li>
                </ul>
                <p>
                  Fill in a copy of page PF 3 for each overseas let property. If you have more than
                  one overseas let property take copies of page PF 3 before you start filling it
                  in, or go to <a href="http://www.gov.uk" target="_blank" class="link">
                    www.gov.uk
                  </a> and search for ‘SA802’ and print more pages. Please put
                  the partnership&#39; name and tax reference next to the property address box on
                  each copy of page PF 3 you fill in.
                </p>
                <p><b>column A</b> Enter the name of the country where the property is situated. </p>
                <p>
                  <b>column B</b> Fill in pages PF 3 and PF 4 of the &#39;Partnership Foreign&#39; pages to
                  calculate the amount to be entered in this column.
                </p>
                <p>
                  <b>column D</b> If the partnership has paid foreign tax on rental income arising
                  in the return period, enter the amount in column D. Enter the full amount, in
                  pounds sterling, of foreign tax paid.
                </p>
                <hr>
                <p><strong>Residential property finance costs from boxes 2.30A or 2.33A </strong></p>
                <a name="2p10A"></a>
                <p><b>Box 2.10A</b></p>
                <p>
                  Transfer the amount from either box 2.30A or 2.33A into box 2.10A. This will be
                  used to calculate a reduction in partners’ income tax.
                </p>
                <p>
                  For more information on the residential property finance costs restriction, go to
                  <a href="http://www.gov.uk/guidance/changes-to-tax-relief-for-residential-landlords-how-its-worked-out-including-case-studies" target="_blank" class="link">
                    www.gov.uk/guidance/changes-to-tax-relief-for-residential-landlords-how-its-worked-out-including-case-studies
                  </a>
                </p>
                <hr>
                <p><b>Filling in page PF 3 </b></p>
                <p>
                  First, enter the full address of the property, or if page PF 3 is to be used for
                  more than one let property, the address of the first property.&nbsp;
                </p>
                <p>
                  Use the &#39;Additional information&#39; box on page PF 4 for the addresses of the other
                  properties.
                </p>
                <hr>
                <p><b>Income</b></p>
                <a name="2p11"></a>
                <p><b>Box 2.11</b></p>
                <p>
                  If the partnership enters into any transaction that produces rents or other
                  receipts from any right or interests it holds in land or property situated
                  abroad, those rents and receipts are taxable.
                </p>
                <p>
                  Enter in box 2.11 the full amount of the receipts from the property in question,
                  but do not include any chargeable premiums.
                </p>
                <div *ngIf="this.userService.Present_year < '2024'">
                  <p>
      Coronavirus support scheme payments are taxable and include payments from the
      Coronavirus Job Retention Scheme (CJRS), any other applicable HMRC coronavirus
      support scheme and payments the partnership was entitled to receive from local
      authorities or devolved administrations.
    </p>
    <p>
      If the partnership received coronavirus support scheme payments, include in box
      2.11 the amount the partnership received.
    </p>
    <p>
      If any of the partners received an assessment issued by an officer of HMRC in
      respect of a coronavirus support scheme payment incorrectly claimed, only
      include in box 2.11 the amount the partnership retained.
    </p>
    <p>
      Do not include in box 2.11 the amount of any sum that has been assessed which
      results in the repayment of coronavirus support scheme payments that the
      partnership was not entitled to.
    </p>
    <p>
      If the partnership received a CJRS or any other applicable HMRC coronavirus
      support scheme payment that it was not entitled to and it has not been
      voluntarily paid back to HMRC or none of the partners have received an
      assessment issued by an officer of HMRC in respect of the incorrectly claimed
      payment, put the incorrectly claimed amount in box 3.118 of the Partnership Tax
      Return.
    </p>
                  </div>
                  <p>
                    &#39;Income&#39; includes receipts in cash or in kind. When using traditional accounting,
                    rather than cash basis, income is taxed when it’s earned, even if the
                    partnership does not receive the money or goods until later. Include in box 2.11
                    any rent the partnership receives (or will receive) after 5 April {{this.userService.Present_year}}
                    that is payment for the year ended 5 April {{this.userService.Present_year}} (because it’s paid in arrears). Exclude
                    from box 2.11 any rent received which relates to any period after 5 April {{this.userService.Present_year}}
                    (because it’s paid in advance). It must be included in the income for the year
                    to which it relates. Make sure you do not count money received in this year if
                    it was included in an earlier year.
                  </p>
                  <p>
                    Cash basis is a simpler way of working out your property profits or losses. Only
                    partnerships that just have individuals as partners can use cash basis. Add up
                    all your property income received and take off any allowable expenses paid in
                    the year.
                  </p>
                  <p>
                    If you use cash basis do not include money you owe or money you are owed at the
                    end of the year.
                  </p>
                  <p>
                    Generally, most income will be rental income from a tenancy, leasing or licensing
                    agreement over the land or property. Include all rental income from furnished,
                    unfurnished, commercial and domestic accommodation, and from any land, in the
                    overall total. If the property is let furnished, any sums that a tenant may pay
                    for the use of furniture will be taxed as income of the business. Add all this
                    income together and enter it in box 2.11.
                  </p>
                  <p><b>Receipts other than rent</b></p>
                  <p>Receipts other than rents are also taxable.</p>
                  <p>
                    &nbsp;Some of the main categories
                    are:
                  </p>
                  <ul>
                    <li>rent charges, ground rents </li>
                    <li>income arising from the grant of sporting rights</li>
                    <li>income arising from letting others tip waste on the land </li>
                    <li>
                      income from letting someone use the land when no lease or licence is created,
                      for example, receipts from a film crew who pay to film on the land
                    </li>
                    <!--<li>coronavirus support scheme payments </li>-->
                  </ul>
                  <p>
                    This is not a complete list. If you do not know whether to include a particular
                    sum, ask us or your tax adviser.
                  </p>
                  <hr>
                  <p><b>Chargeable premiums</b></p>
                  <p>
                    Premiums paid for the grant of a lease, certain other lump sum payments and other
                    forms of consideration given in connection with the right to possession of a
                    property, are also taxable but on a special basis.
                  </p>
                  <p>
                    Broadly, for leases over 50 years the entire premium is treated as a capital
                    receipt and so does not form part of the income of the business. For leases of
                    up to 50 years the premium is treated as partly capital and partly rent, and
                    only the rent is taxed.
                  </p>
                  <p>Use the working sheet below to calculate the taxable amount. </p>
                  <table width="930" border="1" cellpadding="0" cellspacing="0" bordercolor="#83C1C0">
                    <tr>
                      <td align="center" valign="middle">
                        <table width="900" border="0" cellspacing="0" cellpadding="0">
                          <tr>
                            <td width="50%" height="35" align="left" valign="middle">
                              <p style="font-size:20px; font-weight:bold;">
                                Working sheet for chargeable premiums – leases up to 50 years
                              </p>
                            </td>
                            <td width="23%">&nbsp;</td>
                            <td>&nbsp;</td>
                            <td width="23%">&nbsp;</td>
                          </tr>
                          <tr>
                            <td width="50%" height="35" align="left" valign="middle">
                              <p style="font-size:18px; ">
                                &nbsp;
                              </p>
                            </td>
                            <td width="23%">&nbsp;</td>
                            <td>&nbsp;</td>
                            <td width="23%">&nbsp;</td>
                          </tr>
                          <tr>
                            <td width="50%" height="35" align="left" valign="middle">
                              <p style="font-size:18px; ">
                                Premium
                              </p>
                            </td>
                            <td width="23%">&nbsp;</td>
                            <td>&nbsp;</td>
                            <td width="23%">
                              <table width="100%" border="0" cellspacing="0" cellpadding="2">
                                <tr>
                                  <td width="25%">
                                    <table width="100%" border="1" cellpadding="0" cellspacing="0" bordercolor="#83C1C0" bgcolor="#83C1C0">
                                      <tr>
                                        <td width="100%" height="46" align="center" valign="middle" bgcolor="#83C1C0" style="font-weight:bold; font-size:24px; color:#000; font-family:verdana;">
                                          A
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                  <td>
                                    <table width="100%" border="1" cellpadding="0" cellspacing="0" bordercolor="#83C1C0">
                                      <tr>
                                        <td width="100%" height="46" align="left" valign="middle" bgcolor="#FFFFFF" style="font-weight:bold; font-size:24px; color:#000; font-family:verdana;">
                                          &nbsp;£
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td width="50%" height="35" align="left" valign="middle">&nbsp;</td>
                            <td width="23%">&nbsp;</td>
                            <td>&nbsp;</td>
                            <td width="23%">&nbsp;</td>
                          </tr>
                          <tr>
                            <td width="50%" height="35" align="left" valign="middle">
                              <p style="font-size:18px; ">
                                Number of complete periods of 12 months in the lease (ignore the first 12 months
                                of the lease)
                              </p>
                            </td>
                            <td width="23%">&nbsp;</td>
                            <td>&nbsp;</td>
                            <td width="23%">
                              <table width="100%" border="0" cellspacing="0" cellpadding="2">
                                <tr>
                                  <td width="25%">
                                    <table width="100%" border="1" cellpadding="0" cellspacing="0" bordercolor="#83C1C0" bgcolor="#83C1C0">
                                      <tr>
                                        <td width="100%" height="46" align="center" valign="middle" bgcolor="#83C1C0" style="font-weight:bold; font-size:24px; color:#000; font-family:verdana;">
                                          B
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                  <td>
                                    <table width="100%" border="1" cellpadding="0" cellspacing="0" bordercolor="#83C1C0">
                                      <tr>
                                        <td width="100%" height="46" align="left" valign="middle" bgcolor="#FFFFFF" style="font-weight:bold; font-size:24px; color:#000; font-family:verdana;">
                                          £
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td width="50%" height="35" align="left" valign="middle">
                              <p style="font-size:18px; ">
                                50 minus box B
                              </p>
                            </td>
                            <td width="23%">&nbsp;</td>
                            <td>&nbsp;</td>
                            <td width="23%">
                              <table width="100%" border="0" cellspacing="0" cellpadding="2">
                                <tr>
                                  <td width="25%">
                                    <table width="100%" border="1" cellpadding="0" cellspacing="0" bordercolor="#83C1C0" bgcolor="#83C1C0">
                                      <tr>
                                        <td width="100%" height="46" align="center" valign="middle" bgcolor="#83C1C0" style="font-weight:bold; font-size:24px; color:#000; font-family:verdana;">
                                          C
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                  <td>
                                    <table width="100%" border="1" cellpadding="0" cellspacing="0" bordercolor="#83C1C0">
                                      <tr>
                                        <td width="100%" height="46" align="left" valign="middle" bgcolor="#FFFFFF" style="font-weight:bold; font-size:24px; color:#000; font-family:verdana;">
                                          £
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td width="50%" height="35" align="left" valign="middle" style="border-bottom:solid 0px #83C1C0;">
                              <p style="font-size:18px;">
                                Box C divided by 50
                              </p>
                            </td>
                            <td width="23%" style="border-bottom:solid 0px #83C1C0;">&nbsp;</td>
                            <td style="border-bottom:solid 0px #83C1C0;">&nbsp;</td>
                            <td width="23%" height="35" style="border-bottom:solid 0px #83C1C0;">
                              <table width="100%" border="0" cellspacing="0" cellpadding="2">
                                <tr>
                                  <td width="25%">
                                    <table width="100%" border="1" cellpadding="0" cellspacing="0" bordercolor="#83C1C0" bgcolor="#83C1C0">
                                      <tr>
                                        <td width="100%" height="46" align="center" valign="middle" bgcolor="#83C1C0" style="font-weight:bold; font-size:24px; color:#000; font-family:verdana;">
                                          D
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                  <td>
                                    <table width="100%" border="1" cellpadding="0" cellspacing="0" bordercolor="#83C1C0">
                                      <tr>
                                        <td width="100%" height="46" align="left" valign="middle" bgcolor="#FFFFFF" style="font-weight:bold; font-size:24px; color:#000; font-family:verdana;">
                                          £
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td width="50%" height="20" align="left" valign="middle">&nbsp;</td>
                            <td width="23%" height="20">&nbsp;</td>
                            <td height="20">&nbsp;</td>
                            <td width="23%" height="20">&nbsp;</td>
                          </tr>
                          <tr>
                            <td width="50%" height="35" align="left" valign="middle">
                              <p style="font-size:18px; ">
                                Box A multiplied by box D
                              </p>
                              <p style="font-size:18px; ">&nbsp;</p>
                            </td>
                            <td width="23%">&nbsp;</td>
                            <td>&nbsp;</td>
                            <td width="23%">
                              <table width="100%" border="0" cellspacing="0" cellpadding="2">
                                <tr>
                                  <td width="25%">
                                    <table width="100%" border="1" cellpadding="0" cellspacing="0" bordercolor="#83C1C0" bgcolor="#83C1C0">
                                      <tr>
                                        <td width="100%" height="46" align="center" valign="middle" bgcolor="#83C1C0" style="font-weight:bold; font-size:24px; color:#000; font-family:verdana;">
                                          E
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                  <td>
                                    <table width="100%" border="1" cellpadding="0" cellspacing="0" bordercolor="#83C1C0">
                                      <tr>
                                        <td width="100%" height="46" align="left" valign="middle" bgcolor="#FFFFFF" style="font-weight:bold; font-size:24px; color:#000; font-family:verdana;">
                                          &nbsp;£
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td height="35" align="left" valign="middle">Copy figure in box E to box 'chargeable premiums' row in column B.</td>
                            <td>&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <p>
                    If the partnership paid foreign tax in respect of the premium, apportion the
                    amount of foreign tax as appropriate and enter in column D the amount
                    appropriate to the part of the premium that is taxable in the UK.
                  </p>
                  <p>
                    If you’re in doubt whether any payment you have received constitutes a premium,
                    ask us or your tax adviser. There can be a premium charge where you’ve assigned
                    a lease but not required the payment of a premium. If you’ve assigned a lease
                    and are not sure of all the consequences of that assignment, ask us or your tax
                    adviser.
                  </p>
                  <p><b>Reverse premiums</b></p>
                  <p>
                    If the partnership receives a payment or other benefit as an inducement to take
                    an interest in any property for letting, the receipt will be chargeable as
                    income from property. Include the receipt in box 2.11. If it receives an
                    inducement in respect of premises from which it’s to trade, each partner will
                    have to include their share of the inducement on their ‘Partnership’ pages.
                  </p>
                  <p>
                    If you’re in any doubt about the proper tax treatment of a reverse premium, ask
                    us or your tax adviser.
                  </p>
                  <a name="2p11a"></a>
                  <p><b>Box 2.11A</b></p>
                  <p>
                    Tick box 2.11A if box 2.11 contains income from more than one overseas let
                    property.
                  </p>
                  <a name="2p11b"></a>
                  <p><b>Box 2.11B</b></p>
                  <p>
                    Tick box 2.11B if box 2.11 contains income that is unremittable. Fill in a
                    separate page PF 3 for each property producing unremittable income.
                  </p>
                  <p>
                    &nbsp;Exclude the box 2.26 or box 2.27 figure from the &#39;Taxable profit or loss&#39;
                    column on page PF 4.
                  </p>
                  <hr>
                  <p><b>Expenses</b></p>
                  <a name="2p12-17"></a>
                  <p><b>Boxes 2.12 to 2.17</b></p>
                  <p>
                    If the total property income in the year before expenses is less than £85,000
                    annually, you do not have to list expenses separately. Instead, enter the total
                    expenses in box 2.17.
                  </p>
                  <p>
                    The following guidelines give an indication of the main types of expenses likely
                    to arise in a rental business and what usually can or cannot be claimed as a
                    deduction against income from land and property.
                  </p>
                  <p><b>Non-allowable expenses:</b></p>
                  <p>These are:</p>
                  <ul>
                    <li>a partner&#39;s personal expenses (see the notes about box 2.20 on page PFN 9) </li>
                    <li>
                      capital costs, such as expenses relating to the purchase of the land or property
                      that the partnership intends to let, or the cost of buying machinery,
                      furnishings or furniture
                    </li>
                    <li>any loss made on the sale of a property </li>
                  </ul>
                  <p><b>Allowable expenses:</b></p>
                  <p>These are:</p>
                  <ul>
                    <li>
                      In general, any costs you incurred for the sole
                      purpose of earning business profits.
                    </li>
                    <li> You may be able to claim the cost of replacing domestic items in residential lettings or capital allowances on the cost of buying a capital asset.</li>
                  </ul>
                  <p>
                    The expenses must be allocated to the correct return period and it may be
                    necessary to apportion certain expenses to arrive at the correct amount.
                  </p>
                  <hr>
                  <p>&nbsp;</p>
                  <a name="2p12"></a>
                  <p><b>Box 2.12&nbsp; Rent, rates, insurance, ground rents, etc.</b></p>
                  <p>
                    Any rent paid under a lease of a property let to someone else can be deducted in
                    working out the business profits.
                  </p>
                  <p>
                    Other expenses connected with the property such as local rates or ground rents
                    are also allowable. Enter in box 2.12 the total of any such expenses incurred in
                    the period for all properties included in your business.
                  </p>
                  <p>
                    Include in box 2.12 any expenses the partnership must incur as landlord insuring
                    any let property and its contents. Insurance against loss of rents is also an
                    allowable cost, but you must include in box 2.11 any income received as a result
                    of taking out such insurance. But insurances that extend beyond the rental
                    business, such as partners&#39; personal policies or those insuring your private
                    belongings, are not allowable costs.
                  </p>
                  <hr>
                  <p>&nbsp;</p>
                  <a name="2p13"></a>
                  <p><b>Box 2.13&nbsp; Repairs and maintenance</b></p>
                  <p>
                    Expenses that prevent the property from deteriorating can be deducted as a
                    repair. Examples include exterior and interior painting, stone cleaning, damp
                    treatment, roof repairs, furniture repairs and repairs to lifts and other
                    machines that form part of the property.
                  </p>
                  <p>
                    Where maintenance and repairs of property are made unnecessary by improvements,
                    additions or alterations, part of the outlay equal to the estimated cost of the
                    maintenance and repairs can be claimed as an expense. No expense can be claimed,
                    however, where:
                  </p>
                  <ul>
                    <li>
                      the alterations and so on, are so extensive as to amount to the reconstruction
                      of the property
                    </li>
                    <li>
                      there’s a change in the use of the property that would have made the maintenance
                      or repairs unnecessary
                    </li>
                  </ul>
                  <p>
                    If you’re in doubt whether any work undertaken on the property is a repair or
                    maintenance, ask us or your tax adviser.
                  </p>
                  <p><b>Renewals</b></p>
                  <p>
                    The renewals allowance for the cost of replacing items is no longer available.
                    You may be able to claim capital allowances on some of your capital items such
                    as furniture, furnishings, appliances and kitchenware in box 2.24.
                  </p>
                  <hr>
                  <p><b>Box 2.14 </b><strong>Non-residential property finance costs</strong> </p>
                  <a name="2p14"></a>
                  <p>
                    For non-residential property you can deduct the costs of getting a loan or
                    alternative finance to buy a property that you let, and the full amount of any
                    interest on such a loan or alternative finance payments.
                  </p>
                  <p>
                    For residential properties, for the tax year 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}
                    non-corporate partners cannot deduct the cost of getting a loan, or alternative
                    finance to buy a property that’s let, or any interest on such a loan or
                    alternative finance payments.
                  </p>
                  <p>Interest costs will be used to calculate a reduction in partners’ Income Tax</p>
                  <p>
                    Partnerships with corporate and non-corporate members will need to calculate the
                    costs of getting a loan, or alternative finance to buy a property that is let or
                    any interest on such a loan or alternative finance arrangements separately for
                    non-corporates only.
                  </p>
                  <p>
                    If interest costs for non-corporate partners refer to residential property in tax
                    year 6 April {{this.userService.Previous_Previousyear}} to 5 April {{this.userService.Previous_year}} or earlier, a proportion of the interest can
                    be deducted. The remaining proportion should be entered in box 2.30A or box
                    2.33A if you filled in more than one page PF3.
                  </p>
                  <p>
                    If you’re not sure whether any financial cost is allowable as a deduction, ask us
                    or your tax adviser.
                  </p>
                  <p>
                    For more information on the residential property finance costs restriction, go to
                    <a href="http://www.gov.uk/guidance/changes-to-tax-relief-for-residential-landlords-how-its-worked-out-including-case-studies" target="_blank" class="link">
                      www.gov.uk/guidance/changes-to-tax-relief-for-residential-landlords-how-its-worked-out-including-case-studies
                    </a>
                  </p>
                  <hr>
                  <p><b>Box 2.15 Legal and professional costs</b></p>
                  <a name="2p15"></a>
                  <p>
                    Below are some examples of expenses the partnership cannot deduct and those it
                    can.
                  </p>
                  <p><b>Non-allowable expenses:</b></p>
                  <p>These are:</p>
                  <ul>
                    <li>
                      expenses in connection with the first letting or subletting of a property for
                      more than one year – these include, for example, legal expenses (such as the
                      cost of drawing up the lease), agents&#39; and surveyors&#39; fees and commission
                    </li>
                    <li>
                      any proportion of the legal and so on, costs that relate to the payment of a
                      premium on the renewal of a lease
                    </li>
                    <li>
                      fees incurred in obtaining planning permission or on the registration of title
                      when buying a property
                    </li>
                  </ul>
                  <p><b>Allowable expenses:</b></p>
                  <p>These are:</p>
                  <ul>
                    <li>expenses for the let of one year or less </li>
                    <li>
                      the normal legal and professional fees you incurred on the renewal of a lease,
                      if the lease is for less than 50 years
                    </li>
                    <li>
                      professional fees incurred evicting an unsatisfactory tenant, with a view to
                      reletting, or those on an appeal against a compulsory purchase order
                    </li>
                    <li>professional fees in drawing up accounts </li>
                  </ul>
                  <p>
                    If you’re not sure whether any legal or professional fee is allowable as a
                    deduction, ask us or your tax adviser.
                  </p>
                  <hr>
                  <p>&nbsp;</p>
                  <a name="2p16"></a>
                  <p>
                    <b>
                      Box 2.16 Cost of services provided,
                      including wages
                    </b>
                  </p>
                  <p>
                    If, in addition to letting a property, the partnership provides any service to
                    the tenant (such as gardening, porterage, cleaning or even communal hot water)
                    which requires a degree of maintenance and thus expense, you can claim the cost
                    of these services to the extent that they’re provided wholly and exclusively for
                    the purposes of the letting.
                  </p>

                  <p>
                    Include in box 2.16 the total of any such expenses for all properties and their
                    associated services. If the partnership receives any income for any service
                    provided, enter this in box 2.11.
                  </p>
                  <hr>
                  <p>&nbsp;</p>
                  <a name="2p17"></a>
                  <p><b>Box 2.17&nbsp; Other expenses</b></p>
                  <p>
                    Enter in box 2.17 all expenses incurred wholly and exclusively for the purpose of
                    the rental business that are not already included in boxes 2.12 to 2.16.
                    Examples include the costs of rent collection, advertising for tenants,
                    travelling solely for business purposes, stationery, phone calls and other
                    miscellaneous expenses.
                  </p>
                  <p>
                    Partners&#39; personal expenses are not allowable. If the partnership spends money on
                    something only partly used for the property, you must enter only the amount
                    expended for business purposes in box 2.17. Alternatively, enter the whole
                    amount and deduct in box 2.20 the proportion of the cost that represents
                    personal use.
                  </p>
                  <hr>
                  <p><b>Tax adjustments</b></p>
                  <p>
                    To arrive at the income (or the allowable loss) for tax purposes, you need to
                    make certain adjustments to the net profit or loss arising in the year in box
                    2.19. The main adjustments are listed below.
                  </p>
                  <p>&nbsp;</p>
                  <a name="2p20"></a>
                  <p><b>Box 2.20&nbsp; Private use</b></p>
                  <p>
                    Private and personal expenses are not allowable. If the partnership spends money
                    on something only partly used for the rental business and partly for a
                    non-business reason you must either enter the:
                  </p>
                  <ul>
                    <li>amount expended for business purposes in boxes 2.12 to 2.17</li>
                    <li>
                      whole expenses in those boxes and deduct in box 2.20 the proportion of the cost
                      that represents your personal use or the non-business element
                    </li>
                  </ul>
                  <p>
                    For example, if the partnership lets out a property for only 8 months in a year
                    and a partner uses it for the other 4 months, you can put the full annual cost
                    of insuring the property in box 2.12. If you do, add back one third of that cost
                    in box 2.20.
                  </p>
                  <p>
                    If you’re in any doubt whether a particular expense needs to be apportioned
                    between business and private use, ask us or your tax adviser.
                  </p>
                  <hr>
                  <p>&nbsp;</p>
                  <a name="2p21"></a><a name="2p23"></a><a name="2p21-23"></a>
                  <p>
                    <strong>
                      Boxes 2.21, 2.21A, 2.21B, 2.21C and 2.23 Capital allowances and balancing
                      charges
                    </strong>
                  </p>
                  <p>In working out the rental business profits do not deduct: </p>
                  <ul>
                    <li>the cost of buying, altering, building, installing or improving fixed assets </li>
                    <li>depreciation or any losses that arise when you sell them </li>
                  </ul>
                  <p>
                    Instead, the partnership may be able to claim capital allowances in boxes 2.21A ,
                    2.21B, 2.21C or 2.23. These reduce a profit or increase a loss. An adjustment,
                    known as a balancing charge, may arise when it sells an item, gives it away or
                    stops using it in the business. Enter the amount of balancing charges in box
                    2.21. These increase the profits or reduce a loss.
                  </p>
                  <p>
                    However, if the partnership lets a dwelling house, capital allowances are not
                    available on any fixtures, machines, furniture, furnishings or other items for
                    use in the property or the building or any structure to do with the dwelling
                    house.
                  </p>
                  <p>See the notes on box 2.24 (on the cost of replacing domestic items). </p>
                  <p>
                    Do not claim capital allowances if you’re using cash basis. The only exception is
                    cars.
                  </p>
                  <p>
                    Your tax adviser, if you have one, will tell you how to calculate capital
                    allowances and balancing charges.
                  </p>
                  <p>&nbsp;</p>
                  <p><strong>Fixtures</strong></p>
                  <p>
                    Under rules introduced in 2012 and 2014, a purchaser of a second-hand business
                    property containing fixtures (such as kitchen fittings, electrical or heating
                    systems) will not usually be entitled to claim allowances unless the past owner
                    has ‘pooled’ its qualifying expenditure and has fixed the value of the fixtures.
                    ‘
                  </p>
                  <p>
                    ‘Pooling’ includes making a claim for first year allowance or annual investment
                    allowance for the expenditure. It is not necessary for the past owner to claim
                    writing down allowances. As a rule, the past owner is the last person who was
                    entitled to claim capital allowances on fixtures.
                  </p>
                  <p>
                    Normally, the value will be fixed by means of a joint Section 198 CAA 2001
                    election, which must be notified to HMRC within 2 years of the property
                    transaction. The amount the seller can bring in to any pool as the disposal
                    value will be the same as the amount the buyer can bring in as the acquisition
                    value for capital allowances purposes.
                  </p>
                  <p><strong>Box 2.21 Balancing charges </strong>
                  <p>
                    Balancing charges may arise following disposal or balancing event, such as the
                    sale, loss or destruction of assets or on the cessation of business use, where
                    the proceeds from the event are more than their tax value. If you sell an item
                    you’ve claimed capital allowances on, and the sale proceeds or value of the item
                    is more than the pool value or cost you’ll have to pay tax on the difference (a
                    ‘balancing charge’). This includes items where the pool value is nil because you
                    claimed all of the cost previously. Put the total balancing charge in box 2.21.
                    <hr>
                    <a name="2p21a"></a>
                  <p>&nbsp;</p>
                  <p><b>Box 2.21A Electric charge-point allowance</b></p>
                  <p>
                    You can claim 100% first year capital allowances for expenditure invested in the
                    acquisition and installation of new and unused electric charge-points for
                    electric vehicles. Put the amount of expenditure incurred in box 2.21A.
                  </p>
                  <hr>
                  <a name="2p21b"></a>
                  <p>&nbsp;</p>
                  <p><b>Box 2.21B The Structures and Buildings Allowance</b></p>
                  <p>
                    If the partnership is eligible to claim the new Structures and Buildings
                    Allowance (SBA), put the amount of the claim in box 2.21B. If claiming for the
                    first time in respect of an amount of qualifying expenditure, use the
                    ‘Additional information’ box, box 2.35, to record the:
                  </p>
                  <ul>
                    <li>
                      date the building first came into qualifying use or, if later, the date the
                      qualifying expenditure was incurred
                    </li>
                    <li> total amount of new qualifying expenditure incurred </li>
                  </ul>
                  <p>
                    To check if and how much you can claim, go to
                    <a href="http://www.gov.uk/guidance/claiming-capital-allowances-for-structures-and-buildings" target="_blank" class="link">
                      www.gov.uk/guidance/claiming-capital-allowances-for-structures-and-buildings
                    </a>
                  <p>&nbsp;</p>
                  <hr>
                  <a name="2p21c"></a>
                  <p>&nbsp;</p>
                  <p><b>Box 2.21C&nbsp; <b>Zero-emission car allowance</b></b></p>
                  <p>
                    Claim the 100% first year allowance (the full cost) of any new and unused
                    zero-emission or electric cars in this box.
                  </p>
                  <p>
                    If you use a car outside of your business, you must reduce the claim in
                    proportion to the non-business use.
                  </p>
                  <p>
                    If you do not have a tax adviser and want more information, go to
                    <a href="https://www.gov.uk/business-tax/capital-allowances" target="_blank" class="link">
                      www.gov.uk/business-tax/ capital-allowances
                    </a>
                    <a name="2p23a"></a>
                    <hr>
                  <p>&nbsp;</p>
                  <a name="2p24"></a>
                  <p><strong>Box 2.23 All other capital allowances</strong></p>
                  <p>
                    The type of capital allowance and amount that you can claim will depend on the
                    cost, type of assets and other circumstances. For example, you can only claim
                    capital allowances for furniture and fixtures or other equipment for use in a
                    dwelling house if it qualifies as a Furnished Holiday Letting (FHL). Put your
                    total capital allowances not claimed elsewhere in box 2.23.
                  </p>
                  <p>You may be able to claim some of these allowances: </p>
                  <ul>
                    <li>
                      18% writing down allowance (WDA) on the balance of your purchases after
                      deducting any Annual Investment Allowance (AIA) if your total costs were more
                      than AIA maximum amount
                    </li>
                    <li>
                      6% WDA for ‘special rate’ equipment such as electrical systems and cars with
                      higher CO2 emissions
                    </li>
                  </ul>
                  <p>
                    For more information on CO2 emissions, go to
                    <a href="http://www.gov.uk/capital-allowances/business-cars" target="_blank" class="link">
                      www.gov.uk/capital-allowances/business-cars
                    </a>
                  </p>
                  <p><strong>Box 2.24 Costs of replacing domestic items </strong></p>
                  <p>
                    If the partnership lets any residential accommodation (such as a house or flat,
                    other than as a furnished holiday letting), capital allowances are not available
                    on expenditure on assets in the dwelling house.
                  </p>
                  <p>
                    Instead, the partnership can claim the cost of any replacement domestic items for
                    use in the accommodation by putting an amount in box 2.24.
                  </p>
                  <p>This cost can be claimed where: </p>
                  <ul>
                    <li> it is actually incurred on purchasing a replacement domestic item – you can’t claim the initial cost for an item provided for use in the accommodation for the first time </li>
                    <li> the new item is provided solely for the use of the tenants in the accommodation and the old item is no longer be available for use</li>
                    <li>  if the new item is an improvement on the old item, the cost you can claim is limited to the cost of purchasing an equivalent replacement to the old item – the new item is an improvement if there’s a substantial upgrade in the function, materials or quality over the old item, unless this is due to the new item being the nearest available modern equivalent </li>
                  </ul>
                  <p>Domestic items include items such as: </p>
                  <ul>
                    <li>
                      moveable furniture, for example, beds,
                      free-standing wardrobes
                    </li>
                    <li>  furnishings for example, curtains, linens, carpets, floor coverings</li>
                    <li>  household appliances for example, televisions, fridges, freezers </li>
                    <li> kitchenware for example, crockery and cutlery</li>
                  </ul>
                  <p>
                    Capital allowances can be claimed for items in a property, if the partnership lets furnished, but not residential accommodation. If capital allowances can be claimed, you cannot claim the cost of replacing domestic items.
                  </p>
                  <hr>
                  <p>&nbsp;</p>
                  <a name="2p26"></a><a name="2p27"></a><a name="2p26-27"></a>
                  <p><b>Boxes 2.26 and 2.27&nbsp; Adjusted profit or loss</b></p>
                  <p><b>If you filled in one page PF 3 </b></p>
                  <p>
                    Enter any net profit in box 2.26. If there’s a net loss enter &#39;0&#39; in box 2.26 and
                    the amount of the loss in box 2.27. Go on to page PF 4.
                  </p>
                  <p><b>If you filled in more than one page PF 3</b></p>
                  <p>
                    Enter any net profit in box 2.26. If there’s a net loss enter &#39;0&#39; in box 2.26 and
                    the amount of the loss in box 2.27. Go on to page PF 4.
                  </p>
                  <p><b>Filling in page PF 4  </b></p>
                  <p>
                    If you completed one page PF 3 fill in boxes 2.28 to 2.30. If you completed more
                    than one page PF 3, give details about each let property, using a separate line
                    of the grid for each, and then fill in boxes 2.31 to 2.34.
                  </p>
                  <hr>
                  <p><strong>Taxable profit for the year if you filled in one page PF 3 </strong></p>
                  <a name="2p28-29"></a>
                  <p><b>Boxes 2.28 and 2.29</b></p>
                  <p>
                    If you completed only one page PF 3 and there’s more than one let property, the
                    profits and losses of all the rented properties must be pooled in order to
                    calculate the overall result.
                  </p>
                  <a name="2p28"></a>
                  <p><b>Box 2.28</b></p>
                  <p>
                    Enter in box 2.28 the taxable profit from box 2.26 on page PF 3. Copy box 2.28 to
                    column B on page PF 2.
                  </p>
                  <a name="2p29"></a>
                  <p><b>Box 2.29</b></p>
                  <p>
                    Enter in box 2.29 any allowable loss from box 2.27 on page PF 3. Copy box 2.29 to
                    box 2.10 on page PF 2.
                  </p>
                  <hr>
                  <p><b>Foreign tax paid on the rental income</b></p>
                  <a name="2p30"></a>
                  <p><b>Box 2.30</b></p>
                  <p>
                    If you have paid foreign tax enter the amount of foreign tax paid in box 2.30.
                    Copy box 2.30 to column D on page PF 2.
                  </p>
                  <p><b>Filling in the columns on page PF 2</b></p>
                  <p><b>column A</b> Enter the name of the country where the property is situated. </p>
                  <p><b>column B</b> Enter the amount of profit for the year from box 2.28. </p>
                  <p>
                    <b>column D</b> Enter the amount of any foreign tax paid for income chargeable to
                    UK tax from box 2.30.
                  </p>
                  <hr>
                  <p>
                    <b>
                      Taxable profit for the year if you filled in more
                      than one page PF 3
                    </b>
                  </p>
                  <p>&nbsp;</p>
                  <a name="2p30A"></a><a name="2p30A"></a>
                  <p><b>Box 2.30A&nbsp; <strong>Residential property finance costs </strong></b></p>
                  <p>
                    Put residential property finance costs for non-corporate partners in box 2.30A.
                    This will be used to calculate a reduction in partners’ income tax.
                  </p>
                  For more information on the residential property finance	costs	restriction,
                  go	to
                  <a href="http://www.gov.uk/guidance/changes-to-tax-relief-for-residential-landlords-how-its-worked-out-including-case-studies" target="_blank" class="link">
                    www.gov.uk/guidance/changes-to-tax-relief-for-residential-landlords-how-its-worked-out-including-case-studies
                  </a>
                  <a name="2p31"></a><a name="2p31-33"></a>
                  <p><b>Boxes 2.31 to 2.33</b></p>
                  <p>
                    Profits and losses of all the let properties must be pooled in order to calculate
                    the overall result. Exclude from this pool any unremittable property income.
                  </p>
                  <p>
                    Normally, the tax authorities of the country where the let property is situated
                    will also charge tax on the letting profits. If no more relief was given this
                    would mean that the partners would pay tax, on the same profits both here and
                    abroad. But the double charge is relieved by deducting the overseas tax paid on
                    the property income from the UK tax due on the same income. This is done either
                    under the terms of a Double Taxation Treaty with the overseas country or, where
                    no treaty exists, under separate UK rules.
                  </p>
                  <p>
                    If the overseas income has suffered foreign tax and a claim to tax credit relief
                    is made by the partners, it’ll be necessary to identify the amount of UK tax
                    attributable to income from each particular property. Therefore, where Foreign
                    Tax Credit Relief is claimed, separate computations of profits and losses for
                    each property will be needed.
                  </p>
                  <p>
                    For the purposes of calculating Foreign Tax Credit Relief, losses should be
                    deducted in the order most favourable. Normally, this will mean that losses are
                    allocated first against the source that has suffered the lowest rate of foreign
                    tax. See the example below:
                  </p>
                  <table border="0" bordercolor="#111111" cellpadding="5" cellspacing="0" style="border-collapse: collapse" width="60%">
                    <tr>
                      <td align="center" width="30%">&nbsp;</td>
                      <td align="center" width="17.5%"><b>Country A</b></td>
                      <td align="center" width="18%">
                        <p align="right"><b>Country B</b></p>
                      </td>
                      <td align="center" width="17%"><b>Country C</b></td>
                      <td align="center" width="17.5%"><b>Total</b></td>
                    </tr>
                    <tr>
                      <td bgcolor="#E6E7E7" width="30%">Income</td>
                      <td align="center" bgcolor="#E6E7E7" width="17.5%">&pound;6,000</td>
                      <td align="center" bgcolor="#E6E7E7" width="17.5%">&pound;4,000</td>
                      <td align="center" bgcolor="#E6E7E7" width="17.5%">&pound;6,000</td>
                      <td align="center" bgcolor="#E6E7E7" width="17.5%">&nbsp;</td>
                    </tr>
                    <tr>
                      <td bgcolor="#E6E7E8" width="30%">Expenses</td>
                      <td align="center" bgcolor="#E6E7E8" width="17.5%">&pound;1,000</td>
                      <td align="center" bgcolor="#E6E7E8" width="17.5%">&pound;6,000</td>
                      <td align="center" bgcolor="#E6E7E8" width="17.5%">&pound;4,000</td>
                      <td align="center" bgcolor="#E6E7E8" width="17.5%">&nbsp;</td>
                    </tr>
                    <tr>
                      <td bgcolor="#E6E7E8" width="30%">Profit (loss)</td>
                      <td align="center" bgcolor="#E6E7E8" width="17.5%">&pound;5,000</td>
                      <td align="center" bgcolor="#E6E7E8" width="17.5%">(&pound;2,000)</td>
                      <td align="center" bgcolor="#E6E7E8" width="17.5%">&pound;2,000</td>
                      <td align="center" bgcolor="#E6E7E8" width="17.5%">&pound;5,000</td>
                    </tr>
                    <tr>
                      <td colspan="5" bgcolor="#E6E7E8"><p>The following amounts of foreign tax have been paid:</p></td>
                    </tr>
                    <tr bgcolor="#E6E7E8">
                      <td width="30%">&nbsp;</td>
                      <td align="center" width="20%">&nbsp;</td>
                      <td align="center" width="25%"><b>Rate of foreign tax</b></td>
                      <td align="center" width="25%"><b>Tax deducted</b></td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr bgcolor="#E6E7E8">
                      <td width="30%">Country A</td>
                      <td align="center" bgcolor="#E6E7E8" width="20%">&pound;5,000</td>
                      <td align="center" bgcolor="#E6E7E8" width="25%">10%</td>
                      <td align="center" bgcolor="#E6E7E8" width="25%">&pound;500</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr bgcolor="#E6E7E8">
                      <td bgcolor="#E6E7E8" width="30%">Country B</td>
                      <td align="center" bgcolor="#E6E7E8" width="20%">Nil</td>
                      <td align="center" bgcolor="#E6E7E8" width="25%">&nbsp;</td>
                      <td align="center" bgcolor="#E6E7E8" width="25%">&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr bgcolor="#E6E7E8">
                      <td bgcolor="#E6E7E8" width="30%">Country C</td>
                      <td align="center" bgcolor="#E6E7E8" width="20%">&pound;2,000</td>
                      <td align="center" bgcolor="#E6E7E8" width="25%">30%</td>
                      <td align="center" bgcolor="#E6E7E8" width="25%">&pound;600</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr bgcolor="#E6E7E8">
                      <td bgcolor="#E6E7E8" width="30%">Total foreign tax</td>
                      <td align="center" bgcolor="#E6E7E8" width="20%">&nbsp;</td>
                      <td align="center" bgcolor="#E6E7E8" width="25%">&nbsp;</td>
                      <td align="center" bgcolor="#E6E7E8" width="25%">£1,100</td>
                      <td>&nbsp;</td>
                    </tr>
                  </table>
                  <p>
                    Assuming that all of the income is wholly chargeable at 20% the Income Tax due
                    will be as follows:
                  </p>
                  <p><b>Country A</b></p>
                  <p>£5,000 &#64; 20% = £1,000</p>
                  <p>All of the foreign tax paid of £500 is available for Foreign Tax Credit Relief. </p>
                  <table id="AutoNumber7" border="0" bordercolor="#111111" cellpadding="5" cellspacing="0" style="border-collapse: collapse" width="32%">
                    <tr>
                      <td width="28%">Profit</td>
                      <td width="72%">£5,000</td>
                    </tr>
                    <tr>
                      <td width="28%">Losses</td>
                      <td width="72%">£2,000</td>
                    </tr>
                    <tr>
                      <td width="28%">Net</td>
                      <td width="72%">£3,000 &#64; 20%  £600</td>
                    </tr>
                  </table>
                  <p>
                    All of the foreign tax paid of £500 is available for foreign tax credit
                    relief.
                  </p>
                  <p><b>Country C</b></p>
                  <p>£2,000 &#64; 20% = £400</p>
                  <p>
                    Although foreign tax of £600 has been paid, the amount available for Foreign Tax
                    Credit Relief is limited to the amount of UK tax charged on the same income,
                    that is £400.
                  </p>
                  <table id="AutoNumber8" border="0" bordercolor="#111111" cellpadding="5" cellspacing="0" style="border-collapse: collapse" width="45%">
                    <tr>
                      <td width="52%"><b>Summary</b></td>
                      <td align="right" width="19%">&nbsp;</td>
                      <td align="right" width="28%">&nbsp;</td>
                      <td align="right" width="14%">&nbsp;</td>
                    </tr>
                    <tr>
                      <td width="52%">Income Tax due</td>
                      <td align="right" width="19%">£600</td>
                      <td align="right" width="28%">+ £400 =</td>
                      <td align="right" width="14%">£1,000</td>
                    </tr>
                    <tr>
                      <td width="52%">Foreign tax credit relief</td>
                      <td align="right" width="19%">£500</td>
                      <td align="right" width="28%">+ £400 =</td>
                      <td align="right" width="14%">£900</td>
                    </tr>
                    <tr>
                      <td width="52%">Net UK tax payable</td>
                      <td align="right" width="19%">&nbsp;</td>
                      <td align="right" width="28%">=</td>
                      <td align="right" width="14%">£100</td>
                    </tr>
                  </table>
                  <p>
                    If you need any help to work out the relief due, please contact us or your tax
                    adviser.
                  </p>
                  <p>If box 2.31 is a profit, copy it to column B on page PF 2. </p>
                  <hr>
                  <p><b>Foreign tax paid on rental income</b></p>
                  <a name="2p32"></a>
                  <p><b>Box 2.32</b></p>
                  <p>Add up the foreign tax deducted and enter the total in box 2.32. </p>
                  <a name="2p33"></a>
                  <p><b>Box 2.33</b></p>
                  <p>
                    After allocating any losses in the most favourable way, add up the amounts
                    chargeable and enter the total in box 2.33.
                  </p>
                  <p>&nbsp;</p>
                  <a name="2p33A"></a>
                  <p><b>Box 2.33A&nbsp; <strong>Residential property finance costs </strong></b></p>
                  <p>
                    Put residential property costs for non-corporate partners in box 2.33A. This will
                    be used to calculate a reduction in partners’ income tax.
                  </p>
                  <p>
                    For more information on the residential property finance costs restriction, go to
                    <a href="http://www.gov.uk/guidance/changes-to-tax-relief-for-residential-landlords-how-its-worked-out-including-case-studies" target="_blank" class="link">
                      www.gov.uk/guidance/changes-to-tax-relief-for-residential-landlords-how-its-worked-out-including-case-studies
                    </a>
                  </p>
                  <a name="2p34"></a>
                  <p><b>Box 2.34 </b></p>
                  <p>
                    If the overall result is a loss, enter the loss in box 2.34. Copy box 2.34 to box
                    2.10 on page PF 2.
                  </p>
                  <p><b>Filling in the columns on page PF 2</b></p>
                  <p><b>column A</b>.</p>
                  <p>Enter &#39;see page PF 4&#39;. </p>
                  <p><b>column B</b> Enter the overall amount of profit for the year from box 2.31. </p>
                  <p><b>column D</b> Enter any foreign tax deducted from box 2.32. </p>
                  <hr>
                  <table border="0" cellpadding="5" cellspacing="0" style="text-align: justify; border: 1px #000 solid;" width="90%">
                    <tr>
                      <td>
                        These notes are for guidance only and reflect the position at the time of
                        writing. They do not affect the right of appeal.
                      </td>
                    </tr>
                  </table>
                  <p style="text-align: right; margin: 0px; padding: 0px;">

                  </p>
                </div>
              </div>
          </div>
          <!--SA803 starts here........-->
          <div id="SA803" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <table id="AutoNumber1" border="0" bordercolor="#111111" cellpadding="0" cellspacing="0"
                       style="border-collapse: collapse" width="98%">
                  <tr>
                    <td align="right" width="93%">
                      <p style="text-align: right;">
                        <span style="font-size: 25px;" class="headingSA">
                          Partnership disposal of chargeable assets
                        </span><br>
                        <span style="font-size: 15px;" class="headingSA">
                          <b>Tax year 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}({{this.userService.Currenttax_shortyear}}) </b>
                        </span>
                      </p>
                    </td>
                    <td width="7%">
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td align="right" bgcolor="#00ABBC" width="93%">
                    </td>
                    <td bgcolor="#15BECC" width="7%">
                      &nbsp;
                    </td>
                  </tr>
                </table>
                <br>
                <table id="AutoNumber2" border="0" bordercolor="#111111" cellpadding="0" cellspacing="0"
                       style="border-collapse: collapse" width="98%">
                  <tr>
                    <!--<td valign="top" width="20%">
                      <a name="top"></a><a class="link" href="#4p1">Box 4.1</a>
                    </td>-->
                  </tr>
                </table>
                <p>
                  <b>Filling in the' Partnership Disposal of Chargeable Assets' pages</b>
                </p>
                Gather together the material you need, such as:
                <ul>
                  <li>contracts for the purchase and sale of assets</li>
                  <li>invoices for allowable expenditure.</li>
                </ul>

                <p>
                  You must complete these pages if the partnership disposed of any chargeable
                  assets in {{this.userService.Previous_year}} to {{this.userService.Present_year}}. Also complete these pages if the partnership carries on
                  its business as a Limited Liability Partnership and has disposed of any
                  chargeable assets in the period. If the partnership is a ‘CT Partnership’, see
                  page PTRG 5 of the Partnership Tax Return Guide.
                </p>
                <p>
                  You may need to provide each partner with additional information, for example,
                  the incidental costs of buying or selling an asset, so that they can calculate
                  their own chargeable gains. You can find details of the information the partners
                  may need when filling in their own tax returns in the Capital Gains Tax notes
                  and helpsheets for the personal tax return. To get copies, go to
                  <a href="http://www.gov.uk/taxreturnforms" target="_blank" class="link">
                    www.gov.uk/taxreturnforms
                  </a>
                </p>
                <p>
                  <b>An introduction to Capital Gains Tax </b>
                </p>
                <p>
                  These ‘notes’ are a simplified summary of the Capital Gains Tax law as it
                  applies in some common cases. If you’re in any doubt about their relevance to
                  your partnership, ask us or your tax adviser.
                </p>
                <p>
                  <b>Chargeable assets</b>
                </p>
                <p>
                  Any form of property, wherever it is situated, may be a chargeable asset. The
                  most common assets include:
                </p>
                <ul>
                  <li>stocks, shares and units in unit trusts </li>
                  <li>land and buildings </li>
                  <li>business assets such as goodwill </li>
                </ul>
                <p>
                  Some assets are exempt from Capital Gains Tax but most of these exemptions are
                  unlikely to apply to partnership assets. If you’re in any doubt, ask us or your
                  tax adviser.
                </p>
                <p>
                  <strong>What is a chargeable gain? </strong>
                </p>
                <p>
                  A chargeable gain is made when something a person owns (an asset) is wholly or
                  partly disposed of (or treated as disposed of) and either:
                </p>
                <ul>
                  <li>
                    has increased in value since it was acquired, or since 31 March 1982 if that is
                    later
                  </li>
                  <li>
                    as a result of a claim to relief, their acquisition costs have been reduced and
                    the proceeds of disposal are greater than this reduced amount
                  </li>
                </ul>
                <p>
                  We may also treat a person as making a gain in other circumstances, for example,
                  where:
                </p>
                <ul>
                  <li>
                    a gain on an earlier disposal of an asset has been deferred and the deferral
                    period has come to an end
                  </li>
                  <li>
                    the value of an asset has increased by a transfer of rights, or by any other
                    means that would not by itself be regarded as a disposal
                  </li>
                  <li>
                    a wasting asset is disposed of that has not diminished in value as quickly as
                    was expected
                  </li>
                  <li>
                    a capital sum is derived from ownership of an asset, for example, compensation
                    for damage or an insurance receipt
                  </li>
                </ul>
                <p>
                  We’ll normally treat each of the members of the partnership as owning a
                  proportionate interest in the partnership’s assets. When an asset is disposed
                  of, each partner may make a chargeable gain on the disposal of their interest in
                  that asset. But the gains made by each partner may vary; for example, because
                  one partner is entitled to a smaller or larger interest in the asset than the
                  other partners or because one partner has been entitled to an interest in the
                  asset for a shorter or longer period than the other partners.
                </p>
                <p>
                  Because the gains are chargeable on the partners rather than the partnership,
                  there’s no such thing as a ‘partnership chargeable gain’. Therefore, you’re
                  required simply to make a return of the information included in a partnership
                  account for the period from 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}, namely the proceeds
                  from any disposals of partnership assets.
                </p>
                <p style="text-align: right">

                </p>
                <p>
                  <strong>Exempt assets </strong>
                </p>
                <p>
                  Exemptions that might apply to the partnership assets include:
                </p>
                <ul>
                  <li>motor cars</li>
                  <li>UK Government stocks (gilts) and certain corporate bonds</li>
                  <li>
                    life assurance policies and deferred annuity contracts, unless at any time acquired
                    for actual consideration.
                  </li>
                </ul>
                <p>
                  If the only disposals are of assets in the list above, you do not need to
                  complete these pages.
                </p>
                <p>
                  Gains from the disposal of personal effects or goods, each of which was worth
                  £6,000 or less when disposed of, are exempt. You can find more details in
                  Helpsheet 293, ‘Personal Possessions and Capital Gains Tax’, go to <a href="http://www.gov.uk" target="_blank" class="link">
                    www.gov.uk
                  </a>
                  and search for ‘HS293’.
                </p>
                <p>
                  <b>Information about disposals</b>
                </p>
                <p>
                  <b>Disposals</b>
                </p>
                <p>
                  Tax is payable on gains from the disposal of chargeable assets. A disposal will
                  occur when the partnership either:
                </p>
                <ul>
                  <li>sells, or</li>
                  <li>gives away, or</li>
                  <li>exchanges an asset, or</li>
                  <li>receives a capital sum from the ownership of an asset </li>
                  <li>
                    the value of an asset it owns has been increased at the expense of an asset
                    owned by some other person
                  </li>
                </ul>
                <p>
                  A capital sum is a sum that is not part of the partnership’s taxable income.
                </p>
                <p>
                  The tax due, if any, arises on the disposal of the partners’ interests in the
                  partnership asset. This is a matter for the partners themselves.
                </p>
                <p>
                  There are a number of special rules for chargeable disposals which, although not
                  relevant to the Partnership Tax Return, the partners may need to consider. These
                  include:
                </p>
                <ul>
                  <li>claims that an asset has become of negligible value</li>
                  <li>
                    disposals of interests in partnership assets (for example, when a partner’s
                    proportionate interest in a partnership asset is reduced even though there’s
                    been no disposal of the asset by the partnership itself)
                  </li>
                  <li>
                    occasions when the market value of an asset must be taken into account rather
                    than the actual disposal proceeds (‘market value’ is defined on page PAN 4 of
                    these ‘notes’)
                  </li>
                  <li>a part disposal of an asset</li>
                  <li>
                    disposals to a connected person (‘connected persons’ is defined on page PAN 5 of
                    these ‘notes’)
                  </li>
                </ul>
                <p>
                  <b>Building society mergers, conversions and takeovers</b>
                </p>
                <p>
                  If the partnership has either:
                </p>
                <ul>
                  <li>received cash as a result of a merger of 2 or more building societies </li>
                  <li>
                    received cash, or been issued with shares, or received both cash and shares, as
                    a result of either
                    <ul style="list-style-type: none;">
                      <li>– a conversion of a building society to a company, or</li>
                      <li>– a takeover of a building society by a company,</li>
                    </ul>
                  </li>
                </ul>
                <p>
                  there may be liability to either Income Tax or Capital Gains Tax. The building
                  society may be able to tell you whether there’s any tax liability. If not, ask
                  us or your tax adviser.
                </p>
                <p>
                  <b>Share reorganisations, company reconstructions and takeovers</b>
                </p>
                <p>
                  If shares in a company held by the partnership have changed because the company
                  has reorganised its share capital or if the partnership has exchanged shares in
                  a company for other shares or securities as part of a company reconstruction or
                  takeover, the partners will not be treated as if they had made a disposal of
                  their original interests in the shares. Instead the chargeable gain or allowable
                  loss in respect of the interest in the original shares will arise when the
                  partnership makes a disposal of the new shares or securities. The 2 main
                  exceptions to this general approach, where the partners may be treated as having
                  made a disposal on which there is a chargeable gain or allowable loss, are
                  where:
                </p>
                <ul>
                  <li>
                    they receive anything else in addition to the new shares or securities, such as
                    a sum of money
                  </li>
                  <li>
                    a company reconstruction or takeover is not carried out for commercial
                    reasons, or is made in order to avoid tax – companies can apply for clearance in
                    advance in respect of these anti-avoidance rules (the clearance will only be
                    valid if the takeover or reconstruction is carried out in accordance with the
                    clearance application)
                  </li>
                </ul>
                <p>
                  If you think that the partnership has obtained shares or securities as part of a
                  company reconstruction or takeover, or you need advice about the anti-avoidance
                  rules, download Helpsheet 285, ‘Capital Gains Tax, share reorganisations and
                  company takeovers’, go to <a href="http://www.gov.uk" target="_blank" class="link">
                    www.gov.uk
                  </a>
                  and search for ‘HS285’.
                </p>
                <p>
                  <b>What is the date of disposal?</b>
                </p>
                <p>
                  If the disposal was made by way of a contract, the date of disposal is usually the
                  date of the contract.
                </p>
                <p>
                  In a small number of cases the contract may be conditional. This will apply if
                  one or more conditions have to be met before the contract becomes binding. In
                  these cases the date of disposal is the date on which the last of the conditions
                  is met.
                </p>
                <p>
                  In other cases the date of disposal will usually be the date when ownership of
                  the asset was effectively transferred.
                </p>
                <p>
                  There may be exceptions, particularly if the asset is land that has been
                  compulsorily purchased.
                </p>
                <p style="text-align: right">

                </p>
                <table id="AutoNumber4" border="0" bordercolor="#111111" cellpadding="10" cellspacing="0"
                       style="border-collapse: collapse" width="60%">
                  <tr>
                    <td align="justify" bgcolor="#DEE2EE" width="100%">
                      <strong>Example 1</strong><br>
                      <br>
                      The partnership sells a piece of land under an unconditional contract dated 2
                      April {{this.userService.Present_year}}. The land was conveyed to the purchaser on 2 May {{this.userService.Present_year}}. The disposal
                      is treated as taking place on 2 April {{this.userService.Present_year}}. This date falls in the tax year
                      ending on 5 April {{this.userService.Present_year}}. Return the disposal in the Partnership Tax Return for
                      that year.
                    </td>
                  </tr>
                </table>
                <p>
                  <b>What are the disposal proceeds?</b>
                </p>
                <p>
                  The total amount of disposal proceeds may include:
                </p>
                <ul>
                  <li>cash payable now or in the future </li>
                  <li>the value of any asset received in exchange for the asset disposed of </li>
                  <li>the right to receive future payments </li>
                </ul>
                <p>
                  Any amount received in cash or which can be turned into cash should be included
                  in the disposal proceeds. But you should not include anything that is partnership
                  income, with the exception of any premium received for the grant of a sublease out
                  of a short lease.
                </p>
                <p>
                  Include any amount received in cash or which can be turned into cash in the
                  disposal proceeds. Do not include anything that is partnership income, with the
                  exception of any premium received for the grant of a sublease out of a short
                  lease.
                </p>
                <table id="AutoNumber5" border="0" bordercolor="#111111" cellpadding="10" cellspacing="0"
                       style="border-collapse: collapse" width="60%">
                  <tr>
                    <td align="left" bgcolor="#DEE2EE" width="100%">
                      <strong>Example 2</strong>
                      <br>
                      The partnership exchanges a piece of land worth £30,000 for a painting worth
                      £33,000. The disposal proceeds of the land are £33,000, the value of the
                      consideration received.
                    </td>
                  </tr>
                </table>
                <p>
                  The distinction between future payments and the right to receive future payments
                  is important when the partnership does not receive all of the money it is
                  entitled to straightaway.
                </p>
                <p>
                  If the amount to which the partnership is entitled is known or can be
                  calculated, bring in the full amount. No allowance can be made for the
                  possibility that the whole sum may not be paid.
                </p>
                <p>
                  However, if it becomes clear later on that the partnership will never receive
                  some part of the total amount originally due, the calculation of the partners’
                  gains can be adjusted.
                </p>
                <p>
                  If the partnership receives the total amount due in instalments over a period
                  exceeding 18 months, the partners may not have to pay the tax which is due on
                  their gains in one sum. Ask us for details.
                </p>
                <p style="text-align: right">

                </p>
                <table id="AutoNumber6" border="0" bordercolor="#111111" cellpadding="10" cellspacing="0"
                       style="border-collapse: collapse" width="60%">
                  <tr>
                    <td align="justify" bgcolor="#DEE2EE" width="100%">
                      <b>Example 3</b>
                      <br>
                      The partnership sells shares in a company in December {{this.userService.Previous_year}} for £50,000, plus a
                      sum equal to 10% of the profits made by the company in the year ended 30 June
                      {{this.userService.Previous_year}}. Begin the calculation by bringing in the £50,000 and the sum equal to the
                      percentage of profits to which the partnership is entitled. The sum can be
                      calculated by reference to the company accounts for the period ended 30 June
                      {{this.userService.Previous_year}}.
                    </td>
                  </tr>
                </table>
                <p>
                  If some part of the amount to which the partnership is entitled cannot yet be
                  calculated when the disposal is made because some factor is not yet known,
                  include the value of the right to that future sum.
                </p>
                <p>
                  When the partnership receives the amount, it is entitled to, there’ll be a
                  further disposal of that right.
                </p>
                <table id="AutoNumber7" border="0" bordercolor="#111111" cellpadding="10" cellspacing="0"
                       style="border-collapse: collapse" width="60%">
                  <tr>
                    <td align="justify" bgcolor="#DEE2EE" width="100%">
                      <strong>Example 4 </strong>
                      <br>
                      The partnership sells land for £40,000 and will also be entitled to receive 50%
                      of any increase in the value of the land if the purchaser is able to obtain
                      planning permission for a housing development. When the disposal is made you
                      cannot know whether planning permission will be granted or what effect that will
                      have on the value of the land. So you do not know how much the partnership will
                      finally get. The disposal proceeds are £40,000 plus your estimate of the value
                      of the right to receive more money.
                    </td>
                  </tr>
                </table>
                <p>
                  In some circumstances, the amount that the partnership is to receive is taken to
                  be the market value of the asset it has disposed of.
                </p>
                <p>
                  Market value will apply where the disposal is,
                </p>
                <p>
                  for example, either:
                </p>
                <ul>
                  <li>to a connected person</li>
                  <li>
                    otherwise than by a bargain made at arm’s length, for example, a gift (unless
                    ‘no gain/no loss’ applies)
                  </li>
                  <li>wholly or partly for consideration which cannot be valued </li>
                </ul>
                <p>
                  A disposal which is otherwise than by way of a bargain made at arm’s length, is
                  a disposal in which one or other of the parties did not intend to get the best
                  commercial return that could have been obtained. It is a disposal in which one
                  of the parties intends in that transaction to confer a gratuitous benefit on the
                  other.
                </p>
                <table id="AutoNumber8" border="0" bordercolor="#111111" cellpadding="10" cellspacing="0"
                       style="border-collapse: collapse" width="60%">
                  <tr>
                    <td align="left" bgcolor="#DEE2EE" width="100%">
                      <strong>Example 5 </strong>
                      <br>
                      The partnership sells land worth £40,000 to the husband or wife or civil partner
                      of a partner for £25,000. The disposal proceeds are £40,000, the market value.
                    </td>
                  </tr>
                </table>
                <p style="text-align: right">

                </p>
                <p>
                  <strong>What is meant by ‘market value’ and ‘connected persons’? </strong>
                </p>
                <p>
                  <b>Market value</b>
                </p>
                <p>
                  In some cases the price the partnership paid when it obtained the asset or the
                  price it received when it disposed of the asset may be replaced by the market
                  value of the asset in working out the gain or loss. The market value is the
                  price the asset might reasonably have been expected to fetch on the open market
                  at the date of acquisition or disposal.
                </p>
                <p>
                  There’s a special rule that applies if the partnership has disposed of assets by
                  a series of linked disposals to connected persons so that the value of the
                  assets transferred taken separately is less than their combined value. When
                  calculating the gain, the value of each separate asset is replaced by a
                  proportion of the total market value of the assets in the series. Any disposal
                  which took place more than 6 years before the next disposal in the series can be
                  excluded.
                </p>
                <table id="AutoNumber9" border="0" bordercolor="#111111" cellpadding="10" cellspacing="0"
                       style="border-collapse: collapse" width="60%">
                  <tr>
                    <td align="justify" bgcolor="#DEE2EE" width="100%">
                      <strong>Example 6 </strong>
                      <br>
                      The partnership owns the freehold interest in a piece of land. That interest is
                      worth £100,000.&nbsp;
                      <br>
                      <br>
                      Four years later the partnership grants the brother of one of the partners a
                      lease over that land. The lease is worth £20,000.
                      <br>
                      <br>
                      The partnership then sells the brother the freehold reversion for its market
                      value of £60,000. Because the freehold and the lease considered separately are
                      worth less than the unrestricted freehold, the partnership has only realised a
                      total of £80,000 in disposing of land worth £100,000. Because this was a series
                      of transactions, the £20,000 the partnership got from the grant of the lease
                      must be replaced by £25,000 in working out the gain.
                      <br>
                      <br>
                      The £60,000 which the partnership got for the freehold reversion must be
                      replaced by £75,000 in working out any gain on that disposal.
                    </td>
                  </tr>
                </table>
                <p>
                  In the case of shares or securities quoted on the Stock Exchange Daily Official
                  List (SEDOL) the market value is the lower of:
                </p>
                <ul>
                  <li>
                    a figure one quarter up from the lower of the 2 prices in the quotation for the
                    relevant day
                  </li>
                  <li>
                    the figure halfway between the highest and the lowest prices of recorded
                    bargains for that day
                  </li>
                </ul>
                <p>
                  If you make an estimate of market value, give details in box 4.2 on page PA 2 of
                  the ‘Partnership disposal of chargeable assets’ pages. We may need to check if
                  that value is accurate. We approach specialist valuers to value some assets,
                  mainly unlisted shares, land, goodwill and works of art. You will be able to
                  discuss the values you have used with our valuers and if we cannot agree after
                  discussion the partners will be able to appeal to an independent tribunal. If we
                  do not check the computation of a chargeable gain, you must not assume that
                  valuations used in that computation are agreed.
                </p>
                <p style="text-align: right">

                </p>
                <p>
                  <b>Connected person</b>
                </p>
                <p>
                  If an asset is disposed of to a connected person, the price paid or received is
                  replaced by the market value of the asset in working out any gain or loss on the
                  disposal of that asset.
                </p>
                <p>
                  Connected persons are:
                </p>
                <ul>
                  <li>the husband or wife or civil partner of a partner </li>
                  <li>
                    a partner’s brother or sister, or the partner’s spouse’s or civil partner’s
                    brother or sister
                  </li>
                  <li>
                    a partner’s parents, grandparents or other ancestors or the partner’s spouse’s
                    or civil partner’s parents, grandparents or other ancestors
                  </li>
                  <li>
                    a partner’s children and other lineal descendants or the partner’s spouse’s or
                    civil partner’s children and other lineal descendants
                  </li>
                  <li>the husbands or wives or civil partners of any of the relatives quoted above</li>
                  <li>
                    business partners and their relatives, together with the husbands and wives and
                    civil partners of those partners, except for genuine commercial acquisitions or
                    disposals of partnership assets
                  </li>
                  <li>any company controlled either by a partner or any of the persons quoted above</li>
                  <li>
                    the trustees of a settlement of which a partner, or any person connected with
                    the partner, is the settlor
                  </li>
                </ul>
                <p>
                  If a loss is made on the disposal of an asset to a connected person, that loss
                  can only be set against gains the partner makes on disposals to that same
                  connected person.
                </p>
                <p>
                  <strong>Filling in pages PA 1 and PA 2 </strong>
                </p>
                <p>
                  Filling in page PA 1
                </p>
                <p>
                  Fill in page PA 1 to give details about the partnership’s disposals in the year
                  ended 5 April {{this.userService.Present_year}}. The information you provide helps us to decide if we need to
                  ask you any more questions about the Partnership Tax Return.
                  <br>
                  If there is not enough space on the page for all of the disposals, photocopy it
                  before filling it in and continue on the photocopy.
                </p>
                <p>
                  <b>Column 1</b>
                </p>
                <p>
                  Enter in this column details of each chargeable asset the partnership has
                  disposed of. For example, if the partnership disposed of land or buildings, give
                  the address and a description of the property. If the partnership disposed of
                  shares, give the name of the company, the type of shares disposed of and how
                  many were disposed of.
                </p>
                <p>
                  <b>Column 2</b>
                </p>
                <p>
                  This column is only relevant if the asset you quoted in column 1 is shares or
                  securities.
                </p>
                <p>
                  Tick the box in this column unless the shares or securities were quoted shares
                  or other securities as defined below. You will also need to tick the box if you
                  obtained the shares or securities on a share exchange, or other company
                  reorganisation, and the shares or securities you originally held were not shares
                  or other securities throughout the period you held them.
                </p>
                <p style="text-align: right">

                </p>
                <p>
                  <strong>
                    Definition of shares or other securities, for the ‘Partnership disposal
                    of chargeable assets’ pages
                  </strong>
                </p>
                <p>
                  For the purpose of completing the ‘Partnership disposal of chargeable assets’
                  pages only, ‘listed shares or other securities of a company’ means any of the
                  following:
                </p>
                <ul>
                  <li>
                    shares or securities of a company which were either listed on a recognised stock
                    exchange throughout the period the partnership owned them – ignoring any period
                    when the listing or quotation was temporarily suspended
                  </li>
                  <li>
                    shares in a company which was a UK open-ended investment company (OEIC)
                    throughout the period the partnership held them
                  </li>
                  <li>
                    units in a unit trust that was an authorised unit trust throughout the
                    partnership period of ownership
                  </li>
                  <li>
                    shares or units in an offshore fund that has been approved by HMRC as a
                    ‘reporting fund’ (if you disposed of shares or units in an offshore fund that is
                    not a reporting fund you may need to complete form SA802, ‘Partnership Foreign’
                    pages – see the SA802, ‘Partnership Foreign notes’ for box 2.9 for further
                    information, go to <a href="http://www.gov.uk" target="_blank" class="link">
                      www.gov.uk
                    </a>
                    and search for ‘SA802’)
                  </li>
                </ul>
                <p>
                  Any shares or securities not within the ‘listed shares and securities’
                  definition above are to be treated as ‘unlisted’.
                </p>
                <p>
                  A list of recognised stock exchanges is available on our website at
                  <a href="http://www.gov.uk/guidance/" target="_blank" class="link">
                    www.gov.uk/guidance/
                  </a> recognised-stock-exchanges or you can ask us.
                </p>
                <p>
                  <b>Column 3</b>
                </p>
                <p>
                  Enter in this column the total amount of disposal proceeds the partnership has
                  received or will receive from each disposal. Pages PAN 3 to PAN 5 of these notes
                  explain what to include in disposal proceeds.
                </p>
                <a name="4p1"></a>
                <p>
                  <b>Box 4.1</b>
                </p>
                <p>
                  Enter in box 4.1 the total proceeds from all disposals. Make sure that you enter
                  the gross disposal proceeds. Do not deduct incidental disposal proceeds. The
                  partners must make deductions (for their share of the costs) in their personal
                  tax returns.
                </p>
                <p>
                  <b>Column 4</b>
                </p>
                <p>
                  Use this space to provide other information. For example:
                </p>
                <ul>
                  <li>why you use valuations </li>
                  <li>whether the value you transfer is chargeable under section 29 or 30 TCGA 1992 </li>
                  <li>whether there’s been a part disposal</li>
                  <li>
                    • whether there’s been an exchange of shares or securities in a company
                    reconstruction or takeover
                  </li>
                </ul>
                <p>
                  Filling in page PA 2
                </p>
                <p>
                  You may use box 4.2 on page PA 2 to give any more information that you think
                  will help us to understand the entries on page PA 1.
                </p>
                <p>
                  <strong>Transfer of information to the ‘Partnership Statement (full)’</strong>
                </p>
                <p>
                  Transfer the total proceeds from all disposals of chargeable assets to box 30 in
                  the ‘Partnership Statement (full)’.
                </p>
                <p>
                  You may need to allocate shares of the proceeds from each disposal to the
                  appropriate partners before you can complete the rest of the Partnership
                  Statement. Even if you do not need to do this to complete the Partnership
                  Statement, the partners will need this information to calculate the chargeable
                  gains arising to them, if any, on the disposals of their interests in
                  partnership assets. See the notes on pages PTRG 23 to PTRG 29 of the Partnership
                  Tax Return Guide for more details, including example 11 on page PTRG 29.
                </p>
                <p>
                  &nbsp;
                </p>
                <p style="text-align: right">

                </p>
                <hr>
                <table border="0" cellpadding="5" cellspacing="0" style="text-align: justify; border: 1px #000 solid;"
                       width="90%">
                  <tr>
                    <td>
                      These notes are for guidance only and reflect the position at the time of
                      writing. They do not affect the right of appeal.
                    </td>
                  </tr>
                </table>
                <p style="text-align: right; margin: 0px; padding: 0px;">

                </p>
              </div>
            </div>
          </div>
          <!--SA804 starts here.....-->
          <div id="SA804" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
            <div id="mainSection">
              <div id="mainBody">
                <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse" bordercolor="#111111" width="98%" id="AutoNumber1">
                  <tr>
                    <td width="93%" align="right">
                      <p style="text-align:right;">
                        <span style="font-size: 25px;" class="headingSA">
                          Partnership savings, investments
                          and other income
                        </span><br>
                        <span style="font-size: 15px;" class="headingSA">
                          Tax year 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}} ({{this.userService.Currenttax_shortyear}} )
                        </span>
                      </p>
                    </td>
                    <td width="7%">&nbsp;</td>
                  </tr>
                  <tr>
                    <td width="93%" align="right" bgcolor="#00ABBC">
                      <font color="#FFFFFF" face="Arial" size="3"></font>
                    </td>
                    <td bgcolor="#ABA000" width="7%">&nbsp;</td>
                  </tr>
                </table>
                <br>
                <table border="0" cellpadding="3" cellspacing="0" width="98%">
                  <tr>
                    <td width="25%" valign="top"><a name="top"></a><a class="link" href="help#7p1-25">Boxes 7.1, 7.2, 7.24 and 7.25</a></td>
                    <td width="25%" valign="top"><a class="link" href="help#7p3">Box 7.3</a></td>
                    <td width="25%" valign="top"><a class="link" href="help#7p4">Box 7.4</a></td>
                    <td width="25%" valign="top"><a class="link" href="help#7p5">Box 7.5</a></td>
                  </tr>
                  <tr>
                    <td width="25%" valign="top"><a class="link" href="help#7p7-9">Boxes 7.7 to 7.9</a></td>
                    <td width="25%" valign="top"><a class="link" href="help#7p14-16">Boxes 7.14 to 7.16</a></td>
                    <td width="25%" valign="top"><a class="link" href="help#7p19">Box 7.19</a></td>
                    <td width="25%" valign="top"><a class="link" href="help#7p20">Box 7.20</a></td>
                  </tr>
                  <tr>
                    <td width="25%" valign="top"><a class="link" href="help#7p21">Box 7.21</a></td>
                    <td width="25%" valign="top"><a class="link" href="help#7p22">Box 7.22</a></td>
                    <td width="25%" valign="top"><a class="link" href="help#7p24-25">Boxes 7.24 and 7.25</a></td>
                    <td width="25%" valign="top"><a class="link" href="help#7p26">Box 7.26</a></td>
                  </tr>
                  <tr>
                    <td width="25%" valign="top"><a class="link" href="#7p42-43">Boxes 7.42 and 7.43</a></td>
                    <td width="25%" valign="top"><a class="link" href="help#7p27">Box 7.27</a></td>
                    <td width="25%" valign="top"><a class="link" href="help#7p28-30">Boxes 7.28 to 7.30</a></td>
                  </tr>
                </table>
                <hr>
                <p><b>Filling in the 'Partnership Savings' Pages</b></p>
                <p style="padding:5px 5px 0px 5px;"> Gather together the information you might need: </p>
                <ul>
                  <li>dividend vouchers </li>
                  <li>
                    printouts of details of dividend or interest distributions provided
                    electronically by UK authorised unit trusts, open-ended investment companies or
                    investment trusts, as an alternative to a paper tax voucher
                  </li>
                  <li>interest statements or tax deduction certificates </li>
                  <li>
                    trust vouchers (if the partnership is entitled to income of a trust, the trustee
                    should provide a voucher identifying the various sources of income)
                  </li>
                </ul>

                <p>Use the following notes to help you fill in the boxes. They ask for totals. </p>
                <p>
                  Check the following lists to see whether the partnership&#39;s income from savings
                  and investments should be included in the Partnership Tax Return. Company
                  partners should read the note &#39;Loan relationships and so on&#39; on page PTRG 5 of
                  the Partnership Tax Return Guide.
                </p>
                <p><strong>What to exclude from the Partnership Tax Return</strong></p>
                <ul>
                  <li>
                    Interest or dividends or bonuses from tax exempt investments (for example,
                    National Savings and Investments Savings Certificates), unless something has
                    happened to make the income taxable.
                  </li>
                  <li>Premium Bond, National Lottery and gambling prize winnings.</li>
                  <li>
                    Interest awarded by a UK court as part of an award of damages for personal
                    injury or death.
                  </li>
                  <li>
                    Receipts under a permanent health insurance policy. However, receipts which are
                    treated as trading receipts, because they help meet the cost to the partnership
                    of providing sick pay for employees, should be included on these pages.
                  </li>
                  <li>
                    Gains on UK life assurance policies, life annuities or capital redemption
                    policies, even if the policy or contract was effected by, or is in the
                    partnership’s name. There are special rules for dividing a gain between
                    partners. Each partner’s share of the gain should be shown on their personal tax
                    return. If you need more help, ask us or your tax adviser
                  </li>
                  <li>
                    Accrued income on transfer of securities (but see the notes on page PSN 8 of
                    these notes)
                  </li>
                </ul>
                <p><b>What to include in the Partnership Tax Return</b></p>
                <p>Interest etc including interest in kind received from:</p>
                <ul>
                  <li>
                    banks and building societies (including internet accounts) – current and deposit
                    accounts (including those paying alternative finance receipts)
                  <li>UK authorised unit trusts or open-ended investment companies
                  <li>investment trusts
                  <li>
                    National Savings and Investments products <br>– where
                    tax is taken off before you receive it (such as fixed rate saving bonds,
                    Guaranteed Income or Growth Bonds) <br>
                    – where no tax is deducted (such as Investment Accounts) but exclude accumulated
                    interest on Savings Certificates (including index-linked)
                </ul>
                <p>
                  Other savings income, including taxable interest received on compensation
                  payments, for example, payment protection insurance (PPI), disguised interest,
                  annuities and deeply discounted securities.
                <p>
                  Dividends from UK companies, UK authorised unit trusts, open-ended investment
                  companies and investment trusts, other distributions and UK stock dividends.
                </p>
                <p>
                  Property income distributions (PIDs) from UK Real Estate Investment Trusts
                  (REITs) and Property Authorised Investment Funds (PAIFs).
                </p>
                <p>
                  If the partnership has received a bonus sum following the merger of 2 or more
                  building societies, the bonus sum is subject to Income Tax and should be entered
                  in boxes 7.7 to 7.9.
                </p>
                <p>
                  If, however, the partnership has received a bonus sum following the conversion of
                  a building society to a company or following the takeover of a building society
                  by a company, then details of that sum should be given in the &#39;Partnership
                  disposal of chargeable assets&#39; pages.
                </p>
                <p>
                  If the partnership’s only savings income for the period was interest or
                  alternative finance receipts paid with no tax deducted by banks or building
                  societies, fill in box 7.9A on page 8 of the Partnership Tax Return using the
                  guidance in the Partnership Tax Return Guide. Otherwise, you will need to fill
                  in the full &#39;Partnership Savings&#39; pages. These notes will help.
                </p>

                <p><b>Return period</b></p>
                <p>
                  <b>
                    Taxed income (and the corresponding tax
                    deducted, tax credits or notional tax)
                  </b>
                </p>
                <p>All taxed income should be returned for the period 6 April {{this.userService.Previous_year}} to 5 {{this.userService.Present_year}}. </p>
                <p>
                  If accounts are made up for any other period, you should apportion figures in the
                  sets of accounts that between them cover the period 6 April {{this.userService.Previous_year}} to 5 {{this.userService.Present_year}}.
                </p>
                <p><b>Untaxed income</b></p>
                <p>
                  You should return all untaxed income for the return period appropriate for your
                  partnership. Determine this period using the rules below.
                </p>
                <a name="7p1"></a>
                <a name="7p2"></a>
                <a name="7p24"></a>
                <a name="7p25"></a>
                <a name="7p1-25"></a>
                <p id="7p1-25"><b>Boxes 7.1, 7.2, 7.24 and 7.25</b></p>
                <p>
                  For all partnerships (except investment partnerships – see below) complete the
                  untaxed income sections of the ‘Partnership Savings’ pages to show details of
                  the partnership’s untaxed income for its accounting periods ended in the tax
                  year {{this.userService.Previous_year}} to {{this.userService.Present_year}}. In these circumstances, show the date on which the accounting
                  period starts in boxes 7.1 and 7.24 and this should be the same as the date
                  you’ve entered in box 3.4 on page 2 of the Partnership Tax Return.
                </p>
                <p>
                  Show the date on which the accounting period ends in boxes 7.2 and 7.25 and this
                  should be the same as the date that you’ve entered in box 3.5 on page 2 of your
                  Partnership Tax Return.
                </p>
                <p>
                  Where there’s more than one such accounting period you may have to complete more
                  than one set of ‘Partnership Savings’ pages. (Read the notes on page PTRG 9 of
                  the Partnership Tax Return Guide.)
                </p>
                <p>
                  Where there is no such accounting period, you need only complete the ‘taxed
                  income’ sections of the ‘Partnership Savings’ pages. (Read the notes on page
                  PTRG 9 of the Partnership Tax Return Guide.)
                </p>
                <p><b>Investment partnerships</b></p>
                <p>
                  Where, exceptionally, a partnership does not carry on a trade or profession, the
                  untaxed income sections of the ‘Partnership Savings’ pages must, be completed
                  for the profits in the period 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}.
                </p>
                <p>Enter &#39;06/04/{{this.userService.Previous_year}}&#39; in boxes 7.1 and 7.24 and &#39;05/04/{{this.userService.Present_year}}&#39; in boxes 7.2 and 7.25. </p>
                <p>
                  If accounts are made up for any other period, you should apportion figures in the
                  sets of accounts, which between them cover the period 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}.
                </p>

                <hr>
                <p><b>Interest and alternative finance receipts</b></p>
                <p>
                  The boxes ask for totals. If the partnership has more than one source of interest
                  and alternative finance receipts, add together, for example, interest paid (with
                  tax deducted) by the partnership&#39;s bank or building society and put total
                  figures in boxes 7.7 to 7.9. Keep details of the separate accounts in case we
                  ask for these later.
                </p>
                <p><b>Interest and alternative finance receipts with no UK tax deducted</b></p>
                <p>
                  If interest or alternative finance receipts with no tax deducted are the only
                  income from savings or investments, you can fill in box 7.9A on page 8 of the
                  Partnership Tax Return. Otherwise you will need to complete these pages.
                </p>
                <a name="7p3"></a>
                <p id="7p3">
                  <strong>
                    Box 7.3 Interest and alternative finance receipts from UK banks and
                    building societies
                  </strong>
                </p>
                <p>
                  The partnership’s bank or building society statement will show you the interest
                  (including interest in kind) or alternative finance receipts the partnership
                  received without tax being taken off (gross). Add up all the amounts received
                  during the partnership’s return period. Enter the total in box 7.3.
                </p>
                <p>
                  Include in box 7.3 any interest distributions from UK authorised unit trusts and
                  UK open-ended investment companies and investment trusts. If the units or shares
                  are accumulation units or shares (where the interest distribution is
                  automatically reinvested in the unit trust or open-ended investment company) you
                  must still enter the total interest distribution.
                </p>
                <p>
                  Put annual payments received from UK unauthorised unit trusts in boxes 7.28 to
                  7.30.
                </p>
                <p>Do not include National Savings and Investments interest, put this inbox 7.4</p>

                <hr>
                <a name="7p4"></a>
                <p id="7p4"><strong>Box 7.4 National Savings and Investments </strong></p>
                <p>Enter in box 7.4 the interest received from the following:</p>
                <ul>
                  <li>Direct Saver
                  <li>Investment Account
                  <li>Income Bonds.
                  <li>Capital Bonds
                </ul>
                <p>For National Savings and Investments with tax deducted, use boxes 7.7 to 7.9. </p>

                <hr>
                <p><strong>Box 7.5 Other income from UK savings and investments </strong></p>
                <p>
                  If no tax has been taken off, include in box 7.5 any other interest the
                  partnership received, for example:
                </p>
                <ul>
                  <li>
                    on government stocks (gilts), including those bought through the National
                    Savings and Investments Stock Register
                  <li>on other loan stocks
                  <li>on loans to an individual or organisation
                  <li>from credit unions and friendly societies
                  <li>disguised interest
                </ul>
                <p>If tax has been taken off, fill in boxes 7.14 to 7.16 instead. Disguised </p>

                <hr>
                <p><b>Disguised interest</b></p>
                <p>
                  If you receive an &#39;interest-like&#39; amount from financial products or arrangements,
                  it will be taxable under new rules for &#39;disguised interest&#39; if it is not taxed
                  under other rules. An amount will be interest-like if it’s calculated like
                  interest and it’s practically certain from the outset that you’ll receive it.
                  The new rules apply to products you invest in and arrangements you entered into
                  from 6 April 2013 onwards.
                </p>
                <p><b>Deeply discounted securities</b></p>
                <p>
                  &#39;Deeply discounted securities&#39; have replaced those types of securities previously
                  termed deep discount bonds and deep gain securities. Broadly, these are
                  securities where the investment&#39;s return is mainly made up of a discount or
                  premium payable on redemption of the bond, rather than by interest payable over
                  the life of the bond. The discount or premium is the difference between the
                  price at which the bond was issued and the amount payable on redemption.
                </p>
                The discount or premium must be capable of being more than:
                <ul>
                  <li>15% of the redemption price, or if smaller
                  <li>
                    0.5% of the redemption price for
                    each year of the bond&#39;s life (for example, in the case of a 10-year bond, any
                    discount of 5% or more would mean that it’s a relevant discounted bond)
                </ul>
                <p>
                  A security with an uncertain yield (for example, linked to the Retail Price
                  Index) will normally be a deeply discounted security. A security fully linked to
                  the value of assets which would be chargeable assets under the Capital Gains Tax
                  rules (for example, a security whose yield is fully linked to the FTSE index,
                  and gives no guaranteed minimum return on your investment), will not normally be
                  a deeply discounted security.
                </p>
                <p>
                  If you have a deeply discounted security, you’ll generally be taxable only when
                  you dispose of the security or redeem it. At that time you’ll be taxable on the
                  difference between the amount you paid for the security and the amount you
                  received when you redeemed or sold it. Income Tax is not deducted from the
                  payment, so the gross amount of income should be entered in box 7.5.
                </p>
                <p><b>Losses on deeply discounted securities</b></p>
                <p>
                  You cannot claim any relief for a loss on redemption or disposal of a deeply
                  discounted security unless the firm held the security since 26 March 2003 and it
                  was then, or had been, listed on a recognised stock exchange. If that applies,
                  enter the qualifying loss in box 7.27.
                </p>
                <p><b>Transfer of the right to income</b></p>
                <p>
                  If the partnership sold or transferred the right to receive dividends before 22
                  April 2009 but did not dispose of the underlying securities, the income from
                  those securities is still treated as partnership income. Enter the amount of
                  income paid out (even if the partnership has not received it) in boxes 7.19 to
                  7.20, as appropriate.
                </p>
                <p>
                  If the partnership sells or transfers the right to
                  income from 22 April 2009 onwards without disposing of the underlying asset from
                  which the income arises, the consideration that’s receivable in return for the
                  transfer is treated as partnership income. Include it in the box in which the
                  income would have been entered had it not been transferred. If the market value
                  of the right is substantially greater than the consideration that’s receivable,
                  then the market value of the right (rather than the consideration) is treated as
                  partnership income. If this is the case, include the market value of the right
                  in the box in which the income would have been entered had it not been
                  transferred.
                </p>
                <p><b>Transfer of information to the Partnership Statement</b></p>
                <p>
                  The partners need to distinguish between certain classes of savings, investments
                  and other income (and, where appropriate, the corresponding tax) when
                  calculating the tax due on their shares of such income. The same distinctions
                  are made in the Partnership Tax Return.
                </p>
                <p>
                  Transfer the amount of the untaxed income in box 7.6 to box 13 in the
                  &#39;Partnership Statement&#39;. If you want to allocate this figure between the
                  partners at the same time, read pages PTRG 26 to PTRG 29 of the Partnership Tax
                  Return Guide before you do so. Otherwise, fill in the rest of the &#39;Partnership
                  Savings&#39; pages.
                </p>

                <p><strong>Interest and alternative finance receipts with UK tax deducted </strong></p>
                <a name="7p7-9"></a>
                <a name="7p7"></a><a name="7p8"></a><a name="7p9"></a>
                <p>
                  <strong>
                    Boxes 7.7 to 7.9 Taxed UK interest and alternative finance receipts
                  </strong>
                </p>
                <p>
                  The partnership’s bank or building society statement or passbook will usually
                  show you the amount after tax is deducted (sometimes described as ‘net interest’
                  or ‘net alternative finance receipts’), the amount of tax deducted and the
                  amount before tax was deducted (‘gross interest’ or ‘gross alternative finance
                  receipts’).
                </p>
                <p>
                  (If you do not have all 3 figures, the Working Sheet on page PSN 5 of these notes
                  shows you how to work them out.)
                </p>
                <p>
                  Add up the amounts for the return period for all the partnership’s accounts.
                  Enter the totals in boxes 7.7, 7.8 and 7.9. If you do not have the information,
                  ask the bank or building society to provide the partnership with a tax deduction
                  certificate.
                </p>
                <p>Include National Savings and Investments with tax deducted in boxes 7.7 to 7.9.</p>
                <p>&nbsp;</p>
                <hr>
                <a name="7p10"></a>
                <a name="7p11"></a>
                <a name="7p12"></a>
                <a name="7p10-12"></a>

                <hr>
                <a name="7p14"></a>
                <a name="7p15"></a>
                <a name="7p16"></a>
                <a name="7p14-16"></a>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    Boxes 7.14 to 7.16 Other taxed income from UK savings and investments
                  </strong>
                </p>
                <p>
                  Enter the totals of the following items in boxes 7.14 to 7.16. Keep details of
                  income included in the totals in case we ask for more information later.
                </p>
                <p><strong>Interest not included in boxes 7.7 to 7.9 </strong></p>
                <p>
                  Include in boxes 7.14 to 7.16 interest the partnership received with tax deducted
                  but which did not go in boxes 7.7 to 7.12, for example:
                </p>
                <ul>
                  <li>
                    on government stocks (gilts), including those bought through the National
                    Savings and Investments Stock Register
                  </li>
                  <li>on other loan stocks </li>
                  <li>on loans to an individual or organisation </li>
                  <li>from credit unions and friendly societies </li>
                  <li>interest in kind </li>
                </ul>
                <p>
                  <strong>
                    If no tax has been deducted, enter the interest received in box 7.5.
                  </strong>
                </p>
                <p>
                  <strong>Interest in kind </strong>
                </p>
                <p>
                  Interest includes interest received in non-cash form. You will usually receive
                  any interest in kind, specifically non-cash interest, after tax has been taken
                  off. The company or person taking off the tax must provide you with a statement
                  showing the value of the interest before tax is taken off, the amount of tax
                  taken off, the net value of the interest paid and the date of the payment.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>
                <p>
                  <strong>Transfer of information to the Partnership Statement (full) </strong>
                </p>
                <p>
                  Transfer the amount of tax deducted on the taxed income in box 7.17 to box 25 in
                  the &#39;Partnership Statement&#39;. If you want to allocate this figure between the
                  partners at the same time, read pages PTRG 26 to PTRG 29 of the Partnership Tax
                  Return Guide before you do so. Otherwise, finish filling in the rest of the
                  &#39;Partnership Savings&#39; pages.
                </p>

                <hr>
                <p><b>Dividends</b></p>
                <a name="7p19"></a>
                <p ALIGN="LEFT" id="7p19"><strong>Box 7.19 <b>Dividends from UK companies</b></strong></p>
                <p>The partnership's dividend voucher shows the amount of the dividend.</p>
                <p>Do not include stock dividends here. Include these in box 7.21. </p>
                <p><b>Other distributions</b></p>
                <p>
                  A company makes a distribution when it passes value to a shareholder, for
                  example:
                </p>
                <ul>
                  <li>by selling an asset to a shareholder at less than market value
                  <li>
                    by paying
                    interest at more than a commercial rate on a loan
                </ul>
                <p>
                  (Put bonus issues of securities and redeemable shares and loans written off in
                  box 7.22).
                </p>
                <p>
                  Enter the amount in box 7.19 and give details in the &#39;Additional information&#39;
                  box, box 3.116 on page 3 of the Partnership Tax Return, explaining the
                  circumstances in which the distribution arose.
                </p>

                <hr>
                <a name="7p20"></a>
                <p id="7p20">
                  <strong>
                   Box 7.20&nbsp; <b>
                      Dividend distributions from
                      UK authorised unit trusts and open-ended
                      investment companies
                    </b>
                  </strong>
                </p>
                <p>
                  The dividend voucher, or details provided electronically, shows the amount of the
                  dividend.
                </p>
                <p>
                  If the partnership has accumulation units or shares, the dividend is
                  automatically reinvested in the unit trust or open-ended investment company. You
                  must still enter the amount of the dividend distribution.
                </p>
                <p>
                  If you do not have a dividend voucher, or details provided electronically, ask
                  the partnership&#39;s unit trust, open-ended investment company or investment trust
                  manager for a tax voucher.
                </p>
                <p>
                  Do not enter here any amount shown on the tax voucher as &#39;equalisation&#39;. This
                  amount, if shown, is a repayment of capital paid and is not subject to tax. In
                  calculating their capital gains, each partner should deduct their share of the
                  amount of the equalisation from the cost of the units or shares purchased during
                  the year.
                </p>
                <hr>
                <a name="7p21"></a>
                <p id="7p21"><strong>Box 7.21 Stock dividends from UK companies </strong></p>
                <p>
                  Ask the company for a statement if you have not got one. Enter the information
                  from the statement in box 7.21. If you have doubts about what to include, ask us
                  or your tax adviser for help.
                </p>
                <p>
                  If the partnership took up an offer of shares in place of a cash dividend, this
                  is a &#39;stock&#39; dividend. You have to enter stock dividends separately.
                </p>
                <p>
                  The dividend statement should have &#39;the appropriate amount in cash&#39; on it – this
                  is the amount you should enter in box 7.21.
                </p>
                <hr>
                <a name="7p22"></a>
                <p id="7p22">
                  <strong>
                    Box 7.22 Bonus issues of securities and redeemable shares and loans
                    written off
                  </strong>
                </p>
                <p>
                  If the partnership receives a bonus issue of securities or redeemable shares, the
                  amount of the distribution is:
                </p>
                <ul>
                  <li>for &#39;redeemable share&#39;, their nominal value plus any premium payable
                  <li>
                    for
                    &#39;securities&#39;, the amount of the principal secured plus any premium payable minus
                    any new consideration given for that issue
                </ul>
                <p>Enter the amount of the distribution in box 7.22. </p>
                <p>
                  A loan or advance made by a company wholly or partly released or written off may
                  be taxable. If so, the amount released or written off is treated as a net amount
                  of income.
                </p>
                <p>Include in box 7.22 the amount of the loan released or written off.</p>
                <p><strong>Transfer of information to the Partnership Statement (full) </strong></p>
                <p>
                  Transfer the amount in box 7.23 to box 22A in the &#39;Partnership Statement&#39;. Read
                  pages PTRG 26 to PTRG 29 of the Partnership Tax Return Guide if you want to
                  allocate these figures between the partners at the same time. Otherwise, finish
                  filling in the rest of the &#39;Partnership Savings&#39; pages.
                </p>

                <hr>
                <p><strong>Other partnership income </strong></p>
                <p>
                  If the partnership received income which is taxable and you have not included it
                  elsewhere in this form or elsewhere in the Partnership Tax Return, fill in boxes
                  7.26 to 7.30 using the notes starting below. If you’re in doubt about what to
                  include, ask us or your tax adviser for help.
                </p>
                <p>
                  There are many types of transaction which produce such taxable income. Examples
                  include:
                </p>
                <ul>
                  <li>
                    all casual earnings not declared elsewhere on the Partnership Tax Return
                    including &#39;one-off&#39; freelance income
                  <li>
                    payments under covenants entered into for genuine commercial reasons in
                    connection with the payer&#39;s trade or profession
                  <li>profits from isolated literary or artistic activities
                  <li>rental income from leasing equipment owned by the partnership
                  <li>underwriting or sub-underwriting commissions
                  <li>
                    income received after winding up the partnership&#39;s business – for example,
                    payments for the sale of copyright or bad debts, or recovered post-cessation
                    receipts
                  <li>sale of patent rights if the partnership received a capital sum
                  <li>annual payments from UK unauthorised unit trusts
                </ul>
                <p><b>Expenses</b></p>
                <p>
                  The amount of taxable income is the gross income the partnership is entitled to
                  (whether or not it received it) in the return period minus allowable expenses
                  incurred in that period. <br />
                  Allowable expenses are those, which:
                </p>
                <ul>
                  <li>had to be spent solely to earn the receipts
                  <li>
                    were not spent for private or
                    personal reasons
                  <li>
                    were not spent to buy something that the partnership intends to keep for a while
                    (such as a computer) – but it may be able to claim capital allowances for this
                    expenditure (ask us or your tax adviser for help)
                </ul>
                <p>You cannot set expenses against annual payments. </p>
                <p><strong>How to fill in boxes 7.26 to 7.30 </strong></p>
                <p>
                  Enter the aggregate figures for all the partnership&#39;s other income in the boxes.
                  Keep a record of the separate items of income, and any relevant expenses
                  relating to each item, in case we ask for details later.
                </p>

                <hr>
                <p><b>Other income received without UK tax deducted</b></p>
                <a name="7p24"></a>
                <a name="7p25"></a>
                <a name="7p24-25"></a>
                <p id="7p24-25"><b>Boxes 7.24 and 7.25</b></p>
                <p>
                  Enter the details of the return period (read the note on page PSN 2 of these
                  notes) to which the information in boxes 7.26 to 7.30 relates.
                </p>

                <hr>
                <a name="7p26"></a>
                <p id="7p26"><strong>&nbsp;Box 7.26&nbsp; <b>Profit</b></strong></p>
                <p>&nbsp;</p>
                <p>
                  Enter in box 7.26 the gross amount of income minus expenses for the return
                  period. If the partnership made an overall loss, enter &#39;0&#39; in box 7.26 and the
                  amount of the loss in box 7.27.
                </p>
                <p><b>Post-cessation receipts and similar business receipts</b></p>
                <p>
                  If the basis on which the partnership&#39;s trade or professional profits are taxable
                  has changed (because, for example, there’s been a change from the accounts basis
                  to the earnings basis) include in box 7.26 any receipts that, as a result of
                  that change, will not be taken into account during the lifetime of the business.
                </p>
                <p>
                  All other post-cessation receipts received by the partnership after its business
                  has ceased should be included in the partners&#39; personal tax returns – read the
                  notes for box 17 on page TRG 8 in the personal Tax Return Guide.
                </p>

                <hr>
                <a name="7p27"></a>
                <p id="7p27"><strong>Box 7.27 <b>Loss</b></strong></p>
                <p>&nbsp;</p>
                <p>
                  If the partnership&#39;s allowable expenses are more than its income, it has suffered
                  a loss. Enter in box 7.27 the amount of any overall loss for the year.
                </p>

                <hr>
                <p><b>Other income received with UK tax deducted</b></p>
                <a name="7p28"></a>
                <a name="7p29"></a>
                <a name="7p30"></a>
                <a name="7p28-30"></a>
                <p id="7p28-30"><b>Boxes 7.28 to 7.30</b></p>
                <p>
                  Enter in box 7.28 the amount of the other income received after tax has been
                  taken off (net), in box 7.29 the amount of tax taken off and in box 7.30 the
                  amount before tax has been taken off (gross).
                </p>
                <p>
                  Give details of annual payments received in the year, including annual payments
                  from unauthorised unit trusts.
                </p>
                <p>
                  Property income distributions (PIDs) from UK Real Estate Investment Trusts
                  (REITs) and Property Authorised Investment Funds (PAIFs) should also be entered
                  in these boxes (tax should have been deducted from PIDs).
                </p>
                <p><b>Accrued income</b></p>
                <p>
                  Accrued income securities include all interest bearing securities, including
                  permanent interest bearing shares in a building society, government loan stock,
                  and company loan stock, but not shares in a company or National Savings and
                  Investments Certificates. Although they can own and buy and sell such
                  securities, partnerships are not themselves within the accrued income scheme.
                </p>
                Provide each partner with full details of accrued income securities bought and
                sold, including whether they were bought or sold with accrued interest
                (cum-dividend) or without the right to the next interest payment (ex-dividend).
                <p>Each partner will need to know their share of: </p>
                <ul>
                  <li>the nominal holdings bought or sold
                  <li>
                    any accrued income scheme charge
                    any accrued income relief (rebate interest)
                  <li>the the actual interest received
                </ul>
                <p>
                  Any charge arising on a partner&#39;s share of partnership accrued income securities
                  bought and sold will be reflected on their partnership pages, and any relief
                  will reduce the share of the interest returned.
                </p>
                <p>&nbsp;</p>
                <p><b>Transfer of information to the Partnership Statement (Full)</b></p>
                <p>
                  Transfer the amount of any untaxed profit (or loss) in box 7.26 (or box 7.27) to
                  box 15 (or box 16) in the &#39;Partnership Statement&#39;.
                </p>
                <p>
                  Transfer the amount of any taxed income in box 7.30 to box 23 and the
                  corresponding tax credit in box 7.29 to box 25, in the &#39;Partnership Statement&#39;.
                </p>
                <p>
                  Read pages PTRG 26 to PTRG 29 of the Partnership Tax Return Guide if you want to
                  allocate these figures between the partners at the same time.
                </p>

                <hr>
                <table border="0" cellpadding="5" cellspacing="0" style="text-align: justify; border: 1px #000 solid;" width="90%">
                  <tr>
                    <td>
                      These notes are for guidance only and reflect the position at the time of
                      writing. They do not affect the right of appeal.
                    </td>
                  </tr>
                </table>
                <p style="text-align: right; margin: 0px; padding: 0px;">

                </p>
              </div>
            </div>
          </div>
          <!--SA800PS START.....-->
          <div id="SA800PS" class=" tab-pane ">
            <div id="header">
              <table cellpadding="0" cellspacing="0" height="66px;" width="100%">
                <tr>
                  <td width="50%" height="66px;" align="right" background="../../assets/screens/bg_image.jpg" style="background-repeat:no-repeat;"></td>
                  <td width="50%" align="right" valign="bottom" background="../../assets/screens/bg1.jpg" style="background-repeat:repeat-x;">
                    <!--<b style="font-size: 15px; color: #412121; font-weight: bold;">Andica Self Assessment Software</b>-->
                  </td>
                </tr>
              </table>
            </div>
               <div id="mainSection">
                 <div id="mainBody">
                   <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse" bordercolor="#111111" width="98%" id="AutoNumber1">
                     <tr>
                       <td width="93%" align="right">
                         <p style="text-align:right;">
                           <span style="font-size: 25px;" class="headingSA">
                             Partnership Statement
                           </span><br>
                           <span style="font-size: 15px;" class="headingSA">
                             Tax year 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}} ({{this.userService.Currenttax_shortyear}} )
                           </span>
                         </p>
                       </td>
                       <td width="7%">&nbsp;</td>
                     </tr>
                     <tr>
                       <td width="93%" align="right" bgcolor="#00ABBC">
                         <font color="#FFFFFF" face="Arial" size="3"></font>
                       </td>
                       <td bgcolor="#ABA000" width="7%">&nbsp;</td>
                     </tr>
                   </table>

                   <p><b>Filling in the 'Partnership Savings' Pages</b></p>
                   <p>
                     If the partnership’s only other income is interest or alternative finance
                     receipts paid by a bank or building society, fill in box 7.9A on page 8.
                     Otherwise go to <a href="http://www.gov.uk" target="_blank" class="link"> www.gov.uk </a>and search for ‘SA804’ for the full Partnership
                     Savings pages and fill in those instead.
                   </p>
                   <p><b>Interest and alternative finance receipts paid with tax taken off</b></p>
                   <p>
                     The return period for all taxed income is the period {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}. (Read page
                     PTRG 5 of this guide if you’re a ‘CT Partnership’.)
                   </p>
                   <p><b>Box7.9A</b></p>

                   <p>
                     Copy the figure in box 7.9A to box 13 of the
                     Partnership Statement. on page 6.
                   </p>

                   <p><b><a name="Top"></a>Filling in the Partnership Statement</b></p>
                   <p>
                     The Partnership Tax Return includes a Partnership
                     Statement(on page6 and 7) for summarising the
                     profits, losses or income allocated to the partners.
                   </p>
                   <p>There are 2 types of Partnership Statement:</p>
                   <ul>
                     <li>
                       a 'full' version covering all the possible types of
                       partnership income
                     </li>
                     <li>
                       a 'short' version for partnerships that have only
                       trading or professional income and interest or
                       alternative finance receipts with tax taken off
                       from banks, building societies or other
                       deposit takers
                     </li>
                   </ul>
                   <p>
                     The Partnership Tax Return that HMRC have sent to
                     you includes the 'short' Partnership Statement.
                     Ask the Self Assessment Orderline if you need
                     the 'full' version SA800(PS) or go to <a href="http://www.gov.uk/taxreturnforms" target="_blank" class="link">www.gov.uk/taxreturnforms</a>
                   </p>
                   <p>
                     You may need to fill in more than 1 Partnership
                     Statement if, for example, the partnership has
                     made up more than one account ending in {{this.userService.Previous_year}} to {{this.userService.Present_year}}

                     or if the partners included companies or
                     non-residents – read the notes for Question 5 PTRG 6 of this guide.
                   </p>
                   <p>
                     The 'short' Statement caters for up to 3 partners.
                     If there were more than 3 partners, either
                     photocopy of the Partnership Tax Return
                     before you fill it in and use the photocopies, go to <a href="http://www.gov.uk/taxreturnforms" target="_blank" class="link">www.gov.uk/taxreturnforms </a> or
                     ask the Self Assessment Orderline for more copies. Attach the pages to the Partnership Tax Return when you send it back. Work your way
                     through the Partnership Statement box by box.
                   </p>
                   <p>
                     It is set
                     out to help you provide a summary of the:
                   </p>
                   <ul>
                     <li>
                       partnership profit (and related items) for
                       {{this.userService.Previous_year}} to {{this.userService.Present_year}}

                     </li>
                     <li>
                       shares of profit (and related items) allocated
                       to the partners who were members of the
                       partnership during the return period or periods
                       covered by the Partnership Tax Return.
                     </li>
                   </ul>
                   <p>
                     The return periods for different types of income
                     are explained on page PTRG 4 of this guide.
                   </p>
                   <p>
                     You need only fill in the boxes appropriate to your partnership. You
                     should enter a valid Unique Taxpayer Reference (UTR) for each partner in
                     box 8 on the Partnership Statement. For partners who are not resident in
                     the UK, Requests to register and obtain a UTR for either a
                     UK resident partner or a non-UK resident partner
                     should be made using:
                   </p>
                   <ul>
                     <li>form SA401 for an individual</li>
                     <li>
                       form SA402 for a partner who is not an
                       individual
                     </li>
                   </ul>
                   <p>go to <a href="http://www.gov.uk/registerforataxreturn" target="_blank" class="link">www.gov.uk/registerforataxreturn</a></p>
                   <p>
                     You must also allocate each item in the summary of the partnership profit to the
                     partners. A summary of the shares allocated to each partner is needed for each
                     partner who was a member of the partnership during the period (or periods)
                     covered by the Partnership Tax Return. Each partner will also need this same
                     information to fill in their personal tax return for {{this.userService.Previous_year}} to {{this.userService.Present_year}}
                     (or for {{this.userService.Previous_year}} to {{this.userService.Previous_year}} if they ceased to be a partner before 6 April {{this.userService.Previous_year}} but during the return
                     period covered by the Partnership Tax Return).
                   </p>
                   <p>
                     If the profits are shared between the partners on a simple percentage
                     basis (for example, 50% each), then HMRC do not need any more information.
                     HMRC can work out the profit sharing arrangement from the allocated shares
                     if HMRC need to correct any mistakes.
                   </p>
                   <p>
                     If the profits are not shared on a simple percentage
                     basis, for example, if a partner is entitled to a
                     fixed share or salary, give details of the profit
                     sharing arrangements in box 3.116 'Additional
                     information'on page 3  of the Partnership
                     Tax Return.
                   </p>
                   <p>
                     The green text on the Partnership Statement
                     indicates where the shares of profit (and related
                     items) must be entered in each partner's own
                     personal tax return. A copy of the Partnership
                     Statement, or the section of the Statement
                     containing the summary of the shares allocated to
                     a particular partner, will usually provide all the
                     information partners need to fill in the Partnership
                     pages of their own tax return. (You may need to
                     provide partners with more detailed or additional
                     information on foreign income and disposals of
                     chargeable assets.)
                   </p>

                   <p><b>How to allocate profit to the partners</b></p>
                   <p>
                     In the first instance the allocation of profit
                     (or related item) should usually follow the
                     commercial profit sharing arrangement for the
                     period for which that profit (or related item) has
                     been returned.
                   </p>
                   <p>However, there are special rules for 2 situations:</p>
                   <ul>
                     <li>
                       where the commercial allocation produces a
                       mixture of notional profits and losses
                     </li>
                     <li>where the new 'mixed membership' rules apply</li>
                   </ul>
                   <p class="headingSA">Mixture of notional profits and losses</p>
                   <p><b>Trade and professional income</b></p>
                   <p>
                     The allocation of profit (or related item) should,
                     in the first instance, follow the commercial profit
                     sharing arrangement for the accounting period
                     for which the trade or professional income has
                     been returned.
                   </p>
                   <p>For each partner enter the total share allocated in the box provided.</p>
                   <table bgcolor="#DEE2EE" border="0" bordercolor="#111111" cellpadding="5" cellspacing="0" width="80%">
                     <tr>
                       <td width="100%"><strong>Example 4</strong></td>
                     </tr>
                     <tr>
                       <td width="100%">
                         A partnership consisting of Mr Jones and Mrs Smith
                         made a trading profit of £10,000. So the entry in box 11 of the
                         Partnership Statement is £10,000.
                       </td>
                     </tr>
                     <tr>
                       <td width="100%">
                         Mrs Smith was entitled under the terms of their
                         partnership agreement to a salary of £2,000 with the balance of
                         profits being shared equally, that is:
                       </td>
                     </tr>
                     <tr>
                       <td width="100%">£10,000 - £2,000 = £8,000 x 50% = £4,000 each.</td>
                     </tr>
                     <tr>
                       <td width="100%">
                         For Mr Jones the total profit allocated is £4,000
                         and is entered in box 11 in Mr Jones&#39;s Statement.
                       </td>
                     </tr>
                     <tr>
                       <td width="100%">
                         For Mrs Smith, the total profit allocated is: £2,000
                         + £4,000 = £6,000 and is entered in box 11 in Mrs Smith&#39;s Statement.
                       </td>
                     </tr>
                     <tr>
                       <td width="100%">
                         The &#39;Additional information&#39; box, box 3.116 on  page 3 of the Partnership Tax Return should be completed to show
                         that Mrs Smith is entitled to a salary of £2,000 with the balance
                         of profits being shared equally.
                       </td>
                     </tr>
                   </table>
                   <p>
                     However, the allocation process cannot create
                     or increase a loss. For tax purposes the allocation
                     of profit (or loss) between partners must result
                     in a straight apportionment of the actual profit
                     (or loss) made by the partnership. If the initial
                     allocation using the commercial profit sharing
                     arrangement for all the partners produces a
                     mixture of notional profits and losses, the actual
                     partnership profit (or loss) must be reallocated
                     between the profit-making (or loss-making)
                     partners alone. This reallocation is made in
                     proportion to the notional profit (or loss) initially
                     allocated to those partners.
                   </p>
                  
                     <table bgcolor="#DEE2EE" border="0" cellpadding="5" cellspacing="0" width="80%">
                       <tr>
                         <td colspan="9" width="100%"><strong>Example 5</strong></td>
                       </tr>
                       <tr>
                         <td colspan="9" width="100%">
                           Mr Red, Ms White and Mrs Blue are in
                           partnership and made a trading profit of £8,900, so the entry in
                           box 11 of the Partnership Statement is £8,900.
                         </td>
                       </tr>
                       <tr>
                         <td colspan="9" width="100%">
                           Mr Red and Ms White are entitled to
                           salaries of £5,200. The balance of profits are shared equally. The
                           partnership profits would initially be allocated as follows:
                         </td>
                       </tr>
                       <tr>
                         <td align="center" width="25%">&nbsp;</td>
                         <td align="center" width="10%"><b>Red</b></td>
                         <td align="center" width="3%">&nbsp;</td>
                         <td align="center" width="10%"><b>White</b></td>
                         <td align="center" width="4%">&nbsp;</td>
                         <td align="center" width="10%"><b>Blue</b></td>
                         <td align="center" width="3%">&nbsp;</td>
                         <td align="center" width="10%"><b>Total</b></td>
                         <td align="center" width="15%">&nbsp;</td>
                       </tr>
                       <tr>
                         <td style="height: 26px; width: 142px;">Partnership profit</td>
                         <td style="height: 26px">&nbsp;</td>
                         <td style="height: 26px">&nbsp;</td>
                         <td style="height: 26px">&nbsp;</td>
                         <td style="height: 26px">&nbsp;</td>
                         <td style="height: 26px">&nbsp;</td>
                         <td style="height: 26px">&nbsp;</td>
                         <td align="right" style="height: 26px">£8,900</td>
                         <td style="height: 26px"></td>
                       </tr>
                       <tr>
                         <td style="width: 142px">Salary</td>
                         <td align="right">£5,200</td>
                         <td>&nbsp;</td>
                         <td align="right">£5,200</td>
                         <td>&nbsp;</td>
                         <td>nil</td>
                         <td>&nbsp;</td>
                         <td align="right">£10,400</td>
                         <td>&nbsp;</td>
                       </tr>
                       <tr>
                         <td style="width: 142px">Balance of profit</td>
                         <td align="right">(£500)</td>
                         <td>&nbsp;</td>
                         <td align="right">(£500)</td>
                         <td>&nbsp;</td>
                         <td align="right">(£500)</td>
                         <td>&nbsp;</td>
                         <td align="right">(£1,500)</td>
                         <td>&nbsp;</td>
                       </tr>
                       <tr>
                         <td style="width: 142px"><b>Net Allocated</b></td>
                         <td align="right" style="border-top-style: solid; border-top-width: 1" width="15%">
                           <b>£4,700</b>
                         </td>
                         <td>&nbsp;</td>
                         <td align="right" style="border-top-style: solid; border-top-width: 1" width="15%">
                           <b>£4,700</b>
                         </td>
                         <td>&nbsp;</td>
                         <td align="right" style="border-top-style: solid; border-top-width: 1" width="15%">
                           <b>(£500)</b>
                         </td>
                         <td>&nbsp;</td>
                         <td align="right" style="border-top-style: solid; border-top-width: 1" width="15%">
                           <b>£8,900</b>
                         </td>
                         <td>&nbsp;</td>
                       </tr>
                       <tr>
                         <td colspan="8" width="100%">
                           But the aggregate notional profit allocated
                           to Mr Red and Ms White is £9,400 (not £8,900, the profit actually
                           made by the partnership), whilst Mrs Blue has been allocated a notional
                           loss of (£500), even though no loss was actually made by the partnership.
                         </td>
                         <td width="100%">&nbsp;</td>
                       </tr>
                       <tr>
                         <td colspan="8" width="100%">
                           So the actual partnership profits must
                           be reallocated between the profit making partners. The reallocation
                           is made in proportion to the notional profit initially allocated
                           to each partner: that is, in the ratio:
                         </td>
                         <td width="100%">&nbsp;</td>
                       </tr>
                       <tr>
                         <td style="width: 142px">&nbsp;</td>
                         <td align="center">(4700)</td>
                         <td align="center" width="3%"><b>:</b></td>
                         <td align="center">(4700)</td>
                         <td align="center" width="4%">or</td>
                         <td align="center" width="15%">50%</td>
                         <td align="center" width="3%"><b>:</b></td>
                         <td align="center" width="15%">50%</td>
                         <td align="center" width="15%">&nbsp;</td>
                       </tr>
                       <tr>
                         <td style="width: 142px">&nbsp;</td>
                         <td align="center" style="border-top-style: solid; border-top-width: 1" width="15%">
                           (9400)
                         </td>
                         <td align="center" width="3%">&nbsp;</td>
                         <td align="center" style="border-top-style: solid; border-top-width: 1" width="15%">
                           (9400)
                         </td>
                         <td align="center" width="4%">&nbsp;</td>
                         <td align="center" width="15%">&nbsp;</td>
                         <td align="center" width="3%">&nbsp;</td>
                         <td align="center" width="15%">&nbsp;</td>
                         <td align="center" width="15%">&nbsp;</td>
                       </tr>
                       <tr>
                         <td style="width: 142px">&nbsp;</td>
                         <td align="center" width="15%"><b>Red</b></td>
                         <td align="center" width="3%">&nbsp;</td>
                         <td align="center" width="15%"><b>White</b></td>
                         <td align="center" width="4%">&nbsp;</td>
                         <td align="center" width="15%"><b>Blue</b></td>
                         <td align="center" width="3%">&nbsp;</td>
                         <td align="center" width="15%"><b>Total</b></td>
                         <td align="center" width="15%">&nbsp;</td>
                       </tr>
                       <tr>
                         <td style="width: 142px">Net Allocated</td>
                         <td class="style2">£4,700</td>
                         <td class="style2">&nbsp;</td>
                         <td class="style2">£4,700</td>
                         <td class="style2">&nbsp;</td>
                         <td class="style2">(£500)</td>
                         <td class="style2">&nbsp;</td>
                         <td class="style2">£8,900</td>
                         <td>&nbsp;</td>
                       </tr>
                       <tr>
                         <td style="width: 142px">Percentage</td>
                         <td class="style2">(50%)</td>
                         <td class="style2">&nbsp;</td>
                         <td class="style2">(50%)</td>
                         <td class="style2">&nbsp;</td>
                         <td class="style2">(0%)</td>
                         <td class="style2">&nbsp;</td>
                         <td class="style2">&nbsp;</td>
                         <td>&nbsp;</td>
                       </tr>
                       <tr>
                         <td style="width: 142px"><b>Re-allocated</b></td>
                         <td class="style1">£4,450</td>
                         <td class="style2">&nbsp;</td>
                         <td class="style1">£4,450</td>
                         <td class="style2">&nbsp;</td>
                         <td class="style1">nil</td>
                         <td class="style2">&nbsp;</td>
                         <td class="style1">£8,900</td>
                         <td>&nbsp;</td>
                       </tr>
                       <tr>
                         <td colspan="8" width="100%">
                           For Mr Red and Ms White this reallocation
                           is equivalent to a 50% share of the balance of profit - in this
                           case a loss - (£750). <br>For Mrs Blue this reallocation is equivalent
                           to a 0% share of the balance of profit, £0.
                         </td>
                         <td width="100%">&nbsp;</td>
                       </tr>
                       <tr>
                         <td colspan="8" width="100%">
                           For Mr Red (and Ms White) the total
                           profit allocated is £5,200 minus £750 = £4,450 and is entered in
                           their Statements.
                         </td>
                         <td width="100%">&nbsp;</td>
                       </tr>
                       <tr>
                         <td colspan="8" width="100%">
                           For Mrs Blue the total profit allocated
                           is 0 + 0 = 0 and is entered in her Statement. (Of course Mrs Blue
                           may be entitled to a share in some other source of income.)
                         </td>
                         <td width="100%">&nbsp;</td>
                       </tr>
                       <tr>
                         <td colspan="8" width="100%">
                           The &#39;Additional information&#39; box the Partnership Tax Return should be completed to explain
                           that Mr Red and Ms White are entitled to shares of £5,200 and the
                           balance of profits are shared equally.
                         </td>
                         <td width="100%">&nbsp;</td>
                       </tr>
                       <tr>
                         <td colspan="8" width="100%">
                           If you require further advice on how
                           to allocate profits (or losses), please ask HMRC or your tax adviser.
                         </td>
                         <td width="100%">&nbsp;</td>
                       </tr>
                     </table>
                

                   <p><strong>Mixed membership partnership rules</strong>
                   <p>
                     New rules in Finance Act 2014 require the
                     reallocation of profits or losses under certain
                     circumstances. A mixed membership partnership
                     is a partnership which has both individual and
                     non-individual members. Further guidance on
                     the application of these rules can be found by
                     searching for 'mixed membership partnership', go
                     to <a href="http://www.gov.uk" target="_blank" class="link">www.gov.uk</a>
                   <p>
                     If you are unsure about the applicability of
                     these rules, or how to allocate profits or losses
                     when they apply, then ask HMRC, or contact your
                     tax adviser.

                   <p><b>Other types of income, and related items</b></p>
                   <p>
                     The allocation of any other item of profit (or a related item such as
                     a tax credit) should, in the first instance, follow the commercial profit
                     sharing arrangement for the period for which that profit (or related
                     item) has been returned.
                   </p>
                   <p>
                     If the profit (or related item) has been returned for an accounting period,
                     then the allocation should be made to the partners who were partners during
                     that accounting period.
                   </p>
                   <p>
                     If the profit (or related item) has been returned
                     for the period 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}, then
                     the allocation should be made to the partners who
                     were partners during the period 6 April {{this.userService.Previous_year}} to 5 April {{this.userService.Present_year}}.
                   </p>
                   <p>
                     Where exceptionally this process produces a
                     mixture of notional profits and losses, the special
                     rules apply (see aside and the example above).
                   </p>
                   <p>
                     However, in most cases partnership income other
                     than from a trade or profession is shared on a
                     straight apportionment basis in the same ratio
                     as applies to the balance of trade or
                     professional income.
                   </p>
                   <p>
                     <b>
                       Examples of allocations for different types of income (and related
                       items)
                     </b>
                   </p>
                   <p><b>Trade and professional income</b></p>
                   <table bgcolor="#DEE2EE" border="0" bordercolor="#111111" cellpadding="5" style="margin-left: 54px; width:60%;">
                     <tr>
                       <td colspan="3" width="100%"><strong>Example 6</strong></td>
                     </tr>
                     <tr>
                       <td colspan="3" width="100%">
                         Richard and Jonathan are in partnership
                         as carpenters. The results recorded in the Partnership Trading Pages
                         are as follows:
                       </td>
                     </tr>
                     <tr>
                       <td width="40%">Profit</td>
                       <td align="center">box 3.83</td>
                       <td align="right">£18,000</td>
                     </tr>
                     <tr>
                       <td width="40%">&#39;CIS deductions&#39;</td>
                       <td align="center">box 3.97</td>
                       <td align="right">£6,000</td>
                     </tr>
                     <tr>
                       <td colspan="3" width="100%">
                         In the summary of partnership profits
                         £18,000 is entered in box 11 and £6,000 in box 24.
                       </td>
                     </tr>
                     <tr>
                       <td colspan="3" width="100%">
                         The profits and &#39;CIS deductions&#39; are
                         allocated in the ratio two-thirds: one-third, as follows:
                       </td>
                     </tr>
                     <tr>
                       <td width="40%">&nbsp;</td>
                       <td align="center">Richard</td>
                       <td align="center">Jonathan</td>
                     </tr>
                     <tr>
                       <td width="40%">Profit</td>
                       <td align="center">£12,000</td>
                       <td align="center">£6,000</td>
                     </tr>
                     <tr>
                       <td width="40%">&#39;CIS deductions&#39;</td>
                       <td align="center">£4,000</td>
                       <td align="center">£2,000</td>
                     </tr>
                     <tr>
                       <td colspan="3" width="100%">
                         In the Statement for Richard, £12,000
                         will be entered in box 11 and £4,000 in box 24. In the Statement
                         for Jonathan, £6,000 will be entered in box 11 and £2,000 in box
                         24.
                       </td>
                     </tr>
                   </table>

                   <table width="748" border="0" cellpadding="5" cellspacing="0" bordercolor="#111111" bgcolor="#DEE2EE" id="" style="margin-left:54px;">
                     <tr>
                       <td colspan="4" width="100%"><b>Example 7</b></td>
                     </tr>
                     <tr>
                       <td colspan="4" width="100%">
                         Julia and Caroline are in partnership.
                         Their business activities comprise two trades; running a village
                         shop and running the tea rooms at a local stately home. The results
                         from each activity are recorded in separate sets of Partnership
                         Trading Pages as follows:
                       </td>
                     </tr>
                     <tr>
                       <td>Village shop</td>
                       <td width="20%">loss</td>
                       <td align="center" width="25%">in box 3.84</td>
                       <td align="center" width="25%">(£12,000)</td>
                     </tr>
                     <tr>
                       <td>Tea rooms</td>
                       <td width="20%">profit</td>
                       <td align="center" width="25%">in box 3.83</td>
                       <td align="center" width="25%">£22,500</td>
                     </tr>
                     <tr>
                       <td colspan="4" width="100%">
                         Separate Partnership Statements are
                         required for each trade. The summary for the village shop will show
                         (£12,000) at box 12; the summary for the tea rooms will show £22,500
                         at box 11.
                       </td>
                     </tr>
                     <tr>
                       <td colspan="4" width="100%">
                         The profits and losses are allocated
                         equally, as follows:
                       </td>
                     </tr>
                     <tr>
                       <td>&nbsp;</td>
                       <td width="20%">&nbsp;</td>
                       <td align="center" width="25%"><b>Julia</b></td>
                       <td align="center" width="25%"><b>Caroline</b></td>
                     </tr>
                     <tr>
                       <td>Village shop</td>
                       <td width="20%">loss</td>
                       <td align="center" width="25%">(£6,000)</td>
                       <td align="center" width="25%">(£6,000)</td>
                     </tr>
                     <tr>
                       <td>Tea rooms</td>
                       <td width="20%">profit</td>
                       <td align="center" width="25%">£11,250</td>
                       <td align="center" width="25%">£11,250</td>
                     </tr>
                     <tr>
                       <td colspan="4" width="100%">
                         Two summaries of partner&#39;s share will
                         be required for each partner. On one a loss of £6,000 will be entered
                         in box 12, on the other a profit of £11,250 will be entered in box
                         11.
                       </td>
                     </tr>
                   </table>
                   <p><b>Savings, investments and other income</b></p>
                   <p><b>Foreign income</b></p>
                   <p>
                     An essential feature of the allowance of relief for foreign tax paid is the need to separately
                     identify each and every item of overseas income so that each partner can claim tax relief for their
                     share of any foreign tax paid.
                   </p>
                   <table id="" bgcolor="#DEE2EE" border="0" bordercolor="#111111" cellpadding="5" cellspacing="0" style="margin-left: 54px; width:60%;">
                     <tr>
                       <td colspan="3" width="100%"><b>Example 8</b></td>
                     </tr>
                     <tr>
                       <td colspan="3" width="100%">
                         The entries on the Partnership Foreign
                         Pages may include:
                       </td>
                     </tr>
                     <tr>
                       <td width="50%">Nature of income</td>
                       <td align="center" width="25%">Foreign profits</td>
                       <td align="center" width="25%">Foreign tax paid</td>
                     </tr>
                     <tr>
                       <td width="50%">Dividends (Company X)</td>
                       <td align="center" width="25%">£1,200</td>
                       <td align="center" width="25%">£150</td>
                     </tr>
                     <tr>
                       <td width="50%">Dividends (Company Y)</td>
                       <td align="center" width="25%">&nbsp;&nbsp; £600</td>
                       <td align="center" width="25%">£150</td>
                     </tr>
                     <tr>
                       <td width="50%">Interest (Company N)</td>
                       <td align="center" width="25%">£3,000</td>
                       <td align="center" width="25%">£1,500</td>
                     </tr>
                     <tr>
                       <td colspan="3" width="100%">
                         Allocate to each partner their share
                         of each item of foreign income (and the associated tax credit).
                         For example, if the partnership has three partners entitled to equal
                         shares of profits:
                       </td>
                     </tr>
                     <tr>
                       <td width="50%"><strong>Nature of income</strong></td>
                       <td align="center" width="25%">Share of foreign profits</td>
                       <td align="center" width="25%">Share of foreign tax paid</td>
                     </tr>
                     <tr>
                       <td width="50%">Dividends (Company X)</td>
                       <td align="center" width="25%">£400</td>
                       <td align="center" width="25%">£50</td>
                     </tr>
                     <tr>
                       <td width="50%">Dividends (Company Y)</td>
                       <td align="center" width="25%">£200</td>
                       <td align="center" width="25%">£50</td>
                     </tr>
                     <tr>
                       <td width="50%">Interest (Company N)</td>
                       <td align="center" width="25%">£1,000</td>
                       <td align="center" width="25%">£500</td>
                     </tr>
                   </table>
                   <p>
                     When you have allocated the foreign income and
                     foreign credits on a country-by-country basis you
                     should let each partner have details in the format
                     shown above. You should let HMRC have a copy of
                     the allocation to the partners in the same format
                     in box 3.116, 'Additional information' of the Partnership Tax Return.
                   </p>
                   <table id="AutoNumber12" bgcolor="#DEE2EE" border="0" bordercolor="#111111" cellpadding="5" cellspacing="0" style="margin-left: 54px; width:60%;">
                     <tr>
                       <td colspan="4" width="100%"><strong>Example 9</strong></td>
                     </tr>
                     <tr>
                       <td colspan="4" width="100%">
                         John, Hugh and David are in partnership.
                         They share profits equally. In the year ended 31 December 2013 the
                         partnership, in addition to its trading profits, received income
                         from the letting of a property situated abroad. The partnership
                         made a profit of £12,000 from the letting and foreign tax of £3,000
                         was paid on this.
                       </td>
                     </tr>
                     <tr>
                       <td colspan="4" width="100%">
                         PF3 is completed and £12,000 is
                         entered in column B on PF2 against the entry &#39;Income from land
                         and property&#39; and the tax of £3,000 in column D. £12,000 should
                         also be entered in box 2.7 and £3,000 in box 2.8.
                       </td>
                     </tr>
                     <tr>
                       <td colspan="4" width="100%">
                         These figures are then allocated amongst
                         the partners as follows:
                       </td>
                     </tr>
                     <tr>
                       <td width="40%">&nbsp;</td>
                       <td align="center" width="20%"><b>John</b></td>
                       <td align="center" width="20%"><b>Hugh</b></td>
                       <td align="center" width="20%"><b>David</b></td>
                     </tr>
                     <tr>
                       <td width="40%">Income from land and property</td>
                       <td align="center" width="20%">£4,000</td>
                       <td align="center" width="20%">£4,000</td>
                       <td align="center" width="20%">£4,000</td>
                     </tr>
                     <tr>
                       <td width="40%">Foreign tax</td>
                       <td align="center" width="20%">£1,000</td>
                       <td align="center" width="20%">£1,000</td>
                       <td align="center" width="20%">£1,000</td>
                     </tr>
                     <tr>
                       <td colspan="4" width="100%">
                         £4,000 should be entered in box 17
                         in the summary of partner&#39;s share for each partner, and £1,000 should
                         be entered in box 28. £12,000 is entered in box 17 and £3,000 in
                         box 28 of the Partnership Statement (Full).
                       </td>
                     </tr>
                   </table>
                   <p><b>Rental business</b></p>
                   <table id="AutoNumber13" bgcolor="#DEE2EE" border="0" bordercolor="#111111" cellpadding="5" cellspacing="0" style="margin-left: 54px; width:60%;">
                     <tr>
                       <td colspan="4" width="100%"><strong>Example 10</strong></td>
                     </tr>
                     <tr>
                       <td colspan="4" width="100%">Nigel, Tom and Alice are in partnership.</td>
                     </tr>
                     <tr>
                       <td colspan="4" width="100%">
                         In the year ended 5 April 2013 they
                         have property income as follows:
                       </td>
                     </tr>
                     <tr>
                       <td colspan="2" width="60%">Furnished holiday lettings</td>
                       <td width="20%">profit</td>
                       <td width="20%">£12,000</td>
                     </tr>
                     <tr>
                       <td colspan="2" width="60%">Furnished lettings</td>
                       <td width="20%">profit</td>
                       <td width="20%">£3,000</td>
                     </tr>
                     <tr>
                       <td colspan="2" width="60%">Unfurnished lettings</td>
                       <td width="20%">profit</td>
                       <td width="20%">£6,000</td>
                     </tr>
                     <tr>
                       <td colspan="4" width="100%">They share profits and losses equally.</td>
                     </tr>
                     <tr>
                       <td colspan="4" width="100%">
                         A completed set of PL1 and PL2
                         for Nigel, Tom and Alice will have box entries as follows:
                       </td>
                     </tr>
                     <tr>
                       <td width="40%">&nbsp;</td>
                       <td width="20%">&nbsp;</td>
                       <td width="20%">box 1.16</td>
                       <td width="20%">£12,000</td>
                     </tr>
                     <tr>
                       <td width="40%">&nbsp;</td>
                       <td width="20%">&nbsp;</td>
                       <td width="20%">box 1.39</td>
                       <td width="20%">£9,000</td>
                     </tr>
                     <tr>
                       <td colspan="4" width="100%">
                         The amount entered in box 20 in the
                         Partnership Statement is  £12,000 and in box 19 is £9,000.
                       </td>
                     </tr>
                     <tr>
                       <td colspan="4" width="100%">
                         The profits and losses are allocated
                         as follows:
                       </td>
                     </tr>
                     <tr>
                       <td width="40%">&nbsp;</td>
                       <td align="center" width="20%"><b>Nigel</b></td>
                       <td align="center" width="20%"><b>Torn</b></td>
                       <td align="center" width="20%"><b>Alice</b></td>
                     </tr>
                     <tr>
                       <td width="40%">Furnished holiday lettings profit</td>
                       <td align="center" width="20%">£4,000</td>
                       <td align="center" width="20%">£4,000</td>
                       <td align="center" width="20%">£4,000</td>
                     </tr>
                     <tr>
                       <td width="40%">Rental profit</td>
                       <td align="center" width="20%">£3,000</td>
                       <td align="center" width="20%">£3,000</td>
                       <td align="center" width="20%">£3,000</td>
                     </tr>
                     <tr>
                       <td colspan="4" width="100%">
                         The entries required in the <em>
                           Partnership
                           Statement (Full)
                         </em> are the same for each partner. An entry of  £4,000
                         is made in box 20 and an entry of £3,000 in box 19.
                       </td>
                     </tr>
                   </table>

                   <p><b>Disposal of chargeable assets</b></p>
                   <p>
                     Although you only need to enter the total
                     proceeds allocated to each partner in box 30 of
                     the Partnership Statement (full), the partner will
                     need the details of each disposal to calculate any
                     tax due.
                   </p>
                   <table id="AutoNumber14" bgcolor="#DEE2EE" border="0" bordercolor="#111111" cellpadding="5" cellspacing="0" style="margin-left: 54px; width:60%;">
                     <tr>
                       <td colspan="4" width="100%"><b>Example 11</b></td>
                     </tr>
                     <tr>
                       <td colspan="4" width="100%">
                         Sue, Bob and Anna are in partnership.
                         During the year {{this.userService.Previous_Previousyear}} to {{this.userService.Previous_year}} the partnership disposes of three chargeable
                         assets:
                       </td>
                     </tr>
                     <tr>
                       <td width="40%">Asset 1</td>
                       <td width="20%">£10,000</td>
                       <td width="20%">&nbsp;</td>
                       <td width="20%">&nbsp;</td>
                     </tr>
                     <tr>
                       <td width="40%">Asset 2</td>
                       <td width="20%">£7,000</td>
                       <td width="20%">&nbsp;</td>
                       <td width="20%">&nbsp;</td>
                     </tr>
                     <tr>
                       <td width="40%">Asset 3</td>
                       <td width="20%">£21,000</td>
                       <td width="20%">&nbsp;</td>
                       <td width="20%">&nbsp;</td>
                     </tr>
                     <tr>
                       <td width="40%" style="border-top:1px #c6c6c6 solid;">Total proceeds</td>
                       <td width="20%" style="border-top:1px #c6c6c6 solid;">£38,000</td>
                       <td width="20%">&nbsp;</td>
                       <td width="20%">&nbsp;</td>
                     </tr>
                     <tr>
                       <td colspan="4" width="100%">
                         The amount to be entered in box 4.1
                         on PA1 and in box 30 in the summary of partnership&#39;s profits
                         is £38,000.
                       </td>
                     </tr>
                     <tr>
                       <td colspan="4" width="100%">
                         Sue, Bob and Anna had shares in these
                         three assets as follows:
                       </td>
                     </tr>
                     <tr>
                       <td width="40%">&nbsp;</td>
                       <td width="20%"><b>Sue</b></td>
                       <td width="20%"><b>Bob</b></td>
                       <td width="20%"><b>Anna</b></td>
                     </tr>
                     <tr>
                       <td width="40%">Asset 1</td>
                       <td width="20%">3/4</td>
                       <td width="20%">1/4</td>
                       <td width="20%">nil</td>
                     </tr>
                     <tr>
                       <td width="40%">Asset 2</td>
                       <td width="20%">1/2</td>
                       <td width="20%">1/2</td>
                       <td width="20%">nil</td>
                     </tr>
                     <tr>
                       <td width="40%">Asset 3</td>
                       <td width="20%">1/3</td>
                       <td width="20%">1/3</td>
                       <td width="20%">1/3</td>
                     </tr>
                     <tr>
                       <td colspan="4" width="100%">
                         The disposal proceeds must be allocated
                         as follows:
                       </td>
                     </tr>
                     <tr>
                       <td width="40%">&nbsp;</td>
                       <td width="20%"><b>Sue</b></td>
                       <td width="20%"><b>Bob</b></td>
                       <td width="20%"><b>Anna</b></td>
                     </tr>
                     <tr>
                       <td width="40%">Asset 1</td>
                       <td width="20%">£7,500</td>
                       <td width="20%">£2,500</td>
                       <td width="20%">0</td>
                     </tr>
                     <tr>
                       <td width="40%">Asset 2</td>
                       <td width="20%">£3,500</td>
                       <td width="20%">£3,500</td>
                       <td width="20%">0</td>
                     </tr>
                     <tr>
                       <td width="40%">Asset 3</td>
                       <td width="20%">£7,000</td>
                       <td width="20%">£7,000</td>
                       <td width="20%">£7,000</td>
                     </tr>
                     <tr>
                       <td colspan="4" width="100%">
                         The amounts entered in the summaries
                         of partner&#39;s share in box 30 in the Statement for each partner are:
                       </td>
                     </tr>
                     <tr>
                       <td width="40%">Sue</td>
                       <td width="20%">£18,000</td>
                       <td width="20%">&nbsp;</td>
                       <td width="20%">&nbsp;</td>
                     </tr>
                     <tr>
                       <td width="40%">Bob</td>
                       <td width="20%">£13,000</td>
                       <td width="20%">&nbsp;</td>
                       <td width="20%">&nbsp;</td>
                     </tr>
                     <tr>
                       <td width="40%">Anna</td>
                       <td width="20%">£7,000</td>
                       <td width="20%">&nbsp;</td>
                       <td width="20%">&nbsp;</td>
                     </tr>
                   </table>
                   
                   <hr>
                   <table border="0" cellpadding="5" cellspacing="0" style="text-align: justify; border: 1px #000 solid;" width="90%">
                     <tr>
                       <td>
                         These notes are for guidance only, and reflect the position
                         at the time of writing. They do not affect any rights of appeal.
                         Any subsequent amendments to these notes can be found at
                         <a class="link" href="http://www.hmrc.gov.uk" target="_blank">
                           www.hmrc.gov.uk
                         </a>
                       </td>
                     </tr>
                   </table>
                 </div>
              </div>
        </div>
      </div>
  </div>
</div>
</div>
