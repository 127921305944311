import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { dashboardModel } from './dashboardModel';
import { GetUser_Licence_Details } from '../dashboard/user-management/classdata';
import { Userfrm } from '../dashboard/user-management/classdata';
import { ConfigurationService } from '../../Configuration/configuration.service';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardServiceService {
  constructor(private http: HttpClient, private configurationService: ConfigurationService, public localService: UsersService ) { }
  url = this.configurationService.get().apiEndpoint + '/api/Dashboard';
  reporturl = this.configurationService.get().apiEndpoint + '/api/Reports';
  Taxadviserurl = this.configurationService.get().apiEndpoint + '/api/TaxAdviser';
  urlsubcription = this.configurationService.get().subscriptionURL + 'api/Values/GetPlansInformation?CentralUserID=';  //comment by kiran need to uncomment when subscription
  TaxadviserFlag: boolean
  getAllTaxpayer(Userid, TaxYear): Observable<dashboardModel[]> {
     
    return this.http.get<dashboardModel[]>(this.url + '/GetTaxPayer/' + Userid + '/' + TaxYear);
    
  }
  deleteEmployeeById(employeeid: string): Observable<number> {
     
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<number>(this.url + '/Delete_emp/' + employeeid,
      httpOptions);
  }

  getwebtotwebsupplyforms(id) {
    return this.http.get(this.url + '/webtowebsuplyforms/' + id);
  }

  UpdateOpendRecordStatusClose(UserLoginID: string): Observable<number> {
    debugger;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<number>(this.url + '/UpdateOpendRecordStatusToClose/' + UserLoginID,
      httpOptions);
  }

  IsRecordOpen(CreatedBy, TaxPayerId) {
    debugger;
    return this.http.get(this.url + '/IsRecordOpen/' + CreatedBy + '/' + TaxPayerId);
  }

  DeleteTaxadviserId(taxPayerId,TaxYear) {
    let headers = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8'
      })
    }
    return this.http.post(this.Taxadviserurl + '/DeleteTaxAdviser/' + taxPayerId + '/' + TaxYear, headers );
  }
  GetTaxadviserId(taxPayerId, TaxYear, MasterUserID) {
    
    return this.http.get(this.Taxadviserurl + '/GetTaxAdviserDetails/' + taxPayerId + '/' + TaxYear + '/' + MasterUserID)
  }

  Insert_Update_Tax_Adviser_Service(formData) {
     
    let headers = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8'
      })
    }
    return this.http.post(this.Taxadviserurl + '/Insert_Update_Tax_Adviser', formData, headers);
  }
  //GetReportModule(Userid, flag, download_flag, PartnershipName, isIrmark) {
  //  debugger
  //  let headers = {
  //    headers: new HttpHeaders({
  //      'Accept': 'application/json',
  //      'Content-Type': 'application/json; charset=UTF-8'
  //    })
  //  }
  //  console.log(this.reporturl);
  //  return this.http.get(this.reporturl + '/index/' + Userid + '/' + flag + '/' + download_flag + '/' + PartnershipName + '/' + isIrmark, headers)
  //}
  GetReportModule(CurrTaxPeriod,Userid, flag, download_flag, PartnershipName, isIrmark) {
     
    console.log(this.reporturl);
    return this.http.get(this.reporturl + '/index/' + CurrTaxPeriod + '/' + Userid + '/' + flag + '/' + download_flag + '/' + PartnershipName + '/' + isIrmark )
  }

  insertTime(data) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.url + '/Loginout', data, httpOptions);
  }

  GetUser_Licence_Details(id1, TaxYear): Observable<GetUser_Licence_Details> {
    return this.http.get<GetUser_Licence_Details>(this.url + '/Get_Master_Licence_Details/' + id1 + '/' + TaxYear);    //used by kiran Get_Master_Licence_Details insted of GET_User_Licence_Details_P bcoz API name changed.
  }
  // Checking Licence valid Add by kiran
  get_license_details(Masterid,TaxYear) {
    return this.http.get(this.url + '/Get_licensevalid/' + Masterid+ '/'+TaxYear);
  }
  IsUsernameExist(UserName, UserLoginID, TaxYear) {
    return this.http.get(this.url + '/IsUsernameExist/' + btoa(UserName) + '/' + UserLoginID + '/' + TaxYear);
  }

  InsertUserInfo(userInfoObj) {
     
    let headers = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8'
      })
    }
    return this.http.post(this.url + '/InsertUserInfo', userInfoObj, headers);
  }
  //Adde by kiran for azure async
  InsertUserManagement(userInfoObj) {
    let headers = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8'
      })
    }
    return this.http.post(this.url + '/ADCreateUserAsync', userInfoObj, headers);
  }
  getUserManagementDetails(MasterId, UserLoginId,TaxYear): Observable<Userfrm[]> {

    return this.http.get<Userfrm[]>(this.url + '/GetUserManagementDetails/' + MasterId + '/' + UserLoginId + '/' + TaxYear);
  }

  DeleteMultipleUserLoginHistory(ids) {
    const indexes = JSON.stringify({ "ids": ids });
    let headers = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8'
      })
    }
    return this.http.post(this.url + '/DeleteMultipleUserLoginHistory', indexes, headers);
  }

  Getsubscriptioncheck(CentralUserID) {                            //Commented by kiran need to uncomment At the time of subscription
    return this.http.get(this.urlsubcription + CentralUserID)
  }


  importdata(xml)
  {
    var taxyear = '';
    //if (xml.TaxYear == this.localService.Current_taxyear)
    //  taxyear = 'currentYear'
    //else
    //  taxyear = 'LatYear'
    //const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.url + '/importdata', xml);
  }

  importdatamdb(Mdb) {
    return this.http.post(this.url + '/importdatamdb', Mdb, { responseType:'text' });
  }

  importdataweb(xml) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.url + '/importdataweb', xml, httpOptions);
  }

  importDataStatus(Userid, TaxYear) {
    return this.http.get(this.url + '/ImportDataStatus/' + Userid + "/" + TaxYear)
  }

  importformsdata(formdata) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.url + '/insertsuplyforms', formdata, httpOptions)
  }

  GetSubstituteReportModule_ListofTaxPayers(CurrTaxPeriod,Flag) {
    debugger;
    return this.http.get(this.reporturl + '/GetTaxpayerList/' + CurrTaxPeriod + '/' + Flag + '/' + localStorage.getItem("MasterUserID"))
  }
  //GetSubstituteReportModule(Userid, flag, Reporttype, download_flag, PartnershipName, isIrmark) {
  //  let headers = {
  //    headers: new HttpHeaders({
  //      'Accept': 'application/json',
  //      'Content-Type': 'application/json; charset=UTF-8'
  //    })
  //  }



  //  return this.http.get(this.reporturl + '/Substitute_Index/' + Userid + '/' + flag + '/' + Reporttype + '/' + download_flag + '/' + PartnershipName + '/' + isIrmark, headers)
  //}
 

  GetSubstituteReportModule(CurrTaxPeriod,Userid, flag, Reporttype, download_flag, PartnershipName, isIrmark) {
     
    return this.http.get(this.reporturl + '/Substitute_Index/' + CurrTaxPeriod + '/' + Userid + '/' + flag + '/' + Reporttype + '/' + download_flag + '/' + PartnershipName + '/' + isIrmark)
  }
  GetPartnerDetail(CurrTaxPeriod,UserId, SAWizardFlag, fullFlag) {
     
    return this.http.get(this.reporturl + '/GetPartnerDetail/' + CurrTaxPeriod + '/' + UserId + '/' + SAWizardFlag + '/' + fullFlag)
  }
  GetsubModuleStatusByUser(RecordID) {
     
    return this.http.get(this.url + '/GetSubModulebyStatus/' + RecordID)
  }
  GetMasterUserId(UserMaster_Loginname) {
     
    return this.http.get(this.url + '/GET_User_Login_P/' + UserMaster_Loginname);
  }
  UpdateShowStartHeretatus(UserLoginID: string): Observable<number> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<number>(this.url + '/UpdateShowStartHereStatus/' + UserLoginID,
      httpOptions);
  }
  UpdateShowStartHeretatusClose(UserLoginID: string): Observable<number> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<number>(this.url + '/UpdateShowStartHereStatusClose/' + UserLoginID,
      httpOptions);
    //UpdateShowStartHeretatus(RecordID: string): Observable < number > {
    //  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    //  return this.http.post<number>(this.url + '/UpdateShowStartHereStatus/' + RecordID,
    //    httpOptions);
    //}
    //UpdateShowStartHeretatusClose(RecordID: string): Observable < number > {
    //  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    //  return this.http.post<number>(this.url + '/UpdateShowStartHereStatus/' + RecordID,
    //    httpOptions);
  }
  SaveShowbubble(recID: any) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.url + '/SaveShowBubble?recID=' + recID, httpOptions)
  }
  GetShowBubble(recID: any) {
    return this.http.get(this.url + '/getShowBubble?recID=' + recID)
  }

  GetIsMailSendFlag(email) {
    return this.http.get(this.url + '/GetIsMailSendFlag/' + email)
  }
  GetIsApprovedFlag(email) {
    return this.http.get(this.url + '/GetIsApprovedFlag/' + email)
  }
  InsertApprovedFlag(email: string): Observable<number> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<number>(this.url + '/InsertApprovedFlag/' + email,
      httpOptions);
  }

  SetExpiredFlagFalse(email: string): Observable<number> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<number>(this.url + '/SetExpiredFlagFalse/' + email,
      httpOptions);
  }
  
  InsertDeclinedFlag(email: string): Observable<number> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<number>(this.url + '/InsertDeclinedFlag/' + email,
      httpOptions);
  }

  


}
